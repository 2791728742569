export function validatePort(value: number) {
    return !(value > 0 && value < 65536) ? "Port must be between 1 and 65535" : undefined;
}

export function validateSourcePort(
    value: number,
    forwarding_rules: { source_port: number }[],
    rulesContainSelf: boolean,
) {
    const portResult = validatePort(value);
    if (portResult !== undefined) {
        return portResult;
    }

    const found = forwarding_rules.filter((item) => item.source_port === value);
    return (rulesContainSelf ? found.length > 1 : found.length > 0) ? "Source port must be unique" : undefined;
}
