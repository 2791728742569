import { useQuery } from "@apollo/client";
import { todoAction } from "../../dev/todoSubmit.ts";
import { useLogError } from "../../modules/error/useLogError.tsx";
import { AdminLinkButton } from "../AdminLinkButton.tsx";
import { Extra } from "../AdminTable.tsx";
import { gql } from "../graphql.gen/gql.ts";
import type { BillingAccount } from "../graphql.gen/graphql.ts";

export function BillingAccountBlock({
    value,
    showId,
}: {
    value: Pick<BillingAccount, "title" | "email" | "id">;
    showId?: boolean;
}) {
    const title = (
        <>
            {showId && <>[{value.id}] </>}
            {value.title}
        </>
    );

    const content = (
        <Extra className="max-w-300px text-ellipsis overflow-x-hidden" value={title}>
            {value.email}
        </Extra>
    );
    // TODO: Enable this when links are implemented
    const _link = <AdminLinkButton action={todoAction} label={content} />;
    return content;
}

BillingAccountBlock.fragment = gql(/* GraphQL */ `
    fragment BillingAccountBlock on BillingAccount {
        title
        email
        id
    }
`);

const BA_ID_QUERY = gql(/* GraphQL */ `
    query GetBillingAccountById($id: String!) {
        billing_account_details(id: $id) {
            ...BillingAccountBlock
        }
    }
`);

export function BillingAccountById({ value }: { value: BillingAccount["id"] | number }) {
    const id = String(value);

    const { data, error } = useQuery(BA_ID_QUERY, { variables: { id } });
    // TODO: Centralized logging of Apollo errors?
    useLogError(error, "Error loading billing account");

    return data ? (
        <BillingAccountBlock showId value={data.billing_account_details} />
    ) : (
        <BillingAccountBlock showId value={{ title: id, email: error?.message ?? "...", id }} />
    );
}
