import C from "./WMeter.module.css";

import type { ReactNode } from "react";
import { Label, Meter } from "react-aria-components";
import { cn } from "../utils/classNames.ts";
import { WTooltip, type WTooltipProps } from "./WTooltip.tsx";

export function WMeter({
    title,
    value,
    tooltip,
    block,
    grey,
}: {
    title?: ReactNode;
    /** Value between 0 and 100 */
    value: number;
    tooltip?: WTooltipProps["text"];
    block?: boolean;
    grey?: boolean;
}) {
    const item = (
        <Meter value={value} aria-label="Quantity" className={cn(C.Meter, block && C.block, grey && C.grey)}>
            {({ percentage, valueText }) => (
                <>
                    {!!title && <Label>{title}</Label>}
                    <span className={C.value}>{valueText}</span>
                    <div className={C.bar}>
                        <div className={C.fill} style={{ width: `${percentage}%` }} />
                    </div>
                </>
            )}
        </Meter>
    );

    return tooltip ? (
        <WTooltip reverse text={tooltip}>
            {item}
        </WTooltip>
    ) : (
        item
    );
}
