interface Promisable<T> extends PromiseLike<T> {
    promise: Promise<T>;
    state: "pending" | "resolved" | "rejected";
    result: T | undefined;
    error: unknown;
    resolve: (value: T) => void;
    reject: (error: unknown) => void;
}

export function makePromise<T>() {
    let resolve: (value: T) => void;
    let reject: (error: unknown) => void;
    const promise = new Promise<T>((res, rej) => {
        resolve = res;
        reject = rej;
    });
    const self: Promisable<T> = {
        promise,
        // biome-ignore lint/suspicious/noThenProperty: This is actually a promise
        then: promise.then.bind(promise),
        state: "pending",
        result: undefined,
        error: undefined,
        resolve: (v) => {
            resolve!(v);
            self.state = "resolved";
            self.result = v;
        },
        reject: (e) => {
            reject!(e);
            self.state = "rejected";
            self.error = e;
        },
    };
    return self;
}
