import type { ResourceTypePrices } from "@warrenio/api-spec/spec.oats.gen";
import { mustFindBy } from "../../mock-api/mustFindBy.ts";

export type PricingResourceType = ResourceTypePrices["resource_type"];

export interface PricingResourceItemType {
    type: PricingResourceType;
    title: string;
    unit: string;
    gradual?: "num_cpus_from" | "gigabytes_from";
    icon: string;
}

export const pricingResourceTypes: PricingResourceItemType[] = [
    {
        type: "CPU",
        title: "CPU",
        unit: "num cpus",
        gradual: "num_cpus_from",
        icon: "jp-virtual-machine-icon",
    },
    {
        type: "RAM",
        title: "RAM",
        unit: "gigabytes",
        gradual: "gigabytes_from",
        icon: "jp-pulse-icon",
    },
    {
        type: "BLOCK_STORAGE",
        title: "Block Storage",
        unit: "gigabytes",
        gradual: "gigabytes_from",
        icon: "jp-stack-icon",
    },
    {
        type: "BLOCK_STORAGE_BACKUP",
        title: "Block Storage Backup",
        unit: "gigabytes",
        gradual: "gigabytes_from",
        icon: "jp-time-reverse-icon",
    },
    {
        type: "BLOCK_STORAGE_SNAPSHOT",
        title: "Block Storage Snapshot",
        unit: "gigabytes",
        gradual: "gigabytes_from",
        icon: "jp-time-reverse-icon",
    },
    {
        type: "WINDOWS_LICENSE",
        title: "Windows License",
        unit: "",
        icon: "jp-windows-icon",
    },
    {
        type: "OBJECT_STORAGE",
        title: "Object Storage",
        unit: "",
        // gradual: "gigabytes_from",
        icon: "jp-block-storage-icon",
    },
    {
        type: "UNASSIGNED_FLOATING_IP",
        title: "Unassigned Floating IP",
        unit: "",
        icon: "jp-network-2-icon",
    },
    {
        type: "ASSIGNED_FLOATING_IP",
        title: "Assigned Floating IP",
        unit: "",
        icon: "jp-network-2-icon",
    },
    {
        type: "LOAD_BALANCER",
        title: "Load Balancer",
        unit: "",
        icon: "jp-load-balancer-icon",
    },
    {
        type: "BARE_METAL_SERVER",
        title: "Bare Metal Server",
        unit: "",
        icon: "jp-host-icon",
    },
];

export function getResourceTypeInfo(type: PricingResourceType): PricingResourceItemType {
    return mustFindBy(pricingResourceTypes, (item) => item.type, type, "resource type");
}
