import { useMatchRoute, useRouter } from "@tanstack/react-router";

export const adminEnabled = true;

export function useIsInAdminMode() {
    const router = useRouter();
    // TODO: Violates the rules of hooks, make a better fix
    if (!router) return;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const matchRoute = useMatchRoute();
    return !!matchRoute({ to: "/admin", fuzzy: true });
}
