import { z, type ZodRawShape } from "zod";
import { zfd } from "zod-form-data";
import { extendZodWithOpenApi, type ZodOpenApiPathsObject } from "zod-openapi";
import { errorResponse, formBody, formBoolean, successResponse } from "../util.ts";
import {
    accepted_at,
    billing_account_id,
    created_at,
    exampleEmail,
    int,
    simpleSuccessResponse,
    updated_at,
    user_id,
    uuid,
} from "./common.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

//#region Common fields/types

const user_email_type = z
    .string()
    // Do not validate email for "incoming" data since the backend allows invalid emails
    /* .email() */
    .describe("User's email")
    .openapi({ example: exampleEmail });

//#endregion

//#region API tokens
const ApiTokenFields = {
    id: int.describe("Token ID"),
    billing_account_id,
    consumer_id: uuid,
    kong_id: uuid,
    description: z.string(),
    restricted: z
        .boolean()
        .optional()
        .describe("Restricted token has limited access to only selected billing account resources."),
    token: z.string(),
    created_at,
    updated_at: updated_at.optional(),
    user_id,
    acting_user_id: int.optional(),
} as const satisfies ZodRawShape;

export const ApiToken = z.object(ApiTokenFields).openapi({ ref: "ApiToken" });

namespace form {
    export const billing_account_id = zfd.numeric(ApiTokenFields.billing_account_id.optional());
    export const restricted = formBoolean(ApiTokenFields.restricted.default(false));
}

export const ApiTokenCreateBody = z
    .object({
        billing_account_id: form.billing_account_id,
        description: ApiTokenFields.description,
        restricted: form.restricted,
    })
    .openapi({ ref: "ApiTokenCreateBody" });

export const ApiTokenModifyBody = z
    .object({
        token_id: zfd.numeric(ApiTokenFields.id),
        billing_account_id: form.billing_account_id,
        description: ApiTokenFields.description.optional(),
        restricted: form.restricted,
    })
    .openapi({ ref: "ApiTokenModifyBody" });

export const ApiTokenDeleteBody = z
    .object({
        token_id: zfd.numeric(ApiTokenFields.id),
    })
    .openapi({ ref: "ApiTokenDeleteBody" });

export const TokenListResponse = z.array(ApiToken);

//#endregion

//#region Access delegation
const AccessDelegationImpersonationFields = {
    accepted_at: accepted_at.optional(),
    created_at,
    grantee_id: int.optional(),
    // XXX: The backend allows invalid e-mails to be added, so we can't use `.email()` here
    grantee_username: user_email_type,
    is_accepted: z.boolean().describe("Has the user accepted the invitation?"),
    uuid,
} as const satisfies ZodRawShape;

export const AccessDelegationImpersonation = z
    .object(AccessDelegationImpersonationFields)
    .openapi({ ref: "AccessDelegationImpersonation" });

export const AccessDelegationImpersonationCreateBody = z
    .object({
        grantee_username: AccessDelegationImpersonationFields.grantee_username,
    })
    .openapi({ ref: "AccessDelegationImpersonationCreateBody" });

const AccessDelegationFields = {
    id: int.describe("Access delegation ID"),
    billing_account_id: billing_account_id.optional(),
    impersonations: z.array(AccessDelegationImpersonation),
    name: z.string(),
    owner_id: int, // uuid or int????
    created_at,
    updated_at: updated_at.optional(),
} as const satisfies ZodRawShape;

export const AccessDelegation = z.object(AccessDelegationFields).openapi({ ref: "AccessDelegation" });

export const AccessDelegationListResponse = z.array(AccessDelegation);

//#endregion

//#region Access impersonation
const AccessImpersonationFields = {
    accepted_at: accepted_at.optional(),
    created_at,
    access_owner: user_email_type,
    billing_account_name: z.string().optional(),
    is_accepted: z.boolean().describe("Has the user accepted the invitation?"),
    uuid,
} as const satisfies ZodRawShape;

export const AccessImpersonation = z.object(AccessImpersonationFields).openapi({ ref: "AccessImpersonation" });

export const AccessGroupCreateBody = z
    .object({
        billing_account_id: form.billing_account_id,
        name: z.string(),
    })
    .openapi({ ref: "AccessGroupCreateBody" });

export const AccessGroupAddEmailBody = z
    .object({
        // Validate email for "outgoing" data (see comment above)
        grantee_username: user_email_type.email(),
    })
    .openapi({ ref: "AccessGroupAddEmailsBody" });

export const AccessImpersonationListResponse = z.array(AccessImpersonation);

//#endregion

const delegation_id = z.string().openapi({ param: { name: "delegation_id", in: "path" } });
const impersonation_id = z.string().openapi({ param: { name: "impersonation_id", in: "path" } });

export const accessPaths: ZodOpenApiPathsObject = {
    "/user-resource/token/list": {
        get: {
            summary: "List user API tokens",
            tags: ["token"],
            responses: {
                ...successResponse(TokenListResponse),
            },
        },
    },
    "/user-resource/token": {
        post: {
            summary: "Create new token and register it at API Gateway",
            tags: ["token"],
            requestBody: formBody(ApiTokenCreateBody),
            responses: {
                ...successResponse(ApiToken, "Success: Created API token"),
                ...errorResponse("Failed to create kong token"),
            },
        },
        patch: {
            summary: "Update API token options",
            description: "Update token description or billing account",
            tags: ["token"],
            requestBody: formBody(ApiTokenModifyBody, {
                examples: {
                    "Update token description": {
                        value: {
                            token_id: 1,
                            description: "New description",
                        },
                    },
                    "Update token billing account": {
                        value: {
                            token_id: 1,
                            billing_account_id: 2,
                        },
                    },
                },
            }),
            responses: {
                ...successResponse(ApiToken, "Success: Updated API token"),
                ...errorResponse("Failed to update kong token"),
            },
        },
        delete: {
            summary: "Delete API token",
            tags: ["token"],
            requestBody: formBody(ApiTokenDeleteBody),
            responses: {
                ...simpleSuccessResponse,
                ...errorResponse("Failed to delete kong token"),
            },
        },
    },
    "/user-resource/access_group": {
        get: {
            summary: "List user Access delegations",
            tags: ["delegation"],
            responses: {
                ...successResponse(AccessDelegationListResponse),
            },
        },
        post: {
            summary: "Create new Access delegation group",
            tags: ["delegation"],
            requestBody: formBody(AccessGroupCreateBody),
            responses: {
                ...successResponse(AccessDelegation),
                ...errorResponse("Failed to create access delegation group"),
            },
        },
    },
    "/user-resource/access_group/{id}": {
        delete: {
            summary: "Delete Access delegations",
            tags: ["delegation"],
            parameters: [params.id],
            responses: {
                ...simpleSuccessResponse,
                ...errorResponse("Failed to delete access delegation"),
            },
        },
    },
    "/user-resource/access_group/{delegation_id}/impersonation": {
        post: {
            summary: "Creating Impersonation key",
            tags: ["delegation"],
            requestBody: formBody(AccessDelegationImpersonationCreateBody),
            parameters: [delegation_id],
            responses: {
                ...successResponse(AccessDelegationImpersonation),
                ...errorResponse("Failed to creating impersonation key"),
            },
        },
    },
    "/user-resource/access_group/{delegation_id}/impersonation/{impersonation_id}": {
        delete: {
            summary: "Delete Impersonation key",
            tags: ["delegation"],
            parameters: [delegation_id, impersonation_id],
            responses: {
                ...simpleSuccessResponse,
                ...errorResponse("Failed to delete impersonation key"),
            },
        },
    },
    "/user-resource/impersonation": {
        get: {
            summary: "List shared impersonations",
            tags: ["impersonation"],
            responses: {
                ...successResponse(AccessImpersonationListResponse),
            },
        },
    },
};
