import D from "./Dashboard.module.css";

import { useAtomValue } from "jotai/react";
import { ContentPane } from "../../components/ContentPane.tsx";
import { resourceTypesEnabledAtom } from "../../modules/api/resourceTypes.tsx";
import { useTitle } from "../../utils/react/useTitle.ts";
import { ComputeAllocationSection } from "./ComputeAllocationSection.tsx";
import { CustomersSection } from "./CustomersSection.tsx";
import { FloatingSection } from "./FloatingSection.tsx";
import { InvoicedRevenueSection } from "./InvoicedRevenueSection.tsx";
import { ManagedServicesSection } from "./ManagedServicesSection.tsx";
import { StorageSection } from "./StorageSection.tsx";
import { UsageBasedRevenueSection } from "./UsageBasedRevenueSection.tsx";
import { UserConversionSection } from "./UserConversionSection.tsx";
import { VirtualMachinesSection } from "./VirtualMachinesSection.tsx";

export function DashboardView() {
    useTitle("Dashboard");

    const enabled = useAtomValue(resourceTypesEnabledAtom);

    return (
        <ContentPane className={D.Dashboard}>
            {enabled.virtual_machine && <ComputeAllocationSection />}

            <UserConversionSection />

            <InvoicedRevenueSection />

            <CustomersSection />

            {enabled.virtual_machine && <VirtualMachinesSection />}

            {enabled.managed_service && <ManagedServicesSection />}

            {(enabled.bucket || enabled.virtual_machine || enabled.managed_service) && <StorageSection />}

            {enabled.ip_address && <FloatingSection />}

            <UsageBasedRevenueSection />
        </ContentPane>
    );
}
