import S from "../../components/Slider.module.css";

import { Suspense, useEffect, useState, type ChangeEvent, type ReactNode } from "react";
import { Label, Slider, SliderThumb, SliderTrack, Text } from "react-aria-components";
import { isDeepEqual } from "remeda";
import invariant from "tiny-invariant";
import type { SetOptional } from "type-fest";
import { Loading } from "../../components/loading/Loading.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { showWarn } from "../error/errorStream.ts";
import { pricesAtom } from "../pricing/query.ts";
import { getVmCreatePrice, type Price, type ResourcePrices, type VmPriceFields } from "../pricing/resourcePricing.ts";
import { SizeBanner } from "./SizeBanner.tsx";
import type { VmSizePackage } from "./VmSize.types.ts";
import { extractOsFields } from "./os/os.ts";
import { usePackages } from "./vmPackages.ts";
import {
    convertToSizeValue,
    filterOsPackages,
    getIsSizeRestricted,
    getSizeComponentRanges,
    matchesPackageOsField,
    sizeToVmPriceFields,
    useSizeParams,
    type ComponentRangeValues,
    type SizeValue,
} from "./vmSizeSelectUtils.ts";

export type VmPriceFieldsOmitSize = Omit<VmPriceFields, keyof ReturnType<typeof sizeToVmPriceFields>>;

export interface VmSizeSelectProps {
    /** Base VM data to use for price calculation (except the size, which will be provided by this component) */
    vmData: VmPriceFieldsOmitSize;

    value: SizeValue;
    onChange: (value: SizeValue) => void;

    ranges: ComponentRangeValues;
    packages?: VmSizePackage[];
    diskOnly?: boolean;
    isDiskPrimary?: boolean;
    /** When resizing current VM or package */
    isResize?: boolean;

    priceList: ResourcePrices;
    priceCalculator?: (prices: ResourcePrices, vm: VmPriceFields) => Price;
}

export function VmSizeSelectCustom({
    vmData,
    value,
    ranges,
    onChange,
    packages,
    diskOnly,
    isDiskPrimary = true,
    priceCalculator = getVmCreatePrice,
    isResize = false,
    priceList,
}: VmSizeSelectProps) {
    invariant(diskOnly == null || isDiskPrimary != null, "diskOnly and isDiskPrimary must be set together");

    //#region Hooks

    const allPackages = usePackages();
    const sizeParams = useSizeParams();

    //#endregion

    const os = extractOsFields(vmData);
    packages ??= filterOsPackages(allPackages, os);

    function getPriceForSize(size: SizeValue) {
        return priceCalculator(priceList, {
            ...vmData,
            // NB: This always overwrites `status` with "running"
            ...sizeToVmPriceFields(size, isDiskPrimary),
        });
    }

    function changeSelection(item: SizeValue) {
        onChange(item);
    }

    const packagesRanges = value.isCustom && !isResize ? getSizeComponentRanges(sizeParams, os) : ranges;
    const items = packages.map((item: VmSizePackage, index: number) => {
        const sizeValue = convertToSizeValue(item, false);

        // When custom slider is modified, we need to use ranges of current OS for packages, not the ones from the slider.
        const isSizeRestricted = getIsSizeRestricted(item, packagesRanges ?? ranges, diskOnly);

        const isDisabled = isSizeRestricted || matchesPackageOsField(item, os, "disabled");
        const isSelected = isDeepEqual(sizeValue, value) && !isDisabled;

        const notice = isSizeRestricted ? "Currently not available" : item.notice;

        return (
            <SizeBanner
                key={index}
                price={getPriceForSize(sizeValue)}
                isSelected={isSelected}
                isDisabled={isDisabled}
                onClick={() => changeSelection(sizeValue)}
                notice={notice}
            >
                {!diskOnly && <div>{item.cpu} CPU</div>}
                {!diskOnly && <div>{item.ram / 1024} GB RAM</div>}
                <div>{item.ssd} GB DISK</div>
            </SizeBanner>
        );
    });

    function onChangeCustomSize(componentValue: Partial<SizeValue>) {
        changeSelection({ ...value, ...componentValue, isCustom: true });
    }

    return (
        <>
            {items}

            <SizeBanner
                key="custom"
                variant="custom"
                price={getPriceForSize(value)}
                isSelected={value.isCustom}
                onClick={() => onChangeCustomSize({ ...value })}
            >
                {!diskOnly && (
                    <CustomSlider
                        label="CPU"
                        items={ranges.cpu}
                        value={value.vcpu}
                        onChange={(v) => {
                            onChangeCustomSize({ vcpu: v });
                        }}
                    />
                )}
                {!diskOnly && (
                    <CustomSlider
                        label="GB RAM"
                        items={ranges.ram}
                        convertToText={(value) => String(value / 1024)}
                        convertFromText={(value) => Number(value) * 1024}
                        value={value.ram}
                        onChange={(v) => {
                            onChangeCustomSize({ ram: v });
                        }}
                    />
                )}

                <CustomSlider
                    label="GB DISK"
                    items={ranges.ssd}
                    value={value.disks}
                    onChange={(v) => {
                        onChangeCustomSize({ disks: v });
                    }}
                />
            </SizeBanner>
        </>
    );
}

interface CustomSliderProps {
    items: number[];
    value: number;
    onChange: (value: number) => void;
    allowInvalidValue?: boolean;

    /** Convert from internal value to displayed/editable value */
    convertToText?: (value: number) => string;
    /**
     * Convert from editable value to internal value.
     * @returns `undefined` or `NaN` to indicate invalid value.
     */
    convertFromText?: (value: string) => number | undefined;

    label: ReactNode;
    children?: ReactNode;
}

function CustomSlider({
    items,
    value,
    onChange,
    allowInvalidValue = false,
    convertToText = (value) => String(value),
    convertFromText = (value) => Number(value),
    label,
    children,
}: CustomSliderProps) {
    invariant(items.length > 0, "items must not be empty");

    const [manualText, setManualText] = useState<string>(convertToText(value));

    // Update manual input when value changes
    useEffect(() => {
        setManualText(convertToText(value));
        // eslint-disable-next-line react-hooks/exhaustive-deps -- should not trigger when `convertToUi` changes
    }, [value]);

    let valueIndex = items.findIndex((item) => item === value);
    if (valueIndex === -1)
        if (allowInvalidValue) {
            // If no exact match, find the closest larger value (assumes items are sorted)
            valueIndex = items.findIndex((item) => item > value);
            // Default to the first item
            if (valueIndex === -1) {
                valueIndex = 0;
            }
        } else {
            showWarn("CustomSlider: Invalid value: %o; valid values are: %o", value, items);
            return (
                <div className="text-error">
                    <i>{label}</i>: Invalid value: <u>{convertToText(value)}</u>
                </div>
            );
        }

    function onInputChange(event: ChangeEvent<HTMLInputElement>) {
        setManualText(event.target.value);
    }

    function onInputBlur() {
        const numManual = convertFromText(manualText);
        // Fall back to the current value if the manual input is invalid
        const cValue = Number.isNaN(numManual) || numManual == null ? value : numManual;

        const closest = items.reduce((prev: number, curr: number) =>
            Math.abs(curr - cValue) < Math.abs(prev - cValue) ? curr : prev,
        );

        setManualText(convertToText(closest));

        if (closest !== value) {
            onChange(closest);
        }
    }

    return (
        <Slider
            className={S.Slider}
            minValue={0}
            maxValue={items.length - 1}
            value={valueIndex}
            onChange={(index) => onChange(items[index])}
        >
            <Label className={S.Label}>{label}</Label>
            <div className={S.Holder}>
                <div className={S.Range}>
                    <div>{convertToText(items[0])}</div>
                    <div>{convertToText(items[items.length - 1])}</div>
                </div>

                <SliderTrack className={S.SliderTrack}>
                    <SliderThumb className={S.SliderThumb} />
                </SliderTrack>
            </div>

            <Text className={S.Description} slot="description">
                <input
                    type="text"
                    inputMode="decimal"
                    pattern="[0-9]+([\\.,][0-9]+)?"
                    value={manualText}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    className={S.Input}
                />
                {children}
            </Text>
        </Slider>
    );
}

export interface VmSizeSelectLoaderProps extends SetOptional<VmSizeSelectProps, "priceList"> {}

function VmSizeSelectLoader(props: VmSizeSelectLoaderProps) {
    const priceList = useSuspenseQueryAtom(pricesAtom);
    return <VmSizeSelectCustom priceList={priceList} {...props} />;
}

export function VmSizeSelect(props: VmSizeSelectLoaderProps) {
    return (
        <Suspense fallback={<Loading icon="none" />}>
            <VmSizeSelectLoader {...props} />
        </Suspense>
    );
}
