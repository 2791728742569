import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { atomFamily } from "jotai/utils";
import { atom, type Atom } from "jotai/vanilla";
import { mapValues, omitBy, pipe } from "remeda";
import { mergeQueries, queryThen, type QResult } from "../../utils/query/mergeQueries.ts";
import { accessDelegationListQueryAtom, type AccessDelegationWithType } from "../access/delegation/apiOperations.ts";
import { apiTokenListQueryAtom, type ApiTokenWithType } from "../access/token/apiOperations.ts";
import { resourceTypesEnabledAtom, type ResourceType } from "../api/resourceTypes.tsx";
import { allAssignedVmsQueryAtom, type VirtualMachineWithAssigned } from "../compute/joinAssignedQuery.ts";
import type { RecentResource } from "../dashboard/recentQuery.ts";
import { allMetalPricedQueryAtom, type MetalListItemWithPrice } from "../metal/joinPricesQuery.ts";
import {
    allAssignedIpsQueryAtom,
    allAssignedLbsQueryAtom,
    type IpAddressWithPrice,
    type LoadBalancerWithPrice,
} from "../network/ipAddress/joinAssignedQuery.ts";
import type { Price } from "../pricing/resourcePricing.ts";
import { allAssignedServicesQueryAtom, type ManagedServiceWithAssigned } from "../services/joinAssignedQuery.ts";
import type { StorageWithPrice } from "../storage/objectStorage/apiOperations.ts";
import { storageListQueryWithPricesAtom } from "../storage/storageListQuery.ts";

/**
 * Resource types that can be owned by billing accounts
 *
 * @see {@link RecentResource} for a very similar concept and implementation
 */

export type BillingLinkedResource =
    | VirtualMachineWithAssigned
    | ManagedServiceWithAssigned
    | StorageWithPrice
    | LoadBalancerWithPrice
    | IpAddressWithPrice
    | ApiTokenWithType
    | AccessDelegationWithType
    | MetalListItemWithPrice;

type ItemsResult = Map<unknown, BillingLinkedResource> | BillingLinkedResource[];
type QueryAtom = Atom<QResult<ItemsResult>>;

/** Map from resource type to its query atom */
const billingResourceTypeToQuery = {
    virtual_machine: allAssignedVmsQueryAtom,
    managed_service: allAssignedServicesQueryAtom,
    bucket: storageListQueryWithPricesAtom,

    vpc: undefined,
    ip_address: allAssignedIpsQueryAtom,
    load_balancer: allAssignedLbsQueryAtom,

    api_token: apiTokenListQueryAtom,
    access_delegation: accessDelegationListQueryAtom,
    access_impersonation: undefined,

    bare_metal: allMetalPricedQueryAtom,

    billing_account: undefined,
} satisfies Record<ResourceType, QueryAtom | undefined>;

/** Get owning billing account ID for a linked resource */
function getBaId(item: BillingLinkedResource) {
    return item.$type === "virtual_machine" ? item.billing_account : item.billing_account_id;
}

export function getLinkedPrice(item: BillingLinkedResource): Price | undefined {
    const { $type } = item;
    switch ($type) {
        case "virtual_machine":
        case "bucket":
        case "ip_address":
        case "load_balancer":
        case "managed_service":
        case "bare_metal":
            return item.price;

        case "api_token":
        case "access_delegation":
            return undefined;

        default:
            exhaustiveSwitchCheck($type);
    }
}

const allLinkedResourcesQueryAtom = atom((get) => {
    const typeEnabled = get(resourceTypesEnabledAtom);
    const enabledQueries = pipe(
        billingResourceTypeToQuery,
        // Filter non-existent queries and disabled types
        omitBy((atom, type) => atom == null || !typeEnabled[type]),
        mapValues((atom: QueryAtom) => get(atom)),
    );

    return mergeQueries(enabledQueries, (maps) =>
        maps.flatMap((m: ItemsResult): BillingLinkedResource[] =>
            // Some of the atoms above are maps, some are arrays, so convert everything to arrays
            m instanceof Map ? [...m.values()] : m,
        ),
    );
});

export const baLinkedResourcesAtom = atomFamily((billingAccountId: number) =>
    atom((get) =>
        queryThen(get(allLinkedResourcesQueryAtom), (items) =>
            items.filter((item) => getBaId(item) === billingAccountId),
        ),
    ),
);
