import * as cognovum from "../../styles/cognovum/theme";
import * as dataxion from "../../styles/dataxion/theme";
import * as denvr from "../../styles/denvr/theme";
import * as dimensicloud from "../../styles/dimensicloud/theme";
import * as hostnic from "../../styles/hostnic/theme";
import * as hostsailor from "../../styles/hostsailor/theme";
import * as hostzop from "../../styles/hostzop/theme";
import * as idcloudhost from "../../styles/idcloudhost/theme";
import * as metal from "../../styles/metal/theme";
import * as nforce from "../../styles/nforce/theme";
import * as pilvio from "../../styles/pilvio/theme";
import * as rackdog from "../../styles/rackdog/theme";
import * as rackzar from "../../styles/rackzar/theme";
import * as readyidc from "../../styles/readyidc/theme";
import * as serverwala from "../../styles/serverwala/theme";
import * as stacklogic from "../../styles/stacklogic/theme";
import * as tenbyte from "../../styles/tenbyte/theme";
import * as warren from "../../styles/warren/theme";
import * as web1 from "../../styles/web1/theme";
import type { ThemeModule } from "./types.ts";

export let allThemes: Record<string, ThemeModule> = {
    cognovum,
    denvr,
    dataxion,
    dimensicloud,
    hostnic,
    hostsailor,
    hostzop,
    idcloudhost,
    metal,
    nforce,
    pilvio,
    rackdog,
    rackzar,
    readyidc,
    serverwala,
    stacklogic,
    tenbyte,
    warren,
    web1,
};

//#region Hot reload support

export function __setState(newAllThemes: typeof allThemes, newThemeListeners: typeof themeListeners) {
    allThemes = newAllThemes;
    themeListeners = newThemeListeners;
}

let themeListeners = new Set<() => void>();

export function addThemeListener(listener: () => void) {
    themeListeners.add(listener);
    return () => {
        themeListeners.delete(listener);
    };
}

export function __notifyThemeListeners() {
    for (const listener of themeListeners) {
        try {
            listener();
        } catch (e) {
            console.error("Error in theme listener", e);
        }
    }
}

if (import.meta.hot) {
    import.meta.hot.accept((newModule: unknown) => {
        console.debug("Hot reload allThemes...");

        const newInit = newModule as typeof import("./allThemes.ts");
        // Patch the old object with the new themes
        Object.assign(allThemes, newInit.allThemes);

        // Propagate the original object into the "future" (so new hot reloads will still patch the original object)
        newInit.__setState(allThemes, themeListeners);
        newInit.__notifyThemeListeners();
    });
}

//#endregion
