import { useAtomValue } from "jotai/react";
import { NoticeBlock, type NoticeBlockProps } from "../../components/NoticeBlock.tsx";
import { cn } from "../../utils/classNames.ts";
import { firstAccountNoticesAtom, type NoticeItem } from "./firstAccountNotices.store.tsx";

export function FirstAccountNotices(props: Pick<NoticeBlockProps, "full" | "compact">) {
    const firstAccountNotices: NoticeItem[] = useAtomValue(firstAccountNoticesAtom);
    if (!firstAccountNotices.length) {
        return null;
    }

    const { full } = props;
    return (
        <NoticeBlock icon={cn(!full && "jp-trophy-icon")} color="success" {...props}>
            {firstAccountNotices.map(({ content, id }) => (
                <div key={id} className={cn(full && "text-center")}>
                    {content}
                </div>
            ))}
        </NoticeBlock>
    );
}
