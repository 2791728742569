import C from "./Hamburger.module.css";

import { Link } from "@tanstack/react-router";
import { useAtomValue } from "jotai/react";
import { useState } from "react";
import { MenuTrigger, Popover } from "react-aria-components";
import { link, type RouterLinkProps } from "../../../components/Action.tsx";
import { WButton } from "../../../components/button/WButton.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { mcn, type BaseProps } from "../../../utils/baseProps.ts";
import type { NavItem } from "../SideNav.tsx";
import { sectionsAtom } from "../sidebar/Sidebar.store.tsx";

export function Hamburger(props: BaseProps) {
    const [isOpen, setOpen] = useState(false);
    return (
        <div {...mcn("flex items-center", props)}>
            <MenuTrigger isOpen={isOpen} onOpenChange={setOpen}>
                {/* <MaskIcon className="i-lucide:menu size-1.3rem" /> */}
                {/* XXX: should this even use `WButton`? Too much custom styling required... */}
                <WButton
                    icon="jp-icon-menu"
                    variant="ghost"
                    color="primary"
                    size="bar"
                    ariaLabel="Open menu"
                    action={undefined}
                />
                <Popover offset={8} containerPadding={0} className={C.Menu}>
                    <MobileMenu close={() => setOpen(false)} />
                </Popover>
            </MenuTrigger>
        </div>
    );
}

export default Hamburger;

function MobileMenu({
    close,
}: BaseProps & {
    close: () => void;
}) {
    const sidebarSections = useAtomValue(sectionsAtom);
    return (
        <div className="flex flex-row flex-wrap items-center items-stretch gap-0.25rem justify-content-start p-4">
            {sidebarSections.map((section) => (
                <MobileMenuItem key={section.id} {...section} onClick={close} />
            ))}
        </div>
    );
}

function MobileMenuItem({ id, icon, label, to, onClick }: NavItem & { onClick?: () => void }) {
    const linkProps: RouterLinkProps = to
        ? ({ to } as RouterLinkProps)
        : link({ to: "/section/$name", params: { name: id } });
    return (
        <Link
            className="min-w-5rem p-2 flex flex-col items-center justify-center gap-2 hover:bg-primary-light is-[.active]:bg-primary-light b b-primary rounded"
            {...linkProps}
            onClick={() => {
                onClick?.();
            }}
        >
            <MaskIcon className={`${icon} size-1.5rem color-primary`} />
            {label}
        </Link>
    );
}
