import { useState } from "react";
import { Label } from "react-aria-components";
import { getPreferredLocation, type LocationWithEnabled } from "../modules/location/location.ts";
import { LocationSelectCustom } from "../modules/location/LocationSelect.tsx";
import { usePricingLocations } from "../modules/location/query.ts";

export function AdminLocationSelect({ onChange }: { onChange: (value: LocationWithEnabled) => void }) {
    const locations = usePricingLocations();
    const location = getPreferredLocation(locations);
    const [selectedLocation, setSelectedLocation] = useState<LocationWithEnabled>(location);

    function onChangeLocation(location: LocationWithEnabled) {
        setSelectedLocation(location);
        onChange(location);
    }

    return (
        <div className="flex items-center">
            <Label className="font-size-small pr-1">Location:</Label>
            <LocationSelectCustom items={locations} value={selectedLocation} onChange={onChangeLocation} />
        </div>
    );
}
