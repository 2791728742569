import { notNull } from "@warrenio/utils/notNull";
import { abortSignalToPromise } from "@warrenio/utils/promise/abortSignalToPromise";
import isChromatic from "chromatic";
import { getDefaultStore } from "jotai/vanilla";
import invariant from "tiny-invariant";
import { getBodyClasses } from "../../../styles/bodyClasses.ts";
import { isTestEnvironment } from "../../../utils/environment.ts";
import { makePromise } from "../../../utils/promise/makePromise.ts";
import { showError } from "../../error/errorStream.ts";
import { currentColorSchemeAtom } from "../../main/ColorSchemeSwitcher.store.ts";
import { getFullUrl } from "../../main/urls.ts";
import { colorSchemeAttribute } from "../../theme/shared.ts";
import { themeCssHtmlAtom } from "../../theme/themeCssHtml.ts";
import popupCss from "./popup.css?inline";

// IDs of elements inside the popup
const rootId = "popup-root";
const textId = "popup-text";

function makeHtml() {
    const store = getDefaultStore();

    const styleUrls: string[] = [];
    const styleTags = styleUrls.map((url) => `<link rel="stylesheet" href="${url}">`).join("\n");

    const themeCss = store.get(themeCssHtmlAtom);

    const devClass = isChromatic() ? " chromatic" : "";

    return `
<!DOCTYPE html>
<html>
<head>
    <base href="${getFullUrl("/")}">
    <title>Popup</title>
    ${styleTags}
    ${themeCss}
    <style>${popupCss}</style>
</head>
<body class="${getBodyClasses()}${devClass}" ${colorSchemeAttribute}="${store.get(currentColorSchemeAtom)}">
    <div id="${rootId}">
        <div class="Spinner"><div class="Content"></div></div>
        <div id="${textId}">Loading...</div>
    </div>
</body>
</html>
`;
}

/** Mockable version of {@link Window} */
export interface SimpleWindow {
    document: Document;
    closed: boolean;
    close(): void;
    toString(): string;
}

type PopupState = "initial" | "redirected" | "returned" | "closed";

export class PopupDidNotReturnError extends Error {
    name = "PopupDidNotReturnError";
    constructor(public redirectUrl: string | undefined) {
        super("Popup did not return from redirect");
    }
}

export class PaymentPopup {
    private autoCloseOnReturn = true;

    private hasRedirected = false;
    private redirectUrl: string | undefined;

    /** Promise that is fulfilled when the popup returns from redirect */
    private returnPromise = makePromise<void>();

    /**
     * A timer that periodically checks if the popup has returned.
     *
     *  Necessary because the user can close the popup at any point and if we do not check before than, we will never know whether it returned or not.
     */
    private stateCheckTimer: ReturnType<typeof setInterval> | null | undefined;

    constructor(private readonly window: SimpleWindow) {
        console.debug("popup: Constructing", String(window));

        // NB: Can not use `Blob` for content since it will not have the same origin
        window.document.documentElement.innerHTML = makeHtml();
    }

    /** @param isTrusted - Whether the currently handled event is trusted (eg. generated by a user action) */
    static open(isTrusted: boolean): PaymentPopup | undefined {
        // NB: If the event is generated by eg. react-testing-library, it will not be trusted and a real popup can not be opened
        // (so force attaching to the mock popup)
        if (import.meta.env.DEV && !isTrusted) {
            return attachMockPopup();
        }

        const w = window.open("", "_blank");
        if (!w) {
            if (isTestEnvironment) {
                console.warn("popup: Fallback to mock popup in tests");
                return attachMockPopup();
            }

            console.warn("popup: Failed to open window");
            return undefined;
        }

        try {
            // XXX: Try to work around issue with Mobile Safari opening the tab in the background
            w.focus();
        } catch (e) {
            console.warn("popup: Failed to focus the window", e);
        }

        return new PaymentPopup(w);
    }

    private startStateCheck() {
        invariant(this.stateCheckTimer === undefined, "State check was already started");
        this.stateCheckTimer = setInterval(() => this.runStateCheck(), 50);
    }

    private _lastState: PopupState = "initial";

    private runStateCheck() {
        const state = this.getPopupState();
        if (state !== this._lastState) {
            console.info("popup: State changed: %s -> %s", this._lastState, state);
            this._lastState = state;

            if (state === "returned") {
                this.returnPromise.resolve();
                if (this.autoCloseOnReturn) {
                    console.info("popup: Automatically closing after it has returned");
                    this.close();
                }
            }
        }
    }

    private stopStateCheck() {
        if (this.stateCheckTimer) {
            clearInterval(this.stateCheckTimer);
            this.stateCheckTimer = null;

            // Run state check for the last time in case the stop raced before the next interval
            this.runStateCheck();

            if (this.returnPromise.state === "pending") {
                this.returnPromise.reject(new PopupDidNotReturnError(this.redirectUrl));
            }
        }
    }

    private getPopupState(): PopupState {
        if (this.closed) {
            return "closed";
        }
        if (!this.hasRedirected) {
            return "initial";
        }

        try {
            const location = this.window.document.location;
            // NB: `location` can be `null` if it is a *new* about:blank page
            if (location == null) {
                return "redirected";
            } else if (location.href === "about:blank") {
                return "initial";
            } else {
                // If `location` is accessible and, it means we are on the original origin, therefore assume the redirect is done
                return "returned";
            }
        } catch (e) {
            if (!isSecurityError(e)) {
                console.error("popup: Error in hasReturned", e);
            }
            return "redirected";
        }
    }

    handleRedirect(url: string) {
        console.debug("popup: Redirecting to %s", url);

        invariant(!this.hasRedirected, "Popup must not have redirected before");

        // NB: Replace the content so that the user can not go back to the previous (empty about:blank) page
        this.window.document.location.replace(url);

        this.hasRedirected = true;
        this.redirectUrl = url;

        this.startStateCheck();
    }

    /** Wait for the popup to return from the redirect */
    async waitReturnFromRedirect(signal: AbortSignal) {
        invariant(this.hasRedirected, "Popup must have redirected before waiting for redirect");

        await Promise.race([this.returnPromise, abortSignalToPromise(signal)]);
    }

    get hasReturned() {
        return this.returnPromise.state === "resolved";
    }

    dispose() {
        if (!this.hasRedirected) {
            console.debug("popup: dispose()");
            this.close();
        } else {
            if (!this.hasReturned) {
                console.warn("popup: was disposed before it had returned from redirect");
                // NB: `returnPromise` will be rejected in `stopStateCheck` and that will report the error
            } else {
                console.info("popup: dispose() is closing since it has returned");
                this.close();
            }
        }

        this.stopStateCheck();
    }

    close() {
        console.debug("popup: close()");
        try {
            this.window.close();
        } catch (e) {
            showError("popup: Error in close", e);
        }

        // No point in polling the state anymore
        this.stopStateCheck();
    }

    get closed() {
        return this.window.closed;
    }

    setText(text: string) {
        try {
            const root = this.window.document.getElementById(textId)!;
            root.innerText = text;
        } catch (e) {
            console.warn("popup: Error in setText", e);
        }
    }

    private getLocationHref(): string {
        try {
            const location = this.window.document.location;
            if (location == null) {
                return `<${location}>`;
            }
            return location.href;
        } catch (e) {
            if (isSecurityError(e)) {
                return "<cross-origin>";
            }
            console.warn("popup: Error in getLocation", e);
            return "<unknown>";
        }
    }

    toString() {
        return `Popup(${this.getLocationHref()}, state=${this.getPopupState()}, closed=${this.closed}, redirected=${this.hasRedirected})`;
    }
}

function isSecurityError(e: unknown) {
    return e instanceof DOMException && e.name === "SecurityError";
}

//#region Mock popup

export const mockPopupId = "mock-popup-frame";

function attachMockPopup(): PaymentPopup | undefined {
    const iframe = document.getElementById(mockPopupId) as HTMLIFrameElement | null;
    if (!iframe) {
        console.warn("Mock popup: iframe not found");
        return undefined;
    }
    const iframeDocument = notNull(iframe.contentDocument);

    let closed = false;

    return new PaymentPopup({
        get document() {
            if (this.closed) {
                throw new Error("Mock popup closed");
            }
            return iframeDocument;
        },
        get closed() {
            return closed;
        },
        close() {
            console.debug("Mock popup: close()");
            closed = true;
            iframe.srcdoc = '<html><body>❎ [<span data-testid="popup-closed">Popup closed</span>]</body></html>';
        },
        toString() {
            return "[object MockPopup]";
        },
    });
}

//#endregion
