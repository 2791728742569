import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { successResponse, tagPaths } from "../util.ts";
import { datetime_tz, uuid } from "./common.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

export const StoragePool = z
    .object({
        uuid,
        created_at: datetime_tz,
        is_default: z.boolean(),
        conf: z.string(),
        percent_used: z.number(),
        ceph_fsid: uuid,
        display_name: z.string(),
        stored_gb: z.number(),
        total_gb: z.number(),
        pool_name: z.string(),
    })
    .openapi({ ref: "StoragePool" });

export const StoragePoolResponse = z.array(StoragePool).openapi({ ref: "StoragePoolResponse" });

export const storageAdminPaths = tagPaths("admin_storage")({
    // Admin storage pools
    "/{location}/storage/admin/pools": {
        get: {
            summary: "List storage pools",
            parameters: [params.location],
            responses: {
                ...successResponse(StoragePoolResponse),
            },
        },
    },
});
