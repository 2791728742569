import { useRef } from "react";

export function useLogError(error: unknown, errorMsg: string) {
    const errorRef = useRef(error);

    if (errorRef.current !== error) {
        console.error(errorMsg, error);
        errorRef.current = error;
    }
}
