import T from "../../../components/forms/TextField.module.css";
import LR from "../../../components/LeftRight.module.css";

import * as tanstackForm from "@tanstack/react-form";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { notNull } from "@warrenio/utils/notNull";
import { useAtomValue, useStore } from "jotai/react";
import { useMemo, useRef, useState } from "react";
import { Input, Label, NumberField, type PressEvent } from "react-aria-components";
import invariant from "tiny-invariant";
import { ariaTanstackFieldProps } from "../../../components/forms/ariaTanstackFieldProps.tsx";
import { WSelect, type ConfiguredWSelectProps } from "../../../components/forms/WSelect.tsx";
import { CurrencyBalance } from "../../../components/l10n/Currency.tsx";
import { ContentLoadingSuspense } from "../../../components/loading/Loading.tsx";
import { ModalResult, WModalContent } from "../../../components/modal/WModal.tsx";
import { Separator } from "../../../components/Separator.tsx";
import { useConfig } from "../../../config.ts";
import { UnmountedError } from "../../../utils/react/useUnmountSignal.tsx";
import { notifyErrorCustom } from "../../error/errorReporting.tsx";
import { errorToString } from "../../error/errorToString.tsx";
import { raiseBackgroundErrorToast, raiseToast } from "../../notifications/toast.tsx";
import type { EBillingAccount } from "../billingLogic.tsx";
import { useAccountMethods, type BoundMethod } from "../BoundMethod.ts";
import { ChooseMethod } from "../choose_method/ChooseMethod.tsx";
import { useAddMethodWithProcessors } from "../choose_method/useAddMethod.tsx";
import { ConversionMessage } from "../ConversionMessage.tsx";
import { ErrorTexts } from "../payment_forms/components.tsx";
import { PaymentFees } from "../PaymentFees.tsx";
import type { PaymentMethod } from "../PaymentMethod.tsx";
import { CardShortInfo } from "../paymentMethodLogic.tsx";
import { processorsAtom } from "../paymentProcessorsLogic.tsx";
import { PaymentPopup } from "../popup/popup.ts";
import { lastUsedMethodId } from "./topup.store.ts";
import { TopUpResult, type TopUpActions, type TopUpParams } from "./TopUpParams.ts";
import { invalidateTopUpQueries } from "./topUpUtils.ts";
import { getPaymentReturnUrl } from "./urls.ts";

//#region Payment method selection

const ADD_METHOD = Symbol.for("MethodSelect/ADD_METHOD");
type ADD_METHOD = typeof ADD_METHOD;

const ADD_METHOD_KEY = "__add_method__";
type ADD_METHOD_KEY = typeof ADD_METHOD_KEY;

type SelectableMethodKey = BoundMethod["id"] | ADD_METHOD_KEY;

function validateSelectedMethodKey(key: SelectableMethodKey | null | undefined, methods: BoundMethod[]) {
    if (key === ADD_METHOD_KEY || (key != null && methods.some((m) => m.id === key))) {
        return key;
    } else if (key == null) {
        return null;
    } else {
        console.warn(
            "Invalid default value %o for selected method (available %o)",
            key,
            methods.map((m) => m.id),
        );
        return null;
    }
}

function TopUpMethodSelect(props: ConfiguredWSelectProps<BoundMethod | ADD_METHOD, SelectableMethodKey>) {
    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            placeholder="Select a payment method"
            getKey={(item) => (item === ADD_METHOD ? ADD_METHOD_KEY : item.id)}
            getTextValue={(item) => (item === ADD_METHOD ? "Add method" : item.method.name)}
            {...props}
        >
            {(item) =>
                item === ADD_METHOD ? (
                    "Add a new method..."
                ) : (
                    <>
                        <span className="min-w-12">{item.method.icon({ isSelected: false })}</span>
                        <span className={LR.title}>
                            {item.type === "creditcard" ? <CardShortInfo card={item.card} /> : item.method.name}
                        </span>
                    </>
                )
            }
        </WSelect>
    );
}

//#endregion

function useTopUpMethods(account: EBillingAccount): {
    /** Methods that are available to be added to the account */
    newMethods: PaymentMethod[];
    /** Methods that have already been added to the account */
    existingMethods: BoundMethod[];
} {
    const processors = useAtomValue(processorsAtom);
    const { cardMethods, otherMethods } = useAccountMethods(account);
    return useMemo(() => {
        // Only show methods that can be used for top-up (eg. not invoice)
        const topUpMethods = otherMethods.filter((m) => m.method.TopUpForm);

        const otherMethodIds = new Set(otherMethods.map((m) => m.id));

        return {
            newMethods: [...processors.values()]
                // Filter out existing methods
                // NB: Adding a new credit card is always OK, even if there are existing cards
                .filter((method) => !otherMethodIds.has(method.id))
                .filter((method) => method.type !== "invoice" || (method.type === "invoice" && account.isPostPay)),

            existingMethods: [...cardMethods, ...topUpMethods],
        };
    }, [processors, cardMethods, otherMethods]);
}

interface TopUpInputs {
    amount: number;
}

export interface TopUpModalProps {
    account: EBillingAccount;
    defaultValue?: SelectableMethodKey | null;
}

export function TopUpModal({ account, defaultValue }: TopUpModalProps) {
    //#region Hooks
    const { minimumTopupAmount, siteCurrency } = useConfig();
    const queryClient = useQueryClient();
    const store = useStore();

    //#region Form
    const form = useForm({
        defaultValues: {
            amount: minimumTopupAmount,
        },
    });
    const isSubmitting = tanstackForm.useStore(form.store, (s) => s.isSubmitting);
    //#endregion

    const topUpRef = useRef<TopUpActions>(null);

    //#region Method selection
    const { existingMethods, newMethods } = useTopUpMethods(account);
    const adder = useAddMethodWithProcessors(newMethods);

    defaultValue ??= store.get(lastUsedMethodId);
    // Default to "the" method if there is only one available
    if (existingMethods.length === 1 && !defaultValue) {
        defaultValue = existingMethods[0].id;
    }

    const [methodKeyInternal, setMethod] = useState<SelectableMethodKey | null>(defaultValue ?? null);
    const methodKey = useMemo(
        () => validateSelectedMethodKey(methodKeyInternal, existingMethods),
        [methodKeyInternal, existingMethods],
    );
    const selectedMethod =
        methodKey != null && methodKey !== ADD_METHOD_KEY
            ? notNull(
                  existingMethods.find((item) => item.id === methodKey),
                  "selectedMethod",
              )
            : null;
    //#endregion

    const [error, setError] = useState<string | null>(null);

    //#endregion - Hooks

    function toastAndSetError(e: unknown, prefix = "Top-up error") {
        raiseBackgroundErrorToast(e, prefix);

        // TODO: Better way to get/show the text of specific payment Errors
        setError(errorToString(e));
    }

    function reportAndSetError(message: string) {
        notifyErrorCustom(message, { tags: ["topup"], context: { method: selectedMethod?.id } });
        setError(message);
    }

    async function doTopUp({ amount }: TopUpInputs, ev: PressEvent): Promise<ModalResult> {
        invariant(selectedMethod != null, "Method must be selected");
        const { type, card, id } = selectedMethod;

        store.set(lastUsedMethodId, id);

        let popup: PaymentPopup | undefined;

        const topUpParams: TopUpParams = {
            account,
            amount: amount,
            currency: siteCurrency,
            progress(status) {
                status += "…";
                console.info("Top-up progress: %s", status);
                if (popup) {
                    // TODO: Check if window closed
                    popup.setText(status);
                }
                // TODO: Also display inside modal
            },
            popup: null,
            returnUrl: getPaymentReturnUrl(),
            getCard() {
                invariant(type === "creditcard", "Method must be a credit card");
                return card;
            },
        };

        // TODO: Handle errors in-line
        const actions = notNull(topUpRef.current, "topUpRef");

        try {
            // NB: The popup must be opened synchronously (before anything `async`), otherwise the browser will block it
            if (actions.needsPopUp?.(topUpParams)) {
                popup = PaymentPopup.open(ev.pointerType !== "virtual");
                if (!popup) {
                    reportAndSetError("Please allow popup windows to use this payment method");
                    return ModalResult.KEEP_OPEN;
                }

                topUpParams.popup = popup;
            }

            topUpParams.progress("Initiating payment");
            const result = await actions.topUp(topUpParams);

            switch (result) {
                case TopUpResult.SUCCESS:
                    // Automatically close popup on success
                    popup?.close();

                    raiseToast({ type: "success", icon: "jp-wallet-icon", message: "Your account has been topped up" });

                    await invalidateTopUpQueries(queryClient, account.id);

                    return ModalResult.CLOSE;

                case TopUpResult.CANCELLED:
                    reportAndSetError("Top-up was cancelled");
                    return ModalResult.KEEP_OPEN;

                case TopUpResult.VALIDATION_FAILED:
                    return ModalResult.KEEP_OPEN;
            }
        } catch (e) {
            console.error("Top-up error", e);
            if (e instanceof UnmountedError) {
                // Dialog was closed while in progress...
            } else {
                toastAndSetError(e);

                // TODO: Force navigate the popup to an error page?
            }
        } finally {
            popup?.dispose();
        }

        // Keep the modal open if there was a failure
        return ModalResult.KEEP_OPEN;
    }

    const TopUpForm = selectedMethod?.method.TopUpForm;

    return (
        <WModalContent
            title="Top Up Your Account"
            label={methodKey === ADD_METHOD_KEY ? "Add payment method" : "Top Up"}
            modalAction={async (ev) => {
                // Reset error state on each action
                setError(null);

                if (methodKey === ADD_METHOD_KEY) {
                    if (!adder.hasSelectedMethod || !(await adder.validate(ev))) {
                        return ModalResult.KEEP_OPEN;
                    }

                    try {
                        const result = await adder.addPaymentMethod({ account });

                        // Ignore the result since we are keeping the modal open in any case
                        void result;
                    } catch (e) {
                        toastAndSetError(e, "Error adding payment method");
                        return ModalResult.KEEP_OPEN;
                    }

                    setMethod(adder.props.value!);

                    // NB: Keep the modal open in case the method wants to display a custom top-up form
                    return ModalResult.KEEP_OPEN;
                } else {
                    await form.validateAllFields("submit");
                    if (!form.state.isFormValid) {
                        return ModalResult.KEEP_OPEN;
                    }

                    return await doTopUp(form.state.values, ev);
                }
            }}
            isActionDisabled={methodKey === null || (methodKey === ADD_METHOD_KEY && !adder.hasSelectedMethod)}
            className="flex flex-col gap-3"
        >
            <div className="flex gap-0.5rem">
                <div className="w-50%">
                    <form.Field
                        name="amount"
                        validators={{
                            onBlur: ({ value }) => (value < minimumTopupAmount ? "Amount is too low" : null),
                        }}
                    >
                        {(api) => (
                            <NumberField
                                className={T.NumberField}
                                autoFocus
                                isDisabled={isSubmitting}
                                // Do not allow negative amounts and fractions
                                formatOptions={{
                                    maximumFractionDigits: 0,
                                    signDisplay: "never",
                                }}
                                {...ariaTanstackFieldProps(api)}
                            >
                                <Label className={T.Label}>
                                    Amount (min. <CurrencyBalance value={minimumTopupAmount} />)
                                </Label>
                                <Input className={T.Input} />
                                <ErrorTexts errors={api.state.meta.errors} />
                            </NumberField>
                        )}
                    </form.Field>
                </div>

                <form.Subscribe selector={(s) => s.values.amount}>
                    {(amount) => <PaymentFees account={account} amount={amount} fees={selectedMethod?.method.fee} />}
                </form.Subscribe>
            </div>

            <form.Subscribe selector={(s) => s.values.amount}>
                {(amount) => (
                    <ConversionMessage
                        account={account}
                        amount={amount}
                        fees={selectedMethod?.method.fee}
                        card={selectedMethod?.card}
                    />
                )}
            </form.Subscribe>

            <Separator />

            <div>
                <TopUpMethodSelect
                    isDisabled={isSubmitting}
                    items={[...existingMethods, ADD_METHOD]}
                    valueKey={methodKey}
                    defaultValueKey={null}
                    onChange={(_, k) => setMethod(k)}
                />
            </div>

            {methodKey === ADD_METHOD_KEY && <ChooseMethod {...adder.props} isDisabled={isSubmitting} />}

            {TopUpForm && (
                <ContentLoadingSuspense>
                    <TopUpForm actionsRef={topUpRef} account={account} isSubmitting={isSubmitting} />
                </ContentLoadingSuspense>
            )}

            {error != null && <div className="text-error max-w-50vw">Error: {error}</div>}
        </WModalContent>
    );
}

export default TopUpModal;
