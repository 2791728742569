import TF from "../../../components/forms/TextField.module.css";
import TC from "../../../components/table/Table.module.css";

import { Input, Label, NumberField } from "react-aria-components";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { DeleteButton } from "../../../components/button/DeleteButton.tsx";
import { WButton } from "../../../components/button/WButton.tsx";
import { formPropsToAriaProps } from "../../../components/forms/ariaController.tsx";
import { RhfError } from "../../../components/forms/FormFieldErrors.tsx";
import { requiredMessage } from "../../../components/forms/requiredMessage.ts";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { WTable, WTableBody } from "../../../components/table/WTable.tsx";
import type { BalancerCreateInputs } from "../NetworkCreate.tsx";
import { validatePort, validateSourcePort } from "./validatePorts.ts";

export function ForwardingRulesList() {
    const form = useFormContext<BalancerCreateInputs>();
    const { control } = form;
    const { fields, append, remove } = useFieldArray({ control, name: "rules" });

    function onAdd() {
        // XXX: `append` is broken for some reason
        void append;
        // append([{ source_port: 1, target_port: 2 }]);
        form.setValue("rules", [...form.getValues("rules"), { source_port: NaN, target_port: NaN }]);
    }

    function onDelete(index: number) {
        remove(index);
    }

    return (
        <>
            <WTable className={TC.rules}>
                <thead>
                    <tr>
                        <th>Protocol</th>
                        <th className="text-right">Port</th>
                        <th />
                        <th>Target port</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {fields.map((item, index) => (
                        <tr key={item.id}>
                            <td>TCP</td>
                            <td className="text-right">
                                <Controller
                                    control={control}
                                    name={`rules.${index}.source_port`}
                                    rules={{
                                        required: requiredMessage,
                                        validate: (v, f) => validateSourcePort(v, f.rules, true),
                                    }}
                                    render={(f) => (
                                        <NumberField
                                            className={TF.NumberField}
                                            isRequired
                                            formatOptions={{
                                                useGrouping: false,
                                            }}
                                            {...formPropsToAriaProps(f)}
                                        >
                                            <Label className="hidden">Source port</Label>
                                            <div>
                                                <Input style={{ width: "80px" }} className={TF.Input} />
                                            </div>
                                            <RhfError error={f.fieldState.error} />
                                        </NumberField>
                                    )}
                                />
                            </td>
                            <td className="text-center">
                                <MaskIcon className="jp-arrow-thin-right-icon size-1.25rem color-muted" />
                            </td>
                            <td>
                                <Controller
                                    control={control}
                                    name={`rules.${index}.target_port`}
                                    rules={{
                                        required: requiredMessage,
                                        validate: (v) => validatePort(v),
                                    }}
                                    render={(f) => (
                                        <NumberField
                                            className={TF.NumberField}
                                            isRequired
                                            formatOptions={{
                                                useGrouping: false,
                                            }}
                                            {...formPropsToAriaProps(f)}
                                        >
                                            <Label className="hidden">Target port</Label>
                                            <div>
                                                <Input style={{ width: "80px" }} className={TF.Input} />
                                            </div>
                                            <RhfError error={f.fieldState.error} />
                                        </NumberField>
                                    )}
                                />
                            </td>
                            <td className="text-right">
                                <DeleteButton inTable action={() => onDelete(index)} />
                            </td>
                        </tr>
                    ))}
                </WTableBody>
            </WTable>

            <WButton
                color="primary"
                variant="dashed"
                icon="jp-icon-add"
                action={() => onAdd()}
                className="w-full"
                isDisabled={fields.length >= 8}
            >
                Add New Rule
            </WButton>
        </>
    );
}
