import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { AdminUser, AdminUserCreateBody, AdminUserDeleteBody } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

//#region Response
export type Response = AdminUser[];
//#endregion

//#region Query and parameters
const queryKey: QueryKey = ["admin_users"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            return getResponseData(await client.GET("/user-resource/admin/admin_consumers", { signal }));
        },
    });
}
//#endregion

//#region Atoms
export const adminUsersQueryAtom = atomFromStandardQueryOptions(getQuery);
//#endregion

//#region Mutations

export const adminUserCreateMutation = adminMutation((apiClient) => ({
    entity: "admin user",
    operation: "create",
    requestFn: async ({ body }: { body: AdminUserCreateBody }) =>
        await apiClient.PUT("/user-resource/admin/admin_consumers", {
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));

export const adminUserDeleteMutation = adminMutation((apiClient) => ({
    entity: "admin user",
    operation: "delete",
    requestFn: async ({ body }: { body: AdminUserDeleteBody }) =>
        await apiClient.DELETE("/user-resource/admin/admin_consumers", {
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));

//#endregion Mutations
