import type { VmImage, VmImageVersion } from "@warrenio/api-spec/spec.oats.gen";
import { assertNotNull } from "@warrenio/utils/notNull";

export type OsName = string;
export type OsVersion = string;

export interface SelectedOs {
    os_name: OsName;
    os_version: OsVersion;

    /** Prevent extra fields and force usage of {@link extractOsFields} */
    location?: never;
}

export function extractOsFields({ os_name, os_version }: Pick<SelectedOs, "os_name" | "os_version">): SelectedOs {
    return { os_name, os_version };
}

export type ComputeImageType = "virtual_machine" | "app_catalog";

export function getResourceTypeImages(images: VmImage[], type: ComputeImageType): VmImage[] {
    return images.filter((s) => (type === "virtual_machine" ? !s.is_app_catalog : s.is_app_catalog));
}

export function getDefaultOs(images: VmImage[]): SelectedOs {
    const defaultImage = images.find((i) => i.is_default) ?? images[0];
    assertNotNull(defaultImage, "No default image found");
    return {
        os_name: defaultImage.os_name,
        os_version: getDefaultVersion(defaultImage).os_version,
    };
}

export function getDefaultVersion(image: VmImage): VmImageVersion {
    const defaultVersion = image.versions[image.versions.length - 1];
    assertNotNull(defaultVersion, "No default version found");
    return defaultVersion;
}

export function osIsWindows(os: SelectedOs | string): boolean {
    const os_name = typeof os === "string" ? os : os.os_name;
    return os_name.includes("windows");
}
