import { useQuery } from "@apollo/client";
import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { gf, type GqlFieldsOf } from "../FieldConfig.tsx";
import { ExactNumberFilter, TextFilter, UnixDateRangeFilter } from "../filters.tsx";
import { gql } from "../graphql.gen/gql.ts";
import { type GetUserListQuery, OrderDirection, UserOrderFields } from "../graphql.gen/graphql.ts";
import { extractData } from "../graphql/extractData.tsx";
import { GraphqlTable } from "../GraphqlTable.tsx";
import { ImpersonateBlock } from "./ImpersonationBlock.tsx";

const GET_USERS = gql(/* GraphQL */ `
    query GetUserList(
        $limit: Int
        $page: Int
        $orderField: UserOrderFields
        $orderDir: OrderDirection
        $search: String
        $filters: [UserFilter!]
    ) {
        admin_user_list(
            limit: $limit
            page: $page
            orderField: $orderField
            orderDir: $orderDir
            search: $search
            filters: $filters
        ) {
            items {
                id
                # signup_site
                name
                profile_data {
                    # avatar
                    created_at
                    # updated_at
                    email
                    first_name
                    last_name
                    # personal_id_number
                    # phone_number
                }
            }
            paging {
                total
            }
        }
    }
`);

export type GQUserItem = NonNullable<GetUserListQuery["admin_user_list"]["items"]>[number];

const userFields: GqlFieldsOf<GQUserItem, UserOrderFields> = [
    gf({
        id: "user_id",
        title: "ID",
        get: (a) => a.id,
        copyable: true,
        order: UserOrderFields.Id,
        filter: ExactNumberFilter,
    }),
    gf({
        id: "created_at",
        title: "Created At",
        get: (a) => a.profile_data && apiDatetimeToDate(a.profile_data.created_at!),
        order: UserOrderFields.ProfileCreatedAt,
        filter: UnixDateRangeFilter,
    }),
    gf({
        id: "name",
        title: "Username",
        get: (a) => a.name,
        order: UserOrderFields.Name,
        filter: TextFilter,
    }),
    gf({
        id: "first_name",
        title: "First Name",
        get: (a) => a.profile_data?.first_name,
        order: UserOrderFields.ProfileFirstName,
        filter: TextFilter,
    }),
    gf({
        id: "last_name",
        title: "Last Name",
        get: (a) => a.profile_data?.last_name,
        order: UserOrderFields.ProfileLastName,
        filter: TextFilter,
    }),
];

export function UsersTable() {
    return (
        <GraphqlTable
            title="Users"
            fields={userFields}
            defaults={{ orderField: UserOrderFields.Id, orderDir: OrderDirection.Desc }}
            getId={(item) => item.id}
            useQuery={(variables) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const q = useQuery(GET_USERS, { variables });
                return extractData(q, (d) => d.admin_user_list);
            }}
            actionButtons={(item) => <ImpersonateBlock item={item} />}
        />
    );
}
