import { EditableButton } from "../../../components/forms/EditableButton.tsx";
import { WModal } from "../../../components/modal/WModal.tsx";
import { MetalStatusBadge } from "../../../modules/metal/status/MetalStatus.tsx";
import { LeaseModal } from "./LeaseModal.tsx";
import type { AdminMachineWithOs } from "./machinesWithOs.ts";

export function StatusEditButton({ item }: { item: AdminMachineWithOs }) {
    const canEdit = item.status !== "deleted";
    const badge = <MetalStatusBadge value={item.status} />;
    return canEdit ? (
        <WModal button={<EditableButton action={undefined}>{badge}</EditableButton>}>
            <LeaseModal machine={item} />
        </WModal>
    ) : (
        badge
    );
}
