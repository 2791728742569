import type { QueryResult } from "@apollo/client";

export interface GqData<T> {
    data: T | undefined;
    error?: Error;
    loading: boolean;
    // networkStatus: NetworkStatus;
}

/**
 * Transform a GraphQL query result by using a function to "map" over the data.
 *
 * @returns query result, with `data` returned by {@link extract()}, `undefined` when loading
 */
export function extractData<T, TR>(
    query: QueryResult<T>,
    extract: (data: NonNullable<QueryResult<T>["data"]>) => TR,
): GqData<TR> {
    const { error, loading, data } = query;
    return {
        data: data ? extract(data) : undefined,
        error,
        loading,
        // networkStatus,
    };
}
