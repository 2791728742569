import D from "./Dashboard.module.css";

import { BadgeDot } from "../../components/Badge.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { cn } from "../../utils/classNames.ts";
import { StatusBadge } from "../hosts/StatusBadge.tsx";
import type { AdminHostWithType } from "../hosts/hostQuery.ts";

const Line = ({
    height,
    color,
    value,
    isShort,
    className,
}: {
    height: number;
    value: number;
    color: string;
    isShort?: boolean;
    className?: string;
}) => (
    <div
        className={cn(D.Line, className)}
        style={{
            height: `${height}%`,
            backgroundColor: color,
        }}
    >
        {!isShort && <div className={cn(height < 5 && D.Label)}>{value.toFixed(0)}</div>}
    </div>
);

const Overcommitted = ({ value }: { value: number }) => (
    <div className={cn(D.Line, D.overcommitted)}>{value.toFixed(0)}</div>
);

export function Bar({ item, isShort }: { item: AdminHostWithType; isShort?: boolean }) {
    const {
        name,
        os,
        is_accepting_workloads,
        is_maintenance,
        is_offline,
        vmem_free,
        vcpu_free,
        allocated_vmem,
        usable_vmem_amount,
        allocated_vcpu,
        usable_vcpu_amount,
    } = item;

    const over_vmem = allocated_vmem > usable_vmem_amount ? allocated_vmem - usable_vmem_amount : 0;
    const over_vcpu = allocated_vcpu > usable_vcpu_amount ? allocated_vcpu - usable_vcpu_amount : 0;
    const over_vmem_percent = over_vmem ? (over_vmem / usable_vmem_amount) * 100 : 0;
    const over_vcpu_percent = over_vcpu ? (over_vcpu / usable_vcpu_amount) * 100 : 0;
    const allocated_vmem_percent = (allocated_vmem / usable_vmem_amount) * 100 - over_vmem_percent;
    const allocated_vcpu_percent = (allocated_vcpu / usable_vcpu_amount) * 100 - over_vcpu_percent;
    let free_vmem = 0;
    let free_vmem_percent = 0;
    let free_vcpu = 0;
    let free_vcpu_percent = 0;
    let unavailable_vmem = 0;
    let unavailable_vcpu = 0;
    let unavailable_vmem_percent = 0;
    let unavailable_vcpu_percent = 0;

    if (is_accepting_workloads && !is_maintenance && !is_offline) {
        free_vmem = vmem_free;
        free_vcpu = vcpu_free;
        free_vmem_percent = (vmem_free / usable_vmem_amount) * 100;
        free_vcpu_percent = (vcpu_free / usable_vcpu_amount) * 100;
    } else {
        unavailable_vmem = vmem_free;
        unavailable_vcpu = vcpu_free;
        unavailable_vmem_percent = (unavailable_vmem / usable_vmem_amount) * 100;
        unavailable_vcpu_percent = (unavailable_vcpu / usable_vcpu_amount) * 100;
    }

    const tooltip = (
        <div>
            <div className="pb-1">
                <b>{name}</b>
            </div>

            {isShort && (
                <div className="pb-1">
                    <div>
                        <StatusBadge item={item} />
                    </div>
                    <div>
                        {allocated_vmem_percent > allocated_vcpu_percent
                            ? (allocated_vmem_percent + over_vmem_percent).toFixed(1)
                            : (allocated_vcpu_percent + over_vcpu_percent).toFixed(1)}
                        %
                    </div>
                    <div className="color-muted">{os}</div>
                </div>
            )}

            {!!over_vmem && (
                <BadgeDot color="color-error">
                    vRAM overcommitted: <b>{over_vmem.toFixed(1)}</b> GB
                </BadgeDot>
            )}

            {!!allocated_vmem && (
                <BadgeDot color="color-chart-2">
                    vRAM allocated: <b>{(allocated_vmem - over_vmem).toFixed(1)}</b> GB
                </BadgeDot>
            )}

            {free_vmem > 0 && (
                <BadgeDot color="color-chart-free">
                    vRAM available: <b>{free_vmem.toFixed(1)}</b> GB
                </BadgeDot>
            )}

            {unavailable_vmem > 0 && (
                <BadgeDot color="color-secondary">
                    vRAM not available: <b>{unavailable_vmem.toFixed(1)}</b> GB
                </BadgeDot>
            )}

            {!!over_vcpu && (
                <BadgeDot color="color-error">
                    vCPU overcommitted: <b>{over_vcpu.toFixed(1)}</b>
                </BadgeDot>
            )}

            {!!allocated_vcpu && (
                <BadgeDot color="color-chart-3">
                    vCPU allocated: <b>{(allocated_vcpu - over_vcpu).toFixed(1)}</b>
                </BadgeDot>
            )}

            {free_vcpu > 0 && (
                <BadgeDot color="color-chart-free">
                    vCPU available: <b>{free_vcpu.toFixed(1)}</b>
                </BadgeDot>
            )}

            {unavailable_vcpu > 0 && (
                <BadgeDot color="color-secondary">
                    vCPU not available: <b>{unavailable_vcpu.toFixed(1)}</b>
                </BadgeDot>
            )}
        </div>
    );

    return (
        <div className={D.Bar}>
            <WTooltip reverse wide text={tooltip}>
                <div className={cn(D.Inner, isShort && D.noGap)}>
                    <div className={D.Ram}>
                        {!!over_vmem && <Overcommitted value={over_vmem} />}

                        {free_vmem > 0 && (
                            <Line
                                height={free_vmem_percent}
                                color="var(--color-chart-free)"
                                value={free_vmem}
                                isShort={isShort}
                            />
                        )}

                        {unavailable_vmem > 0 && (
                            <Line
                                height={unavailable_vmem_percent}
                                color="var(--color-secondary)"
                                value={unavailable_vmem}
                                isShort={isShort}
                            />
                        )}

                        {!!allocated_vmem && (
                            <Line
                                height={allocated_vmem_percent}
                                color="var(--color-chart-2)"
                                value={allocated_vmem - over_vmem}
                                isShort={isShort}
                            />
                        )}
                    </div>

                    <div className={D.Cpu}>
                        {!!over_vcpu && <Overcommitted value={over_vcpu} />}

                        {free_vcpu > 0 && (
                            <Line
                                height={free_vcpu_percent}
                                color="var(--color-chart-free)"
                                value={free_vcpu}
                                isShort={isShort}
                            />
                        )}

                        {unavailable_vcpu > 0 && (
                            <Line
                                height={unavailable_vcpu_percent}
                                color="var(--color-secondary)"
                                value={unavailable_vcpu}
                                isShort={isShort}
                            />
                        )}

                        {!!allocated_vcpu && (
                            <Line
                                height={allocated_vcpu_percent}
                                color="var(--color-chart-3)"
                                value={allocated_vcpu - over_vcpu}
                                isShort={isShort}
                            />
                        )}
                    </div>
                </div>
            </WTooltip>

            <div className={D.Title}>
                {isShort ? (
                    <div className="flex justify-center">
                        <StatusBadge item={item} isDot />
                    </div>
                ) : (
                    <>
                        <div>
                            <b>{item.name}</b>
                        </div>
                        <div className="py-1">
                            <StatusBadge item={item} />
                        </div>
                        <div>
                            {allocated_vmem_percent > allocated_vcpu_percent
                                ? (allocated_vmem_percent + over_vmem_percent).toFixed(1)
                                : (allocated_vcpu_percent + over_vcpu_percent).toFixed(1)}
                            %
                        </div>
                        <div className="color-muted">{os}</div>
                    </>
                )}
            </div>
        </div>
    );
}
