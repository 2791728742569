import type { QueryKey } from "@tanstack/react-query";
import type { VmImage } from "@warrenio/api-spec/spec.oats.gen";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";

export type VmImageType = "app_catalog" | "plain_os";

//#region Query and parameters
const queryKey: QueryKey = ["vm_images"];

//#region Mutations

export const vmImageCreateMutation = adminMutation((apiClient) => ({
    entity: "image",
    operation: "create",
    requestFn: async ({ body, image_type }: { image_type: VmImageType; body: VmImage }) =>
        await apiClient.POST("/config/vm_images/{image_type}", {
            params: { path: { image_type: image_type } },
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));

export const vmImageUpdateMutation = adminMutation((apiClient) => ({
    entity: "image",
    operation: "update",
    requestFn: async ({
        os_name,
        image_type,
        body,
    }: {
        os_name: VmImage["os_name"];
        image_type: VmImageType;
        body: VmImage;
    }) =>
        await apiClient.PATCH("/config/vm_images/{image_type}/{os_name}", {
            params: { path: { image_type: image_type, os_name: os_name } },
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));

export const vmImageDeleteMutation = adminMutation((apiClient) => ({
    entity: "image",
    operation: "delete",
    requestFn: async ({ os_name, image_type }: { os_name: VmImage["os_name"]; image_type: VmImageType }) =>
        await apiClient.DELETE("/config/vm_images/{image_type}/{os_name}", {
            params: { path: { image_type: image_type, os_name: os_name } },
        }),
    invalidateQueryKey: queryKey,
}));

//#endregion Mutations
