import type { ReactNode } from "react";
import type { FieldError } from "react-hook-form";

export function FormFieldErrors({ errors }: { errors: ReactNode[] }) {
    if (errors.length === 0) {
        return null;
    }

    return (
        <>
            {errors.map((error, i) => (
                <div key={i} className="react-aria-FieldError">
                    {error}
                </div>
            ))}
        </>
    );
}

export function RhfError({ error }: { error: FieldError | undefined }) {
    return error != null && <FormFieldErrors errors={[error.message]} />;
}
