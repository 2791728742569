import { WModal } from "../../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../../modules/api/useStandardMutation.ts";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { ADeleteButton } from "../../ADeleteButton.tsx";
import { AdminList } from "../../AdminList.tsx";
import { AdminTable } from "../../AdminTable.tsx";
import { AEditButton } from "../../AEditButton.tsx";
import { specFields } from "./specFields.tsx";
import { SpecItemModal } from "./SpecItemModal.tsx";
import { metalSpecQueryAtom, specDeleteMutation } from "./specQuery.ts";

export function SpecsView() {
    return (
        <AdminList entityType="spec" addModal={<SpecItemModal />}>
            <AdminSpecsTable />
        </AdminList>
    );
}

function AdminSpecsTable() {
    const specs = useSuspenseQueryAtom(metalSpecQueryAtom);
    const deleteMutation = useStandardMutation(specDeleteMutation);

    return (
        <AdminTable
            items={[...specs.values()]}
            fields={specFields}
            getId={(e) => e.uuid}
            actionButtons={(item) => (
                <>
                    <WModal button={<AEditButton />}>
                        <SpecItemModal item={item} />
                    </WModal>
                    <ADeleteButton entityType="spec" deleteFn={async () => await deleteMutation.mutateAsync(item)} />
                </>
            )}
        />
    );
}
