import T from "../../../components/forms/TextField.module.css";

import type {
    LinkParamsGeneric,
    MethodConfig,
    ProcessorConfig,
    SslcommerzRedirectSendDataResponse,
} from "@warrenio/api-spec/spec.oats.gen";
import { useImperativeHandle, useRef, type Ref } from "react";
import { Input, TextField } from "react-aria-components";
import { useForm } from "react-hook-form";
import invariant from "tiny-invariant";
import { useAriaField } from "../../../components/forms/ariaFieldRegister.ts";
import { requiredMessage } from "../../../components/forms/requiredMessage.ts";
import { WFakeForm } from "../../../components/forms/WHookForm.tsx";
import { useUnmountSignal } from "../../../utils/react/useUnmountSignal.tsx";
import { useApiClient } from "../../api/apiClient.store.ts";
import { DescriptionLayout, ErrorText } from "../payment_forms/components.tsx";
import { TopUpResult, type TopUpParams, type TopUpProps } from "../topup/TopUpParams.ts";
import { getRedirectUrlFromData, linkSendData, persistentDataRequest, pollForSuccess } from "../topup/topUpUtils.ts";
import { parseSslcommerzPersistentData } from "./parseSslcommerzPersistentData.ts";

export function SslcommerzTopUpForm({
    actionsRef,
    isSubmitting,
    method: { key },
}: {
    config: ProcessorConfig & { type: "link" };
    method: MethodConfig;
} & TopUpProps) {
    //#region Hooks

    const apiClient = useApiClient();
    const signal = useUnmountSignal();
    const getExtraRef = useRef<GetExtraSourceFields>(null);

    useImperativeHandle(actionsRef, () => ({
        needsPopUp(_params) {
            return true;
        },

        async topUp(params: TopUpParams) {
            const { progress, popup, account } = params;

            // Query the form component for extra fields
            const extraFields = await getExtraRef.current?.();
            if (extraFields === false) {
                return TopUpResult.VALIDATION_FAILED;
            }

            progress("Starting payment");
            const sendResult = await linkSendData<LinkParamsGeneric>(apiClient, params, "sslcommerz", {
                paymentMethod: key,
                ...extraFields,
            });

            const data = sendResult.data as SslcommerzRedirectSendDataResponse;
            const { persistent_data_ref } = data;

            invariant("redirect_url" in data, "Expected redirect_url in response");
            invariant(persistent_data_ref, "Expected persistent_data_ref in response");

            progress("Redirecting to payment gateway");
            invariant(popup, "Popup must be provided");
            popup.handleRedirect(getRedirectUrlFromData(data));

            // await popup.returnFromRedirect(signal);

            progress("Waiting for payment confirmation");
            return await pollForSuccess({
                signal,
                popup,
                check: async () =>
                    parseSslcommerzPersistentData(
                        await persistentDataRequest(apiClient, account.id, persistent_data_ref, signal),
                    ) != null,
            });
        },
    }));
    //#endregion

    return (
        <>
            <SslcommerzForm isSubmitting={isSubmitting} getExtraRef={getExtraRef} />
            <TenbyteTermsAndConditions />
        </>
    );
}

// TODO: Only show this for Tenbyte
function TenbyteTermsAndConditions() {
    return (
        <p>
            By continuing with "Top Up" you agree to the{" "}
            <a
                className="react-aria-Link"
                target="_blank"
                href="https://tenbytecloud.com/terms-and-conditions/"
                rel="noopener noreferrer"
            >
                Terms & Conditions
            </a>
            ,{" "}
            <a
                className="react-aria-Link"
                target="_blank"
                href="https://tenbytecloud.com/privacy-policy/"
                rel="noopener noreferrer"
            >
                Privacy Policy
            </a>{" "}
            &{" "}
            <a
                className="react-aria-Link"
                target="_blank"
                href="https://tenbytecloud.com/refund-policy-bd/"
                rel="noopener noreferrer"
            >
                Refund Policy
            </a>
            .
        </p>
    );
}

export default SslcommerzTopUpForm;

interface ExtraSourceParams {
    phone_number: string;
}

type GetExtraSourceFields = () => Promise<ExtraSourceParams | false>;

interface FormProps {
    isSubmitting: boolean;
    getExtraRef: Ref<GetExtraSourceFields>;
}

function SslcommerzForm({ getExtraRef, isSubmitting }: FormProps) {
    interface SslcommerzInputs {
        phone_number: ExtraSourceParams["phone_number"];
    }

    //#region Hooks
    const form = useForm<SslcommerzInputs>({
        disabled: isSubmitting,
        defaultValues: {
            phone_number: "",
        },
    });

    useImperativeHandle(getExtraRef, () => async () => {
        const valid = await form.trigger(undefined, { shouldFocus: true });
        if (!valid) {
            console.debug("Form validation failed");
            return false;
        }

        return form.getValues();
    });

    const phoneF = useAriaField("phone_number", form, { required: requiredMessage });
    //#endregion

    return (
        <WFakeForm form={form}>
            <TextField className={T.TextField} {...phoneF.props}>
                <DescriptionLayout
                    label="Phone number"
                    description="The phone/mobile number to contact if any issue arises"
                >
                    <Input className={T.Input} ref={phoneF.ref} autoFocus={true} />
                    <ErrorText text={phoneF.props.errorMessage} />
                </DescriptionLayout>
            </TextField>
        </WFakeForm>
    );
}
