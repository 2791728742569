import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { VmImage } from "@warrenio/api-spec/spec.oats.gen";
import { mapFrom } from "@warrenio/utils/collections/maps";
import { atom } from "jotai/vanilla";
import { mergeLoadedQueries, queryThen } from "../../utils/query/mergeQueries.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";
import { userIsAdminAtom } from "../user/apiOperations.ts";

export type Response = VmImage[];

export const queryKey: QueryKey = ["vm_images"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            return getResponseData(await client.GET("/config/vm_images", { signal }));
        },
    });
}

export const allImagesQueryAtom = atomFromStandardQueryOptions(getQuery);

function getAvailableImages(images: VmImage[], isAdmin: boolean): VmImage[] {
    const visibleImageVersions = images.map((i) => ({
        ...i,
        versions: isAdmin
            ? i.versions.map((v) => ({
                  ...v,
                  display_name: `${v.display_name}${v.published ? "" : " (unpublished)"}`,
              }))
            : i.versions.filter((v) => v.published),
    }));

    return visibleImageVersions.filter((i) => i.versions.length > 0);
}

export const availableImagesAtom = atom((get) =>
    mergeLoadedQueries({ images: get(allImagesQueryAtom), isAdmin: get(userIsAdminAtom) }, ({ images, isAdmin }) => {
        return getAvailableImages(images, isAdmin);
    }),
);

/** Map from OS name to image */
export const imagesByOsNameAtom = atom((get) =>
    queryThen(get(allImagesQueryAtom), (data) => mapFrom(data, (image) => image.os_name)),
);
