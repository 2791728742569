import C from "./Dialog.module.css";

import { notNull } from "@warrenio/utils/notNull";
import { Suspense, useContext, type ReactNode } from "react";
import {
    Dialog,
    DialogTrigger,
    Heading,
    Modal,
    ModalOverlay,
    OverlayTriggerStateContext,
    type ModalOverlayProps,
    type PressEvent,
} from "react-aria-components";
import invariant from "tiny-invariant";
import type { Promisable } from "type-fest";
import { isTodoFn } from "../../dev/todoFn.ts";
import { WErrorBoundary } from "../../modules/error/ErrorFallback.tsx";
import { raiseBackgroundErrorToast } from "../../modules/notifications/toast.tsx";
import { mcn, type BaseProps } from "../../utils/baseProps.ts";
import { callChildren, cn } from "../../utils/classNames.ts";
import { WButton, type WButtonProps } from "../button/WButton.tsx";
import { Loading } from "../loading/Loading.tsx";

/** Display a component in an overlay (eg. fullscreen with a gray background) */
export function WModalOverlay({ children, ...props }: { children: ReactNode } & ModalOverlayProps) {
    return (
        <ModalOverlay className={C.ModalOverlay} {...props}>
            <Modal className={cn(C.Modal, C.wide)}>
                <ModalBoundary>{children}</ModalBoundary>
            </Modal>
        </ModalOverlay>
    );
}

/** A mock modal overlay for stories (preserves original modal dimensions for automatic screenshots) */
export function MockModalOverlay({ children }: { children: ReactNode }) {
    return (
        <OverlayTriggerStateContext.Provider
            value={{
                isOpen: false,
                setOpen: () => {},
                open: () => {},
                close: () => {},
                toggle: () => {},
            }}
        >
            <div className={cn(C.MockModalOverlay)}>
                <div className={cn(C.Modal, C.wide)}>
                    <ModalBoundary>{children}</ModalBoundary>
                </div>
            </div>
        </OverlayTriggerStateContext.Provider>
    );
}

export interface WModalProps {
    button?: ReactNode;
    children: ReactNode;
}

/** Automatically trigger a modal on clicking the {@link button}. */
export function WModal({ children, button }: WModalProps) {
    return (
        <DialogTrigger>
            {button}
            <WModalOverlay>{children}</WModalOverlay>
        </DialogTrigger>
    );
}

export const enum ModalResult {
    KEEP_OPEN = "KEEP_OPEN",
    CLOSE = "CLOSE",
}

export interface WModalContentSimpleProps extends BaseProps {
    /** Title of the dialog */
    title: ReactNode;
    /** Content */
    children: ReactNode | ((close: () => void) => ReactNode);
}

export interface WModalContentProps extends Omit<WModalContentSimpleProps, "actionButton"> {
    /** Label for the action button */
    label?: ReactNode;
    /** Extra content to display next to the buttons */
    footerNotice?: ReactNode;

    /** Action button */
    actionButton?: ReactNode | ((close: () => void) => ReactNode);
    /** Auto-focus the action button */
    autoFocus?: WButtonProps["autoFocus"];

    /**
     * An optional action to be performed when the modal is accepted.
     *
     * If the action is a promise, the modal will automatically display a loading indicator.
     *
     * If the action returns {@link ModalResult.KEEP_OPEN}, the modal will not close.
     */
    modalAction: ((e: PressEvent) => Promisable<void> | Promisable<ModalResult>) | undefined;
    isActionDisabled?: boolean;
}

export function WModalContentSimple({ title, children }: WModalContentSimpleProps) {
    return (
        <Dialog>
            {({ close }) => (
                <>
                    <Heading className={C.Heading} slot="title">
                        {title}
                    </Heading>

                    {callChildren(children, close)}
                </>
            )}
        </Dialog>
    );
}

export function WModalBody({ children, ...baseProps }: { children: ReactNode } & BaseProps) {
    return (
        <div {...mcn(C.Content, baseProps)}>
            <Suspense fallback={<ModalLoading />}>{children}</Suspense>
        </div>
    );
}

export interface WModalFooterProps extends BaseProps {
    children?: ReactNode;
    footerNotice?: ReactNode;
}

export function WModalFooter({ children, footerNotice, ...baseProps }: WModalFooterProps) {
    const state = notNull(useContext(OverlayTriggerStateContext), "OverlayTriggerStateContext");
    return (
        <div {...mcn(C.Footer, baseProps)}>
            {footerNotice && <p className="color-error font-size-small">{footerNotice}</p>}
            <WButton action={() => state.close()}>Close</WButton>
            {children}
        </div>
    );
}

/** Standard contents wrapper for a (modal) dialog */
export function WModalContent({
    modalAction,
    isActionDisabled,
    autoFocus,
    footerNotice,
    label,
    children,
    className,
    ...props
}: WModalContentProps) {
    invariant(!label || modalAction, "label requires modalAction");

    async function action(close: () => void, e: PressEvent) {
        let result;
        try {
            result = await modalAction?.(e);
        } catch (e) {
            raiseBackgroundErrorToast(e, "Dialog error");
        } finally {
            if (result !== ModalResult.KEEP_OPEN) {
                close();
            }
        }
    }

    return (
        <WModalContentSimple {...props}>
            {(close) => (
                <>
                    <WModalBody className={className}>{callChildren(children, close)}</WModalBody>
                    <WModalFooter footerNotice={footerNotice}>
                        {modalAction && (
                            <WButton
                                color="primary"
                                isDisabled={isActionDisabled}
                                // Uses WButton's built-in loading indicator
                                action={async (e) => await action(close, e)}
                                isTodo={isTodoFn(modalAction)}
                                autoFocus={autoFocus}
                            >
                                {label}
                            </WButton>
                        )}
                    </WModalFooter>
                </>
            )}
        </WModalContentSimple>
    );
}

function ModalLoading() {
    return (
        <div className={C.Content}>
            <Loading white icon="none" fillSpace />
        </div>
    );
}

function ModalBoundary({ children }: { children: ReactNode }) {
    return (
        <WErrorBoundary tags="ModalBoundary">
            <Suspense fallback={<ModalLoading />}>{children}</Suspense>
        </WErrorBoundary>
    );
}
