import { isDeepEqual } from "remeda";

export function changedFields<TTarget extends Record<string, any>, T extends Partial<TTarget>>(
    original: T,
    target: TTarget,
) {
    let changed: Partial<TTarget> | undefined = undefined;
    for (const key in target) {
        if (!isDeepEqual(original[key], target[key])) {
            changed ??= {};
            changed[key] = target[key];
        }
    }
    return changed;
}
