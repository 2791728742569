import { makeOnce } from "@warrenio/utils/makeOnce";
import { unreachableSwitchCase } from "@warrenio/utils/unreachable";
import type { OmiseCreateSourceCallbackSuccessful, OmiseCreateTokenResponse } from "omise-js-typed/dist/lib/omise";
import type { OmiseResponseFailure } from "omise-js-typed/dist/lib/utils";
import invariant from "tiny-invariant";
import { z } from "zod";

// Lazy create the schema to enable tree-shaking
const TokenParamsSchema = makeOnce(() => {
    const num_str = z.union([z.string().regex(/^\d+$/), z.number()]);
    return z.object({
        name: z
            .string()
            .transform((s) => s.trim())
            .refine((s) => s.length >= 4, "Name must be at least 4 characters"),
        number: z
            .string()
            .regex(/^[\d\s]+$/, "Card number must be digits only")
            .transform((s) => s.replace(/\D/g, ""))
            .refine((s) => s.length === 16, "Invalid card number"),
        expiration_month: num_str.transform(Number).refine((check) => check >= 1 && check <= 12, "Invalid month"),
        expiration_year: num_str.transform((s) => Number(s) + 2000),
        security_code: num_str.transform(Number),
    });
});

type TokenParams = z.infer<ReturnType<typeof TokenParamsSchema>>;

export function makeMockOmise(): typeof Omise {
    return {
        setPublicKey(key) {
            console.debug("mock/Omise.setPublicKey(%o)", key);
        },

        createSource(type, sourceParameters, callback) {
            console.debug("mock/Omise.createSource(%o, %o)", type, sourceParameters);

            let response: OmiseCreateSourceCallbackSuccessful;
            switch (type) {
                case "promptpay":
                    response = mockCreateSourceResponse_PromptPay;
                    break;
                case "truemoney":
                    response = mockCreateSourceResponse_TrueMoney;
                    break;
                default:
                    unreachableSwitchCase(type);
            }

            setTimeout(() => callback(200, response), 1);
        },

        createToken(type, params, callback) {
            console.debug("mock/Omise.createToken(%o, %o)", type, params);

            invariant(type === "card", "Unexpected type");

            const { data, error } = TokenParamsSchema().safeParse(params);

            if (error) {
                console.error("mock/Omise.createToken: Invalid params: %s", error.message, error);
            }

            setTimeout(
                () =>
                    error
                        ? callback(400, {
                              code: "invalid",
                              location: "mock",
                              object: "error",
                              message: "Invalid card data",
                          } satisfies OmiseResponseFailure as OmiseCreateTokenResponse)
                        : callback(200, {
                              ...mockCreateTokenResponse,
                              // Store the card values for later usage in other mocks
                              id: JSON.stringify(data),
                          }),
                1,
            );
        },
    };
}

export function parseOmiseMockToken(id: string): TokenParams {
    return TokenParamsSchema().parse(JSON.parse(id));
}

const mockCreateSourceResponse_PromptPay = {
    object: "source",
    id: "src_test_60a8ttuwozns75j1c9e",
    livemode: false,
    location: "/sources/src_test_60a8ttuwozns75j1c9e",
    amount: 1300,
    barcode: null,
    bank: null,
    created_at: "2024-07-02T22:38:49Z",
    currency: "EUR",
    email: null,
    flow: "offline",
    installment_term: null,
    ip: "1.2.3.4",
    absorption_type: null,
    name: null,
    mobile_number: null,
    phone_number: null,
    platform_type: null,
    scannable_code: null,
    billing: null,
    shipping: null,
    items: [],
    references: null,
    provider_references: null,
    store_id: null,
    store_name: null,
    terminal_id: null,
    type: "promptpay",
    zero_interest_installments: null,
    charge_status: "unknown",
    receipt_amount: null,
    discounts: [],
    promotion_code: null,
    // XXX: omise-js-typed declarations are slightly wrong so have to cast here
} as unknown as OmiseCreateSourceCallbackSuccessful;

const mockCreateSourceResponse_TrueMoney = {
    object: "source",
    id: "src_test_60h3dmlcwkzym9gmqn2",
    livemode: false,
    location: "/sources/src_test_60h3dmlcwkzym9gmqn2",
    amount: 1000,
    barcode: null,
    bank: null,
    created_at: "2024-07-20T10:52:48Z",
    currency: "EUR",
    email: null,
    flow: "redirect",
    installment_term: null,
    ip: "1.2.3.4",
    absorption_type: null,
    name: null,
    mobile_number: "1234567890",
    phone_number: "1234567890",
    platform_type: null,
    scannable_code: null,
    billing: null,
    shipping: null,
    items: [],
    references: null,
    provider_references: null,
    store_id: null,
    store_name: null,
    terminal_id: null,
    type: "truemoney",
    zero_interest_installments: null,
    charge_status: "unknown",
    receipt_amount: null,
    discounts: [],
    promotion_code: null,
} as unknown as OmiseCreateSourceCallbackSuccessful;

const mockCreateTokenResponse = {
    object: "token",
    id: "tokn_test_60bb11wxxmb54676x04",
    livemode: false,
    location: "https://vault.omise.co/tokens/tokn_test_60bb11wxxmb54676x04",
    used: false,
    charge_status: "unknown",
    card: {
        object: "card",
        id: "card_test_60bb11wvz4l5f39ez1x",
        livemode: false,
        location: null,
        deleted: false,
        street1: null,
        street2: null,
        city: null,
        state: null,
        phone_number: null,
        postal_code: null,
        country: "us",
        financing: "credit",
        bank: "JPMORGAN CHASE BANK N.A.",
        brand: "Visa",
        fingerprint: "1rDn1WoAHppxW6R/FTajOnVV+tVRecnk7PJ5YfCfrzE=",
        first_digits: null,
        last_digits: "1111",
        name: "Test Success Visa",
        expiration_month: 12,
        expiration_year: 2034,
        security_code_check: true,
        tokenization_method: null,
        created_at: "2024-07-05T15:45:29Z",
    },
    created_at: "2024-07-05T15:45:29Z",
} as unknown as OmiseCreateTokenResponse;
