import T from "../../../components/forms/TextField.module.css";

import { useForm } from "@tanstack/react-form";
import { Input, NumberField } from "react-aria-components";
import { WButton, WSubmitButton } from "../../../components/button/WButton.tsx";
import { ariaTanstackFieldProps } from "../../../components/forms/ariaTanstackFieldProps.tsx";
import { FormFieldErrors } from "../../../components/forms/FormFieldErrors.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import { addForwardingRuleToLoadBalancerMutation, type LoadBalancerWithType } from "./apiOperations.ts";
import { validatePort, validateSourcePort } from "./validatePorts.ts";

interface AddForwardingRuleFormProps {
    obj: LoadBalancerWithType;
    onClose: () => void;
}

export function AddForwardingRuleForm({ obj, onClose }: AddForwardingRuleFormProps) {
    const addForwardingRuleMutation = useStandardMutation(addForwardingRuleToLoadBalancerMutation);

    const { location, uuid } = obj;

    const form = useForm({
        defaultValues: {
            source_port: NaN,
            target_port: NaN,
        },
        async onSubmit({ value }) {
            await addForwardingRuleMutation.mutateAsync({
                location,
                uuid,
                body: value,
            });
            onClose();
        },
    });

    return (
        <>
            <td>TCP</td>
            <td className="text-right">
                <form.Field
                    name="source_port"
                    validators={{
                        onBlur: ({ value }) => validateSourcePort(value, obj.forwarding_rules, false),
                    }}
                >
                    {(api) => (
                        <NumberField
                            autoFocus
                            className={T.NumberField}
                            aria-label="Set source port value"
                            isRequired
                            formatOptions={{
                                useGrouping: false,
                            }}
                            {...ariaTanstackFieldProps(api)}
                        >
                            <div>
                                <Input className={T.Input} />
                            </div>
                            <FormFieldErrors errors={api.state.meta.errors} />
                        </NumberField>
                    )}
                </form.Field>
            </td>
            <td className="text-center">
                <MaskIcon className="jp-arrow-thin-right-icon size-1.25rem color-muted" />
            </td>
            <td>
                <form.Field
                    name="target_port"
                    validators={{
                        onBlur: ({ value }) => validatePort(value),
                    }}
                >
                    {(api) => (
                        <NumberField
                            className={T.NumberField}
                            aria-label="Set target port value"
                            isRequired
                            formatOptions={{
                                useGrouping: false,
                            }}
                            {...ariaTanstackFieldProps(api)}
                        >
                            <div>
                                <Input className={T.Input} />
                            </div>
                            <FormFieldErrors errors={api.state.meta.errors} />
                        </NumberField>
                    )}
                </form.Field>
            </td>
            <td className="text-right">
                <div className="flex gap-0.5em justify-end">
                    <WSubmitButton icon="jp-icon-add" action={form.handleSubmit}>
                        Add
                    </WSubmitButton>
                    <WButton action={onClose}>Close</WButton>
                </div>
            </td>
        </>
    );
}
