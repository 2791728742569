import type { AdminUser } from "@warrenio/api-spec/spec.oats.gen";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WTableBody } from "../../components/table/WTable.tsx";
import { TODO } from "../../dev/Todo.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { WAdminTable } from "../WAdminTable.tsx";
import { AddAdminUserButton } from "./AddAdminUserModal.tsx";
import { adminUserDeleteMutation, adminUsersQueryAtom } from "./adminUsersQuery.ts";

export function AdminUsersView() {
    const deleteMutation = useStandardMutation(adminUserDeleteMutation);
    const users = useSuspenseQueryAtom(adminUsersQueryAtom);

    async function onDelete({ username }: AdminUser) {
        await deleteMutation.mutateAsync({
            body: {
                username: username,
            },
        });
    }

    return (
        <>
            <AdminTitle title="Admin Users">
                <AddAdminUserButton button={<WModalButton label="New" color="primary" size="bar" variant="basic" />} />
            </AdminTitle>

            <WAdminTable>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {users
                        .sort((a: AdminUser, b: AdminUser) => (parseInt(a.custom_id) > parseInt(b.custom_id) ? 1 : -1))
                        .map((item) => {
                            const { custom_id, username } = item;
                            return (
                                <tr key={custom_id}>
                                    <td>
                                        <ClipBoardTooltip hideIcon>{custom_id}</ClipBoardTooltip>
                                    </td>
                                    <td>
                                        <TODO small title="Link to user table" />
                                        <ClipBoardTooltip hideIcon>{username}</ClipBoardTooltip>
                                    </td>
                                    <td className="text-right">
                                        <div className="flex gap-0.5em justify-end">
                                            <DeleteModal
                                                title="Delete Admin User"
                                                inTable
                                                modalAction={async () => await onDelete(item)}
                                            >
                                                Are you sure you want to remove admin user "<b>{username}</b>"?
                                            </DeleteModal>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </WTableBody>
            </WAdminTable>

            <div className="p-2">
                <AddAdminUserButton
                    button={
                        <WModalButton
                            className="w-full"
                            label="Add Admin User"
                            color="primary"
                            size="lg"
                            variant="dashed"
                            icon="jp-icon-add"
                        />
                    }
                />
            </div>
        </>
    );
}
