import { useAtomValue, useSetAtom } from "jotai/react";
import { type Atom, atom } from "jotai/vanilla";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";
import { useOnce } from "./useOnce.ts";

function AtomContent({ atom }: { atom: Atom<ReactNode> }) {
    const content = useAtomValue(atom);
    return content;
}

export interface UsePortal {
    portalNode: ReactNode;
    element: HTMLDivElement;
    setContent: (content: ReactNode) => void;
}

/**
 * Create an invisible element which can be rendered into using {@link setContent}.
 *
 * Workaround for using React to render into arbitrary HTML elements which will be added to the DOM somewhere else.
 *
 * NB:
 * - {@link element} must be added to the DOM manually for it to be visible.
 * - {@link portalNode} must be somewhere in the React tree to be rendered.
 */
export function usePortal(): UsePortal {
    const contentAtom = useOnce(() => atom<ReactNode>(null));
    const setContent = useSetAtom(contentAtom);
    const element = useOnce(() => document.createElement("div"));
    return {
        portalNode: createPortal(<AtomContent atom={contentAtom} />, element),
        element,
        setContent,
    };
}
