import { notNull } from "@warrenio/utils/notNull";
import type { ReactNode } from "react";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import type { GQVmItem } from "../vm/VmsTable.tsx";
import { unassignFloatingIpMutation } from "./floatingIpsQuery.ts";

export interface UnassignFloatingIpModalProps {
    address: string;
    location: string;
}

export function UnassignFloatingIpModalContent({ address, location }: UnassignFloatingIpModalProps) {
    console.log(address, location);
    const unassignMutation = useStandardMutation(unassignFloatingIpMutation);

    async function onSubmit() {
        await unassignMutation.mutateAsync({ address, location });
    }

    return (
        <WModalContent
            title="Unassign Public IPv4 Address"
            label="Unassign"
            autoFocus
            modalAction={async () => await onSubmit()}
        >
            <p>
                Removing the public IPv4 address assignment from this resource will make the resource inaccessible from
                the public Internet. The IP address will remain reserved to the user's account.
            </p>
        </WModalContent>
    );
}

interface UnassignFloatingIpProps {
    item: GQVmItem;
    button: ReactNode;
}

export function UnassignFloatingIpBlock(props: UnassignFloatingIpProps) {
    const { item } = props;
    const address = item.ip_address_object?.address;

    return (
        <WModal button={props.button}>
            <UnassignFloatingIpModalContent address={address ?? ""} location={notNull(item._location)} />
        </WModal>
    );
}
