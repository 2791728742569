import { atom, type Atom } from "jotai/vanilla";
import { map, mapValues, omitBy, pick, pipe, values } from "remeda";
import { mergeQueries, type QResult } from "../../utils/query/mergeQueries.ts";
import { accessDelegationListQueryAtom, type AccessDelegationWithType } from "../access/delegation/apiOperations.ts";
import type { AccessImpersonationWithType } from "../access/impersonation/apiOperations.ts";
import { apiTokenListQueryAtom, type ApiTokenWithType } from "../access/token/apiOperations.ts";
import { resourceTypesEnabledAtom, type ResourceType } from "../api/resourceTypes.tsx";
import { allVmQueryAtom, type VirtualMachineLoc } from "../compute/vmQuery.ts";
import { metalQueryAtom, type MetalListItemWithType } from "../metal/metalQuery.ts";
import { allIpQueryAtom, type IpAddressWithType } from "../network/ipAddress/apiOperations.ts";
import { allLbsQueryAtom, type LoadBalancerWithType } from "../network/loadbalancer/apiOperations.ts";
import { allVpcsQueryAtom, type VpcWithType } from "../network/vpc/apiOperations.ts";
import { allServicesQueryAtom, type ManagedServiceLoc } from "../services/servicesQuery.ts";
import type { StorageWithType } from "../storage/objectStorage/apiOperations.ts";
import { storageListQueryAtom } from "../storage/storageListQuery.ts";

/** Any resource type that can be shown in the "Recent" block */
export type RecentResource =
    | ApiTokenWithType
    | AccessDelegationWithType
    | AccessImpersonationWithType
    | IpAddressWithType
    | LoadBalancerWithType
    | VpcWithType
    | VirtualMachineLoc
    | ManagedServiceLoc
    | StorageWithType
    | MetalListItemWithType;

type ItemsResult = Map<unknown, RecentResource>;
type QueryAtom = Atom<QResult<ItemsResult>>;

/** Map from resource type to its query atom */
const resourceTypeToQuery = {
    virtual_machine: allVmQueryAtom,
    managed_service: allServicesQueryAtom,
    bucket: storageListQueryAtom,
    vpc: allVpcsQueryAtom,
    ip_address: allIpQueryAtom,
    load_balancer: allLbsQueryAtom,
    api_token: apiTokenListQueryAtom,
    access_delegation: accessDelegationListQueryAtom,
    bare_metal: metalQueryAtom,
    // Not visible except in profile
    access_impersonation: undefined,
    // Listed in separate dashboard block
    billing_account: undefined,
} satisfies Record<ResourceType, QueryAtom | undefined>;

const enabledQueriesAtom = atom((get) => {
    const typeEnabled = get(resourceTypesEnabledAtom);
    return pipe(
        resourceTypeToQuery,
        // Filter non-existent queries and disabled types
        omitBy((atom, type) => atom == null || !typeEnabled[type]),
    );
});

/**
 * All resources (used for dashboard "Recent" block).
 *
 * NB: Only queries enabled resource types to prevent query spam in "lightweight" deployments (eg. bare metal).
 */
export const allResourcesAtom = atom((get) => {
    const enabledTypeQueries = pipe(
        get(enabledQueriesAtom),
        // NB: Must explicitly declare `queryAtom` type here, otherwise TypeScript gets confused since it is a union of all the different query types.
        mapValues((queryAtom: QueryAtom) => get(queryAtom)),
    );

    return mergeQueries(enabledTypeQueries, (maps) =>
        // Flatten the maps into a single array
        maps.flatMap((m: Map<unknown, RecentResource>) => [...m.values()]),
    );
});

/** User-created resources (used to show banner to create something) */
const realResourceQueriesAtom = atom((get) =>
    pipe(
        get(enabledQueriesAtom),
        // Only check resource types that are considered "real" (eg. charged for)
        pick(["virtual_machine", "managed_service", "bucket", "vpc", "ip_address", "load_balancer"]),
        values(),
        // NB: (see comment above for `QueryAtom`)
        map((atom: QueryAtom) => get(atom).data),
    ),
);

// Only export a boolean instead of a query to prevent unnecessary re-renders (an updating `QueryResult` keeping
// the same `data` will still always cause a re-render, because it is an object and Jotai uses `Object.is` for
// comparison).
export const _hasAnyResourcesAtom = atom((get) => get(realResourceQueriesAtom).some((m) => m && m.size > 0));

export const hasNoResourcesAtom = atom((get) => get(realResourceQueriesAtom).every((m) => m && m.size === 0));
