import D from "./Dashboard.module.css";

import { useState } from "react";
import { BadgeDot } from "../../components/Badge.tsx";
import { Separator } from "../../components/Separator.tsx";
import { getPreferredLocation, type LocationWithEnabled } from "../../modules/location/location.ts";
import { usePricingLocations } from "../../modules/location/query.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminLocationSelect } from "../AdminLocationSelect.tsx";
import { adminAllHostsQueryAtom } from "../hosts/hostQuery.ts";
import { BarChart } from "./BarChart.tsx";
import { DonutChart, SmallDonut } from "./DonutChart.tsx";
import { LightBlock } from "./LightBlock.tsx";

function ComputeAllocationSectionContent() {
    const hosts = useSuspenseQueryAtom(adminAllHostsQueryAtom);
    const locations = usePricingLocations();
    const location = getPreferredLocation(locations);
    const [selectedLocation, setSelectedLocation] = useState<LocationWithEnabled>(location);

    const locationHosts = [...hosts.values()].filter((item) => item.location === selectedLocation.slug);
    const locationAvailable = locationHosts.filter(
        (item) => item.is_accepting_workloads && !item.is_maintenance && !item.is_offline,
    );

    let availableCount = 0;

    let totalRam = 0;
    let totalRamUsed = 0;
    let totalRamFree = 0;
    let totalRamUnavailable = 0;

    let totalCpu = 0;
    let totalCpuUsed = 0;
    let totalCpuFree = 0;
    let totalCpuUnavailable = 0;

    for (const item of hosts.values()) {
        totalRamUsed += item.allocated_vmem;
        totalCpuUsed += item.allocated_vcpu;

        if (item.is_accepting_workloads && !item.is_maintenance && !item.is_offline) {
            availableCount++;
            totalRam += item.usable_vmem_amount;
            totalCpu += item.usable_vcpu_amount;

            totalRamFree += item.vmem_free > 0 ? item.vmem_free : 0;
            totalCpuFree += item.vcpu_free > 0 ? item.vcpu_free : 0;
        } else {
            totalRam += item.allocated_vmem;
            totalCpu += item.allocated_vcpu;

            totalRamUnavailable += item.vmem_free;
            totalCpuUnavailable += item.vcpu_free;
        }
    }

    const ramUsedPercent = (totalRamUsed / totalRam) * 100;
    const ramFreePercent = (totalRamFree / totalRam) * 100;
    const ramUnavailablePercent = (totalRamUnavailable / totalRam) * 100;

    const cpuUsedPercent = (totalCpuUsed / totalCpu) * 100;
    const cpuFreePercent = (totalCpuFree / totalCpu) * 100;
    const cpuUnavailablePercent = (totalCpuUnavailable / totalCpu) * 100;

    /* location specific */
    let totalRam_Loc = 0;
    let totalRamUsed_Loc = 0;
    let totalRamFree_Loc = 0;
    let totalRamUnavailable_Loc = 0;

    let totalCpu_Loc = 0;
    let totalCpuUsed_Loc = 0;
    let totalCpuFree_Loc = 0;
    let totalCpuUnavailable_Loc = 0;

    for (const item of locationHosts) {
        totalRamUsed_Loc += item.allocated_vmem;
        totalCpuUsed_Loc += item.allocated_vcpu;

        if (item.is_accepting_workloads && !item.is_maintenance && !item.is_offline) {
            totalRam_Loc += item.usable_vmem_amount;
            totalRamFree_Loc += item.vmem_free > 0 ? item.vmem_free : 0;
            totalCpu_Loc += item.usable_vcpu_amount;
            totalCpuFree_Loc += item.vcpu_free > 0 ? item.vcpu_free : 0;
        } else {
            totalRamUnavailable_Loc += item.vmem_free;
            totalCpuUnavailable_Loc += item.vcpu_free;
            totalRam_Loc += item.allocated_vmem;
            totalCpu_Loc += item.allocated_vcpu;
        }
    }

    const ramUsedPercent_Loc = (totalRamUsed_Loc / totalRam_Loc) * 100;
    const ramFreePercent_Loc = (totalRamFree_Loc / totalRam_Loc) * 100;
    const ramUnavailablePercent_Loc = (totalRamUnavailable_Loc / totalRam_Loc) * 100;

    const cpuUsedPercent_Loc = (totalCpuUsed_Loc / totalCpu_Loc) * 100;
    const cpuFreePercent_Loc = (totalCpuFree_Loc / totalCpu_Loc) * 100;
    const cpuUnavailablePercent_Loc = (totalCpuUnavailable_Loc / totalCpu_Loc) * 100;

    const ramTooltip = (
        <div>
            <BadgeDot color="color-chart-2">
                vRAM allocated: <b>{totalRamUsed.toFixed(0)}</b> GB
            </BadgeDot>

            <BadgeDot color="color-chart-free">
                vRAM available: <b>{totalRamFree.toFixed(0)}</b> GB
            </BadgeDot>

            {!!totalRamUnavailable && (
                <BadgeDot color="color-secondary">
                    Not available: <b>{totalRamUnavailable.toFixed(0)}</b> GB
                </BadgeDot>
            )}

            <BadgeDot color="color-text">
                Total: <b>{(totalRamUsed + totalRamFree + totalRamUnavailable).toFixed(0)}</b> GB
            </BadgeDot>
        </div>
    );

    const cpuTooltip = (
        <div>
            <BadgeDot color="color-chart-3">
                vCPU allocated: <b>{totalCpuUsed.toFixed(0)}</b>
            </BadgeDot>

            <BadgeDot color="color-chart-free">
                vCPU available: <b>{totalCpuFree.toFixed(0)}</b>
            </BadgeDot>

            {!!totalCpuUnavailable && (
                <BadgeDot color="color-secondary">
                    Not available: <b>{totalCpuUnavailable.toFixed(0)}</b>
                </BadgeDot>
            )}

            <BadgeDot color="color-text">
                Total: <b>{(totalCpuUsed + totalCpuFree + totalCpuUnavailable).toFixed(0)}</b>
            </BadgeDot>
        </div>
    );

    const ramTooltipLoc = (
        <div>
            <BadgeDot color="color-chart-2">
                vRAM allocated: <b>{totalRamUsed_Loc.toFixed(0)}</b> GB
            </BadgeDot>

            <BadgeDot color="color-chart-free">
                vRAM available: <b>{totalRamFree_Loc.toFixed(0)}</b> GB
            </BadgeDot>

            {!!totalRamUnavailable_Loc && (
                <BadgeDot color="color-secondary">
                    Not available: <b>{totalRamUnavailable_Loc.toFixed(0)}</b> GB
                </BadgeDot>
            )}

            <BadgeDot color="color-text">
                Total: <b>{(totalRamUsed_Loc + totalRamFree_Loc + totalRamUnavailable_Loc).toFixed(0)}</b> GB
            </BadgeDot>
        </div>
    );

    const cpuTooltipLoc = (
        <div>
            <BadgeDot color="color-chart-3">
                vCPU allocated: <b>{totalCpuUsed_Loc.toFixed(0)}</b>
            </BadgeDot>

            <BadgeDot color="color-chart-free">
                vCPU available: <b>{totalCpuFree_Loc.toFixed(0)}</b>
            </BadgeDot>

            {!!totalCpuUnavailable_Loc && (
                <BadgeDot color="color-secondary">
                    Not available: <b>{totalCpuUnavailable_Loc.toFixed(0)}</b>
                </BadgeDot>
            )}

            <BadgeDot color="color-text">
                Total: <b>{(totalCpuUsed_Loc + totalCpuFree_Loc + totalCpuUnavailable_Loc).toFixed(0)}</b>
            </BadgeDot>
        </div>
    );

    const ramItems = [
        { value: ramUsedPercent, color: "var(--color-chart-2)" },
        { value: ramFreePercent, color: "var(--color-chart-free)" },
        { value: ramUnavailablePercent, color: "var(--color-secondary)" },
    ];

    const cpuItems = [
        { value: cpuUsedPercent, color: "var(--color-chart-3)" },
        { value: cpuFreePercent, color: "var(--color-chart-free)" },
        { value: cpuUnavailablePercent, color: "var(--color-secondary)" },
    ];

    const ramItemsLoc = [
        { value: ramUsedPercent_Loc, color: "var(--color-chart-2)" },
        { value: ramFreePercent_Loc, color: "var(--color-chart-free)" },
        { value: ramUnavailablePercent_Loc, color: "var(--color-secondary)" },
    ];

    const cpuItemsLoc = [
        { value: cpuUsedPercent_Loc, color: "var(--color-chart-3)" },
        { value: cpuFreePercent_Loc, color: "var(--color-chart-free)" },
        { value: cpuUnavailablePercent_Loc, color: "var(--color-secondary)" },
    ];

    return (
        <>
            <div className="flex justify-between gap-1rem pb-3">
                <div>
                    <h2 className="font-size-heading">Compute Allocation</h2>
                    <p>
                        Hypervisors ({availableCount}/{hosts.size})
                    </p>
                </div>

                <SmallDonut
                    marginLeft
                    title="Total vRAM allocated"
                    items={ramItems}
                    tooltip={ramTooltip}
                    total={`${ramUsedPercent.toFixed(1)}%`}
                />

                <SmallDonut
                    title="Total vCPU allocated"
                    items={cpuItems}
                    tooltip={cpuTooltip}
                    total={`${cpuUsedPercent.toFixed(1)}%`}
                />
            </div>

            <Separator />

            <div className="flex justify-between pt-3 pb-3">
                <div className="flex gap-1rem font-size-small">
                    <BadgeDot color="color-chart-2">vRAM allocated</BadgeDot>

                    <BadgeDot color="color-chart-free">vRAM / vCPU available</BadgeDot>

                    <BadgeDot color="color-chart-3">vCPU allocated</BadgeDot>

                    <BadgeDot color="color-secondary">Not available</BadgeDot>
                </div>

                <AdminLocationSelect onChange={(item) => setSelectedLocation(item)} />
            </div>

            <div className="flex items-center gap-1rem justify-between">
                <div>
                    <div className="font-size-title">{selectedLocation.display_name}</div>
                    <p className="pb-3 font-size-small">
                        Hypervisors ({locationAvailable.length}/{locationHosts.length})
                    </p>
                </div>

                <div className="ml-auto flex items-center gap-0.5rem text-right">
                    <div className="font-size-small">vRAM ({ramUsedPercent_Loc.toFixed(1)}%)</div>

                    <div className="w-2rem">
                        <DonutChart items={ramItemsLoc} tooltip={ramTooltipLoc} />
                    </div>
                </div>

                <div className="flex items-center gap-0.5rem text-right">
                    <div className="font-size-small">vCPU ({cpuUsedPercent_Loc.toFixed(1)}%)</div>

                    <div className="w-2rem">
                        <DonutChart items={cpuItemsLoc} tooltip={cpuTooltipLoc} />
                    </div>
                </div>
            </div>

            <BarChart items={locationHosts} />
        </>
    );
}

export function ComputeAllocationSection() {
    return (
        <LightBlock className={D.Wide}>
            <ComputeAllocationSectionContent />
        </LightBlock>
    );
}
