import FF from "../../../components/forms/FormField.module.css";
import TF from "../../../components/forms/TextField.module.css";

import { useMutation } from "@tanstack/react-query";
import { Navigate } from "@tanstack/react-router";
import type {
    BillingAccountCreateFields,
    BillingAccountField,
    BillingAccountId,
    BillingAccountUpdateFields,
} from "@warrenio/api-spec/spec.oats.gen";
import { editableFields } from "@warrenio/api-spec/spec/billing.editableFields";
import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { useAtom, useAtomValue } from "jotai/react";
import { Fragment, useState, type ReactNode } from "react";
import { Input } from "react-aria-components";
import { Controller, useForm, useFormContext, type SubmitHandler, type UseFormProps } from "react-hook-form";
import { isEmpty, pick } from "remeda";
import { HeroBlock } from "../../../components/HeroBlock.tsx";
import { Separator } from "../../../components/Separator.tsx";
import { ViewTitle } from "../../../components/Title.tsx";
import { Toolbar } from "../../../components/Toolbar.tsx";
import { WButton } from "../../../components/button/WButton.tsx";
import { WToolButton } from "../../../components/button/WToolButton.tsx";
import { FormActions, FormSubmitButton } from "../../../components/forms/CreateFormAction.tsx";
import { FormField, type FormFieldProps } from "../../../components/forms/FormField.tsx";
import { WCheckbox } from "../../../components/forms/WCheckbox.tsx";
import { WHookForm } from "../../../components/forms/WHookForm.tsx";
import { WTextField, type WTextFieldProps } from "../../../components/forms/WTextField.tsx";
import { formPropsToAriaProps } from "../../../components/forms/ariaController.tsx";
import { useAriaField } from "../../../components/forms/ariaFieldRegister.ts";
import { requiredMessage } from "../../../components/forms/requiredMessage.ts";
import { ContentLoadingSuspense } from "../../../components/loading/Loading.tsx";
import { useConfig } from "../../../config.ts";
import { testingLibraryContext } from "../../../dev/BrowserTestContext.testing-library.ts";
import { cn } from "../../../utils/classNames.ts";
import { isStorybook } from "../../../utils/environment.ts";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import { CountrySelect } from "../../location/CountrySelect.tsx";
import { raiseBackgroundErrorToast } from "../../notifications/toast.tsx";
import { useThemeProps } from "../../theme/useTheme.ts";
import { userDataAtom } from "../../user/apiOperations.ts";
import { FirstAccountNotices } from "../FirstAccountNotices.tsx";
import {
    createBillingAccountMutation,
    updateBillingAccountMutation,
    useBillingAccount,
    type BillingAccountWithType,
} from "../billingAccountQuery.ts";
import { billingStateAtom, EBillingAccount } from "../billingLogic.tsx";
import { AddMethodResult } from "../choose_method/AddMethodParams.ts";
import { ChooseMethodContent } from "../choose_method/ChooseMethod.tsx";
import { useAddMethod } from "../choose_method/useAddMethod.tsx";
import { FileWithSourceInput, type FileWithSource } from "../forms/FileWithSourceInput.tsx";
import { billingAccountLink } from "../links.ts";
import { referralCodeAtom } from "../referral/referralLogic.ts";
import { baFieldsConfigAtom, type EditableFieldName } from "./fieldsConfig.ts";
import { baLabels } from "./fieldsInfo.ts";
import { accountCreateInteract } from "./test/accountCreateInteract.tsx";

/**
 * NB: Inconsistency in the Create API, the the input field is `client_email` but the returned object field is named
 * `email`.
 *
 * Pretend {@link BillingAccountCreateFields} looks like {@link BillingAccountUpdateFields} to reduce the amount of
 * special casing.
 *
 * (the inverse transformation is done in the `onSubmit` function)
 */
interface FixedBillingAccountCreateFields extends Omit<BillingAccountCreateFields, "client_email"> {
    email: BillingAccountCreateFields["client_email"];
}

/** A valid field name must be listed in the {@link editableFields} array. */
interface BillingFormInputs extends Pick<FixedBillingAccountCreateFields, EditableFieldName> {}

function useBillingForm(props: UseFormProps<BillingFormInputs>) {
    return useForm<BillingFormInputs>({ ...props });
}

function BillingCreateForm() {
    //#region Hooks
    const config = useConfig();
    const { siteLanguage, siteCountry } = config;

    const adder = useAddMethod();
    const [methodInvalid, setMethodInvalid] = useState(false);

    const [storedReferralCode, setStoredReferralCode] = useAtom(referralCodeAtom);
    const { canEnterReferralCode } = useSuspenseQueryAtom(billingStateAtom);
    const referral_code = !canEnterReferralCode ? undefined : storedReferralCode;

    const form = useBillingForm({
        defaultValues: {
            country: siteCountry,
            referral_code,
        },
    });

    const createMutation = useStandardMutation(createBillingAccountMutation);
    const accountWasCreated = createMutation.isSuccess;

    async function doCreateAccount(inputs: BillingFormInputs) {
        const site = userData.signup_site ?? "__missing__";
        const lang = userData.profile_data?.lang ?? siteLanguage;

        // Payment type, used to force post-payment mode in backend
        const type = adder.props.value === "invoice" ? "invoice" : "other";

        // Rename `email` -> `client_email`
        const { email, ...rest } = inputs;
        const body: BillingAccountCreateFields = { client_email: email, site, lang, type, ...rest };

        const accountData = await createMutation.mutateAsync({ body });

        // Clear referral code after successful creation
        if (canEnterReferralCode) {
            setStoredReferralCode(undefined);
        }

        return accountData;
    }

    /** Mutation `data` will be `null` if there is a failure */
    const fullMutation = useMutation({
        async mutationFn({ inputs }: { inputs: BillingFormInputs }) {
            // Skip creation if we are retrying adding a payment method
            const accountData = createMutation.isSuccess ? createMutation.data : await doCreateAccount(inputs);

            if (!addLater) {
                try {
                    const result = await doAddPaymentMethod(accountData);

                    switch (result) {
                        case AddMethodResult.CANCELLED:
                        case AddMethodResult.VALIDATION_FAILED:
                            return null;
                        case AddMethodResult.SUCCESS:
                            return accountData;
                        default:
                            exhaustiveSwitchCheck(result);
                    }
                } catch (e) {
                    raiseBackgroundErrorToast(e, "Adding payment method failed");
                    return null;
                }
            }

            return accountData;
        },
    });

    async function doAddPaymentMethod(accountData: BillingAccountWithType) {
        const account = new EBillingAccount(accountData, config);

        return await adder.addPaymentMethod({ account });
    }

    const userData = useSuspenseQueryAtom(userDataAtom); // TODO: Move loading this into `onSubmit` (needs query -> promise conversion)

    const [addLater, setAddLater] = useState(false);

    //#endregion

    if (fullMutation.isSuccess && fullMutation.data) {
        return <Navigate {...billingAccountLink(fullMutation.data)} />;
    }

    const onSubmit: SubmitHandler<BillingFormInputs> = async (inputs, event) => {
        console.debug("Creating billing account with", { inputs, addLater, adder, event });
        if (!addLater) {
            if (!adder.hasSelectedMethod) {
                // throw new Error("No payment method selected");
                setMethodInvalid(true);
                return;
            }
            if (!(await adder.validate(null))) {
                return;
            }
        }

        try {
            return await fullMutation.mutateAsync({ inputs });
        } finally {
            adder.dispose();
        }
    };

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            {import.meta.env.DEV && !isStorybook && (
                <Toolbar>
                    <WToolButton
                        label="Autofill"
                        icon="i-lucide:bug"
                        action={async () => await accountCreateInteract(testingLibraryContext(), "success")}
                    />
                </Toolbar>
            )}

            {!accountWasCreated && (
                // Only show the billing form controls if the account has not been created yet (ie. not when we are retrying adding a payment method)
                <>
                    <BillingFormControls />
                    <Separator />
                </>
            )}

            <h2 className="font-size-title pt-4 pl-4">Payment details</h2>

            <div className="pl-4 pt-4">
                <div style={{ maxWidth: "calc(160px + 440px)" }}>
                    <FirstAccountNotices compact />
                </div>
            </div>
            <FormField wide isRequired label="Method">
                <div className="lightBlock" style={{ maxWidth: "calc(440px)" }}>
                    <p className="color-muted pb-4">
                        No payment will be made currently. You can change your payment method at any time.
                    </p>

                    <p>Choose method</p>
                    <div className="py-3">
                        <Separator />
                    </div>

                    <ChooseMethodContent {...adder.props} isDisabled={addLater} />
                    {!addLater && !adder.hasSelectedMethod && methodInvalid && (
                        <div className={FF.FormFieldError}>Select a payment method</div>
                    )}
                    <div className="py-3">
                        <Separator />
                    </div>

                    <WCheckbox isSelected={addLater} onChange={setAddLater}>
                        Add payment method later
                    </WCheckbox>
                </div>
            </FormField>

            {canEnterReferralCode && !accountWasCreated && (
                <>
                    <Separator />
                    <BField name="referral_code" />
                </>
            )}

            <Separator />

            <FormActions>
                <FormSubmitButton>{!accountWasCreated ? "Create" : "Add payment method"}</FormSubmitButton>
            </FormActions>
        </WHookForm>
    );
}

function BillingEditForm({ billingAccount }: { billingAccount: EBillingAccount }) {
    //#region Hooks

    const fieldsConfig = useAtomValue(baFieldsConfigAtom);

    // NB: Only pick fields that can be sent to the API (to prevent form serialization etc. from erroring)
    const visibleFields = editableFields.filter((name) => fieldsConfig[name]?.visible);
    const defaultValues = pick(billingAccount.account, visibleFields);
    const form = useBillingForm({ defaultValues });

    const updateMutation = useStandardMutation(updateBillingAccountMutation);
    //#endregion

    const onSubmit: SubmitHandler<BillingFormInputs> = async (inputs) => {
        const body: BillingAccountUpdateFields = inputs;
        await updateMutation.mutateAsync({ id: billingAccount.id, body });
    };

    if (updateMutation.isSuccess) {
        return <Navigate {...billingAccountLink(updateMutation.data)} />;
    }

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            <Toolbar>
                <WButton
                    color="primary"
                    size="bar"
                    variant="ghost"
                    action={billingAccountLink(billingAccount)}
                    icon="jp-icon-close"
                >
                    Cancel
                </WButton>
            </Toolbar>
            <div className="pt-4 pl-4 pr-4">
                <ViewTitle className="important:pb-0 important:min-h-auto" title={`Edit "${billingAccount.title}"`} />
            </div>

            <BillingFormControls isEdit />

            <Separator />

            <FormActions>
                <FormSubmitButton>Save</FormSubmitButton>
                <WButton action={billingAccountLink(billingAccount)}>Cancel</WButton>
            </FormActions>
        </WHookForm>
    );
}

function BillingFormControls({ isEdit = false }: { isEdit?: boolean }) {
    //#region Hooks
    const fieldsConfig = useAtomValue(baFieldsConfigAtom);
    const { baFieldPresentation } = useThemeProps();
    const { control, setValue } = useFormContext<BillingFormInputs>();
    //#endregion

    function makeSection(fields: { field: keyof BillingFormInputs; element: ReactNode }[]) {
        return fields
            .filter((f) => fieldsConfig[f.field]?.visible)
            .map((f) => <Fragment key={f.field}>{f.element}</Fragment>);
    }

    const baseSection = makeSection([
        {
            field: "integration_id",
            element: <BField name="integration_id" {...baFieldPresentation?.integration_id} />,
        },
    ]);

    const addressSection = makeSection([
        {
            field: "country",
            element: (
                <Controller
                    name="country"
                    control={control}
                    rules={fieldsConfig.country?.required ? { required: requiredMessage } : undefined}
                    render={(p) => (
                        <FormField label="Country" {...formPropsToAriaProps(p)}>
                            <CountrySelect
                                isDisabled={p.field.disabled}
                                valueKey={p.field.value}
                                onChange={(country) => p.field.onChange(country.code.iso2)}
                            />
                        </FormField>
                    )}
                />
            ),
        },
        { field: "county", element: <BField name="county" className="w-65%" /> },
        { field: "city", element: <BField name="city" className="w-65%" /> },
        { field: "address_line1", element: <BField name="address_line1" /> },
        { field: "address_line2", element: <BField name="address_line2" /> },
        { field: "post_index", element: <BField name="post_index" className="w-65%" /> },
    ]);

    const invoiceSection = makeSection([
        { field: "company_name", element: <BField name="company_name" /> },
        { field: "company_reg_code", element: <BField name="company_reg_code" className="w-65%" /> },
        { field: "company_vat_number", element: <BField name="company_vat_number" className="w-65%" /> },
        {
            field: "email",
            element: (
                <BField
                    type="email"
                    name="email"
                    label="Invoice email"
                    isRequired
                    description="E-mail where invoices and reports are sent."
                />
            ),
        },
        { field: "customer_name", element: <BField name="customer_name" /> },
        { field: "customer_phone", element: <BField name="customer_phone" className="w-65%" /> },
        { field: "customer_id_number", element: <BField name="customer_id_number" className="w-65%" /> },
    ]);

    const documentSection = !isEdit
        ? makeSection([
              {
                  field: "document",
                  element: (
                      <Controller
                          name="document"
                          control={control}
                          rules={fieldsConfig.document?.required ? { required: requiredMessage } : undefined}
                          render={(p) => (
                              <FileFormField
                                  label="File"
                                  onFieldChange={(file) => {
                                      p.field.onChange(file?.base64Source);
                                      setValue("document_name", file?.name);
                                  }}
                                  {...formPropsToAriaProps(p)}
                              />
                          )}
                      />
                  ),
              },
          ])
        : [];

    return (
        <>
            <BField
                name="title"
                label="Billing account name"
                isRequired
                description="This will only be used within the system so it's easy for you to recognize the account if you have several."
                autoFocus
            />
            {!isEmpty(baseSection) && baseSection}

            {!isEmpty(invoiceSection) && (
                <>
                    <h2 className="font-size-title pt-4 pl-4">Invoice details</h2>
                    {invoiceSection}
                </>
            )}

            {!isEmpty(addressSection) && (
                <>
                    <h2 className="font-size-title pt-4 pl-4">Address</h2>
                    {addressSection}
                </>
            )}

            {!isEmpty(documentSection) && (
                <>
                    <h2 className="font-size-title pt-4 pl-4">Document</h2>
                    {documentSection}
                </>
            )}
        </>
    );
}

interface FileFormFieldProps extends FormFieldProps {
    onFieldChange: (file: FileWithSource | undefined) => void;
}

function FileFormField({ onFieldChange, ...props }: FileFormFieldProps) {
    const [errorMsg, setErrorMsg] = useState<string>("");
    const maxFileSizeInMB = 15;

    return (
        <FormField
            {...props}
            description={`Please upload a copy of the business registration document or ID card for verification (max ${maxFileSizeInMB}MB).`}
            errorMessage={
                // NB: Parent can also pass in an error message
                errorMsg || props.errorMessage
            }
        >
            <FileWithSourceInput
                acceptedFileTypes={["image/*", "application/pdf"]}
                maxFileSizeInMB={maxFileSizeInMB}
                isDisabled={props.isDisabled}
                onChange={onFieldChange}
                onSetError={setErrorMsg}
            />
        </FormField>
    );
}

interface BFieldProps extends WTextFieldProps {
    name: keyof BillingFormInputs;
}

function BField(props: BFieldProps) {
    const { name } = props;
    //#region Hooks
    const fieldsConfig = useAtomValue(baFieldsConfigAtom);
    //#endregion

    const config = fieldsConfig[name];
    if (!config?.visible) {
        return null;
    }
    return <BFieldInner {...props} config={config} />;
}

function BFieldInner({ name, label, config, ...textFieldProps }: BFieldProps & { config: BillingAccountField }) {
    const form = useFormContext<BillingFormInputs>();

    const { ref, props } = useAriaField(name, form, {
        required: config.required ? requiredMessage : false,
    });

    label ??= baLabels[name];

    return (
        <WTextField type="text" label={label} {...props} {...textFieldProps}>
            <Input ref={ref} className={cn(FF.FormFieldInput, TF.Input)} />
        </WTextField>
    );
}

export function BillingCreate() {
    return (
        <>
            <CreateHero />
            <ContentLoadingSuspense>
                <BillingCreateForm />
            </ContentLoadingSuspense>
        </>
    );
}

export function BillingEdit({ baId }: { baId: BillingAccountId }) {
    const account = useBillingAccount(baId);
    return (
        <ContentLoadingSuspense>
            <BillingEditForm billingAccount={account} />
        </ContentLoadingSuspense>
    );
}

function CreateHero() {
    return (
        <HeroBlock title="Create New Billing Account" icon="jp-card-icon">
            <p className="text-muted">
                You can create multiple billing accounts, each linked to specific payment methods. This approach allows
                you to effectively separate and monitor expenses across different teams, departments, or projects.
            </p>
        </HeroBlock>
    );
}
