import { type Atom, getDefaultStore } from "jotai/vanilla";

/** Listen to changes in the atom's value in the default store.
 *
 * Useful for pushing changes to other global state (eg. error reporting).
 */
export function listenAtom<T>(atom: Atom<T>, listener: (value: T) => void, sendInitial = false) {
    const store = getDefaultStore();

    function callListener() {
        listener(store.get(atom));
    }

    if (sendInitial) {
        callListener();
    }

    return store.sub(atom, callListener);
}
