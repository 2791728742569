import { type QueryClient, type QueryKey, queryOptions } from "@tanstack/react-query";
import type { MetalLease, MetalLeaseUpdateBody, MetalListItem } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../utils/query/runMutation.ts";
import { type ApiClient, getResponseData } from "../api/apiClient.ts";
import { getResourceIconClass } from "../api/resourceTypes.tsx";
import { atomAllLocationsQuery } from "../location/atomAllLocationsQuery.ts";
import { raiseRequestToast, type Toast } from "../notifications/toast.tsx";
import { leaseLink } from "./links.ts";
import type { MetalCreateMutation } from "./metal.store.ts";

//#region Response
/** Bare metal with extra `type` property */
export interface MetalListItemWithType extends MetalListItem {
    $type: "bare_metal";
    location: string;
}

/** Map of {@link MetalListItem}s keyed by ID */
type Response = Map<MetalListItem["uuid"], MetalListItemWithType>;

function metalFromData(data: MetalListItem, location: string): MetalListItemWithType {
    return { ...data, location, $type: "bare_metal" };
}

//#endregion

//#region Query and parameters
export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["metal/list"] : ["metal/list", params.location];
}

export interface Params {
    location: string;
}

export function getQuery(client: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            return mapFromEntries(
                getResponseData(
                    await client.GET("/{location}/metal/leases", {
                        signal,
                        params: { path: { location } },
                    }),
                ),
                (metal) => [metal.uuid, metalFromData(metal, location)] as const,
            );
        },
    });
}
//#endregion

//#region Atoms
export const metalLocationQueryAtom = atomFamily((location: string) =>
    atomFromStandardQueryOptions(getQuery, { location }),
);

export const metalQueryAtom = atomAllLocationsQuery(metalLocationQueryAtom, (results) => mergeMaps(results));
//#endregion

//#region Mutations

export const toastOptions: Partial<Toast> = { icon: getResourceIconClass("bare_metal") };

export function leaseCreateMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["bare_metal/create"],
        async mutationFn({ body, location }: MetalCreateMutation) {
            const data = getResponseData(
                await api.POST("/{location}/metal/leases", {
                    ...jsonEncodedBody,
                    params: { path: { location } },
                    body,
                }),
            );
            return metalFromData(data, location);
        },
        async onSettled(res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Bare Metal created",
                successType: "success",
                error: "Error creating Bare Metal",
                viewLink: res ? leaseLink(res) : undefined,
            });

            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function leaseUpdateMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["bare_metal/update"],
        async mutationFn({
            body,
            location,
            uuid,
        }: {
            body: MetalLeaseUpdateBody;
            location: string;
            uuid: MetalLease["uuid"];
        }) {
            return getResponseData(
                await api.PATCH("/{location}/metal/leases/{uuid}", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location, uuid } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Bare Metal updated",
                error: "Error updating Bare Metal",
            });

            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function leaseDeleteMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["bare_metal/delete"],
        async mutationFn({ uuid, location }: { uuid: MetalLease["uuid"]; location: string }) {
            return getResponseData(
                await api.DELETE("/{location}/metal/leases/{uuid}", {
                    params: { path: { location, uuid } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Bare Metal deleted",
                error: "Error deleting Bare Metal",
            });

            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}
//#endregion
