import { Suspense } from "react";
import { BillingAccountNotices } from "../billing/BillingAccountNotices.tsx";
import { InvitationNotice } from "../user/InvitationNotice.tsx";

export function DashboardNotices() {
    // NB: Do NOT place any elements here such as <div>, because it will cause extra padding to be added to the
    // dashboard. This component MUST render nothing when there are no notices.
    return (
        <Suspense>
            <InvitationNotice />
            <BillingAccountNotices />
        </Suspense>
    );
}
