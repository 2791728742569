import { atom, type Atom } from "jotai/vanilla";
import type { ComponentType } from "react";
import type { PreloadableComponent } from "react-lazy-with-preload";
import { atomAction } from "../../../utils/jotai/atomAction";
import { atomWithSessionStorage } from "../../../utils/jotai/atomStorage";
import { startsWithPrefix } from "../../../utils/paths";
import type { NavItem } from "../SideNav";

export const SIDEBAR_CLOSED = null;

/** Currently selected sidebar section. {@link SIDEBAR_CLOSED} means sidebar is closed. */
export const currentSectionAtom = atomWithSessionStorage<string | typeof SIDEBAR_CLOSED | undefined>(
    "currentSection",
    undefined,
);

/** Separate atom for the currently shown component, for ease of testing. */
export const sidebarComponentAtom = atom((get) => {
    const currentSection = get(currentSectionAtom);
    return get(sectionsAtom).find((s) => s.id === currentSection)?.component;
});

export const closeSidebarAction = atomAction((_, set) => {
    set(currentSectionAtom, SIDEBAR_CLOSED);
});

export interface Section extends NavItem {
    component?: PreloadableComponent<ComponentType> | ComponentType;
    /** If the current URL starts with this prefix, automatically select this section */
    routePrefix?: string;
    enabledAtom?: Atom<boolean>;
}

/** This is initialized in a separate module to prevent cyclic dependencies (as the sidebar's sub-components themselves depend on this file) */
export let sectionsAtom: Atom<Section[]>;
// XXX: This needs to be assigned in a separate statement to work around some Vite/Jotai/esbuild bug where `let` is converted to `const` for exports (related to HMR?)
sectionsAtom ??= atom(() => {
    throw new Error("sectionsAtom not initialized");
});

export function setSectionsAtom(sections: Atom<Section[]>) {
    sectionsAtom = sections;
}

export function findSectionForPath(sidebarSections: Section[], toPath: string) {
    const section =
        sidebarSections.find(({ to }) => to != null && to === toPath) ??
        sidebarSections.find(({ routePrefix }) => routePrefix && startsWithPrefix(routePrefix, toPath));

    // Only return the section if it has a sidebar component (not eg. dashboard)
    if (section?.component == null) {
        return undefined;
    }

    return section.id;
}
