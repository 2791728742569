import "./chat.css";

import { makeOnce } from "@warrenio/utils/makeOnce";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useAtomValue } from "jotai/react";
import { useEffect, type ReactNode } from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useIsInAdminMode } from "../../admin/adminMode.ts";
import { chatConfigAtom, getGlobalConfig_UNTESTABLE } from "../../config.ts";
import { loadScript } from "../../utils/loadScript.ts";
import { getThemeVar_ONCE } from "../theme/useTheme.ts";
import type { BotikaConfig, LeadBoosterConfig, OncehubConfig, QiscusConfig } from "./ChatConfig.types.ts";

/* eslint-disable @typescript-eslint/no-namespace */

//#region Qiscus

declare global {
    class Qismo {
        constructor(key: string, options: object);
    }
}

const qiscusLoader = makeOnce(async ({ qiscus_channel_id, qiscus_client_key }: QiscusConfig) => {
    if (!qiscus_client_key || !qiscus_channel_id) {
        console.warn("Missing Qiscus config");
        return;
    }

    await loadScript("https://s3-ap-southeast-1.amazonaws.com/qiscus-sdk/public/qismo/qismo-v4.js");

    const _ = new Qismo(qiscus_client_key, {
        options: {
            channel_id: qiscus_channel_id,
            extra_fields: [],
        },
    });
});

//#endregion

//#region Botika
declare global {
    namespace BotikaChat {
        function init(options: object): unknown;
    }
}

const botikaLoader = makeOnce(async ({ botika_client_key }: BotikaConfig, primaryColor, logoImage) => {
    const { siteName } = getGlobalConfig_UNTESTABLE();

    if (!botika_client_key) {
        console.warn("Missing Botika config");
        return;
    }

    await loadScript("//chat.botika.online/client/assets/js/botika.widget.js");

    BotikaChat.init({
        client: botika_client_key,
        widget: {
            theme: "custom",
            "theme-color": primaryColor,
            "bubble-color": primaryColor,
            "description-color": "#ffffff",
            title: siteName,
            description: "Customer support",
            caption: "Need help?",
            greeting: true,
            logo: logoImage,
            history: true,
            "greeting-message": "Hello",
            "greeting-button": "Start",
            autocomplete: "",
            registerUser: { name: true, email: true, phone: false },
            startMessage: "",
            clientCustom: "",
            buttonPayload: true,
            voice: { active: false, disabled: true },
        },
    });
});
//#endregion

//#region Oncehub

const oncehubLoader = makeOnce(async ({ oncehub_website_id, oncehub_bot_id }: OncehubConfig) => {
    if (!oncehub_website_id || !oncehub_bot_id) {
        console.warn("Missing Oncehub config");
        return;
    }

    await loadScript("https://cdn.oncehub.com/co/widget.js", (script) => {
        script.id = "co-index";
        script.dataset.coParams = `website_id=${oncehub_website_id}&bot_type=2`;
    });

    const link = document.createElement("a");
    link.id = "oncechat-link";
    link.href = "#";
    link.dataset.coBot = oncehub_bot_id;
    link.setAttribute("onclick", "document.getElementById('oncechat-link').style.display='none'");
    document.body.appendChild(link);
});

//#endregion

//#region LeadBooster

declare global {
    interface Window {
        pipedriveLeadboosterConfig: {
            base: string;
            companyId: string;
            playbookUuid: string;
            version: number;
        };
        LeadBooster: {
            q: unknown[];
            on(n: unknown, h: unknown): void;
            trigger(n: unknown): void;
        };
    }
}

const leadBoosterLoader = makeOnce(async ({ company_id, playbook_uuid }: LeadBoosterConfig) => {
    if (!company_id || !playbook_uuid) {
        console.warn("Missing LeadBooster config");
        return;
    }

    if (window.LeadBooster) {
        console.warn("LeadBooster already exists");
    } else {
        window.LeadBooster = {
            q: [],
            on: function (n, h) {
                this.q.push({ t: "o", n: n, h: h });
            },
            trigger: function (n) {
                this.q.push({ t: "t", n: n });
            },
        };
    }

    window.pipedriveLeadboosterConfig = {
        base: "leadbooster-chat.pipedrive.com",
        companyId: company_id,
        playbookUuid: playbook_uuid,
        version: 2,
    };

    await loadScript("https://leadbooster-chat.pipedrive.com/assets/loader.js");
});

//#endregion

function IntercomAdminHider() {
    const intercom = useIntercom();
    const isAdmin = useIsInAdminMode();
    useEffect(() => {
        console.debug("IntercomAdminHider: %o", isAdmin);
        intercom.update({ hideDefaultLauncher: isAdmin });
    }, [isAdmin]);
    return null;
}

function IntercomWrapper({
    children,
    chatConfig,
    primaryColor,
}: {
    children: ReactNode;
    chatConfig: { intercom_app_id: string };
    primaryColor: string;
}) {
    const isAdmin = useIsInAdminMode();
    return (
        <IntercomProvider
            appId={chatConfig.intercom_app_id}
            initializeDelay={1000}
            autoBoot={!isAdmin}
            autoBootProps={{
                alignment: "left",
                actionColor: primaryColor,
                backgroundColor: primaryColor,
            }}
        >
            {children}
            <IntercomAdminHider />
        </IntercomProvider>
    );
}

export function WChatProvider({ children }: { children: ReactNode }) {
    //#region Hooks
    const chatConfig = useAtomValue(chatConfigAtom);
    //#endregion

    if (!chatConfig || !("type" in chatConfig)) {
        return children;
    }

    const primaryColor = getThemeVar_ONCE("color-primary");
    const logoImage = getThemeVar_ONCE("logo-image");

    // NB: Since this is a React component, technically these initialization functions could be called multiple times
    const { type } = chatConfig;
    switch (type) {
        case "intercom": {
            return (
                <IntercomWrapper chatConfig={chatConfig} primaryColor={primaryColor}>
                    {children}
                </IntercomWrapper>
            );
        }
        case "qiscus": {
            discardPromise(qiscusLoader(chatConfig));
            break;
        }
        case "botika": {
            discardPromise(botikaLoader(chatConfig, primaryColor, logoImage));
            break;
        }
        case "oncehub": {
            discardPromise(oncehubLoader(chatConfig));
            break;
        }
        case "leadbooster": {
            discardPromise(leadBoosterLoader(chatConfig));
            break;
        }
        default: {
            console.warn("Unsupported chat provider type", type);
            break;
        }
    }

    return children;
}
