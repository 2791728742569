import type { ApiErrorResponse, paths } from "@warrenio/api-spec/spec.oats.gen";
import { isObjectWithKey } from "@warrenio/utils/isObject";
import type createClient from "openapi-fetch";
import type { FetchResponse } from "openapi-fetch";
import type { z } from "zod";
import {
    ApiCredentialsError,
    ApiDevError,
    ApiGeneralError,
    ApiStandardError,
    ApiTimeoutError,
    ApiUnknownError,
    type ApiErrorMeta,
    type DevErrorInfo,
    type GeneralErrorInfo,
} from "./ApiError.ts";
import { validateSchema } from "./schemaCheck.ts";

//#region Error handling and exceptions

export function throwApiException(response: Response, error: unknown): never {
    const meta: ApiErrorMeta = {
        requestUrl: response.url,
        status: response.status,
        correlationId: response.headers.get("x-warren-correlation-id"),
    };

    if (isObjectWithKey(error, "message")) {
        if (response.status === 403) {
            throw new ApiCredentialsError(error as GeneralErrorInfo, meta);
        }

        if ("stack" in error) {
            throw new ApiDevError(error as DevErrorInfo, meta);
        }

        throw new ApiGeneralError(error as GeneralErrorInfo, meta);
    }

    if (isObjectWithKey(error, "errors") && isObjectWithKey(error.errors, "Error")) {
        throw new ApiStandardError(error as ApiErrorResponse, meta);
    }

    const errorMessage = typeof error === "string" && error !== "" ? error.slice(0, 250) : response.statusText;
    if (response.status === 504) {
        throw new ApiTimeoutError(error, meta, errorMessage);
    } else {
        throw new ApiUnknownError(error, meta, errorMessage);
    }
}

//#endregion

/** Wrapper interface to debloat IDE tooltips  */
export interface ApiClient extends ReturnType<typeof createClient<paths>> {}

/**
 * Alternate version of openapi-fetch {@link FetchResponse} with the type of `data` directly being represented by the
 * {@link TData} generic type parameter.
 *
 * Needed to be able to infer the type of {@link TData} in other contexts.
 */
export type TypedFetchResponse<TData> =
    | {
          data: TData;
          error?: never;
          response: Response;
      }
    | {
          data?: never;
          error: unknown;
          response: Response;
      };

/** Get the data from an API client fetch response. Throw an error otherwise. */
export function getResponseData<T extends TypedFetchResponse<any>>(fetchResponse: T, schema?: z.ZodTypeAny | false) {
    type ResponseData = Exclude<T["data"], undefined>;

    const { response } = fetchResponse;

    const error: unknown = fetchResponse.error;
    // NB: `error` can be empty for some 500 internal server errors
    // This check should probably be `"data" in fetchResponse` instead.
    if (error !== undefined || fetchResponse.data === undefined) {
        console.info("API error response: %o", fetchResponse);
        throwApiException(response, error);
    }

    /* eslint-disable @typescript-eslint/no-unsafe-return -- ESLint does not understand that a subtype of a generic `any` parameter will not be concretely `any` (unless the caller explicitly uses `any`) */
    const data = fetchResponse.data as ResponseData;

    if (import.meta.env.DEV && schema) {
        return validateSchema(response, schema, data);
    }

    return data;
    /* eslint-enable @typescript-eslint/no-unsafe-return */
}
