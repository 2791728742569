import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { jsonBody, successResponse, tagPaths } from "../util.ts";
import { billing_account_id } from "./common.js";
import { datetime, datetime_epoch_s, simpleSuccessResponse, user_id, uuid } from "./common.ts";

extendZodWithOpenApi(z);

//#region Admin impersonate
const AdminImpersonateBody = z
    .object({
        target_user_id: user_id,
        description: z.string(),
        is_admin: z.boolean(),
    })
    .openapi({ ref: "AdminImpersonateBody" });

const AdminImpersonationStartResponse = z
    .object({
        acting_user_id: z.number(),
        billing_account_id,
        consumer_id: uuid,
        created_at: datetime,
        description: z.string(),
        id: z.number(),
        kong_id: uuid,
        restricted: z.boolean(),
        token: z.string(),
        user_id,
    })
    .openapi({ ref: "AdminImpersonationStartResponse" });
//#endregion

//#region Admin Users
const AdminUser = z
    .object({
        custom_id: z.string(),
        created_at: datetime_epoch_s,
        username: z.string(),
        id: uuid,
    })
    .openapi({ ref: "AdminUser" });

const AdminUserCreateBody = z
    .object({
        username: z.string(),
    })
    .openapi({ ref: "AdminUserCreateBody" });

const AdminUserDeleteBody = z
    .object({
        username: z.string(),
    })
    .openapi({ ref: "AdminUserDeleteBody" });

const AdminUserResponse = z.array(AdminUser);
//#endregion

const UserSignups = z
    .object({
        total_signups: z.number(),
        last_x_days_signups: z.array(
            z.object({
                days: z.number(),
                users: z.number(),
            }),
        ),
        monthly_signups: z.array(
            z.object({
                month: datetime,
                users: z.number(),
            }),
        ),
    })
    .openapi({ ref: "UserSignups" });

export const userAdminPaths = tagPaths("admin_user")({
    "/user-resource/admin/token": {
        post: {
            summary: "Start impersonation",
            requestBody: jsonBody(AdminImpersonateBody),
            responses: {
                ...successResponse(AdminImpersonationStartResponse),
            },
        },
    },

    /* Admin users */
    "/user-resource/admin/admin_consumers": {
        get: {
            summary: "Get admin users list",
            responses: {
                ...successResponse(AdminUserResponse),
            },
        },
        put: {
            summary: "Add admin user",
            requestBody: jsonBody(AdminUserCreateBody),
            responses: { ...successResponse(AdminUser) },
        },
        delete: {
            summary: "Delete admin user",
            requestBody: jsonBody(AdminUserDeleteBody),
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },

    "/user-resource/admin/user_signups": {
        get: {
            summary: "Get user signups stats",
            parameters: [{ name: "limit_months", in: "query" }],
            responses: {
                ...successResponse(UserSignups),
            },
        },
    },
});
