import type { Key } from "react-aria-components";
import { sortBy } from "remeda";
import {
    WSelect,
    type WSelectProps,
    type WSelectStyleProps,
    type WSelectValueProps,
} from "../../../components/forms/WSelect.tsx";
import LR from "../../../components/LeftRight.module.css";
import { cn } from "../../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { metalSpecQueryAtom, type MetalSpecLoc } from "./specQuery.ts";

export interface SpecSelectProps<TKey extends Key>
    extends WSelectStyleProps,
        WSelectValueProps<MetalSpecLoc, TKey>,
        // Allow customizing the `getKey` function (to use either `spec_id` or `uuid` as a key)
        Pick<WSelectProps<MetalSpecLoc, TKey>, "getKey"> {}

export function ASpecSelect<TKey extends Key>(props: SpecSelectProps<TKey>) {
    const specs = useSuspenseQueryAtom(metalSpecQueryAtom);
    return (
        <WSelect
            autoFocusSearch
            items={sortBy([...specs.values()], (l) => l.title)}
            getTextValue={(item) => item.title}
            className="min-w-30em"
            // Needed for the "w-0 min-w-100%" trick to work, otherwise the element will be cropped too small
            itemClassName={cn(LR.item, "w-full")}
            valueClassName={cn(LR.value, "w-full")}
            {...props}
        >
            {(item) => (
                <div className="flex flex-col flex-1 text-left items-start">
                    <div>{item.title}</div>
                    <div
                        // https://stackoverflow.com/questions/24632208/force-flex-element-not-to-grow-in-cross-axis-direction
                        className="text-muted font-size-small text-nowrap text-ellipsis overflow-x-hidden w-0 min-w-100%"
                    >
                        {item.description}
                    </div>
                </div>
            )}
        </WSelect>
    );
}
