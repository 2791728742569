import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { Location, LocationBody } from "@warrenio/api-spec/spec.oats.gen";
import { mustFindBy } from "../../mock-api/mustFindBy.ts";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";
import type { ResourceType } from "../api/resourceTypes.tsx";
import { getLocationsForType, type LocationWithEnabled, type LocationsForType } from "./location.ts";

export type LocationSlug = string;

type Response = Location[];

const queryKey: QueryKey = ["locations"];

function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> =>
            getResponseData(await client.GET("/config/locations", { signal })),
    });
}

export const queryAtom = atomFromStandardQueryOptions(getQuery);

/** NB: Only use this for non-resource purposes.
 * @see {@link useLocationsForType}
 */
function useAllLocationsSuspense() {
    return useSuspenseQueryAtom(queryAtom);
}

export function usePricingLocations() {
    const data = useAllLocationsSuspense();
    // For viewing pricing, all locations can be selected
    return data.map((l): LocationWithEnabled => ({ ...l, isEnabled: true }));
}

export function useSingleLocationSuspense(slug: LocationSlug) {
    const data = useAllLocationsSuspense();
    return mustFindBy(data, (l) => l.slug, slug, "Location");
}

export function useLocationsForType(resourceType: ResourceType | undefined): LocationsForType {
    const data = useAllLocationsSuspense();
    return getLocationsForType(data, resourceType);
}

//#region Mutations
export const locationCreateMutation = adminMutation((apiClient) => ({
    entity: "location",
    operation: "create",
    requestFn: async ({ body, location }: { body: LocationBody; location: string }) =>
        await apiClient.PUT("/config/locations/{location}", {
            params: { path: { location: location } },
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));

export const locationUpdateMutation = adminMutation((apiClient) => ({
    entity: "location",
    operation: "update",
    requestFn: async ({ body, location }: { body: LocationBody; location: string }) =>
        await apiClient.PUT("/config/locations/{location}", {
            params: { path: { location: location } },
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));

export const locationDeleteMutation = adminMutation((apiClient) => ({
    entity: "location",
    operation: "delete",
    requestFn: async ({ location }: { location: string }) =>
        await apiClient.DELETE("/config/locations/{location}", {
            params: { path: { location: location } },
        }),
    invalidateQueryKey: queryKey,
}));
//#endregion Mutations
