import type { Atom } from "jotai/vanilla";
import invariant from "tiny-invariant";

type AtomKey = string | number | symbol | boolean | null;

/** Atom family that supports multiple parameters */
export function atomFamilyMulti<T, TArgs extends AtomKey[]>(
    createAtom: (...args: TArgs) => Atom<T>,
): (...args: TArgs) => Atom<T> {
    type MapValue = Atom<T> | KeyMap;

    type KeyMap = Map<AtomKey, MapValue>;
    const atoms: KeyMap = new Map();

    return (...args: TArgs) => {
        let currentMap: KeyMap = atoms;
        for (let i = 0; i < args.length - 1; i++) {
            const key = args[i];

            if (!currentMap.has(key)) {
                currentMap.set(key, new Map());
            }
            currentMap = currentMap.get(key) as KeyMap;
            invariant(currentMap instanceof Map, "atomFamilyMulti: inconsistent argument lengths");
        }
        let atom;
        const lastKey = args[args.length - 1];
        if (currentMap.has(lastKey)) {
            atom = currentMap.get(lastKey) as Atom<T>;
        } else {
            atom = createAtom(...args);
            currentMap.set(lastKey, atom);
        }
        return atom;
    };
}
