import { z } from "zod";
import { errorResponse, jsonBody, successResponse, tagPaths } from "../util.ts";
import { simpleSuccessResponse, uuid } from "./common.ts";
import * as params from "./params.ts";
import { HostPool } from "./pools.ts";

//#region Admin Host Pool

export const AdminHostPool = HostPool.extend({
    cpu_allocate_coefficient: z.number(),
    cpu_model: z.string(),
    hosts_assigned_count: z.number(),
    is_hv_auto_assigned: z.boolean(),
    is_visible: z.boolean(),
    mem_allocate_coefficient: z.number(),
    os_type: z.string(),
}).openapi({ ref: "AdminHostPool" });

export const AdminHostPoolCreateBody = z
    .object({
        name: z.string(),
        description: z.string().optional(),
        cpu_model: z.string(),
        cpu_allocate_coefficient: z.number(),
        mem_allocate_coefficient: z.number(),
        ui_position: z.number().optional(),
        storage_pool_uuid: z.string(),
    })
    .openapi({ ref: "AdminHostPoolCreateBody" });

export const AdminHostPoolUpdateBody = z
    .object({
        name: z.string(),
        description: z.string().optional(),
        cpu_model: z.string(),
        cpu_allocate_coefficient: z.number(),
        mem_allocate_coefficient: z.number(),
        ui_position: z.number(),
        uuid,
        storage_pool_uuid: z.string(),
        is_visible: z.boolean().optional(),
    })
    .openapi({ ref: "AdminHostPoolUpdateBody" });
//#endregion

export const AdminHostPoolResponse = z.array(AdminHostPool);

export const hostPoolAdminPaths = tagPaths("admin_host_pools")({
    // Admin list
    "/{location}/base-operator/admin/host_pool/list": {
        get: {
            summary: "List Host Pools",
            parameters: [params.location],
            responses: {
                ...successResponse(AdminHostPoolResponse),
                ...errorResponse(`Failed to list host pools from ${params.location}`),
            },
        },
    },
    "/{location}/base-operator/admin/host_pool": {
        post: {
            summary: "Add host pool",
            parameters: [params.location],
            requestBody: jsonBody(AdminHostPoolCreateBody),
            responses: {
                ...successResponse(AdminHostPool),
            },
        },
    },
    "/{location}/base-operator/admin/host_pool/{uuid}": {
        patch: {
            summary: "Update host pool",
            parameters: [params.location, params.uuid],
            requestBody: jsonBody(AdminHostPoolUpdateBody),
            responses: {
                ...successResponse(AdminHostPool),
            },
        },
        delete: {
            summary: "Delete host pool",
            parameters: [params.location, params.uuid],
            responses: { ...simpleSuccessResponse },
        },
    },
    "/{location}/base-operator/admin/host_pool/{uuid}/default_for_vms": {
        put: {
            summary: "Set as default for vms",
            parameters: [params.location, params.uuid],
            responses: {
                ...successResponse(AdminHostPool),
            },
        },
    },
    "/{location}/base-operator/admin/host_pool/{uuid}/default_for_hosts": {
        put: {
            summary: "Set as default for vms",
            parameters: [params.location, params.uuid],
            responses: {
                ...successResponse(AdminHostPool),
            },
        },
    },
});
