import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { VirtualMachineStorage, VmActionLog, VmStatusEnum } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { useState } from "react";
import { TextArea } from "react-aria-components";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { ContentPane } from "../../components/ContentPane.tsx";
import { ResourceWithIcon } from "../../components/ResourceWithIcon.tsx";
import { Separator } from "../../components/Separator.tsx";
import { Spacer } from "../../components/Spacer.tsx";
import { ViewTitle } from "../../components/Title.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";
import { AdminDate, LongDate } from "../../components/l10n/DateFormat.tsx";
import { WModal } from "../../components/modal/WModal.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { WSmartTable, WTable, WTableBody } from "../../components/table/WTable.tsx";
import { todoAction } from "../../dev/todoSubmit.ts";
import { useApiClient } from "../../modules/api/apiClient.store.ts";
import { MachineSizeInfo } from "../../modules/compute/MachineSizeInfo.tsx";
import { VmStatusBadge } from "../../modules/compute/VmStatusBadge.tsx";
import { OsInfo } from "../../modules/compute/os/OsInfo.tsx";
import { extractOsFields, type SelectedOs } from "../../modules/compute/os/os.ts";
import { LocationDetail } from "../../modules/location/LocationDetail.tsx";
import type { MachineCommonFields } from "../../modules/pricing/resourcePricing.ts";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminLinkButton } from "../AdminLinkButton.tsx";
import { Missing } from "../AdminTable.tsx";
import { CurrentPool } from "../host_pools/CurrentPool.tsx";
import { PoolSelect } from "../host_pools/PoolSelect.tsx";
import { CurrentHost } from "../hosts/CurrentHost.tsx";
import { adminHostQueryAtom } from "../hosts/hostQuery.ts";
import { DesignatedVmBlock } from "./DesignatedBlock.tsx";
import { DiskResizeModal } from "./DiskResizeModal.tsx";
import { NicIftuneButton } from "./NicIftuneBlock.tsx";
import type { GQVmItem } from "./VmsTable.tsx";
import { vmActionLogQueryAtom } from "./vmQuery.ts";

export function VmDetailView({ item }: { item: GQVmItem }) {
    return (
        <>
            <VmDetails item={item} />
            <Separator />
            <VmPoolsHosts item={item} />
            <Separator />
            <DisksContent vm={item} />
            <Separator />
            <ActionLog item={item} />
            <Separator />
            <DomainXml item={item} />
        </>
    );
}

function VmDetails({ item }: { item: GQVmItem }) {
    const {
        name,
        status,
        public_ipv6,
        billing_account_object,
        ip_address_object,
        private_ipv4,
        uuid,
        username,
        mac,
        _location,
        vcpu,
        memory,
        storage,
        os_name,
        os_version,
    } = item;

    return (
        <ContentPane className="VStack gap-4">
            <ViewTitle
                title={name ?? ""}
                // TODO: Remove global margins from all headings and instead use container-based layouts (eg. gap)
                // (otherwise it's impossible to lay out optional (nullable) components nicely without passing additional margin properties into every one of them)
                className="important:pb-0 important:min-h-auto"
            />

            <DetailsHolder>
                <DetailsTable>
                    <DetailsTableRow title="Name">{name}</DetailsTableRow>

                    <DetailsTableRow title="Type:">
                        <ResourceWithIcon type="virtual_machine" />
                    </DetailsTableRow>

                    <DetailsTableRow title="OS:">
                        <OsInfo obj={extractOsFields({ os_name: os_name, os_version: os_version } as SelectedOs)} />
                    </DetailsTableRow>

                    <DetailsTableRow title="Status:">
                        <VmStatusBadge value={status as VmStatusEnum} />
                    </DetailsTableRow>

                    {public_ipv6 != null && (
                        <DetailsTableRow title="Public IPv6:">
                            <ClipBoardTooltip isHtml>{public_ipv6}</ClipBoardTooltip>
                        </DetailsTableRow>
                    )}

                    {!!ip_address_object?.address && (
                        <DetailsTableRow
                            title="Public IPv4:"
                            tooltip="Public IP addresses are managed in the Network view."
                        >
                            <ClipBoardTooltip isHtml>{ip_address_object?.address}</ClipBoardTooltip>
                        </DetailsTableRow>
                    )}

                    <DetailsTableRow title="Private IPv4:">
                        <ClipBoardTooltip isHtml>{private_ipv4}</ClipBoardTooltip>
                    </DetailsTableRow>

                    <DetailsTableRow title="Size:">
                        <MachineSizeInfo
                            vm={
                                {
                                    vcpu: vcpu,
                                    memory: memory,
                                    storage: storage,
                                    location: _location,
                                    status: status,
                                } as MachineCommonFields
                            }
                        />
                    </DetailsTableRow>

                    <DetailsTableRow title="Bandwidth Limitations:">
                        <NicIftuneButton item={item} />
                    </DetailsTableRow>
                </DetailsTable>

                <Spacer />

                <DetailsTable>
                    <DetailsTableRow title="UUID:">
                        <ClipBoardTooltip>{uuid}</ClipBoardTooltip>
                    </DetailsTableRow>

                    <DetailsTableRow title="Username:">{username}</DetailsTableRow>

                    <DetailsTableRow title="MAC:">{mac}</DetailsTableRow>

                    <LocationDetail slug={notNull(_location)} />

                    <DetailsTableRow title="Created:">
                        <LongDate date={apiUnixToDate(item.created_at as number)} />
                    </DetailsTableRow>

                    <DetailsTableRow />

                    <DetailsTableRow title="Billing account:">
                        <AdminLinkButton
                            isMissing={!billing_account_object?.title}
                            action={todoAction}
                            label={<span>{billing_account_object?.title}</span>}
                        />
                    </DetailsTableRow>
                </DetailsTable>
            </DetailsHolder>
        </ContentPane>
    );
}

function VmPoolsHosts({ item }: { item: GQVmItem }) {
    const hosts = useSuspenseQueryAtom(adminHostQueryAtom(notNull(item._location)));
    const { hypervisor_uuid, designated_pool_uuid, _location } = item;

    const [designated, setDesignated] = useState<string>(designated_pool_uuid ?? "");
    const defaultHost = hypervisor_uuid ? hosts.get(hypervisor_uuid) : null;
    const defaultPoolUuid = defaultHost?.pool_uuid ?? designated_pool_uuid ?? "";

    function resetDesignated() {
        setDesignated(designated_pool_uuid ?? "");
    }

    return (
        <ContentPane>
            <div className="HStack items-start gap-4">
                <div className="flex-1">
                    <div className="font-size-subtitle pb-2">Current Pool</div>

                    <div className="lightBlock p-3">
                        <CurrentPool location={notNull(_location)} value={notNull(defaultPoolUuid)} />
                    </div>

                    <div className="pt-3">
                        <div className="font-size-subtitle pb-2">Designated Pool</div>

                        <div className="position-relative">
                            <PoolSelect
                                location={notNull(_location)}
                                valueKey={designated}
                                onChange={(item) => setDesignated(item.uuid)}
                            />

                            {designated !== designated_pool_uuid && (
                                <div className="position-absolute right-0">
                                    <DesignatedVmBlock
                                        designated_pool_uuid={designated}
                                        item={item}
                                        button={<WButton variant="edit" icon="jp-icon-checkmark" action={undefined} />}
                                    />
                                    <WButton variant="edit" icon="jp-icon-close" action={resetDesignated} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex-1">
                    <div className="font-size-subtitle pb-2">Current Host</div>

                    <div className="lightBlock p-3">
                        {defaultHost?.uuid ? (
                            <CurrentHost location={notNull(_location)} value={defaultHost?.uuid} />
                        ) : (
                            <Missing />
                        )}
                    </div>
                </div>
            </div>
        </ContentPane>
    );
}

function DisksContent({ vm }: { vm: GQVmItem }) {
    const { storage } = vm;

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Disks</h2>

            <WTable>
                <thead>
                    <tr>
                        <th>UUID</th>
                        <th>Size (GB)</th>
                        <th>Created</th>
                        <th>Mode</th>
                        <th>Type</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {notNull(storage).map((item) => (
                        <tr key={item.uuid}>
                            <td>
                                <ClipBoardTooltip>{item.uuid}</ClipBoardTooltip>
                            </td>
                            <td>{item.size}</td>
                            <td>
                                <LongDate date={apiUnixToDate(item.created_at as number)} />
                            </td>
                            <td>{item.primary ? "Boot" : "-"}</td>
                            <td>SSD disk</td>
                            <td className="text-right">
                                <WModal button={<WModalButton label="Resize" inTable icon="jp-expand-2-icon" />}>
                                    <DiskResizeModal vm={vm} disk={item as VirtualMachineStorage} />
                                </WModal>
                            </td>
                        </tr>
                    ))}
                </WTableBody>
            </WTable>
        </ContentPane>
    );
}

function ActionLog({ item }: { item: GQVmItem }) {
    const defaultLogs: VmActionLog[] = useSuspenseQueryAtom(vmActionLogQueryAtom(notNull(item._location), item.uuid));

    const api = useApiClient();
    const { uuid, _location } = item;
    const [logs, setLogs] = useState<VmActionLog[]>(defaultLogs ?? []);

    async function getActionLogs() {
        const response = await api.GET("/{location}/base-operator/admin/action_log", {
            params: { query: { guest_uuid: uuid }, path: { location: notNull(_location) } },
        });

        setLogs(response.data ?? []);
    }

    return (
        <ContentPane>
            <div className="flex justify-between items-center pb-3">
                <div className="font-size-subtitle">Action log</div>

                <WButton action={() => getActionLogs()} color="primary" size="md" variant="ghost">
                    {logs?.length ? "Reload Logs" : "Load Logs"}
                </WButton>
            </div>

            <WSmartTable
                items={logs}
                hasViewAllButton
                head={
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Created At</th>
                            <th>Hypervisor</th>
                            <th>Hypervisor UUID</th>
                            <th>Acting USER ID</th>
                        </tr>
                    </thead>
                }
            >
                {(item, index) => {
                    const { action, created_at, hypervisor_uuid, acting_user_id } = item;

                    return (
                        <tr key={index}>
                            <td>{action}</td>
                            <td>
                                <AdminDate date={created_at} />
                            </td>
                            <td />
                            <td>
                                <ClipBoardTooltip>{hypervisor_uuid}</ClipBoardTooltip>
                            </td>
                            <td>
                                {acting_user_id ? (
                                    <AdminLinkButton action={todoAction} label={<span>{acting_user_id}</span>} />
                                ) : (
                                    <Missing />
                                )}
                            </td>
                        </tr>
                    );
                }}
            </WSmartTable>
        </ContentPane>
    );
}

function DomainXml({ item }: { item: GQVmItem }) {
    const api = useApiClient();
    const { uuid, _location } = item;
    const [xml, setXml] = useState<string>("");

    async function getXml() {
        const response = await api.GET("/{location}/base-operator/admin/vm/virsh_dumpxml", {
            params: { query: { uuid: uuid }, path: { location: notNull(_location) } },
            parseAs: "text",
        });
        setXml(response.data ?? "");
    }

    return (
        <ContentPane>
            <div className="flex justify-between items-center pb-3">
                <div className="font-size-subtitle">Domain XML</div>

                <WButton
                    isDisabled={item.status === "stopped"}
                    action={() => getXml()}
                    color="primary"
                    size="md"
                    variant="ghost"
                >
                    {xml ? "Reload XML" : "Load XML"}
                </WButton>
            </div>

            <p className="color-muted pb-3">
                Currently effective domain XML that describes this Virtual Machine in libvirt, useful for debugging.
                Available only if VM is defined on some hypervisor, usually not available for stopped VMs.
            </p>

            {!!xml && (
                <div>
                    <TextArea rows={12} value={xml} readOnly className={cn(FF.FormFieldTextArea, TF.TextArea)} />
                </div>
            )}
        </ContentPane>
    );
}
