import { queryOptions } from "@tanstack/react-query";
import { createLazyFileRoute } from "@tanstack/react-router";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import sleep from "@warrenio/utils/promise/sleep";
import { atom } from "jotai/vanilla";
import { useEffect } from "react";
import { isString } from "remeda";
import { ApiGeneralError } from "../../modules/api/ApiError.ts";
import { registerErrorField } from "../../modules/error/errorFields.tsx";
import { mergeQueries } from "../../utils/query/mergeQueries.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";

export const Route = createLazyFileRoute("/_main/test-error")({
    component: RouteComponent,
});

const tq1Atom = atomFromStandardQueryOptions(() =>
    queryOptions({
        queryKey: ["tq1"],
        queryFn: async () => {
            await sleep(0);
            throw new ApiGeneralError(new TestError("Fake query error 1"), { requestUrl: "fake1", status: 501 });
        },
    }),
);

const tq2Atom = atomFromStandardQueryOptions(() =>
    queryOptions({
        queryKey: ["tq2"],
        queryFn: async (): Promise<true> => {
            await sleep(0);
            throw new ApiGeneralError(new TestError("Fake query error 2"), { requestUrl: "fake2", status: 502 });
        },
    }),
);

const tqMergeAtom = atom((get) => {
    return mergeQueries({ tq1: get(tq1Atom), tq2: get(tq2Atom) }, (q) => q);
});

class TestError extends Error {
    name = "TestError";

    constructor(
        message: string,
        public readonly instanceVar = "instanceVar",
    ) {
        super(message);
    }
}

registerErrorField("instanceVar", isString);

async function asyncError() {
    await sleep(1000);
    throw new TestError("Test async error");
}

function RouteComponent() {
    useSuspenseQueryAtom(tqMergeAtom);
    useEffect(() => {
        discardPromise(asyncError());
        // throw new TestError("Test error");
    }, []);
}
