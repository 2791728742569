import LR from "../../components/LeftRight.module.css";

import type { PriceLoc } from "../../admin/prices/priceLocs.tsx";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect, type WSelectStyleProps, type WSelectValueProps } from "../../components/forms/WSelect.tsx";

export interface LocationHostSelectProps extends WSelectStyleProps, WSelectValueProps<PriceLoc, string> {
    baseItems: PriceLoc[];
    disabledKeys?: string[];
}

export function LocationHostSelectCustom({ baseItems, ...props }: LocationHostSelectProps) {
    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select location"
            items={baseItems}
            getKey={(item) => item.key}
            {...props}
        >
            {(item) => <LeftRight icon={item.icon} title={item.title} />}
        </WSelect>
    );
}
