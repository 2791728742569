import C from "./AdminTable.module.css";

import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { Children, Suspense, useRef, type Key, type ReactNode, type Ref } from "react";
import { entries, filter, pipe } from "remeda";
import { ClipBoardTooltip } from "../components/ClipBoardTooltip.tsx";
import { WCheckbox } from "../components/forms/WCheckbox.tsx";
import { AdminDate, type DateLike } from "../components/l10n/DateFormat.tsx";
import { Loading } from "../components/loading/Loading.tsx";
import { WTableBody } from "../components/table/WTable.tsx";
import type { BaseProps } from "../utils/baseProps.ts";
import { cn } from "../utils/classNames.ts";
import type { FieldConfig, IdFieldConfig } from "./FieldConfig.tsx";
import { WAdminTable, type WAdminTableProps } from "./WAdminTable.tsx";

export function NA() {
    return <span className="text-muted">N/A</span>;
}

export function Missing() {
    return <span className="text-muted">-</span>;
}

export function DateViewer({ value }: { value: DateLike }) {
    return <AdminDate date={value} />;
}

export function YesNo({ value }: { value: boolean }) {
    return value ? "Yes" : "No";
}

/** Main text with smaller text under it */
export function Extra({ value, children, ...props }: { value: ReactNode; children: ReactNode } & BaseProps) {
    return (
        <div {...props}>
            <div>{value ?? <Missing />}</div>
            {Children.count(children) > 0 && <div className="text-muted font-size-small">{children}</div>}
        </div>
    );
}

export function Uuid({ value }: { value: string }) {
    return <code className="max-w-9em text-ellipsis overflow-x-hidden">{value}</code>;
}

function isUuid(value: string) {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(value);
}

function FieldValue<TItem, TValue>({
    field,
    value,
    item,
}: {
    field: FieldConfig<TItem, TValue>;
    value: TValue;
    item: TItem;
}) {
    const { render: Render } = field;
    let rendered = Render ? (
        <Render value={value} item={item} />
    ) : value instanceof Date ? (
        <DateViewer value={value} />
    ) : typeof value === "string" ? (
        isUuid(value) ? (
            <Uuid value={value} />
        ) : (
            // NB: Assume raw text, so display "\n" as actual newlines
            <span className="whitespace-pre">{value}</span>
        )
    ) : typeof value === "number" ? (
        String(value)
    ) : typeof value === "boolean" ? (
        <WCheckbox isSelected={value} />
    ) : value == null ? (
        <Missing />
    ) : (
        String(value)
    );
    if (field.copyable) {
        rendered = value ? (
            <ClipBoardTooltip isHtml hideIcon text={typeof value === "string" ? value : undefined}>
                {rendered}
            </ClipBoardTooltip>
        ) : (
            <Missing />
        );
    }
    return rendered;
}

interface AdminTableProps<TItem> extends Omit<WAdminTableProps, "children"> {
    items: TItem[];
    getId: (item: TItem) => Key;
    fields: Record<string, FieldConfig<TItem, any>>;
    actionButtons?: (item: TItem) => ReactNode;
    topRef?: Ref<HTMLTableSectionElement>;
}

export function AdminTable<TItem>({
    items,
    fields,
    getId,
    actionButtons,
    topRef,
    ...tableProps
}: AdminTableProps<TItem>) {
    const fs = pipe(
        fields,
        entries(),
        filter(([, field]) => field.inTable !== false),
    );

    return (
        <WAdminTable {...tableProps}>
            <thead ref={topRef}>
                <tr>
                    {fs.map(([id, field]) => (
                        <th key={id}>{field.title}</th>
                    ))}
                </tr>
            </thead>
            <WTableBody>
                {items.map((item) => (
                    <tr key={getId(item)}>
                        {fs.map(([id, field]) => {
                            const value: unknown = field.get(item);
                            return (
                                <td key={id} className={field.cellClassName}>
                                    <FieldValue field={field} value={value} item={item} />
                                </td>
                            );
                        })}
                        <td className="text-right">
                            <div className="flex gap-0.5em justify-end">{actionButtons?.(item)}</div>
                        </td>
                    </tr>
                ))}
            </WTableBody>
        </WAdminTable>
    );
}

export interface AdminPowerTableProps<TItem, TField extends IdFieldConfig<TItem, any> = IdFieldConfig<TItem, any>>
    extends Omit<WAdminTableProps, "children"> {
    fields: TField[];
    /** `null` means loading */
    items: TItem[] | null | undefined;

    getId: (item: TItem) => string;
    getRowClassname?: (item: TItem) => string | undefined;
    actionButtons?: (item: TItem) => ReactNode;

    renderTitle?: (field: TField) => ReactNode;
    onClickRow?: (item: TItem) => void | Promise<void>;

    topRef?: Ref<HTMLTableSectionElement>;
}

const LOADING_ROW = (
    <tr>
        <td colSpan={99} className="text-left bg-grey-2">
            {/* TODO: This gets centered in the table, meaning it will generally be off-screen since the center of the table is only visible when scrolled */}
            <Loading icon="none" fillSpace />
        </td>
    </tr>
);

export function AdminPowerTable<TItem, TField extends IdFieldConfig<TItem, any>>({
    items,
    fields,
    getId,
    actionButtons,
    topRef,
    renderTitle,
    getRowClassname,
    onClickRow,
    ...tableProps
}: AdminPowerTableProps<TItem, TField>) {
    //#region Hooks

    // Cache last loaded items
    const prevItems = useRef(items);
    if (items) {
        prevItems.current = items;
    }

    //#endregion

    const headerCells = fields.map((field) => (
        <th key={field.id} className={field.align}>
            {renderTitle ? renderTitle(field) : field.title}
        </th>
    ));
    if (actionButtons) {
        headerCells.push(<th key="__actions__" className="text-right" />);
    }

    // Keep the previous items shown when loading to prevent scrollbar jumping
    const cachedItems = items ?? prevItems.current;

    const body = cachedItems
        ? cachedItems.map((item) => (
              <tr
                  key={getId(item)}
                  className={getRowClassname?.(item)}
                  onClick={
                      onClickRow
                          ? () => {
                                discardPromise(onClickRow(item));
                            }
                          : undefined
                  }
              >
                  {fields.map((field) => {
                      const value: unknown = field.get(item);
                      return (
                          <td key={field.id} className={cn(field.cellClassName, field.align)}>
                              <FieldValue field={field} value={value} item={item} />
                          </td>
                      );
                  })}
                  {actionButtons && (
                      <td className="text-right">
                          <div className="flex gap-0.5em justify-end">{actionButtons(item)}</div>
                      </td>
                  )}
              </tr>
          ))
        : LOADING_ROW;

    return (
        <WAdminTable {...tableProps}>
            <thead ref={topRef}>
                <tr className={cn(tableProps.sticky && C.StickyHead)}>{headerCells}</tr>
            </thead>
            <WTableBody
                // Display the loading state as "grayed out" via opacity hack
                className={cn(!items && "opacity-50%", onClickRow && "cursor-default")}
            >
                <Suspense fallback={LOADING_ROW}>{body}</Suspense>
            </WTableBody>
        </WAdminTable>
    );
}
