import type { ProcessorConfig } from "@warrenio/api-spec/spec.oats.gen";

export function hasCreditCardProcessor(processors: ProcessorConfig[]) {
    return !!getCreditCardProcessor(processors);
}

export function hasCreditCardProcessorWithAutoTopup(processors: ProcessorConfig[]) {
    return !!getCreditCardProcessor(processors, true);
}

export function getCreditCardProcessor(processors: ProcessorConfig[], withAutoTopup: boolean = false) {
    return processors.find((p): p is typeof p & { type: "creditcard" } => {
        if (withAutoTopup) {
            return p.type === "creditcard" && (p.supports_auto_topup === undefined || p.supports_auto_topup === true);
        }
        return p.type === "creditcard";
    });
}
