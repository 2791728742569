import { cn } from "../../utils/classNames.ts";
import P from "../prices/PricesView.module.css";

export interface FilterItem {
    key: string;
    title: string;
}

export function CustomRadios({
    onChange,
    value,
    items,
    disabled,
}: {
    onChange: (value: string) => void;
    value: string;
    items: FilterItem[];
    disabled?: boolean;
}) {
    const radios = items.map((item) => {
        const { key, title } = item;

        return (
            <div key={key} className={cn(P.Radio, value === key && P.isActive)} onClick={() => onChange(key)}>
                {title}
            </div>
        );
    });

    return <div className={cn("flex items-center", disabled && "pointer-events-none opacity-70")}>{radios}</div>;
}
