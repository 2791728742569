import type { VmConfBody } from "@warrenio/api-spec/spec.oats.gen";
import { Suspense, useState } from "react";
import { Link } from "react-aria-components";
import { WButton } from "../../components/button/WButton.tsx";
import FF from "../../components/forms/FormField.module.css";
import { WCheckbox } from "../../components/forms/WCheckbox.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { Separator } from "../../components/Separator.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { AInput } from "../form/Fields.tsx";
import { updateVmConfMutation, vmConfQueryAtom } from "./vmConfQuery.ts";

function VmConfViewCustom() {
    const vmConf = useSuspenseQueryAtom(vmConfQueryAtom);
    const modifyMutation = useStandardMutation(updateVmConfMutation);

    const [unlimited, setUnlimited] = useState<boolean>(vmConf?.average_kbps === 0);
    const [average, setAverage] = useState<string | number>(vmConf?.average_kbps || "");
    const [peak, setPeak] = useState<string | number>(vmConf?.peak_kbps || "");
    const [burst, setBurst] = useState<string | number>(vmConf?.burst_kb || "");

    async function onSubmit() {
        const confBody: VmConfBody = unlimited
            ? { average_kbps: 0 }
            : {
                  average_kbps: average ? Number(average) : 0,
              };

        if (!unlimited) {
            if (Number(peak)) confBody.peak_kbps = Number(peak);
            if (Number(burst)) confBody.burst_kb = Number(burst);
        }

        await modifyMutation.mutateAsync({
            body: confBody,
        });
    }

    return (
        <>
            <AdminTitle title="Network" />

            <div className="p-2">
                <p className="pb-3 color-muted">
                    Bandwidth limits that apply to all network interfaces of virtual machines. This includes all traffic
                    that goes through the network interface, there is no distinction between internal and external
                    connections. The same limits are applied to both inbound and outbound connections. This setting
                    becomes effective for VMs that are started after the configuration is changed. When setting new
                    limits, it is advised to set the limits to some test VM first to see if the numbers are acceptable
                    to libvirt. See{" "}
                    <Link href="https://libvirt.org/formatnetwork.html#quality-of-service" target="_blank">
                        libvirt network doc
                    </Link>{" "}
                    for more information about specific iftune parameters.
                </p>
            </div>

            <div className="p-4 pt-0 pb-0">
                <WCheckbox isSelected={unlimited} onChange={setUnlimited}>
                    Unlimited (value 0 equals to unlimited)
                </WCheckbox>
            </div>

            <WTextField
                isReadOnly={unlimited}
                className="max-w-24rem"
                wide
                label="Average (kB/s)"
                onChange={setAverage}
            >
                <AInput value={average} />
            </WTextField>

            <WTextField isReadOnly={unlimited} className="max-w-24rem" wide label="Peak (kB/s):" onChange={setPeak}>
                <AInput value={peak} />
            </WTextField>

            <WTextField isReadOnly={unlimited} className="max-w-24rem" wide label="Burst (kB):" onChange={setBurst}>
                <AInput value={burst} />
            </WTextField>

            <Separator />

            <div className={FF.FormActions}>
                <WButton color="primary" variant="basic" size="md" action={async () => await onSubmit()}>
                    Save
                </WButton>
            </div>
        </>
    );
}

export function VmConfView() {
    return (
        <Suspense fallback={<Loading key="loading" delay={0} icon="none" white />}>
            <VmConfViewCustom />
        </Suspense>
    );
}
