import ReactEChartsCore from "echarts-for-react/lib/core";
import { BarChart, LineChart, type BarSeriesOption, type LineSeriesOption } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    type DatasetComponentOption,
    type GridComponentOption,
    type TooltipComponentOption,
} from "echarts/components";
import type { ComposeOption } from "echarts/core";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// https://apache.github.io/echarts-handbook/en/basics/import/
export type WBarChartOptions = ComposeOption<
    BarSeriesOption | LineSeriesOption | TooltipComponentOption | DatasetComponentOption | GridComponentOption
>;

// Register the required components
echarts.use([TooltipComponent, BarChart, LineChart, GridComponent, CanvasRenderer]);

export function WBarChart({ options }: { options: WBarChartOptions }) {
    return <ReactEChartsCore echarts={echarts} option={options} notMerge={true} />;
}
