import { DAYS } from "@warrenio/utils/timeUnits";
import { atomWithLazy, unwrap } from "jotai/utils";
import { type Atom, atom } from "jotai/vanilla";
import type { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";
import { showError } from "../../modules/error/errorStream.ts";
import { makePromise } from "../promise/makePromise.ts";

interface CacheEntry<T> {
    value: T;
    createdAt: number;
}

/**
 * Gets the resolved value from an async atom, or reads it from the provided storage (ie. cache) if it has not resolved yet.
 *
 * @__NO_SIDE_EFFECTS__
 * @returns A tuple of two atoms: `[waitAtom, latestAtom]`.
 *      - Use `waitAtom` to make sure a value is available.
 *      - Use `latestAtom` to get the latest resolved value.
 */
export function atomAsyncWithCache<T>(
    baseAtom: Atom<Promise<T>>,
    key: string,
    storage: () => SyncStorage<unknown>,
    maxAge = 1 * DAYS,
): [waitAtom: Atom<Promise<void>>, latestAtom: Atom<T | undefined>] {
    function readCache(): T | undefined {
        const entry = storage().getItem(key, undefined) as CacheEntry<T> | undefined;
        if (entry === undefined) {
            return undefined;
        }
        const { value, createdAt } = entry;
        const age = Date.now() - createdAt;
        // console.debug("%s: Read from cache (age: %os)", key, Math.round(age / 1000));
        if (age > maxAge) {
            console.debug("%s: Cache expired", key);
            return undefined;
        }
        return value;
    }

    function writeCache(value: T) {
        const entry: CacheEntry<T> = { value, createdAt: Date.now() };
        storage().setItem(key, entry);
    }

    function clearCache() {
        storage().removeItem(key);
    }

    /** Atom that saves the resolved value to cache storage */
    const baseWithSaveAtom = atom(async (get) => {
        const resolved = await get(baseAtom);
        writeCache(resolved);
        return resolved;
    });

    /** Atom that returns the latest resolved value */
    const syncAtom = unwrap(baseWithSaveAtom, (prev) => prev);

    const cachedAtom = atomWithLazy(readCache);

    /** Atom that returns the latest resolved value or the cached value if not resolved yet. */
    const latestAtom = atom(
        (get) => {
            try {
                const latest = get(syncAtom);
                if (latest !== undefined) {
                    console.debug("%s: Using resolved value: %o", key, latest);
                    return latest;
                }
            } catch (error) {
                showError("%s: Error reading resolved value:", key, error);
                // In development, fall back to cached value if reading the resolved value fails
                if (!import.meta.env.DEV || get(cachedAtom) === undefined) {
                    throw error;
                }
            }

            const cached = get(cachedAtom);
            if (cached !== undefined) {
                console.debug("%s: Using cached value: %o", key, cached);
                return cached;
            }
            return undefined;
        },
        (_get, set, _value: undefined) => {
            clearCache();
            set(cachedAtom, undefined);
        },
    );

    // NB: We use a single, stable promise to make sure we do not cause re-renders after the promise resolves.
    const promiseAtom = atomWithLazy(() => makePromise<void>());

    /** Async atom that waits for the initial resolve (if no cached value is available). */
    const waitAtom = atom((get) => {
        const { promise, resolve } = get(promiseAtom);

        // If we have a value, resolve immediately
        const value = get(latestAtom);
        if (value !== undefined) {
            resolve();
        }

        // Otherwise, wait for the base atom to resolve and forward the result to the `promise`
        void get(baseWithSaveAtom).then(() => resolve());

        return promise;
    });

    return [waitAtom, latestAtom];
}
