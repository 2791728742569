import type { QueryKey } from "@tanstack/react-query";
import { queryOptions } from "@tanstack/react-query";
import type { UserSignups } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient.ts";
import { getResponseData } from "../../modules/api/apiClient.ts";
import { atomFamilyMulti } from "../../utils/jotai/atomFamilyMulti.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { intensiveQueryOptions } from "../intensiveQueryOptions.ts";

//#region Query and parameters
export interface Params {
    limit_months: number;
}

export function getUserSignupsQueryKey(params?: Params): QueryKey {
    return params == null
        ? ["user-resource/admin/user_signups"]
        : ["user-resource/admin/user_signups", params.limit_months];
}

export function getUserSignupsQuery(client: ApiClient, params: Params) {
    return queryOptions({
        ...intensiveQueryOptions,
        queryKey: getUserSignupsQueryKey(params),
        queryFn: async ({ signal }): Promise<UserSignups> => {
            const { limit_months } = params;
            return getResponseData(
                await client.GET("/user-resource/admin/user_signups", {
                    signal,
                    params: { query: { limit_months: String(limit_months) } },
                }),
            );
        },
    });
}
//#endregion

//#region Atoms
export const userSignupsQueryAtom = atomFamilyMulti((limit_months: number) =>
    atomFromStandardQueryOptions(getUserSignupsQuery, { limit_months }),
);
//#endregion
