import { createLazyFileRoute } from "@tanstack/react-router";
import Vnc from "../../admin/vm/Vnc.tsx";

export const Route = createLazyFileRoute("/admin/console/$location/$vmId")({
    component: Component,
});

function Component() {
    const { vmId, location } = Route.useParams();
    return <Vnc vmId={vmId} location={location} />;
}
