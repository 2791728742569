import { notNull } from "@warrenio/utils/notNull";
import { link } from "../../components/Action.tsx";
import { WModalButton, WToolButton } from "../../components/button/WToolButton.tsx";
import { Toolbar } from "../../components/Toolbar.tsx";
import { UnassignFloatingIpBlock } from "../floating_ips/UnassignFloatingIpBlock.tsx";
import { DeleteVmBlock } from "./DeleteVmBlock.tsx";
import { MigrateVmBlock } from "./MigrateVmBlock.tsx";
import { RescueModeVmBlock } from "./RescueModeVmBlock.tsx";
import { StartVmBlock } from "./StartVmBlock.tsx";
import { StopVmBlock } from "./StopVmBlock.tsx";
import type { GQVmItem } from "./VmsTable.tsx";

export function VmToolbar({ item }: { item: GQVmItem }) {
    return (
        <Toolbar>
            <StartVmBlock
                item={item}
                button={
                    <WModalButton isDisabled={item.status === "running"} label="Start" size="md" icon="jp-icon-run" />
                }
            />
            <StopVmBlock
                item={item}
                button={
                    <WModalButton isDisabled={item.status === "stopped"} label="Stop" size="md" icon="jp-icon-stop" />
                }
            />
            <StopVmBlock
                force
                item={item}
                button={
                    <WModalButton
                        isDisabled={item.status === "stopped"}
                        label="Force Stop"
                        size="md"
                        icon="jp-power-icon"
                    />
                }
            />
            <UnassignFloatingIpBlock
                item={item}
                button={
                    <WModalButton
                        isDisabled={item.ip_address_object === null}
                        label="Unassign IP"
                        size="md"
                        icon="jp-wrong-icon"
                    />
                }
            />
            <WToolButton
                label="Terminal"
                icon="jp-monitor-icon"
                action={link({
                    to: "/admin/console/$location/$vmId",
                    params: { location: notNull(item._location), vmId: item.uuid },
                    target: "_blank",
                })}
            />

            <RescueModeVmBlock
                item={item}
                button={<WModalButton label="Rescue Mode" icon="jp-help-icon" isDisabled={item.status === "running"} />}
            />

            <MigrateVmBlock item={item} button={<WModalButton label="Migrate" size="md" icon="jp-export-icon" />} />

            <DeleteVmBlock item={item} button={<WModalButton label="Delete" size="md" icon="jp-trash-icon" />} />
        </Toolbar>
    );
}
