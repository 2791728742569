import C from "../../components/sidebar/ErrorItem.module.css";

import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import type { ReactNode } from "react";
import { ReportingErrorBoundary } from "../../modules/error/ReportingErrorBoundary.tsx";
import { isZodError, ZodErrorMessage } from "../../modules/error/ZodError.tsx";
import { errorToToastMessage } from "../../modules/notifications/toast.tsx";
import { cn } from "../../utils/classNames.ts";
import type { QStatus } from "../../utils/query/mergeQueries.ts";
import { getStatusErrors } from "../../utils/query/queryErrors.ts";
import { WTooltipButton } from "../button/WToolButton.tsx";
import { MaskIcon } from "../icon/MaskIcon.tsx";

export function ErrorMessage({ error }: { error: unknown }): ReactNode {
    if (import.meta.env.DEV && isZodError(error)) {
        return <ZodErrorMessage error={error} />;
    }

    return errorToToastMessage(error);
}

interface ErrorBlockProps {
    error: unknown;
    resetErrorBoundary?: () => void;
}

export function ErrorDisplayBlock({ error, resetErrorBoundary }: ErrorBlockProps) {
    const iconElement = <MaskIcon className={cn("jp-wrong-icon", "size-1rem")} />; // icon && <MaskIcon className={cn(icon, "size-1rem")} />;

    return (
        <div className={C.ErrorBlockLink}>
            <div className={cn(C.ErrorBlock, "Toastify__toast--error")}>
                <div className="Toastify__toast-icon">{iconElement}</div>
                <div className={C.title}>
                    <b>An error happened:</b>
                    <br />
                    <ErrorMessage error={error} />
                </div>
                {resetErrorBoundary && (
                    <WTooltipButton
                        title="Reload"
                        size="xs"
                        variant="ghost"
                        icon="jp-icon-refresh"
                        action={resetErrorBoundary}
                    />
                )}
            </div>
        </div>
    );
}

const ListErrorComponent = ({ error, resetErrorBoundary: _ }: ErrorBlockProps) => (
    // NB: `resetErrorBoundary` does not generally work because errors are cached so do not enable it.
    // TODO: Enable it when the `error` is a re-fetchable query error.
    <ErrorDisplayBlock error={error} resetErrorBoundary={undefined} />
);

export function ListErrorBoundary({ children }: { children: ReactNode }) {
    return (
        <ReportingErrorBoundary FallbackComponent={ListErrorComponent} tags="ListErrorBoundary">
            {children}
        </ReportingErrorBoundary>
    );
}

export function QueryErrorBlocks({ query }: { query: QStatus }) {
    const errors = getStatusErrors(query);
    return errors.map((e) => (
        <ErrorDisplayBlock key={e.id} error={e.error} resetErrorBoundary={() => discardPromise(e.refetch())} />
    ));
}
