import C from "../AdminLayout.module.css";

import { AccordionItem } from "@szhsin/react-accordion";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useEffect, useState } from "react";
import { Link } from "react-aria-components";
import invariant from "tiny-invariant";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useApiClient } from "../../modules/api/apiClient.store.ts";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { AInput, ATextArea } from "../form/Fields.tsx";
import { getFullPath, type ValueEditorProps, valueIsJSON } from "./ConfigEditor.utils";

interface IItem {
    section_key: string;
    data: string;
}

export function ListValue({
    name,
    description,
    wikiLink,
    inputType,
    path,
    inputRows = 10,
    validationFun,
    required,
    placeholder,
}: ValueEditorProps) {
    const api = useApiClient();
    const [loading, setLoading] = useState(true); // indicates that load request is in progress
    const [saving, setSaving] = useState(false); // indicates that save request is in progress
    const [deleting, setDeleting] = useState(false); // indicates that delete request is in progress
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [items, setItems] = useState<IItem[]>([]);
    const [newItems, setNewItems] = useState<IItem[]>([]);

    async function getValue() {
        const { data } = await api.GET("/configuration/admin/value", {
            params: { query: { path, recurse: String(1) } },
        });

        invariant(Array.isArray(data) || !data, "Data must be an array or empty");

        const values = Array.isArray(data)
            ? data.map((i): IItem => {
                  const val = typeof i.data !== "string" ? JSON.stringify(i.data, null, 2) : i.data;
                  return { ...i, data: val };
              })
            : [];

        setItems(values);
        setLoading(false);
    }

    // initial data loading
    useEffect(() => {
        discardPromise(getValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function saveConfig() {
        setSaving(true);
        try {
            setErrorMessages([]);

            // validation
            let hasErrors = false;

            const errMsgs: string[] = [];
            for (const i of [...items, ...newItems]) {
                let msg = "";

                if (validationFun !== undefined) {
                    const validationResult = validationFun(i.data);
                    if (!validationResult.success) {
                        msg = validationResult.errorMsg ? validationResult.errorMsg : "Invalid value.";
                        hasErrors = true;
                    }
                }

                if (!i.section_key) {
                    const keyErrorMessage = "Scope keyword must be filled.";
                    msg = msg === "" ? keyErrorMessage : `${msg} ${keyErrorMessage}`;
                    hasErrors = true;
                }

                errMsgs.push(msg);
            }
            setErrorMessages(errMsgs);

            if (hasErrors) {
                return;
            }

            // saving
            for (const i of [...items, ...newItems]) {
                if (i.section_key) {
                    await api.POST("/configuration/admin/value", {
                        body: { path: `${path}/${i.section_key}`, data: i.data },
                        ...jsonEncodedBody,
                    });
                }
            }

            const mergedItems = [...items, ...newItems];
            setNewItems([]);
            setItems(mergedItems);
        } finally {
            setSaving(false);
        }
    }

    const isNewItem = (index: number): boolean => {
        return index > items.length - 1;
    };

    const changeItem = ({ value, key }: { value?: string; key?: string }, index: number) => {
        const isNew: boolean = isNewItem(index);
        const idx = isNew ? index - items.length : index;

        const tempItems = isNew ? [...newItems] : [...items];
        if (key !== undefined) {
            tempItems[idx] = { ...tempItems[idx], section_key: key };
        } else if (value !== undefined) {
            tempItems[idx] = { ...tempItems[idx], data: value };
        }

        if (isNew) setNewItems(tempItems);
        else setItems(tempItems);
    };

    const addNewItem = () => {
        const tempItems = [...newItems];
        tempItems.push({ section_key: "", data: "" });
        setNewItems(tempItems);
    };

    async function deleteItem(idx: number) {
        setDeleting(true);

        const tempItems = [...items];

        // item saved into server, make delete request, show warning dialog first!
        const item = tempItems.find((i: IItem, _idx: number) => _idx === idx);

        await api.DELETE("/configuration/admin/value", {
            params: { query: { path: `${path}/${item?.section_key}` } },
        });

        tempItems.splice(idx, 1);
        setItems(tempItems);
        setDeleting(false);
    }

    function removeItem(index: number) {
        setDeleting(true);

        const idx = index - items.length;
        const tempItems = [...newItems];
        tempItems.splice(idx, 1);
        setNewItems(tempItems);
        setDeleting(false);
    }

    return (
        <AccordionItem
            className={C.AccordionItem}
            header={
                <>
                    <div>
                        <div className="font-size-subtitle">
                            {name}
                            {required && " *"}
                        </div>
                        <p className="color-muted font-size-small">
                            {getFullPath(path, true)}{" "}
                            {wikiLink && (
                                <Link href={wikiLink} target="_blank">
                                    WIKI
                                </Link>
                            )}
                        </p>
                    </div>

                    {(items.length === 0 ||
                        items.some(
                            (item) =>
                                !item.data.toString() ||
                                (inputType === "textarea" &&
                                    Object.keys(valueIsJSON(item.data) ? (JSON.parse(item.data) as object) : {})
                                        .length === 0),
                        )) &&
                        required && <MaskIcon className="ml-auto jp-warning-icon color-primary size-1.375rem" />}
                </>
            }
        >
            <div className={C.AccordionContent}>
                {description && <div className="pb-1">{description}</div>}

                {loading && <p>Loading..</p>}
                {saving && <p>Saving..</p>}

                {!loading && (
                    <div className="VStack gap-3">
                        {[...items, ...newItems].map((i: IItem, idx: number) => (
                            <div key={idx}>
                                <div>
                                    <div className="position-relative">
                                        <WTextField
                                            headless
                                            aria-label={name}
                                            isDisabled={!isNewItem(idx)}
                                            value={i.section_key}
                                            onChange={(value) => changeItem({ key: value }, idx)}
                                        >
                                            <AInput placeholder="scope" />
                                        </WTextField>

                                        <div className="position-absolute top-8px right-8px">
                                            {isNewItem(idx) ? (
                                                <WButton
                                                    color="primary"
                                                    size="xs"
                                                    variant="ghost"
                                                    isDisabled={saving || loading || deleting}
                                                    action={() => removeItem(idx)}
                                                    icon="jp-trash-icon"
                                                />
                                            ) : (
                                                <DeleteModal
                                                    title="Are You Sure?"
                                                    inTable
                                                    isDisabled={saving || loading || deleting}
                                                    modalAction={async () => await deleteItem(idx)}
                                                >
                                                    You're about to delete config value from path "
                                                    <b>
                                                        {path}/{i.section_key}
                                                    </b>
                                                    "?
                                                </DeleteModal>
                                            )}
                                        </div>
                                    </div>

                                    {inputType === "textarea" && (
                                        <WTextField
                                            headless
                                            aria-label={name}
                                            isDisabled={saving}
                                            value={i.data}
                                            onChange={(value) => changeItem({ value: value }, idx)}
                                        >
                                            <ATextArea rows={inputRows} placeholder={placeholder} />
                                        </WTextField>
                                    )}

                                    {inputType === "input" && (
                                        <WTextField
                                            headless
                                            aria-label={name}
                                            isDisabled={saving}
                                            value={i.data}
                                            onChange={(value) => changeItem({ value: value }, idx)}
                                        >
                                            <AInput placeholder={placeholder} />
                                        </WTextField>
                                    )}

                                    {errorMessages[idx] && (
                                        <p className="pt-1 font-size-small color-error">{errorMessages[idx]}</p>
                                    )}
                                </div>
                            </div>
                        ))}

                        <div>
                            <WButton
                                className="w-100%"
                                color="primary"
                                size="lg"
                                variant="dashed"
                                isDisabled={saving || loading || deleting}
                                action={addNewItem}
                            >
                                + Add New
                            </WButton>
                        </div>
                    </div>
                )}

                <div className="pt-3">
                    <WModal
                        button={
                            <WModalButton
                                isDisabled={saving || loading || deleting}
                                label="Save"
                                color="primary"
                                size="md"
                                variant="basic"
                            />
                        }
                    >
                        <WModalContent
                            title="Are You Sure?"
                            label="Save"
                            isActionDisabled={saving || loading || deleting}
                            modalAction={saveConfig}
                        >
                            Are You Sure?
                        </WModalContent>
                    </WModal>
                </div>
            </div>
        </AccordionItem>
    );
}

export default ListValue;
