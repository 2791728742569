import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import type { AccessDelegationWithType } from "../access/delegation/apiOperations.ts";
import type { AccessImpersonationWithType } from "../access/impersonation/apiOperations.ts";
import type { ApiTokenWithType } from "../access/token/apiOperations.ts";
import type { VirtualMachineLoc } from "../compute/vmQuery.ts";
import type { MetalListItemWithType } from "../metal/metalQuery.ts";
import type { IpAddressWithType } from "../network/ipAddress/apiOperations.ts";
import type { LoadBalancerWithType } from "../network/loadbalancer/apiOperations.ts";
import type { VpcWithType } from "../network/vpc/apiOperations.ts";
import type { ManagedServiceLoc } from "../services/servicesQuery.ts";
import type { StorageWithType } from "../storage/objectStorage/apiOperations.ts";

export type AnyResource =
    | ApiTokenWithType
    | AccessDelegationWithType
    | IpAddressWithType
    | LoadBalancerWithType
    | VpcWithType
    | VirtualMachineLoc
    | ManagedServiceLoc
    | StorageWithType
    | AccessImpersonationWithType
    | MetalListItemWithType;

export function getResourceName(item: AnyResource): string {
    const { $type } = item;
    switch ($type) {
        case "api_token":
            return item.description;

        case "access_delegation":
            return item.name;

        case "ip_address":
            return item.name ? item.name : item.address;

        case "load_balancer":
            return item.display_name || item.private_address;

        case "virtual_machine":
            return item.name;

        case "bucket":
            return item.name;

        case "vpc":
            return item.name ?? "";

        case "managed_service":
            return item.display_name;

        case "access_impersonation":
            return item.access_owner;

        case "bare_metal":
            return item.display_name;

        default:
            exhaustiveSwitchCheck($type);
    }
}
