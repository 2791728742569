import type { ZodOpenApiPathsObject } from "zod-openapi";
import { accessPaths } from "./spec/access.ts";
import { billingPaths } from "./spec/billing.ts";
import { configPaths } from "./spec/config.ts";
import { locationPaths } from "./spec/locations.ts";
import { metalPaths } from "./spec/metal.ts";
import { networkPaths } from "./spec/network.ts";
import { hostPoolPaths } from "./spec/pools.ts";
import { pricingPaths } from "./spec/pricing.ts";
import { servicePaths } from "./spec/services.ts";
import { storagePaths } from "./spec/storage.ts";
import { userPaths } from "./spec/user.ts";
import { vmPaths } from "./spec/vm.ts";

export const paths = {
    ...locationPaths,
    ...vmPaths,
    ...servicePaths,
    ...networkPaths,
    ...pricingPaths,
    ...storagePaths,
    ...accessPaths,
    ...billingPaths,
    ...hostPoolPaths,
    ...userPaths,
    ...configPaths,
    ...metalPaths,
} satisfies ZodOpenApiPathsObject;
