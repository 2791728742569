export interface ValidationFuncResult {
    success: boolean;
    errorMsg?: string;
}

export interface ValueEditorDefinition {
    type: "single" | "list";
    isSecret?: boolean;
    props: ValueEditorProps;
}

type ValidationFun = (v: unknown) => ValidationFuncResult;

export interface ValueEditorProps {
    name: string;
    description?: string;
    wikiLink?: string;
    inputType: "textarea" | "input";
    inputRows?: number;
    validationFun?: ValidationFun;
    path: string;
    required?: boolean;
    placeholder?: string;
}

export function getFullPath(path: string, showScopeToken: boolean): string {
    return `warren/general/config${path}${showScopeToken ? "/{{scope}}" : ""}`;
}

function valueIsNumber(v: unknown): boolean {
    return typeof v === "string" ? !Number.isNaN(+v) : typeof v === "number";
}

function valueIsString(v: unknown): boolean {
    return typeof v === "string";
}

export function valueIsJSON(v: unknown): boolean {
    try {
        return typeof v === "string" && typeof JSON.parse(v) === "object";
    } catch {
        return false;
    }
}

function valueIsBoolean(v: unknown): boolean {
    return typeof v === "boolean" || (typeof v === "string" && /(false|true|f|t|0|1|no|yes|n|y)/i.test(v));
}

export function validateInput(validateType: "number" | "string" | "json" | "boolean"): ValidationFun {
    switch (validateType) {
        case "number":
            return (v) => ({ success: valueIsNumber(v), errorMsg: "Value must be a number." });
        case "string":
            return (v) => ({ success: valueIsString(v), errorMsg: "Value must be a string." });
        case "json":
            return (v) => ({ success: valueIsJSON(v), errorMsg: "Value must be a valid JSON object." });
        case "boolean":
            return (v) => ({
                success: valueIsBoolean(v),
                errorMsg: "Value must be either 'true', 't', 'yes', 'y', '1' or 'false', 'f', 'no', 'n', '0'.",
            });
    }
}
