import type { QueryKey } from "@tanstack/react-query";
import { queryOptions } from "@tanstack/react-query";
import type { IpPoolResponse } from "@warrenio/api-spec/spec.oats.gen";
import { atomFamily } from "jotai/utils";
import type { ApiClient } from "../../modules/api/apiClient.ts";
import { getResponseData } from "../../modules/api/apiClient.ts";
import { atomAllLocationsQuery } from "../../modules/location/atomAllLocationsQuery.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export interface Params {
    location: string;
}

export interface IpPoolWithType extends IpPoolResponse, Params {}

function ipPoolFromData(data: IpPoolResponse, location: string): IpPoolWithType {
    return { ...data, location };
}

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["network/admin/ip_pool_status"] : ["network/admin/ip_pool_status", params.location];
}

export function getSingleQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<IpPoolWithType> => {
            const { location } = params;
            const response = getResponseData(
                await apiClient.GET("/{location}/network/admin/ip_pool_status", {
                    signal,
                    params: { path: { location } },
                }),
            );
            return ipPoolFromData(response, location);
        },
    });
}

export const adminIpPoolQueryAtom = atomFamily((location: string) =>
    atomFromStandardQueryOptions(getSingleQuery, { location }),
);

export const adminAllIpPoolsQueryAtom = atomAllLocationsQuery(adminIpPoolQueryAtom, (results) => results);

//#region Mutations

export const unassignFloatingIpMutation = adminMutation((apiClient) => ({
    entity: "floating ip",
    operation: "unassign",
    requestFn: async ({ location, address }: { location: string; address: string }) =>
        await apiClient.POST("/{location}/network/admin/ip_addresses/{address}/unassign", {
            params: { path: { location, address } },
        }),
}));

export const deleteFloatingIpMutation = adminMutation((apiClient) => ({
    entity: "floating ip",
    operation: "delete",
    requestFn: async ({ location, address }: { location: string; address: string }) =>
        await apiClient.DELETE("/{location}/network/admin/ip_addresses/{address}", {
            params: { path: { location, address } },
        }),
}));

//#endregion Mutations
