import { notNull } from "@warrenio/utils/notNull";
import type { ReactNode } from "react";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { designatedVmMutation } from "./vmQuery.ts";
import type { GQVmItem } from "./VmsTable.tsx";

interface DesignatedVmBlockModalProps {
    item: GQVmItem;
    designated_pool_uuid: string;
}

export function DesignatedVmBlockModalContent({ item, designated_pool_uuid }: DesignatedVmBlockModalProps) {
    const updateMutation = useStandardMutation(designatedVmMutation);

    const { uuid, name, _location } = item;

    async function onSubmit() {
        await updateMutation.mutateAsync({
            body: {
                uuid: uuid,
                designated_pool_uuid: designated_pool_uuid,
            },
            location: notNull(_location),
        });
    }

    return (
        <WModalContent
            title="Change Designated Pool"
            label="Change"
            autoFocus
            modalAction={async () => await onSubmit()}
        >
            <p className="pb-3">
                This will change designated pool for virtual machine "<b>{name}</b>".
            </p>
        </WModalContent>
    );
}

interface DesignatedVmBlockProps extends DesignatedVmBlockModalProps {
    button: ReactNode;
}

export function DesignatedVmBlock(props: DesignatedVmBlockProps) {
    return (
        <WModal button={props.button}>
            <DesignatedVmBlockModalContent {...props} />
        </WModal>
    );
}
