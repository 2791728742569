import type {
    AdminMetalHistoryItem,
    AdminMetalHistoryList,
    AdminMetalMachineItem,
    AdminMetalMachineList,
    AdminMetalSpecList,
    MetalLease,
    MetalListItem,
    MetalListResponse,
    MetalMachine,
    MetalOs,
    MetalOsListResponse,
    MetalOsType,
    MetalSpec,
    MetalSpecListItem,
    MetalSpecListResponse,
    MetalStateHistory,
} from "@warrenio/api-spec/spec.oats.gen";
import * as apiSpec from "@warrenio/api-spec/spec/metal";
import { MetalOsId, MetalOsTypeId } from "@warrenio/api-spec/spec/metal";
import * as adminSpec from "@warrenio/api-spec/spec/metal.admin";
import { filterNulls } from "@warrenio/utils/collections/filterNulls";
import { notNull } from "@warrenio/utils/notNull";
import { randomUUID } from "@warrenio/utils/ponyfill/randomUUID";
import type { HttpHandler } from "msw";
import { HttpResponse } from "msw";
import { pick } from "remeda";
import invariant from "tiny-invariant";
import { getMockDb, markDirty } from "./db.ts";
import { getMockTimeTz } from "./mockTime.ts";
import { http } from "./msw/handlerMiddleware.ts";
import { mustFindBy } from "./mustFindBy.ts";
import { generateId, getUserId, parseJson, throwStandardErrorResponse, validateSpec } from "./requestUtils.ts";

/* eslint-disable @typescript-eslint/require-await */

/** Joins all the metal entities together, starting from machine */
function metalJoin() {
    const db = getMockDb();
    return db.metalMachines.map((machine) => {
        const state = notNull(db.metalStates.find((state) => state.state_id === machine.current_state_id));
        const lease = state.lease_id ? db.metalLeases.find((lease) => lease.lease_id === state.lease_id) : undefined;
        const spec = notNull(db.metalSpecs.find((spec) => spec.spec_id === machine.spec_id));
        const os = state.os_id ? db.metalOs.find((os) => os.os_id === state.os_id) : undefined;
        const os_type = os ? db.metalOsTypes.find((osType) => osType.os_type_id === os.os_type_id) : undefined;
        return {
            machine,
            lease,
            state,
            spec,
            os,
            os_type,
        };
    });
}

function isAvailable(state: MetalStateHistory) {
    return state.status === "available";
}

/** Convert internal entities to user-visible format */
function buildItem(m: {
    machine: MetalMachine;
    lease: MetalLease | undefined;
    state: MetalStateHistory;
    spec: MetalSpec;
}): MetalListItem {
    invariant(m.lease);
    return {
        ...m.lease,
        spec: pick(m.spec, ["spec_id", "uuid", "title", "subtitle", "description"]),
        os_id: m.state.os_id,
        billing_account_id: notNull(m.state.billing_account_id),
        created_at: m.lease.created_at,
        ip_public_v4: m.machine.ip_public_v4,
        ip_public_v6: m.machine.ip_public_v6,
        mac_addresses: m.machine.mac_addresses,
        status: apiSpec.MetalLeasedStatus.parse(m.state.status),
        ssh_credentials: m.machine.ssh_credentials,
    };
}

function changeState(machine: MetalMachine, newState: MetalStateHistory) {
    const db = getMockDb();

    validateSpec(apiSpec.MetalStateHistory, newState);
    db.metalStates.push(newState);

    // NB: Update current state ID
    machine.current_state_id = newState.state_id;

    markDirty();
}

export const metalHandlers: HttpHandler[] = [
    //#region User
    http.get("/{location}/metal/leases", async (r) => {
        const userId = getUserId(r);
        const items = metalJoin()
            .filter((m) => m.lease?.user_id === userId)
            .map((m) => buildItem(m));

        return HttpResponse.json<MetalListResponse>(filterNulls(items));
    }),

    http.get("/metal/os", async (_r) => {
        return HttpResponse.json<MetalOsListResponse>({
            os: getMockDb().metalOs,
            os_types: getMockDb().metalOsTypes,
        });
    }),

    http.get("/{location}/metal/specs", async (_r) => {
        const db = getMockDb();
        const specs = db.metalSpecs
            .filter((s) => s.is_visible)
            .map((s): MetalSpecListItem => {
                const available = db.metalMachines
                    .filter((m) => m.spec_id === s.spec_id)
                    .map((m) => notNull(db.metalStates.find((st) => st.state_id === m.current_state_id)))
                    .filter((st) => isAvailable(st));

                return {
                    ...s,
                    is_available: available.length > 0,
                    ready_os_ids: filterNulls(available.map((st) => st.os_id)),
                };
            });

        return HttpResponse.json<MetalSpecListResponse>(specs);
    }),

    http.post("/{location}/metal/leases", async (r) => {
        const { billing_account_id, display_name, requested_os_id, spec_uuid } = await parseJson(
            r,
            apiSpec.MetalLeaseCreateBody,
        );

        const db = getMockDb();

        const _account = mustFindBy(db.billingAccounts, (a) => a.id, billing_account_id, "billing account");

        const spec = mustFindBy(db.metalSpecs, (s) => s.uuid, spec_uuid, "spec");

        const os = mustFindBy(db.metalOs, (o) => o.os_id, requested_os_id, "OS");

        const availableMachines = metalJoin().filter((m) => m.spec.spec_id === spec.spec_id && isAvailable(m.state));

        if (availableMachines.length === 0) {
            throwStandardErrorResponse("No available machines");
        }

        const { machine, state: currentState } =
            availableMachines.find((m) => m.os?.os_id === os.os_id) ?? availableMachines[0];
        const isReadyNow = currentState.os_id === os.os_id;

        const created_at = getMockTimeTz();
        const lease: MetalLease = {
            lease_id: generateId(),
            uuid: randomUUID(),

            created_at,
            updated_at: created_at,

            user_id: getUserId(r),
            machine_id: machine.machine_id,

            requested_os_id: os.os_id,
            display_name,
        };
        validateSpec(apiSpec.MetalLease, lease);

        const newState: MetalStateHistory = {
            state_id: generateId(),
            changed_at: created_at,

            acting_user_id: getUserId(r),
            action: "user_request",

            machine_id: machine.machine_id,
            billing_account_id,
            os_id: os.os_id,

            lease_id: lease.lease_id,
            status: isReadyNow ? "in_use" : "pending",
        };
        changeState(machine, newState);

        // NB: Push lease after state so DB is not modified if the state change fails
        db.metalLeases.push(lease);
        markDirty();

        return HttpResponse.json<MetalListItem>(buildItem({ machine, lease, state: newState, spec }));
    }),

    http.patch("/{location}/metal/leases/{uuid}", async (r) => {
        const { uuid } = r.params;
        const rest = await parseJson(r, apiSpec.MetalLeaseUpdateBody);
        const lease = mustFindBy(getMockDb().metalLeases, (l) => l.uuid, uuid, "lease");

        Object.assign(lease, rest);
        lease.updated_at = getMockTimeTz();

        // TODO: Check invariants

        return HttpResponse.json({ success: true });
    }),

    http.delete("/{location}/metal/leases/{uuid}", async (r) => {
        const { uuid } = r.params;
        const db = getMockDb();

        const lease = notNull(db.metalLeases.find((l) => l.uuid === uuid));
        const state = notNull(db.metalStates.find((s) => s.machine_id === lease.machine_id));
        const machine = notNull(db.metalMachines.find((m) => m.machine_id === lease.machine_id));

        // TODO: Check invariants

        const newState: MetalStateHistory = {
            state_id: generateId(),
            changed_at: getMockTimeTz(),

            acting_user_id: getUserId(r),
            action: "user_release",

            machine_id: state.machine_id,
            billing_account_id: state.billing_account_id,
            os_id: state.os_id,

            lease_id: null,
            status: "cleaning",
        };
        changeState(machine, newState);

        return HttpResponse.json({ success: true });
    }),
    //#endregion

    //#region Admin

    /// Machines
    http.get("/{location}/admin/metal/machines", async (_r) => {
        return HttpResponse.json<AdminMetalMachineList>(
            metalJoin().map(
                (m): AdminMetalMachineItem => ({
                    ...m.machine,
                    ...pick(m.state, ["os_id", "status", "lease_id", "billing_account_id"]),
                    lease: m.lease,
                    spec: m.spec,
                }),
            ),
        );
    }),

    http.post("/{location}/admin/metal/machines", async (r) => {
        const { os_id, ...body } = await parseJson(r, adminSpec.AdminMetalMachineCreateBody);

        const db = getMockDb();
        const spec = mustFindBy(db.metalSpecs, (s) => s.spec_id, body.spec_id, "spec");

        const created_at = getMockTimeTz();
        const state_id = generateId();
        const machine: MetalMachine = {
            ...body,
            machine_id: generateId(),
            uuid: randomUUID(),
            created_at,
            spec_id: spec.spec_id,
            current_state_id: state_id,
        };
        db.metalMachines.push(machine);
        markDirty();

        const state: MetalStateHistory = {
            state_id,
            changed_at: created_at,
            acting_user_id: getUserId(r),
            action: "admin_create",
            machine_id: machine.machine_id,
            billing_account_id: null,
            os_id,
            lease_id: null,
            status: "offline",
        };
        changeState(machine, state);

        return HttpResponse.json<MetalMachine>(machine);
    }),
    http.patch("/{location}/admin/metal/machines/{uuid}", async (r) => {
        const { uuid } = r.params;
        const { os_id, status, ...body } = await parseJson(r, adminSpec.AdminMetalMachineUpdateBody);

        const db = getMockDb();
        const machine = mustFindBy(db.metalMachines, (m) => m.uuid, uuid, "machine");
        const state = mustFindBy(db.metalStates, (s) => s.state_id, machine.current_state_id, "state");

        // Update machine fields
        Object.assign(machine, body);
        markDirty();

        // Update state if necessary
        if (status !== state.status || os_id !== state.os_id) {
            const newStatus = status ?? state.status;
            const isLeasedStatus = newStatus === "in_use" || newStatus === "pending";

            // Release lease if status changes to non-leased
            const newLeaseId = isLeasedStatus ? state.lease_id : null;
            const newBaId = isLeasedStatus ? state.billing_account_id : null;

            const newState: MetalStateHistory = {
                state_id: generateId(),
                changed_at: getMockTimeTz(),
                acting_user_id: getUserId(r),
                action: "admin_update",
                machine_id: machine.machine_id,
                billing_account_id: newBaId,
                os_id: os_id ?? state.os_id,
                lease_id: newLeaseId,
                status: newStatus,
            };
            // TODO: Invariants
            changeState(machine, newState);
        }

        return HttpResponse.json<MetalMachine>(machine);
    }),
    http.delete("/{location}/admin/metal/machines/{uuid}", async (r) => {
        const { uuid } = r.params;
        const db = getMockDb();
        const machine = mustFindBy(db.metalMachines, (m) => m.uuid, uuid, "machine");

        const newState: MetalStateHistory = {
            state_id: generateId(),
            changed_at: getMockTimeTz(),
            acting_user_id: getUserId(r),
            action: "admin_delete",
            machine_id: machine.machine_id,
            billing_account_id: null,
            os_id: null,
            lease_id: null,
            status: "deleted",
        };
        changeState(machine, newState);

        return HttpResponse.json({ success: true });
    }),

    http.post("/{location}/admin/metal/leases", async (r) => {
        const { billing_account_id, display_name, machine_id, requested_os_id } = await parseJson(
            r,
            adminSpec.AdminMetalLeaseCreateBody,
        );

        const db = getMockDb();

        const ba = mustFindBy(db.billingAccounts, (a) => a.id, billing_account_id, "billing account");

        const machine = mustFindBy(db.metalMachines, (m) => m.machine_id, machine_id, "machine");
        const os = mustFindBy(db.metalOs, (o) => o.os_id, requested_os_id, "OS");
        const state = mustFindBy(db.metalStates, (s) => s.machine_id, machine_id, "state");

        // TODO: Check invariants

        const created_at = getMockTimeTz();
        const lease: MetalLease = {
            lease_id: generateId(),
            uuid: randomUUID(),

            created_at,
            updated_at: created_at,

            user_id: ba.user_id,
            machine_id,
            requested_os_id: os.os_id,
            display_name,
        };
        validateSpec(apiSpec.MetalLease, lease);

        const newState: MetalStateHistory = {
            state_id: generateId(),
            changed_at: created_at,

            acting_user_id: getUserId(r),
            action: "admin_lease_create",

            machine_id: state.machine_id,
            billing_account_id,
            os_id: state.os_id,

            lease_id: lease.lease_id,
            status: "pending",
        };
        changeState(machine, newState);

        db.metalLeases.push(lease);
        markDirty();

        return HttpResponse.json<MetalLease>(lease);
    }),

    http.patch("/{location}/admin/metal/leases/{uuid}", async (r) => {
        // Does not allow changing any `state` fields currently
        const { uuid } = r.params;
        const body = await parseJson(r, adminSpec.AdminMetalLeaseUpdateBody);

        const db = getMockDb();
        const lease = mustFindBy(db.metalLeases, (l) => l.uuid, uuid, "lease");

        Object.assign(lease, body);
        lease.updated_at = getMockTimeTz();
        markDirty();

        return HttpResponse.json<MetalLease>(lease);
    }),

    /// Machines history
    http.get("/{location}/admin/metal/machines/{uuid}/history", async (r) => {
        const { uuid } = r.params;
        const db = getMockDb();
        const machine = mustFindBy(db.metalMachines, (m) => m.uuid, uuid, "machine");

        const history = db.metalStates
            .filter((s) => s.machine_id === machine.machine_id)
            .map(
                (s): AdminMetalHistoryItem => ({
                    ...s,
                    lease: s.lease_id ? db.metalLeases.find((l) => l.lease_id === s.lease_id) : undefined,
                }),
            );

        return HttpResponse.json<AdminMetalHistoryList>(history);
    }),

    /// Specs
    http.get("/{location}/admin/metal/specs", async (_r) => {
        return HttpResponse.json<AdminMetalSpecList>(getMockDb().metalSpecs);
    }),

    http.post("/{location}/admin/metal/specs", async (r) => {
        const body = await parseJson(r, adminSpec.AdminMetalSpecCreateBody);

        const created_at = getMockTimeTz();
        const spec: MetalSpec = {
            ...body,
            spec_id: generateId(),
            uuid: randomUUID(),
            created_at,
            updated_at: created_at,
            is_deleted: false,
        };
        getMockDb().metalSpecs.push(spec);
        markDirty();

        return HttpResponse.json<MetalSpec>(spec);
    }),
    http.patch("/{location}/admin/metal/specs/{uuid}", async (r) => {
        const { uuid } = r.params;
        const body = await parseJson(r, adminSpec.AdminMetalSpecUpdateBody);

        const spec = mustFindBy(getMockDb().metalSpecs, (s) => s.uuid, uuid, "spec");
        Object.assign(spec, body);
        spec.updated_at = getMockTimeTz();
        markDirty();

        return HttpResponse.json<MetalSpec>(spec);
    }),
    http.delete("/{location}/admin/metal/specs/{uuid}", async (r) => {
        const { uuid } = r.params;
        const spec = mustFindBy(getMockDb().metalSpecs, (s) => s.uuid, uuid, "spec");

        // TODO: invariants

        spec.is_deleted = true;
        markDirty();

        return HttpResponse.json({ success: true });
    }),
    //#endregion

    //#region Operating systems & types
    http.post("/admin/metal/os", async (r) => {
        const body = await parseJson(r, apiSpec.MetalOsCreate);

        const os: MetalOs = {
            ...body,
            is_deleted: false,
            updated_at: getMockTimeTz(),
        };
        getMockDb().metalOs.push(os);
        markDirty();

        return HttpResponse.json(os);
    }),
    http.patch("/admin/metal/os/{os_id}", async (r) => {
        const os_id = MetalOsId.parse(r.params.os_id);
        const body = await parseJson(r, apiSpec.MetalOsUpdate);

        const os = mustFindBy(getMockDb().metalOs, (o) => o.os_id, os_id, "OS");
        Object.assign(os, body);
        os.updated_at = getMockTimeTz();
        markDirty();

        return HttpResponse.json(os);
    }),
    http.delete("/admin/metal/os/{os_id}", async (r) => {
        const os_id = MetalOsId.parse(r.params.os_id);

        getMockDb().metalOs = getMockDb().metalOs.filter((o) => o.os_id !== os_id);
        markDirty();

        return HttpResponse.json({ success: true });
    }),

    // --- OS types ---
    http.post("/admin/metal/os_types", async (r) => {
        const body = await parseJson(r, apiSpec.MetalOsTypeCreate);

        const osType: MetalOsType = {
            ...body,
            is_deleted: false,
            updated_at: getMockTimeTz(),
        };
        getMockDb().metalOsTypes.push(osType);
        markDirty();

        return HttpResponse.json(osType);
    }),
    http.patch("/admin/metal/os_types/{os_type_id}", async (r) => {
        const os_type_id = MetalOsTypeId.parse(r.params.os_type_id);
        const body = await parseJson(r, apiSpec.MetalOsTypeUpdate);

        const osType = mustFindBy(getMockDb().metalOsTypes, (o) => o.os_type_id, os_type_id, "OS type");
        Object.assign(osType, body);
        osType.updated_at = getMockTimeTz();
        markDirty();

        return HttpResponse.json(osType);
    }),
    http.delete("/admin/metal/os_types/{os_type_id}", async (r) => {
        const os_type_id = MetalOsTypeId.parse(r.params.os_type_id);

        getMockDb().metalOsTypes = getMockDb().metalOsTypes.filter((o) => o.os_type_id !== os_type_id);
        markDirty();

        return HttpResponse.json({ success: true });
    }),
    //#endregion
];
