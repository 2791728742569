import type { MetalListItem } from "@warrenio/api-spec/spec.oats.gen";
import { mapOnValues, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { atom } from "jotai/vanilla";
import { mergeLoadedQueries, type QMergedResult } from "../../utils/query/mergeQueries.ts";
import { atomAllLocationsQuery } from "../location/atomAllLocationsQuery.ts";
import { pricesAtom } from "../pricing/query.ts";
import { getBareMetalSpecPrice, type Price } from "../pricing/resourcePricing.ts";
import type { MetalListItemWithType } from "./metalQuery.ts";
import { metalLocationQueryAtom } from "./metalQuery.ts";

export interface MetalListItemWithPrice extends MetalListItemWithType {
    price: Price;
}

export type MetalPricedResponse = Map<MetalListItem["uuid"], MetalListItemWithPrice>;

export const metalPricedQueryAtom = atomFamily((location: string) =>
    atom((get): QMergedResult<MetalPricedResponse | undefined> => {
        return mergeLoadedQueries(
            {
                items: get(metalLocationQueryAtom(location)),
                prices: get(pricesAtom),
            },
            ({ items, prices }) =>
                mapOnValues(
                    items,
                    (item): MetalListItemWithPrice => ({
                        ...item,
                        price: getBareMetalSpecPrice(prices, item.location, item.spec.uuid),
                    }),
                ),
        );
    }),
);

export const allMetalPricedQueryAtom = atomAllLocationsQuery(metalPricedQueryAtom, (results) => mergeMaps(results));
