import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { GoogleTagManagerKey } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

//#region Query and parameters
const queryKey: QueryKey = ["config/ui/gtm_key"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<GoogleTagManagerKey> => {
            return getResponseData(await client.GET("/config/ui/gtm_key", { signal }));
        },
    });
}
//#endregion

//#region Atoms
export const googleTagManagerQueryAtom = atomFromStandardQueryOptions(getQuery);
//#endregion

//#region Mutations

export const googleTagManagerMutation = adminMutation((apiClient) => ({
    entity: "gtm_key",
    operation: "update",
    requestFn: async ({ gtm_key }: GoogleTagManagerKey) =>
        await apiClient.PUT("/config/ui/gtm_key", {
            params: { query: { gtm_key } },
        }),
    invalidateQueryKey: queryKey,
}));

//#endregion
