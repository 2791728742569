import type { QueryClient } from "@tanstack/query-core";
import type { AddCardBody, ApplyForInvoiceBody, CardVerifyRequest } from "@warrenio/api-spec/spec.oats.gen";
import invariant from "tiny-invariant";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { invalidateQueries } from "../../../utils/query/invalidateQueries.ts";
import { runMutation } from "../../../utils/query/runMutation.ts";
import { getResponseData, type ApiClient } from "../../api/apiClient.ts";
import type { PaymentMethodId } from "../PaymentMethod.tsx";
import * as billingAccountQuery from "../billingAccountQuery.ts";
import { parseAdditionalData } from "../billingAccountQueryUtils.ts";
import * as billingCardsQuery from "../billingCardsQuery.ts";
import { AddMethodResult, type AddMethodParams } from "./AddMethodParams.ts";

export async function addLinkMethodSimple(
    queryClient: QueryClient,
    apiClient: ApiClient,
    { account }: AddMethodParams,
    id: PaymentMethodId,
) {
    const newMethods = [...(parseAdditionalData(account.account)?.link_methods ?? []), id];

    const { success } = await runMutation(
        queryClient,
        billingAccountQuery.saveBillingMethodsMutation(apiClient, queryClient),
        { billing_account_id: account.id, method_ids: newMethods },
    );
    invariant(success, "Unknown error adding payment method");
    return AddMethodResult.SUCCESS;
}

export async function invalidateBillingCards(queryClient: QueryClient, billing_account_id: number) {
    await invalidateQueries({
        queryClient,
        immediate: [billingAccountQuery.queryKey, billingCardsQuery.getQueryKey({ billing_account_id })],
    });
}

export async function addCardRequest(apiClient: ApiClient, body: AddCardBody) {
    return getResponseData(await apiClient.POST("/payment/card", { ...jsonEncodedBody, body }));
}

export async function applyForInvoiceRequest(apiClient: ApiClient, body: ApplyForInvoiceBody) {
    return getResponseData(await apiClient.POST("/payment/apply_for_invoice_payment", { ...jsonEncodedBody, body }));
}

export async function cardVerifyRequest(
    apiClient: ApiClient,
    payment_object_id: CardVerifyRequest["payment_object_id"],
    front_base_url: string,
    // PS! 'force_3ds' is temporary param to force 3DS in Omise creditcard processor even when old UI is not using it, remove when old UI no longer exists (refactor also payment's backend code)
    force_3ds: boolean = false,
) {
    return getResponseData(
        await apiClient.POST("/payment/card/verify", {
            ...jsonEncodedBody,
            body: { payment_object_id, front_base_url, force_3ds },
        }),
    );
}
