import P from "./PricesView.module.css";

import type { LocsPricing } from "@warrenio/api-spec/spec.oats.gen";
import { hasOwn } from "@warrenio/utils/collections/getOwnProperty";
import { notNull } from "@warrenio/utils/notNull";
import { useMemo } from "react";
import { keys } from "remeda";
import { Badge } from "../../components/Badge.tsx";
import { SeparatedBy } from "../../components/SeparatedBy.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WModal } from "../../components/modal/WModal.tsx";
import { DEFAULT_KEY } from "../../modules/pricing/resourcePricing.ts";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AddPricesModal } from "./AddPricesModal.tsx";
import { getAllLocKeys, priceLocsAtom, type PriceLoc } from "./priceLocs.tsx";

export function LocationRadios({
    onAdd,
    onChange,
    onDelete,
    isDisabled,
    disabledKeys,
    selectedLocation,
    prices,
}: {
    onAdd: (value: string) => void;
    onChange: (value: string) => void;
    onDelete: () => void;
    isDisabled?: boolean;
    disabledKeys: string[];
    selectedLocation: string;
    prices: LocsPricing | undefined;
}) {
    const priceLocs = useSuspenseQueryAtom(priceLocsAtom);

    const selectedAppliesTo = useMemo(() => {
        const groups: Record<string, PriceLoc[]> = {};
        if (!prices) {
            return groups;
        }

        for (const loc of priceLocs) {
            const applyKey = notNull(getAllLocKeys(loc).find((k) => hasOwn(prices, k)));
            (groups[applyKey] ??= []).push(loc);
        }

        return groups;
    }, [prices, priceLocs]);

    const locationElements = prices
        ? keys(prices).map((locKey) => {
              const loc = priceLocs.find((o) => o.key === locKey);
              // For historical pricing, the corresponding location entity might not exist anymore
              const { title, icon } = loc ?? {
                  title: <span className="text-error">[Unknown - {locKey}]</span>,
                  icon: null,
              };

              return (
                  <div
                      key={locKey}
                      className={cn(P.Radio, selectedLocation === locKey && P.isActive)}
                      onClick={() => onChange(locKey)}
                  >
                      {icon}
                      {title}
                  </div>
              );
          })
        : [];

    const appliesTo = selectedLocation ? selectedAppliesTo[selectedLocation] : undefined;

    return (
        <>
            <div className="flex items-center pt-4 pb-2">
                {locationElements}

                <WModal
                    button={
                        <WTooltip text="Add Prices">
                            <WButton
                                isDisabled={isDisabled}
                                className={P.AddLocation}
                                color="primary"
                                variant="dashed"
                                size="md"
                                icon="jp-icon-add"
                                action={undefined}
                            />
                        </WTooltip>
                    }
                >
                    <AddPricesModal onChange={(value) => onAdd(value)} disabledKeys={disabledKeys} />
                </WModal>

                <WButton
                    isDisabled={selectedLocation === DEFAULT_KEY || isDisabled}
                    className={P.DeleteLocation}
                    color="primary"
                    variant="ghost"
                    size="md"
                    icon="jp-trash-icon"
                    action={() => onDelete()}
                >
                    Delete
                </WButton>
            </div>

            <div className="color-muted pb-4">
                Applied to:
                {appliesTo ? (
                    <SeparatedBy separator=",">
                        {appliesTo.map((loc) => (
                            <Badge key={loc.key} noDot color="text">
                                {loc.title}
                            </Badge>
                        ))}
                    </SeparatedBy>
                ) : (
                    "[NOT USED]"
                )}
            </div>
        </>
    );
}
