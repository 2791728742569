import { useRef, useState, type RefObject } from "react";
import invariant from "tiny-invariant";
import { WButton } from "../components/button/WButton.tsx";
import { showWarn } from "../modules/error/errorStream.ts";
import { mcn, type BaseProps } from "../utils/baseProps.ts";

/** Variables sent to GraphQL */
export interface PageVariables {
    limit: number;
    page: number;
}

/** Variables sent to GraphQL */
export interface CursorVariables {
    limit: number;
    cursor?: string;
}

/** Input to the pager from GraphQL */
export interface PagingResult {
    total: number;
    cursor?: string | null;
}

export type PagerParams = PagePagerParams | CursorPagerParams;

export interface UsePager {
    variables: PageVariables | CursorVariables;
    goFirst: () => void;
    useVisual: (info: PagingResult | undefined) => PagerVisualProps;
}

interface PagerVisualProps {
    pageSize: number;
    currentPage: number;
    lastPage: number | undefined;
    goPrev: (() => void) | undefined;
    goNext: (() => void) | undefined;
}

export interface PagePagerParams {
    pageSize?: number;
    initialState?: number;
}

const DEFAULT_PAGE_SIZE = 50;

export function usePagePager({ pageSize = DEFAULT_PAGE_SIZE, initialState = 1 }: PagePagerParams = {}): UsePager {
    const [currentPage, setCurrentPage] = useState(initialState);

    return {
        variables: {
            limit: pageSize,
            page: currentPage,
        },
        goFirst: () => {
            setCurrentPage(1);
        },
        useVisual: (info) => {
            if (info?.cursor) {
                showWarn("Cursor is not supported by usePagePager, use useCursorPager instead");
            }

            const total = info?.total;
            const lastPage = total != null ? Math.ceil(total / pageSize) : undefined;

            const hasPrev = currentPage > 1;
            const hasNext = info && currentPage * pageSize < info.total;

            return {
                pageSize: pageSize,
                currentPage,
                lastPage,
                goPrev: hasPrev ? () => setCurrentPage((p) => p - 1) : undefined,
                goNext: hasNext ? () => setCurrentPage((p) => p + 1) : undefined,
            };
        },
    };
}

export interface CursorPagerParams {
    pageSize?: number;
}

export interface CursorPagerState {
    cursorIndex: number;
    cursors: string[];
}

const FIRST_INDEX = -1;

export function useCursorPager({ pageSize = DEFAULT_PAGE_SIZE }: CursorPagerParams = {}): UsePager {
    const [state, setState] = useState<CursorPagerState>(() => ({
        cursorIndex: FIRST_INDEX,
        cursors: [],
    }));

    useLog("state", state);

    const { cursorIndex, cursors } = state;

    invariant(cursorIndex < cursors.length, "Cursor index out of bounds");

    return {
        variables: {
            limit: pageSize,
            cursor: cursorIndex !== FIRST_INDEX ? cursors[cursorIndex] : undefined,
        },
        goFirst: () => {
            setState((s) => ({ ...s, cursorIndex: FIRST_INDEX }));
        },
        useVisual: (info) => {
            const total = info?.total;
            const lastPage = total != null ? Math.ceil(total / pageSize) : undefined;

            const nextCursor = info?.cursor;
            const hasPrev = cursorIndex >= 0;

            return {
                pageSize: pageSize,
                currentPage: cursorIndex + 2,
                lastPage,
                goPrev: hasPrev ? () => setState((s) => ({ ...s, cursorIndex: s.cursorIndex - 1 })) : undefined,
                goNext: nextCursor
                    ? () =>
                          setState((state) => {
                              const nextIndex = state.cursorIndex + 1;
                              const newCursors = [...state.cursors];
                              newCursors[nextIndex] = nextCursor;
                              return {
                                  cursorIndex: nextIndex,
                                  cursors: newCursors,
                              };
                          })
                    : undefined,
            };
        },
    };
}

interface PagerProps extends BaseProps {
    pager: UsePager;
    result: PagingResult | undefined;
    topRef: RefObject<HTMLElement | null>;
}

export function Pager({ pager: { useVisual }, result, topRef, ...props }: PagerProps) {
    // Remember the last info to still show the page numbers while new data is loading
    const cachedInfo = useLastDefined(result);

    const { currentPage, lastPage, goPrev, goNext, pageSize } = useVisual(cachedInfo);

    function scrollToTop() {
        topRef.current?.scrollIntoView({ behavior: "instant" });
    }

    const fromNumber = (currentPage - 1) * pageSize + 1;
    const toNumber = currentPage === lastPage ? cachedInfo?.total : currentPage * pageSize;

    return (
        <div {...mcn("HStack gap-2", props)}>
            <WButton
                action={() => {
                    goPrev?.();
                    scrollToTop();
                }}
                isDisabled={!goPrev}
            >
                Prev
            </WButton>
            <span>
                {currentPage} / {lastPage ?? "..."}
            </span>
            <WButton
                action={() => {
                    goNext?.();
                    scrollToTop();
                }}
                isDisabled={!goNext}
            >
                Next
            </WButton>

            <div className="ml-auto">
                Showing {fromNumber} to {toNumber} of {cachedInfo?.total} results
            </div>
        </div>
    );
}

function useLastDefined<T>(value: T | undefined): T | undefined {
    const lastValue = useRef<T | undefined>(value);
    if (value !== undefined) {
        lastValue.current = value;
        return value;
    }
    return lastValue.current;
}

function useLog(name: string, value: unknown) {
    const lastValue = useRef<unknown>({});
    if (value !== lastValue.current) {
        console.debug(`${name}: %o`, value);
        lastValue.current = value;
    }
}
