/** Are we running in Storybook?
 * @see {@link isChromatic()} to specifically check for Chromatic snapshot tests */
export const isStorybook = "STORYBOOK" in import.meta.env;

/** Are we running in Storybook or Vitest? */
export const isTestEnvironment = import.meta.env.MODE === "test" || isStorybook;

/** Are we running in from built code (vs. from development server)? */
export const isBuiltRelease = import.meta.env.MODE === "production";

export const environmentId =
    import.meta.env.MODE === "production" && import.meta.env.DEV ? "staging" : import.meta.env.MODE;
