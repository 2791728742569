import D from "./Dashboard.module.css";

import { BadgeDot } from "../../components/Badge.tsx";
import { Separator } from "../../components/Separator.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { adminAllStoragePoolsQueryAtom } from "../buckets/storagePoolsQuery.ts";
import { BigDonut } from "./DonutChart.tsx";
import { LightBlock } from "./LightBlock.tsx";

function storageGB(x: string) {
    const units = ["GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0;
    let n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
        n = n / 1024;
    }
    //include a decimal point and a tenths-place digit if presenting
    //less than ten of KB or greater units
    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
}

function StorageSectionContent() {
    const storagePools = useSuspenseQueryAtom(adminAllStoragePoolsQueryAtom);

    return (
        <>
            <h2 className="font-size-heading">Storage</h2>
            <p className="pb-3">Block storage usage</p>
            <Separator />
            <div className={D.Grid}>
                {[...storagePools.values()].map((item) => {
                    const { uuid, display_name, location, percent_used, stored_gb, total_gb } = item;

                    const items = [
                        { value: stored_gb, color: "var(--color-chart-2)" },
                        { value: total_gb - stored_gb, color: "var(--color-chart-free)" },
                    ];

                    const tooltipContent = (
                        <div>
                            <BadgeDot color="color-chart-2">
                                Stored: <b>{storageGB(stored_gb.toFixed(0))}</b>
                            </BadgeDot>

                            <BadgeDot color="color-chart-free">
                                Available: <b>{storageGB((total_gb - stored_gb).toFixed(0))}</b>
                            </BadgeDot>
                        </div>
                    );

                    return (
                        <BigDonut
                            key={uuid}
                            displayName={display_name}
                            items={items}
                            tooltipContent={tooltipContent}
                            total={storageGB(total_gb.toFixed(0))}
                            location={location}
                            percentUsed={percent_used}
                        />
                    );
                })}
            </div>
        </>
    );
}

export function StorageSection() {
    return (
        <LightBlock className={D.Wide}>
            <StorageSectionContent />
        </LightBlock>
    );
}
