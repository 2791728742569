import RA from "../../components/forms/Radio.module.css";
import R from "../../components/forms/Radiogroup.module.css";

import { notNull } from "@warrenio/utils/notNull";
import { type ReactNode, useState } from "react";
import { Radio, RadioGroup } from "react-aria-components";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { deleteFloatingIpMutation } from "../floating_ips/floatingIpsQuery.ts";
import { deleteVmMutation } from "./vmQuery.ts";
import type { GQVmItem } from "./VmsTable.tsx";

interface DeleteVmBlockModalProps {
    item: GQVmItem;
}

export function DeleteVmBlockModalContent({ item }: DeleteVmBlockModalProps) {
    const deleteIpMutation = useStandardMutation(deleteFloatingIpMutation);
    const deleteMutation = useStandardMutation(deleteVmMutation);
    const [deleteFloating, setDeleteFloating] = useState<string>("");

    const { uuid, name, ip_address_object, _location } = item;
    const attachedPublicIp = ip_address_object?.address;

    async function onSubmit() {
        await deleteMutation.mutateAsync({ uuid: uuid, location: notNull(_location) });
        if (deleteFloating) {
            await deleteIpMutation.mutateAsync({ location: notNull(_location), address: notNull(attachedPublicIp) });
        }
    }

    return (
        <WModalContent
            title="Delete Virtual Machine"
            label="Delete"
            autoFocus
            isActionDisabled={!!attachedPublicIp && deleteFloating === ""}
            modalAction={async () => await onSubmit()}
        >
            <p className="pb-3">
                Deleting a resource "<b>{name}</b>" will permanently remove it and will not be accessible after that.
            </p>
            <p className="pb-3">
                Deleting a resource will also delete your backups and snapshots and the machine cannot be restored after
                that. If you wish to keep the backups and snapshots consider stopping the machine instead.
            </p>

            {!!attachedPublicIp && (
                <>
                    <p className="pb-3">
                        There is floating IP <b>{attachedPublicIp}</b> assigned to this resource.
                    </p>

                    <p className="font-size-subtitle pb-2">What would you like to do?</p>
                    <RadioGroup
                        className={R.RadioGroup}
                        aria-label="Keep or delete floating IP"
                        value={deleteFloating}
                        orientation="horizontal"
                        onChange={setDeleteFloating}
                    >
                        <Radio className={RA.Radio} value="no">
                            Keep floating IP
                        </Radio>
                        <Radio className={RA.Radio} value="yes">
                            Delete floating IP
                        </Radio>
                    </RadioGroup>
                </>
            )}
        </WModalContent>
    );
}

interface DeleteVmBlockProps extends DeleteVmBlockModalProps {
    button: ReactNode;
}

export function DeleteVmBlock(props: DeleteVmBlockProps) {
    return (
        <WModal button={props.button}>
            <DeleteVmBlockModalContent {...props} />
        </WModal>
    );
}
