import { z, type ZodRawShape } from "zod";
import { zfd } from "zod-form-data";
import type { ZodOpenApiPathsObject } from "zod-openapi";
import { extendZodWithOpenApi } from "zod-openapi";
import { errorResponse, formBody, jsonBody, successResponse } from "../util.ts";
import * as common from "./common.ts";
import { billing_account_id, created_at, datetime, deleted_at, int, updated_at, user_id, uuid } from "./common.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

//#region IP Address

const IpAddressFields = {
    id: int.describe("IP address ID"),
    address: z.string().ip(),
    user_id,
    // TODO: Backend has a bug that erases this field when updating
    billing_account_id: billing_account_id.optional(),
    type: z.enum(["public", "private"]).openapi({ ref: "IpAddressType" }),
    enabled: z.boolean(),
    created_at,
    updated_at: created_at.optional(),
    uuid,
    is_deleted: z.boolean(),
    is_ipv6: z.boolean(),
    name: z.string().optional(),
    assigned_to: z
        .string()
        .optional()
        .describe("ID of the resource this IP is assigned to (usually UUID or integer-as-string)"),
    assigned_to_resource_type: z
        .enum(["virtual_machine", "service", "load_balancer"])
        .optional()
        .openapi({ ref: "IpAddressResourceType" }),
    assigned_to_private_ip: z.string().ip().optional(),
    unassigned_at: datetime.optional(),
} as const satisfies ZodRawShape;

namespace form {
    // TODO: not actually optional, see bug above
    export const billing_account_id = zfd.numeric(common.billing_account_id).optional();
}

export const IpAddress = z.object(IpAddressFields).openapi({ ref: "IpAddress" });

export const IpAddressCreateBody = z
    .object({
        billing_account_id: form.billing_account_id,
        name: IpAddressFields.name,
    })
    .openapi({ ref: "IpAddressCreateBody" });

export const IpAddressModifyBody = z
    .object({
        billing_account_id: form.billing_account_id,
        name: IpAddressFields.name,
    })
    .openapi({ ref: "IpAddressModifyBody" });

export const IpAddressAssignBody = z
    .object({
        private_ip: z.string().ip(),
    })
    .openapi({ ref: "IpAddressAssignBody" });

//#endregion

//#region VPC Network

const ipv6_subnet = z.string().regex(/^[0-9a-fA-F:]+\/\d+$/);

const VpcFields = {
    id: int.optional(),
    name: z.string(),
    user_id: user_id.optional(),
    billing_account_id: billing_account_id.optional(),
    created_at,
    updated_at: created_at.optional(),
    uuid,
    type: z.enum(["public", "private"]).openapi({ ref: "VpcType" }),
    vm_uuids: z.array(uuid),
    resources_count: int,
    subnet_ipv6: z.union([z.literal(""), ipv6_subnet]),
    vlan_id: int.optional(),
    is_default: z.boolean(),
    subnet: z.string(),
} as const satisfies ZodRawShape;

export const Vpc = z
    .object({
        ...VpcFields,
        name: VpcFields.name.nullable(),
    })
    .openapi({ ref: "Vpc" });

export const VpcCreateBody = z
    .object({
        name: VpcFields.name,
    })
    .openapi({ ref: "VpcCreateBody" });

export const VpcUpdateBody = z
    .object({
        name: VpcFields.name,
    })
    .openapi({ ref: "VpcUpdateBody" });

//#endregion

//#region Load Balancer
const LbSessionPersistence = z.enum(["SOURCE_IP"]).openapi({ ref: "LbSessionPersistence" });

export const LbForwardingRule = z
    .object({
        protocol: z.enum(["TCP"]).openapi({ ref: "LbProtocol" }),
        uuid,
        created_at,
        source_port: int,
        target_port: int,
        settings: z.object({
            connection_limit: int,
            session_persistence: LbSessionPersistence,
        }),
    })
    .openapi({ ref: "LbForwardingRule" });

const LbTargetType = z.enum(["vm"]).openapi({ ref: "LbTargetType" });

export const LbTarget = z
    .object({
        created_at,
        target_uuid: uuid,
        target_type: LbTargetType,
        target_ip_address: z.string().ip().optional(),
    })
    .openapi({ ref: "LbTarget" });

const LoadBalancerFields = {
    uuid,
    display_name: z.string(),
    user_id,
    billing_account_id,
    created_at,
    updated_at: updated_at.optional(),
    deleted_at: deleted_at.nullable(),
    is_deleted: z.boolean(),
    private_address: z.string().ip(),
    network_uuid: uuid,
    forwarding_rules: z.array(LbForwardingRule),
    targets: z.array(LbTarget),
} as const satisfies ZodRawShape;

export const LoadBalancer = z.object(LoadBalancerFields).openapi({ ref: "LoadBalancer" });

export const LbCreateForwardingRule = z
    .object({
        source_port: int,
        target_port: int,
    })
    .openapi({ ref: "LbCreateForwardingRule" });

export const LbCreateTarget = z
    .object({
        target_type: LbTargetType,
        target_uuid: uuid,
    })
    .openapi({ ref: "LbCreateTarget" });

export const LoadBalancerCreateBody = z
    .object({
        display_name: LoadBalancerFields.display_name,
        billing_account_id: LoadBalancerFields.billing_account_id,
        network_uuid: LoadBalancerFields.network_uuid,
        rules: z.array(LbCreateForwardingRule),
        targets: z.array(LbCreateTarget),
        reserve_public_ip: z.boolean(),
    })
    .openapi({ ref: "LoadBalancerCreateBody" });

export const LoadBalancerRenameBody = z
    .object({
        display_name: LoadBalancerFields.display_name,
    })
    .openapi({ ref: "LoadBalancerRenameBody" });

const set_id_param = z
    .string()
    .describe("Billing account ID to set")
    .openapi({ param: { name: "set_id", in: "query" } });

const address_param = z.string().openapi({ param: { name: "address", in: "path", ref: "address" } });

//#endregion

export const VpcListResponse = z.array(Vpc);
export const LoadBalancerListResponse = z.array(LoadBalancer);
export const IpListResponse = z.array(IpAddress);

export const networkPaths: ZodOpenApiPathsObject = {
    "/{location}/network/ip_addresses": {
        get: {
            tags: ["ip_address"],
            summary: "List IP addresses",
            parameters: [params.location],
            responses: {
                ...successResponse(IpListResponse),
                ...errorResponse(`Failed to list IP addresses from ${params.location}`),
            },
        },
        post: {
            summary: "Create IP address",
            tags: ["ip_address"],
            requestBody: jsonBody(IpAddressCreateBody),
            parameters: [params.location],
            responses: {
                ...successResponse(IpAddress, "Success: Created IP address"),
                ...errorResponse("Failed to create IP address"),
            },
        },
    },
    "/{location}/network/ip_addresses/{address}": {
        patch: {
            summary: "Update IP address",
            tags: ["ip_address"],
            requestBody: jsonBody(IpAddressModifyBody),
            parameters: [params.location, address_param],
            responses: {
                ...successResponse(IpAddress, "Success: IP address updated"),
                ...errorResponse("Failed to update IP address"),
            },
        },
        delete: {
            summary: "Delete IP address",
            tags: ["ip_address"],
            parameters: [params.location, address_param],
            responses: {
                ...successResponse(z.boolean()),
                ...errorResponse("Failed to delete IP address"),
            },
        },
    },
    "/{location}/network/admin/ip_addresses/{address}/unassign": {
        post: {
            summary: "Admin Unassign IP address",
            tags: ["ip_address"],
            parameters: [params.location, address_param],
            responses: {
                ...successResponse(IpAddress, "Success: IP address unassigned"),
                ...errorResponse("Failed to update IP address"),
            },
        },
    },
    "/{location}/network/admin/ip_addresses/{address}": {
        delete: {
            summary: "Admin Delete IP address",
            tags: ["ip_address"],
            parameters: [params.location, address_param],
            responses: {
                ...successResponse(z.boolean()),
                ...errorResponse("Failed to delete IP address"),
            },
        },
    },
    "/{location}/network/ip_addresses/{address}/assign": {
        post: {
            summary: "Assign IP address to resource",
            tags: ["ip_address"],
            requestBody: formBody(IpAddressAssignBody),
            parameters: [params.location, address_param],
            responses: {
                ...successResponse(IpAddress, "Success: IP address assigned"),
                ...errorResponse("Failed to assign IP address"),
            },
        },
    },
    "/{location}/network/ip_addresses/{address}/unassign": {
        post: {
            summary: "Unassign IP address",
            tags: ["ip_address"],
            parameters: [params.location, address_param],
            responses: {
                ...successResponse(IpAddress, "Success: IP address unassigned"),
                ...errorResponse("Failed to unassign IP address"),
            },
        },
    },
    "/{location}/network/networks": {
        get: {
            tags: ["vpc"],
            summary: "List VPC networks with resources",
            parameters: [params.location],
            responses: {
                ...successResponse(VpcListResponse),
                ...errorResponse(`Failed to list VPCs from ${params.location}`),
            },
        },
    },
    "/{location}/network/network": {
        post: {
            summary: "Create new VPC network",
            tags: ["vpc"],
            requestBody: formBody(VpcCreateBody),
            parameters: [params.location],
            responses: {
                ...successResponse(Vpc, "Success: Created VPC network"),
                ...errorResponse("Failed to create VPC network"),
            },
        },
    },
    "/{location}/network/network/{uuid}": {
        delete: {
            summary: "Delete VPC network",
            tags: ["vpc"],
            parameters: [params.location, params.uuid],
            responses: {
                ...successResponse(z.unknown(), "Success: VPC network deleted"),
                ...errorResponse("Failed to delete VPC network"),
            },
        },
        patch: {
            summary: "Update VPC network",
            tags: ["vpc"],
            requestBody: jsonBody(VpcUpdateBody),
            parameters: [params.location, params.uuid],
            responses: {
                ...successResponse(z.unknown(), "Success: VPC network updated"),
                ...errorResponse("Failed to update VPC network"),
            },
        },
    },
    "/{location}/network/network/{uuid}/default": {
        put: {
            summary: "Set VPC network as default",
            tags: ["vpc"],
            parameters: [params.location, params.uuid],
            responses: {
                ...successResponse(Vpc, "Success: VPC network set as default"),
                ...errorResponse("Failed to set VPC network as default"),
            },
        },
    },
    "/{location}/network/load_balancers": {
        get: {
            tags: ["load_balancer"],
            summary: "List load balancers",
            parameters: [params.location],
            responses: {
                ...successResponse(LoadBalancerListResponse),
                ...errorResponse(`Failed to list load balancers from ${params.location}`),
            },
        },
        post: {
            summary: "Create load balancer",
            tags: ["load_balancer"],
            requestBody: jsonBody(LoadBalancerCreateBody),
            parameters: [params.location],
            responses: {
                ...successResponse(LoadBalancer, "Success: Created load balancer"),
                ...errorResponse("Failed to create load balancer"),
            },
        },
    },
    "/{location}/network/load_balancers/{uuid}": {
        delete: {
            summary: "Delete load balancer",
            tags: ["load_balancer"],
            parameters: [params.location, params.uuid],
            responses: {
                ...successResponse(z.unknown(), "Success: Load balancer deleted"),
                ...errorResponse("Failed to delete load balancer"),
            },
        },
        patch: {
            summary: "Rename load balancer",
            tags: ["load_balancer"],
            requestBody: jsonBody(LoadBalancerRenameBody),
            parameters: [params.location, params.uuid],
            responses: {
                ...successResponse(LoadBalancer, "Success: Load balancer renamed"),
                ...errorResponse("Failed to update load balancer"),
            },
        },
    },
    "/{location}/network/load_balancers/{uuid}/billing_account": {
        put: {
            summary: "Change billing account of load balancer",
            tags: ["load_balancer"],
            parameters: [params.location, params.uuid, set_id_param],
            responses: {
                ...successResponse(LoadBalancer, "Success: Billing account changed"),
                ...errorResponse("Failed to change billing account"),
            },
        },
    },

    "/{location}/network/load_balancers/{uuid}/targets": {
        post: {
            summary: "Add target to load balancer",
            tags: ["load_balancer"],
            requestBody: jsonBody(LbCreateTarget),
            parameters: [params.location, params.uuid],
            responses: {
                ...successResponse(LbTarget, "Success: Target added to load balancer"),
                ...errorResponse("Failed to add target to load balancer"),
            },
        },
    },
    "/{location}/network/load_balancers/{uuid}/targets/{target_uuid}": {
        delete: {
            summary: "Unlink target from load balancer",
            tags: ["load_balancer"],
            parameters: [params.location, params.uuid, params.target_uuid],
            responses: {
                ...successResponse(z.unknown(), "Success: Target removed from load balancer"),
                ...errorResponse("Failed to remove target from load balancer"),
            },
        },
    },
    "/{location}/network/load_balancers/{uuid}/forwarding_rules": {
        post: {
            summary: "Add forwarding rule to load balancer",
            tags: ["load_balancer"],
            requestBody: jsonBody(LbCreateForwardingRule),
            parameters: [params.location, params.uuid],
            responses: {
                ...successResponse(LbForwardingRule, "Success: Forwarding rule added to load balancer"),
                ...errorResponse("Failed to add forwarding rule to load balancer"),
            },
        },
    },
    "/{location}/network/load_balancers/{uuid}/forwarding_rules/{rule_uuid}": {
        delete: {
            summary: "Remove forwarding rule from load balancer",
            tags: ["load_balancer"],
            parameters: [params.location, params.uuid, params.rule_uuid],
            responses: {
                ...successResponse(z.unknown(), "Success: Forwarding rule removed from load balancer"),
                ...errorResponse("Failed to remove forwarding rule from load balancer"),
            },
        },
    },
};
