import D from "./Dashboard.module.css";

import { sortBy } from "remeda";
import { cn } from "../../utils/classNames.ts";
import type { AdminHostWithType } from "../hosts/hostQuery.ts";
import { Bar } from "./Bar.tsx";

function ipToSortKey(ip: string): string {
    return ip
        .split(".")
        .map((num) => `000${num}`.slice(-3))
        .join("");
}

export function BarChart({ items }: { items: AdminHostWithType[] }) {
    const isShort = items.length > 10;
    const chartItems = sortBy(items, (item) => ipToSortKey(item.ip)).map((item) => (
        <Bar isShort={isShort} item={item} key={item.uuid} />
    ));

    return <div className={cn(D.BarChart, isShort ? "gap-1" : "gap-2")}>{chartItems}</div>;
}
