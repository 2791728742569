import { Fragment } from "react";
import { groupBy } from "remeda";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { WCheckbox } from "../../components/forms/WCheckbox.tsx";
import { WFakeRadio } from "../../components/forms/WFakeRadio.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WTableBody } from "../../components/table/WTable.tsx";
import { InfoTooltip } from "../../components/WTooltip.tsx";
import { todoAction } from "../../dev/todoSubmit.ts";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminLinkButton } from "../AdminLinkButton.tsx";
import { Uuid } from "../AdminTable.tsx";
import { AdminTitle } from "../AdminTitle.tsx";
import { adminAllStoragePoolsQueryAtom } from "../buckets/storagePoolsQuery.ts";
import { LocationItem } from "../table_fields/LocationItem.tsx";
import { WAdminTable } from "../WAdminTable.tsx";
import { AddHostPoolButton, EditHostPoolButton } from "./AddHostPoolModal.tsx";
import { adminAllHostPoolsQueryAtom, type AdminHostPoolWithType, hostPoolDeleteMutation } from "./hostPoolsQuery.ts";
import { ToggleDefaultDesignatedModal } from "./ToggleDefaultDesignatedModal.tsx";
import { ToggleHvAutoAssignedModal } from "./ToggleHvAutoAssignedModal.tsx";
import { ToggleVisibleModal } from "./ToggleVisibleModal.tsx";

export function HostPoolsView() {
    const pools = useSuspenseQueryAtom(adminAllHostPoolsQueryAtom);
    const storagePools = useSuspenseQueryAtom(adminAllStoragePoolsQueryAtom);
    const deleteMutation = useStandardMutation(hostPoolDeleteMutation);

    const groupedItems = groupBy([...pools.values()], (l) => l.location);

    async function onDelete(item: AdminHostPoolWithType) {
        await deleteMutation.mutateAsync({ uuid: item.uuid, location: item.location });
    }

    return (
        <>
            <AdminTitle title="Hypervisor Pools">
                <AddHostPoolButton button={<WModalButton label="New" color="primary" size="bar" variant="basic" />} />
            </AdminTitle>

            <WAdminTable>
                <thead>
                    <tr>
                        <th>UI pos</th>
                        <th>Name</th>
                        <th>UUID</th>
                        <th>Description</th>
                        <th>CPU model</th>
                        <th className="text-right">CPU coef.</th>
                        <th className="text-right">Mem coef.</th>
                        <th>
                            <div className="flex justify-end">
                                Default pool for VMs
                                <InfoTooltip
                                    className="ml-1"
                                    text="Default designated pool for new virtual machines."
                                />
                            </div>
                        </th>
                        <th>
                            <div className="flex justify-end">
                                Pool is visible
                                <InfoTooltip className="ml-1" text="Pool visibility for end-users." />
                            </div>
                        </th>
                        <th>Hosts</th>
                        <th>
                            <div className="flex justify-end">
                                Default pool for hosts
                                <InfoTooltip
                                    className="ml-1"
                                    text="New hosts added to the cluster will be assigned to the default pool."
                                />
                            </div>
                        </th>
                        <th>Storage Pool</th>
                        <th>Storage Pool UUID</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {Object.keys(groupedItems).map((key) => {
                        return (
                            <Fragment key={key}>
                                <tr>
                                    <td colSpan={99} className="text-center bg-grey-1">
                                        <LocationItem value={key} />
                                    </td>
                                </tr>
                                {groupedItems[key].map((item) => {
                                    const {
                                        ui_position,
                                        name,
                                        uuid,
                                        description,
                                        cpu_model,
                                        cpu_allocate_coefficient,
                                        mem_allocate_coefficient,
                                        is_default_designated,
                                        is_visible,
                                        hosts_assigned_count,
                                        is_hv_auto_assigned,
                                        storage_pool_uuid,
                                    } = item;

                                    const storagePool = storagePools.get(storage_pool_uuid);

                                    return (
                                        <tr key={uuid}>
                                            <td>{ui_position}</td>
                                            <td>{name}</td>
                                            <td>
                                                <ClipBoardTooltip isHtml hideIcon>
                                                    <Uuid value={uuid} />
                                                </ClipBoardTooltip>
                                            </td>
                                            <td>{description}</td>
                                            <td>{cpu_model}</td>
                                            <td className="text-right">{cpu_allocate_coefficient}</td>
                                            <td className="text-right">{mem_allocate_coefficient}</td>
                                            <td>
                                                <ToggleDefaultDesignatedModal
                                                    button={
                                                        <WFakeRadio
                                                            isSelected={is_default_designated}
                                                            isDisabled={is_default_designated}
                                                            onClick={undefined}
                                                        />
                                                    }
                                                    item={item}
                                                />
                                            </td>
                                            <td>
                                                <ToggleVisibleModal
                                                    button={<WCheckbox isSelected={is_visible} />}
                                                    item={item}
                                                />
                                            </td>
                                            <td>
                                                <AdminLinkButton
                                                    isMissing={!hosts_assigned_count}
                                                    action={todoAction}
                                                    label={<span>{hosts_assigned_count} host(s)</span>}
                                                />
                                            </td>
                                            <td>
                                                <ToggleHvAutoAssignedModal
                                                    button={
                                                        <WFakeRadio
                                                            isSelected={is_hv_auto_assigned}
                                                            isDisabled={is_hv_auto_assigned}
                                                            onClick={undefined}
                                                        />
                                                    }
                                                    item={item}
                                                />
                                            </td>
                                            <td>{storagePool?.display_name}</td>
                                            <td>
                                                <ClipBoardTooltip isHtml hideIcon>
                                                    <Uuid value={storage_pool_uuid} />
                                                </ClipBoardTooltip>
                                            </td>
                                            <td className="text-right">
                                                <div className="flex gap-0.5em justify-end">
                                                    <EditHostPoolButton
                                                        item={item}
                                                        button={
                                                            <WModalButton label="Edit" inTable icon="jp-icon-edit" />
                                                        }
                                                    />

                                                    <DeleteModal
                                                        isDisabled={
                                                            item.is_hv_auto_assigned || !!item.hosts_assigned_count
                                                        }
                                                        title="Delete Host Pool"
                                                        inTable
                                                        modalAction={async () => await onDelete(item)}
                                                    >
                                                        Deleting a host pool "<b>{name}</b> will permanently remove it.
                                                    </DeleteModal>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </Fragment>
                        );
                    })}
                </WTableBody>
            </WAdminTable>

            <div className="p-2">
                <AddHostPoolButton
                    button={
                        <WModalButton
                            className="w-full"
                            label="Add Hypervisor Pool"
                            color="primary"
                            size="lg"
                            variant="dashed"
                            icon="jp-icon-add"
                        />
                    }
                />
            </div>
        </>
    );
}
