import type { VmRescueStartBody } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { type ReactNode, useState } from "react";
import { WCheckbox } from "../../components/forms/WCheckbox.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AInput } from "../form/Fields.tsx";
import { rescueModeMutation } from "./vmQuery.ts";
import type { GQVmItem } from "./VmsTable.tsx";

interface RescueModeVmBlockModalProps {
    item: GQVmItem;
}

export function RescueModeVmBlockModalContent({ item }: RescueModeVmBlockModalProps) {
    const startInRescueMutation = useStandardMutation(rescueModeMutation);
    const [isCustom, setIsCustom] = useState<boolean>(false);
    const [customName, setCustomName] = useState<string>("");

    const { uuid, name, _location } = item;

    async function onSubmit() {
        const body: VmRescueStartBody = {
            uuid: uuid,
        };
        if (customName) body.rescue_image_name = customName;
        await startInRescueMutation.mutateAsync({ body: body, location: notNull(_location) });
        // TODO: start VM console
    }

    function toggleCustom(value: boolean) {
        setIsCustom(value);
    }

    return (
        <WModalContent
            title="Start Resource in Rescue Mode"
            label="Start"
            autoFocus
            modalAction={async () => await onSubmit()}
        >
            <p className="pb-3">
                When VM is booted in rescue mode, a live image of SystemRescue toolkit will be attached and used as a
                boot medium. You can then use the Virtual Console to access your machine. The rescue mode is enabled
                only for the current launch. Next time this VM is started it will follow a normal routine.
            </p>

            <WCheckbox isSelected={isCustom} onChange={toggleCustom}>
                Use custom ISO image
            </WCheckbox>

            {isCustom && (
                <WTextField block label="ISO image name" onChange={setCustomName}>
                    <AInput value={customName} />
                </WTextField>
            )}
        </WModalContent>
    );
}

interface RescueModeVmBlockProps extends RescueModeVmBlockModalProps {
    button: ReactNode;
}

export function RescueModeVmBlock(props: RescueModeVmBlockProps) {
    return (
        <WModal button={props.button}>
            <RescueModeVmBlockModalContent {...props} />
        </WModal>
    );
}
