import { notNull } from "@warrenio/utils/notNull";
import type { ReactNode } from "react";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { startVmMutation } from "./vmQuery.ts";
import type { GQVmItem } from "./VmsTable.tsx";

interface StartVmBlockModalProps {
    item: GQVmItem;
}

export function StartVmBlockModalContent({ item }: StartVmBlockModalProps) {
    const startMutation = useStandardMutation(startVmMutation);

    const { uuid, name, _location } = item;

    async function onSubmit() {
        await startMutation.mutateAsync({ uuid: uuid, location: notNull(_location) });
    }

    return (
        <WModalContent title="Start Virtual Machine" label="Start" autoFocus modalAction={async () => await onSubmit()}>
            <p className="pb-3">
                This will start the resource "<b>{name}</b>".
            </p>
        </WModalContent>
    );
}

interface StartVmBlockProps extends StartVmBlockModalProps {
    button: ReactNode;
}

export function StartVmBlock(props: StartVmBlockProps) {
    return (
        <WModal button={props.button}>
            <StartVmBlockModalContent {...props} />
        </WModal>
    );
}
