import { atom } from "jotai/vanilla";
import { lazyWithPreload } from "react-lazy-with-preload";
import { delayed } from "../../../utils/promise/delayed.ts";
import { anyResourceEnabledAtomFactory } from "../../api/resourceTypes.tsx";
import { setSectionsAtom, type Section } from "./Sidebar.store";

const sections: Section[] = [
    {
        id: "dashboard",
        to: "/",
        // NB: No `routePrefix`
        label: "Dashboard",
        icon: "jp-view-apps-icon",
    },
    {
        id: "compute",
        component: lazyWithPreload(() => delayed(import("../../compute/VmList"))),
        routePrefix: "/compute",
        label: "Compute",
        icon: "jp-compute-cloud-icon",
        enabledAtom: anyResourceEnabledAtomFactory(["virtual_machine"]),
    },
    {
        id: "metal",
        component: lazyWithPreload(() => delayed(import("../../metal/MetalList"))),
        routePrefix: "/metal",
        label: "Bare Metal",
        icon: "jp-host-icon",
        enabledAtom: anyResourceEnabledAtomFactory(["bare_metal"]),
    },
    {
        id: "storage",
        component: lazyWithPreload(() => delayed(import("../../storage/StorageList"))),
        routePrefix: "/storage",
        label: "Storage",
        icon: "jp-stack-icon",
        enabledAtom: anyResourceEnabledAtomFactory(["bucket"]),
    },
    {
        id: "network",
        component: lazyWithPreload(() => delayed(import("../../network/NetworkList"))),
        routePrefix: "/network",
        label: "Network",
        icon: "jp-network-icon",
        enabledAtom: anyResourceEnabledAtomFactory(["vpc", "ip_address", "load_balancer"]),
    },
    {
        id: "services",
        component: lazyWithPreload(() => delayed(import("../../services/ServicesList"))),
        routePrefix: "/services",
        label: "Services",
        icon: "jp-services-icon",
        enabledAtom: anyResourceEnabledAtomFactory(["managed_service"]),
    },
    {
        id: "access",
        component: lazyWithPreload(() => delayed(import("../../access/AccessList"))),
        routePrefix: "/access",
        label: "Access",
        icon: "jp-access-icon",
        enabledAtom: anyResourceEnabledAtomFactory(["access_delegation", "api_token"]),
    },
    {
        id: "billing",
        component: lazyWithPreload(() => delayed(import("../../billing/BillingList"))),
        // No `routePrefix` to prevent billing from opening when creating the first billing account
        // routePrefix: "/billing",
        label: "Billing",
        icon: "jp-card-icon",
        enabledAtom: anyResourceEnabledAtomFactory(["billing_account"]),
    },
];

const sectionsAtom_internal = atom((get) => {
    // NB: the actual sections are constants since Jotai works best when references are stable (otherwise unnecessary re-renders occur)
    return sections.filter(({ enabledAtom }) => enabledAtom == null || get(enabledAtom));
});

export function initSections() {
    setSectionsAtom(sectionsAtom_internal);
}
