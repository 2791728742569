import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { pick } from "remeda";
import { BillingAccountById } from "../../accounts/BillingAccountBlock.tsx";
import { field, type FieldsOf } from "../../FieldConfig.tsx";
import { whenNotNull } from "../../table_fields/whenNotNull.tsx";
import type { AdminMachineWithOs, ItemOsInfo } from "./machinesWithOs.ts";
import { OsViewer } from "./OsViewer.tsx";
import { StatusEditButton } from "./StatusEditButton.tsx";

export const machineItemFields = {
    uuid: field("UUID", (e) => e.uuid, { copyable: true, inTable: false }),

    label: field("Label", (e) => e.label),
    status: field("Status", (e) => e.status, { render: StatusEditButton }),
    spec_title: field("Spec", (e) => e.spec.title),
    os: field("OS", (e): ItemOsInfo => pick(e, ["os_type", "os"]), { render: OsViewer }),
    admin_notes: field("Notes", (e) => e.admin_notes, { editTitle: "Internal Notes" }),
    ip_public_v4: field("IPv4", (e) => e.ip_public_v4, { inTable: false }),
    ip_public_v6: field("IPv6", (e) => e.ip_public_v6, { inTable: false }),

    ssh_credentials: field("SSH creds", (e) => e.ssh_credentials, { inTable: false }),
    billing_account_id: field("Billing Account", (e) => e.billing_account_id, {
        render: whenNotNull(BillingAccountById),
    }),
    lease_name: field("Lease Name", (e) => e.lease?.display_name),

    created_at: field("Created At", (e) => apiDatetimeToDate(e.created_at)),
} satisfies FieldsOf<AdminMachineWithOs>;
