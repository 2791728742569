import { billingAdminPaths } from "./spec/billing.admin.ts";
import { campaignsPaths } from "./spec/campaigns.admin.ts";
import { configAdminPaths } from "./spec/config.admin.ts";
import { hostPaths } from "./spec/hosts.admin.ts";
import { locationAdminPaths } from "./spec/locations.admin.ts";
import { metalAdminPaths } from "./spec/metal.admin.ts";
import { networkAdminPaths } from "./spec/network.admin.ts";
import { hostPoolAdminPaths } from "./spec/pools.admin.ts";
import { pricingAdminPaths } from "./spec/pricing.admin.ts";
import { referralPaths } from "./spec/referral.admin.ts";
import { serviceAdminPaths } from "./spec/services.admin.ts";
import { storageAdminPaths } from "./spec/storage.admin.ts";
import { trackingPaths } from "./spec/tracking.admin.ts";
import { userAdminPaths } from "./spec/user.admin.ts";
import { vmAdminPaths } from "./spec/vm.admin.ts";

export const adminPaths = {
    ...referralPaths,
    ...trackingPaths,
    ...campaignsPaths,
    ...hostPaths,
    ...metalAdminPaths,
    ...billingAdminPaths,
    ...locationAdminPaths,
    ...hostPoolAdminPaths,
    ...vmAdminPaths,
    ...serviceAdminPaths,
    ...storageAdminPaths,
    ...pricingAdminPaths,
    ...networkAdminPaths,
    ...userAdminPaths,
    ...configAdminPaths,
};
