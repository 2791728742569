import type {
    AccessDelegation,
    AccessDelegationImpersonation,
    ApiToken,
    AutomatedTopUpEnableDisableResponse,
    BillingAccount,
    BillingAccountCard,
    BillingAccountCardData,
    CampaignActivateResponse,
    CardVerifyResponse,
    FinalAmountResponse,
    IpAddress,
    IpAddressResourceType,
    LbForwardingRule,
    LbTarget,
    LinkParams,
    LinkSendDataResponse,
    LoadBalancer,
    ManagedService,
    ManagedServicesVmBody,
    ModifyLowBalanceNoticeSettingsResponse,
    OmiseCreditBuyResponse,
    PayInvoiceResponse,
    PersistentDataResponse,
    RestrictionLevelClearTopupThreshold,
    StorageKey,
    UserMfaResponse,
    VirtualMachine,
    VirtualMachineStorage,
    VirtualMachineStorageReplica,
    Vpc,
} from "@warrenio/api-spec/spec.oats.gen";
import * as accessSpec from "@warrenio/api-spec/spec/access";
import * as billingSpec from "@warrenio/api-spec/spec/billing";
import { InvoiceStatusEnum } from "@warrenio/api-spec/spec/billingEnums";
import * as networkSpec from "@warrenio/api-spec/spec/network";
import * as serviceSpec from "@warrenio/api-spec/spec/services";
import * as storageSpec from "@warrenio/api-spec/spec/storage";
import * as vmSpec from "@warrenio/api-spec/spec/vm";
import { findById } from "@warrenio/utils/collections/findById";
import { mustGet } from "@warrenio/utils/collections/mustGet";
import { notNull } from "@warrenio/utils/notNull";
import sleep from "@warrenio/utils/promise/sleep";
import { unreachableSwitchCase } from "@warrenio/utils/unreachable";
import { HttpResponse, type HttpHandler } from "msw";
import { last } from "remeda";
import invariant from "tiny-invariant";
import z from "zod";
import { zfd } from "zod-form-data";
import { parseOmiseMockToken } from "../modules/billing/omise/mockOmise.ts";
import type { OmiseChargeData } from "../modules/billing/omise/parseOmisePersistentData.ts";
import { isDbService } from "../modules/services/utils.ts";
import { exampleVisaId, makeAdditionalCardData } from "./data/billingAccountCardsExample.ts";
import { createFieldValues } from "./data/billingAccountExample.ts";
import { defaultBillingAccountId } from "./data/billingAccountIds.ts";
import { dbServiceDefaultProperties } from "./data/servicesExample.ts";
import { getMockDb, markDirty } from "./db.ts";
import type { LocationSpecificData } from "./defaultDb.ts";
import { metalHandlers } from "./metalHandlers.ts";
import { getMockTimeEpoch, getMockTimeTz } from "./mockTime.ts";
import type { StandardRequest } from "./msw/StandardRequest.ts";
import { apiPrefix, http } from "./msw/handlerMiddleware.ts";
import { mustFindBy } from "./mustFindBy.ts";
import {
    randomAlphaNumeric,
    randomBigInt,
    randomLowerAlpha,
    randomMac,
    randomPrivateIpv4,
    randomSubnetIpv4,
    randomSubnetIpv6,
    randomUpperAlphaNumeric,
    randomUuid,
} from "./randomTypes.ts";
import {
    generateId,
    getLocationDb,
    getParam,
    getQueryParams,
    getUserEmail,
    getUserId,
    getUuidParam,
    parseForm,
    parseJson,
    throwStandardErrorResponse,
    validateSpec,
} from "./requestUtils.ts";

/** Used in mock service worker registration to filter requests */
export { apiPrefix };

/* eslint-disable @typescript-eslint/require-await */

async function getChangeVmRunningStatusHandler(db: LocationSpecificData, uuid: string, status: "stopped" | "running") {
    const vm = mustFindBy(db.vms, (v) => v.uuid, uuid, "VM");
    vm.status = status;
    markDirty();

    return HttpResponse.json(vm);
}

export const handlers: HttpHandler[] = [
    //#region Compute
    http.get("/{location}/user-resource/vm/list", async (r) => {
        return HttpResponse.json(getLocationDb(r).vms);
    }),

    // NB: Need to use `Promise<Response>` since TypeScript can not infer a `StrictResponse` type
    //     if there are multiple different return statements.
    http.post("/{location}/user-resource/vm", async (r) => {
        const {
            billing_account_id = defaultBillingAccountId,
            // TODO: actual pool selection logic
            designated_pool_uuid = randomUuid(),
            os_name,
            os_version,
            description,
            name,
            username,
            ram,
            disks,
            vcpu,
            ...body
        } = await parseForm(r, vmSpec.VmCreateBody);

        const designated_pool_name = "Some pool";

        const created_at = getMockTimeTz();

        const user_id = getUserId(r);

        const vmStorage: VirtualMachineStorage = {
            created_at,
            name: "vda",
            pool: "n/a",
            primary: true,
            replica: [],
            shared: false,
            size: disks ?? 0,
            type: "n/a",
            user_id,
            uuid: randomUuid(),
        };

        const vm: VirtualMachine = {
            designated_pool_uuid,
            backup: false,

            current_pool_name: designated_pool_name,
            current_pool_uuid: designated_pool_uuid,
            designated_pool_name,

            hostname: randomAlphaNumeric(),
            mac: randomMac(),
            private_ipv4: randomPrivateIpv4(),
            storage: [vmStorage],
            user_id,

            os_name,
            os_version,
            description,
            name,
            username,
            vcpu,

            created_at,
            updated_at: created_at,
            memory: ram,
            uuid: randomUuid(),
            status: "creating",
            billing_account: billing_account_id,
        };
        console.debug("Creating mock VM: %o", { body, vm });

        validateSpec(vmSpec.VirtualMachine, vm);

        getLocationDb(r).vms.push(vm);
        markDirty();

        // Pretend to take some time to create the VM
        await sleep(3000);

        vm.status = "running";
        markDirty();

        return HttpResponse.json(vm);
    }),

    http.patch("/{location}/user-resource/vm", async (r) => {
        const db = getLocationDb(r);
        const { uuid, name, ram, vcpu } = await parseJson(r, vmSpec.VmModifyBody);

        const vm = mustFindBy(db.vms, (v) => v.uuid, uuid, "VM");

        if (name !== undefined) vm.name = name;
        if (ram !== undefined) vm.memory = ram;
        if (vcpu !== undefined) vm.vcpu = vcpu;

        markDirty();

        return HttpResponse.json(vm);
    }),

    // change vm user password
    http.patch("/{location}/user-resource/vm/user", async (_r) => {
        // nothing needs to be done here as password is never exposed to front
        return HttpResponse.json({ success: true });
    }),

    http.post("/{location}/user-resource/vm/rescue_start", async (r) => {
        const db = getLocationDb(r);
        const { uuid } = await parseJson(r, vmSpec.VmStartInRescueBody);

        const vm = mustFindBy(db.vms, (v) => v.uuid, uuid, "VM");
        vm.status = "running";

        markDirty();
        return HttpResponse.json(vm);
    }),

    http.post("/{location}/user-resource/vm/reinstall", async (r) => {
        const db = getLocationDb(r);
        const { uuid, os_name, os_version } = await parseJson(r, vmSpec.VmReinstallBody);

        const vm = mustFindBy(db.vms, (v) => v.uuid, uuid, "VM");
        vm.os_name = os_name;
        vm.os_version = os_version;

        markDirty();
        return HttpResponse.json(vm);
    }),

    //#region VM disk actions

    // Add disk to VM
    http.post("/{location}/user-resource/vm/storage", async (r) => {
        const db = getLocationDb(r);
        const { uuid, size_gb } = await parseJson(r, vmSpec.VmAddDiskBody);
        const vm = db.vms.find((vm) => vm.uuid === uuid);

        if (!vm) {
            throwStandardErrorResponse("VM not found");
        }
        //take last disk and increment it
        const lastDisk = last(vm.storage);
        const newName = lastDisk ? `vd${String.fromCharCode(lastDisk.name.charCodeAt(2) + 1)}` : "vda";

        const newDisk: VirtualMachineStorage = {
            name: newName,
            pool: "n/a",
            shared: false,
            type: "n/a",
            user_id: getUserId(r),
            uuid: randomUuid(),
            size: size_gb,
            created_at: getMockTimeTz(),
            primary: false,
        };
        console.debug("Adding disk to VM: %o", { uuid, newDisk });

        vm.storage.push(newDisk);
        markDirty();

        return HttpResponse.json(newDisk, { status: 201 });
    }),

    // Resize disk
    http.patch("/{location}/user-resource/vm/storage", async (r) => {
        const db = getLocationDb(r);
        const { uuid, disk_uuid, size_gb } = await parseJson(r, vmSpec.VmResizeDiskBody);
        const vm = db.vms.find((vm) => vm.uuid === uuid);

        if (!vm) {
            throwStandardErrorResponse("VM not found");
        }

        const disk = vm.storage.find((disk) => disk.uuid === disk_uuid);
        if (!disk) {
            throwStandardErrorResponse(`Disk with uuid ${disk_uuid} not found`);
        }
        if (disk.size > size_gb) {
            throwStandardErrorResponse("Shrinking disk is not supported");
        }

        disk.size = size_gb;
        markDirty();

        return HttpResponse.json(disk);
    }),

    // Delete disk from VM
    http.delete("/{location}/user-resource/vm/storage", async (r) => {
        const db = getLocationDb(r);
        const { uuid, storage_uuid } = await parseJson(r, vmSpec.VmDeleteDiskBody);
        const vm = db.vms.find((vm) => vm.uuid === uuid);

        if (!vm) {
            throwStandardErrorResponse("VM not found");
        }

        const disk = vm.storage.find((disk) => disk.uuid === storage_uuid);
        if (!disk) {
            throwStandardErrorResponse(`Disk with uuid ${storage_uuid} not found`);
        }

        if (disk.primary) {
            throwStandardErrorResponse("Primary disk can not be deleted");
        }

        vm.storage = vm.storage.filter((disk) => disk.uuid !== storage_uuid);
        markDirty();

        return HttpResponse.json({ success: true });
    }),

    //#endregion VM disk actions

    //#region VM snapshot actions

    // Create snapshot
    http.post("/{location}/user-resource/vm/replica", async (r) => {
        const db = getLocationDb(r);
        const { uuid } = await parseJson(r, vmSpec.VmReplicaCreateBody);
        const vm = db.vms.find((vm) => vm.uuid === uuid);

        if (!vm) {
            throwStandardErrorResponse("VM not found");
        }

        const primaryStorage = vm.storage.find((disk) => disk.primary);
        if (!primaryStorage) {
            throwStandardErrorResponse("Primary disk not found");
        }

        const snapshot: VirtualMachineStorageReplica = {
            created_at: getMockTimeTz(),
            master_uuid: primaryStorage.uuid,
            pool: "",
            size: primaryStorage.size,
            type: "snapshot",
            uuid: randomUuid(),
        };
        console.debug("Creating snapshot for VM: %o", { uuid, snapshot });

        const replicas = primaryStorage.replica;
        if (!replicas) {
            throwStandardErrorResponse("Replicas not found");
        }
        replicas.push(snapshot);
        markDirty();

        return HttpResponse.json(primaryStorage, { status: 201 });
    }),

    // Delete snapshot
    http.delete("/{location}/user-resource/vm/replica", async (r) => {
        const db = getLocationDb(r);
        const { replica_uuid } = await parseJson(r, vmSpec.VmReplicaDeleteBody);
        //get vm with replica
        const vm = db.vms.find((vm) =>
            vm.storage.some((disk) => disk.replica!.some((replica) => replica.uuid === replica_uuid)),
        );

        if (!vm) {
            throwStandardErrorResponse("VM not found");
        }

        const primaryStorage = vm.storage.find((disk) => disk.primary);
        if (!primaryStorage) {
            throwStandardErrorResponse("Primary disk not found");
        }

        const snapshot = primaryStorage.replica!.find((replica) => replica.uuid === replica_uuid);
        if (!snapshot) {
            throwStandardErrorResponse(`Snapshot with uuid ${replica_uuid} not found`);
        }

        primaryStorage.replica = primaryStorage.replica!.filter((replica) => replica.uuid !== replica_uuid);
        markDirty();

        return HttpResponse.json({ success: true });
    }),

    // Restore snapshot
    http.post("/{location}/user-resource/vm/rebuild", async (r) => {
        const db = getLocationDb(r);
        const { uuid, replica_uuid } = await parseJson(r, vmSpec.VmReplicaRebuildBody);
        const vm = db.vms.find((vm) => vm.uuid === uuid);

        if (!vm) {
            throwStandardErrorResponse("VM not found");
        }

        const primaryStorage = vm.storage.find((disk) => disk.primary);
        if (!primaryStorage) {
            throwStandardErrorResponse("Primary disk not found");
        }

        const snapshot = primaryStorage.replica!.find((replica) => replica.uuid === replica_uuid);
        if (!snapshot) {
            throwStandardErrorResponse(`Snapshot with uuid ${replica_uuid} not found`);
        }

        //restore snapshot, update updated_at
        vm.updated_at = getMockTimeTz();
        markDirty();

        return HttpResponse.json(vm, { status: 201 });
    }),

    //#endregion VM snapshot actions

    //#region VM backup actions

    // enable/disable VM backups
    http.post("/{location}/user-resource/vm/backup", async (r) => {
        const db = getLocationDb(r);
        const { uuid } = await parseJson(r, vmSpec.VmBackupEnableDisableBody);
        const vm = db.vms.find((vm) => vm.uuid === uuid);

        if (!vm) {
            throwStandardErrorResponse("VM not found");
        }

        vm.backup = !vm.backup;
        markDirty();

        return HttpResponse.json(vm);
    }),

    //#endregion VM backup actions

    http.post("/{location}/user-resource/vm/clone", async (r) => {
        const db = getLocationDb(r);
        const { uuid, name } = await parseJson(r, vmSpec.VmCloneBody);

        const created_at = getMockTimeTz();

        const sourceVm = db.vms.find((vm) => vm.uuid === uuid)!;
        const clonedStorage = sourceVm.storage.map((s) => ({
            ...s,
            uuid: randomUuid(),
            created_at,
        }));

        const clonedVmUuid = randomUuid();
        const clonedVm: VirtualMachine = {
            ...sourceVm,
            name,
            uuid: clonedVmUuid,
            created_at,
            updated_at: created_at,
            hostname: randomAlphaNumeric(),
            mac: randomMac(),
            private_ipv4: randomPrivateIpv4(),
            status: "creating",
            storage: clonedStorage,
        };

        getLocationDb(r).vms.push(clonedVm);

        const sourceVmAttachedIp = db.ipAddresses.find(
            (ip) => ip.assigned_to_resource_type === "virtual_machine" && ip.assigned_to === uuid,
        );
        if (sourceVmAttachedIp) {
            // create new IP and attach it to a cloned VM
            const newIp: IpAddress = {
                ...sourceVmAttachedIp,
                uuid: randomUuid(),
                address: randomPrivateIpv4(),
                created_at,
                updated_at: created_at,
                name: sourceVmAttachedIp.name !== "" ? `${sourceVmAttachedIp.name} clone` : "",
                assigned_to: clonedVmUuid,
            };
            db.ipAddresses.push(newIp);
        }

        markDirty();

        // Pretend to take some time to create the VM
        await sleep(3000);
        clonedVm.status = "running";
        markDirty();

        return HttpResponse.json(clonedVm);
    }),

    http.post("/{location}/user-resource/vm/start", async (r) => {
        const db = getLocationDb(r);
        const { uuid } = await parseJson(r, vmSpec.VmDeleteBody);
        return await getChangeVmRunningStatusHandler(db, uuid, "running");
    }),

    http.post("/{location}/user-resource/vm/stop", async (r) => {
        const db = getLocationDb(r);
        const { uuid } = await parseJson(r, vmSpec.VmDeleteBody);
        return await getChangeVmRunningStatusHandler(db, uuid, "stopped");
    }),

    http.delete("/{location}/user-resource/vm", async (r) => {
        const db = getLocationDb(r);
        const { uuid } = await parseJson(r, vmSpec.VmDeleteBody);

        const attachedIp = db.ipAddresses.find(
            (ip) => ip.assigned_to_resource_type === "virtual_machine" && ip.assigned_to === uuid,
        );
        if (attachedIp) {
            unAssignIp(attachedIp);
        }

        const vms = db.vms.filter((vm) => vm.uuid !== uuid);
        db.vms = vms;
        markDirty();

        return HttpResponse.json({ success: true });
    }),

    http.get("/api/parameters/vm", async (_r) => {
        return HttpResponse.json(getMockDb().vmParameters);
    }),
    //#endregion Compute

    //#region Storage
    //#region queries
    http.get("/storage/bucket/list", async (_r) => {
        return HttpResponse.json(getMockDb().storages);
    }),

    http.get("/storage/user/keys", async (_r) => {
        return HttpResponse.json(getMockDb().storageKeys);
    }),

    http.get("/storage/api/s3", async (_r) => {
        return HttpResponse.json(getMockDb().s3Url);
    }),

    http.put("/storage/bucket/cors", async (_r) => {
        return HttpResponse.xml("<mock />");
    }),

    //#endregion queries

    //#region mutations
    http.delete("/storage/user/keys", async (r) => {
        const { access_key } = await parseForm(r, storageSpec.AccessKeyDeleteBody);
        getMockDb().storageKeys = getMockDb().storageKeys.filter((key) => key.accessKey !== access_key);
        markDirty();
        return HttpResponse.json({ success: true });
    }),
    http.post("/storage/user/keys", async (r) => {
        const key: StorageKey = {
            accessKey: randomUpperAlphaNumeric(20),
            secretKey: randomAlphaNumeric(40),
            userId: getUserEmail(r),
        };
        getMockDb().storageKeys.push(key);
        markDirty();
        return HttpResponse.json(key);
    }),
    //#endregion mutations

    //#endregion Storage

    //#region Services
    //#region queries
    http.get("/user-resource/service/packages", async (_r) => {
        return HttpResponse.json(getMockDb().services);
    }),

    http.post("/user-resource/service/package", async (r) => {
        const {
            billing_account_id,
            display_name,
            is_multi_node,
            package_parameters: { location },
            reserve_public_ip,
            service,
            version,
            vm_cpu,
            vm_disk_gb,
            vm_ram,
        } = await parseJson(r, serviceSpec.ManagedServiceCreateBody);

        // PS! handle kubernetes variant also!
        if (isDbService(service)) {
            const created_at = getMockTimeTz();
            const defaultProperties = dbServiceDefaultProperties[service];
            const image = getMockDb().serviceImages.find((i) => i.service_name === service)!;

            const getVmResource = (): ManagedServicesVmBody => ({
                resource_allocation: {
                    memory: vm_ram,
                    vcpu: vm_cpu,
                    status: "running",
                    storage: [
                        {
                            primary: true,
                            size: vm_disk_gb,
                            uuid: randomUuid(),
                            replica: [],
                        },
                    ],
                },
                resource_id: randomUuid(),
                resource_location: location,
                resource_type: "vm",
            });

            const resources: ManagedServicesVmBody[] = [getVmResource()];
            if (is_multi_node) resources.push(getVmResource());

            if (reserve_public_ip) {
                resources.push({
                    resource_allocation: {},
                    resource_id: randomUuid(),
                    resource_location: location,
                    resource_type: "virtual_ip",
                });
            }

            const managedService: ManagedService = {
                uuid: randomUuid(),
                billing_account_id,
                created_at,
                updated_at: created_at,
                display_name,
                is_multi_node,
                is_deleted: false,
                service,
                version,
                user_id: getUserId(r),
                status: "active",
                properties: {
                    ...defaultProperties,
                    service_ip: randomPrivateIpv4(),
                    location,
                },
                prices: image.prices,
                resources: resources,
            };
            console.debug("Creating mock service: %o", managedService);

            validateSpec(serviceSpec.ManagedService, managedService);

            getMockDb().services.push(managedService);
            markDirty();

            // Pretend to take some time to create the VM
            await sleep(4000);

            return HttpResponse.json(managedService);
        } else {
            console.warn("Only Db service mock is implemented right now, service selected: %o", { service });
            throwStandardErrorResponse("Only Db service mock is implemented right now");
        }
    }),

    http.get("/user-resource/service/configurations", async (_r) => {
        return HttpResponse.json(getMockDb().serviceImages);
    }),

    http.get("/user-resource/service/package/{uuid}/whitelist_addresses", async (_r) => {
        return HttpResponse.json(getMockDb().serviceWhitelist);
    }),

    http.get("/user-resource/service/package/{uuid}/secrets", async (_r) => {
        return HttpResponse.json(getMockDb().serviceSecrets);
    }),

    //#endregion queries

    //#endregion Services

    http.get("/config/vm_images", async (_r) => {
        return HttpResponse.json(getMockDb().vmImages);
    }),

    //#region API Tokens
    http.get("/user-resource/token/list", async (_r) => {
        return HttpResponse.json(getMockDb().tokens);
    }),
    http.post("/user-resource/token", async (r) => {
        const {
            billing_account_id = defaultBillingAccountId,
            description,
            restricted = false,
        } = await parseForm(r, accessSpec.ApiTokenCreateBody);

        const apiToken: ApiToken = {
            billing_account_id,
            consumer_id: randomUuid(),
            created_at: getMockTimeTz(),
            description,
            id: generateId(),
            kong_id: randomUuid(),
            restricted,
            token: randomAlphaNumeric(32),
            user_id: getUserId(r),
        };
        console.debug("Creating mock token: %o", apiToken);

        validateSpec(accessSpec.ApiToken, apiToken);

        getMockDb().tokens.push(apiToken);
        markDirty();

        return HttpResponse.json(apiToken);
    }),
    http.patch("/user-resource/token", async (r) => {
        const { token_id, description, billing_account_id } = await parseForm(r, accessSpec.ApiTokenModifyBody);

        const apiToken = findById(getMockDb().tokens, token_id);
        if (description) apiToken.description = description;
        if (billing_account_id) apiToken.billing_account_id = billing_account_id;

        console.debug("Updating mock token: %o", apiToken);

        validateSpec(accessSpec.ApiToken, apiToken);

        markDirty();

        return HttpResponse.json(apiToken);
    }),
    http.delete("/user-resource/token", async (r) => {
        const { token_id } = await parseForm(r, accessSpec.ApiTokenDeleteBody);

        getMockDb().tokens = getMockDb().tokens.filter((t) => t.id !== token_id);
        markDirty();

        return HttpResponse.json({ success: true });
    }),
    //#endregion

    //#region Access Delegations
    http.get("/user-resource/access_group", async (_r) => {
        return HttpResponse.json(getMockDb().delegations);
    }),
    http.post("/user-resource/access_group", async (r) => {
        const { billing_account_id = defaultBillingAccountId, name } = await parseForm(
            r,
            accessSpec.AccessGroupCreateBody,
        );

        const created_at = getMockTimeTz();

        const delegation: AccessDelegation = {
            billing_account_id,
            created_at,
            updated_at: created_at,
            id: generateId(),
            impersonations: [],
            name,
            owner_id: getUserId(r),
        };
        console.debug("Creating mock delegation: %o", delegation);

        validateSpec(accessSpec.AccessDelegation, delegation);

        getMockDb().delegations.push(delegation);
        markDirty();

        return HttpResponse.json(delegation);
    }),
    http.delete("/user-resource/access_group/{id}", async (r) => {
        const id = Number(r.params.id);

        getMockDb().delegations = getMockDb().delegations.filter((d) => d.id !== id);
        markDirty();

        return HttpResponse.json({ success: true });
    }),

    http.post("/user-resource/access_group/{id}/impersonation", async (r) => {
        const id = Number(r.params.id);
        const { grantee_username } = await parseForm(r, accessSpec.AccessDelegationImpersonationCreateBody);

        const impersonation: AccessDelegationImpersonation = {
            created_at: getMockTimeTz(),
            grantee_username,
            is_accepted: false,
            uuid: randomUuid(),
        };
        console.debug("Creating mock impersonation: %o", impersonation);

        validateSpec(accessSpec.AccessDelegationImpersonation, impersonation);

        const delegation = findById(getMockDb().delegations, id);
        delegation.impersonations.push(impersonation);
        markDirty();

        return HttpResponse.json(impersonation);
    }),
    http.delete("/user-resource/access_group/{id}/impersonation/{impersonation_id}", async (r) => {
        const id = Number(getParam(r, "id"));
        const impersonation_id = getParam(r, "impersonation_id");

        const delegation = findById(getMockDb().delegations, id);
        delegation.impersonations = delegation.impersonations.filter((i) => i.uuid !== impersonation_id);
        markDirty();

        return HttpResponse.json({ success: true });
    }),
    //#endregion

    //#region Access impersonation
    http.get("/user-resource/impersonation", async (_r) => {
        return HttpResponse.json(getMockDb().impersonations);
    }),
    //#endregion

    //#region User & profile
    http.get("/user-resource/user", async (_r) => {
        return HttpResponse.json(getMockDb().users[0]);
    }),
    http.get("/user-resource/user/privileges", async (_r) => {
        // This actually indicates the user is an admin
        return HttpResponse.json({ message: "Not found" }, { status: 404 });
    }),

    http.get("/user-resource/mfa/is_active", async (_r) => {
        return HttpResponse.json<UserMfaResponse>({ success: false });
    }),
    //#endregion

    //#region IP Addresses
    http.get("/{location}/network/ip_addresses", async (r) => {
        return HttpResponse.json(getLocationDb(r).ipAddresses);
    }),
    http.post("/{location}/network/ip_addresses", async (r) => {
        const { billing_account_id = defaultBillingAccountId, name } = await parseJson(
            r,
            networkSpec.IpAddressCreateBody,
        );

        const created_at = getMockTimeTz();

        const ipAddress: IpAddress = {
            id: generateId(),
            address: randomPrivateIpv4(),
            user_id: getUserId(r),
            billing_account_id,
            type: "public",
            enabled: true,
            created_at,
            updated_at: created_at,
            uuid: randomUuid(),
            is_deleted: false,
            is_ipv6: false,
            name,
        };
        console.debug("Creating mock IP: %o", { ipAddress });

        validateSpec(networkSpec.IpAddress, ipAddress);

        getLocationDb(r).ipAddresses.push(ipAddress);
        markDirty();

        return HttpResponse.json(ipAddress);
    }),
    http.post("/{location}/network/ip_addresses/{address}/assign", async (r) => {
        const { private_ip } = await parseJson(r, networkSpec.IpAddressAssignBody);

        const db = getLocationDb(r);
        const ipAddress = getRequestIpAddress(r);

        let assignedObj:
            | {
                  assigned_to: string;
                  assigned_to_resource_type: IpAddressResourceType;
                  assigned_to_private_ip: string;
              }
            | undefined = undefined;
        let lb: LoadBalancer | undefined;

        const vm = db.vms.find((vm) => vm.private_ipv4 === private_ip);
        if (vm) {
            assignedObj = {
                assigned_to: vm.uuid,
                assigned_to_resource_type: "virtual_machine",
                assigned_to_private_ip: vm.private_ipv4,
            };
        }

        if (assignedObj === undefined) {
            lb = db.loadBalancers.find((l) => l.private_address === private_ip);
            if (lb) {
                assignedObj = {
                    assigned_to: lb.uuid,
                    assigned_to_resource_type: "load_balancer",
                    assigned_to_private_ip: lb.private_address,
                };
            }
        }

        if (assignedObj === undefined) {
            throw new Error(`VM or Load Balancer with private IP ${private_ip} not found`);
        }

        const old_ip = db.ipAddresses.find((ip) => ip.assigned_to === assignedObj.assigned_to);
        if (
            old_ip &&
            // Do not unassign if just re-assigned to the same IP
            old_ip.uuid !== ipAddress.uuid
        ) {
            unAssignIp(old_ip);
        }

        ipAddress.assigned_to = assignedObj.assigned_to;
        ipAddress.assigned_to_resource_type = assignedObj.assigned_to_resource_type;
        ipAddress.assigned_to_private_ip = assignedObj.assigned_to_private_ip;
        delete ipAddress.unassigned_at;

        markDirty();

        validateSpec(networkSpec.IpAddress, ipAddress);

        return HttpResponse.json(ipAddress);
    }),
    http.post("/{location}/network/ip_addresses/{address}/unassign", async (r) => {
        const ipAddress = getRequestIpAddress(r);
        unAssignIp(ipAddress);
        markDirty();

        validateSpec(networkSpec.IpAddress, ipAddress);

        return HttpResponse.json(ipAddress);
    }),
    http.patch("/{location}/network/ip_addresses/{address}", async (r) => {
        const { name, billing_account_id } = await parseJson(r, networkSpec.IpAddressModifyBody);

        const ipAddress = getRequestIpAddress(r);
        if (!name) {
            delete ipAddress.name;
        } else {
            ipAddress.name = name;
        }
        if (billing_account_id) ipAddress.billing_account_id = billing_account_id;
        markDirty();

        console.debug("Updating ip address: %o", ipAddress);

        validateSpec(networkSpec.IpAddress, ipAddress);

        return HttpResponse.json(ipAddress);
    }),
    http.delete("/{location}/network/ip_addresses/{address}", async (r) => {
        const address = getParam(r, "address");

        const db = getLocationDb(r);
        db.ipAddresses = db.ipAddresses.filter((ip) => ip.address !== address);
        markDirty();

        return HttpResponse.json(true);
    }),
    //#endregion

    //#region VPC Networks
    http.get("/{location}/network/networks", async (r) => {
        return HttpResponse.json(getLocationDb(r).vpcNetworks);
    }),
    http.post("/{location}/network/network", async (r) => {
        const { name } = await parseForm(r, networkSpec.VpcCreateBody);

        const created_at = getMockTimeTz();

        const network: Vpc = {
            id: generateId(),
            vlan_id: randomBigInt(),
            subnet: randomSubnetIpv4(),
            name,
            created_at,
            updated_at: created_at,
            uuid: randomUuid(),
            type: "private",
            is_default: getLocationDb(r).vpcNetworks.length === 0,
            vm_uuids: [],
            resources_count: 0,
            subnet_ipv6: randomSubnetIpv6(),
        };
        console.debug("Creating mock VPC network: %o", { name });

        validateSpec(networkSpec.Vpc, network);

        getLocationDb(r).vpcNetworks.push(network);
        markDirty();

        return HttpResponse.json(network);
    }),
    http.patch("/{location}/network/network/{uuid}", async (r) => {
        const { name } = await parseJson(r, networkSpec.VpcUpdateBody);

        const vpcNetwork = getRequestVpcNetwork(r);
        if (name) vpcNetwork.name = name;
        markDirty();

        console.debug("Updating vpc network: %o", vpcNetwork);

        validateSpec(networkSpec.Vpc, vpcNetwork);

        return HttpResponse.json(vpcNetwork);
    }),
    http.put("/{location}/network/network/{uuid}/default", async (r) => {
        const vpcNetwork = getRequestVpcNetwork(r);
        const db = getLocationDb(r);
        db.vpcNetworks = db.vpcNetworks.map((n) => {
            return { ...n, is_default: n.uuid === vpcNetwork.uuid };
        });
        markDirty();

        return HttpResponse.json(true);
    }),
    http.delete("/{location}/network/network/{uuid}", async (r) => {
        const vpcNetwork = getRequestVpcNetwork(r);

        const db = getLocationDb(r);
        db.vpcNetworks = db.vpcNetworks.filter((n) => n.uuid !== vpcNetwork.uuid);
        markDirty();

        return HttpResponse.json(true);
    }),
    //#endregion

    //#region Load Balancers
    http.get("/{location}/network/load_balancers", async (r) => {
        return HttpResponse.json(getLocationDb(r).loadBalancers);
    }),
    http.post("/{location}/network/load_balancers", async (r) => {
        const { billing_account_id, display_name, network_uuid, rules, targets, reserve_public_ip } = await parseJson(
            r,
            networkSpec.LoadBalancerCreateBody,
        );

        const lbPrivateAddress = randomPrivateIpv4();
        const lbUuid = randomUuid();

        const created_at = getMockTimeTz();
        const lb: LoadBalancer = {
            uuid: lbUuid,
            display_name,
            user_id: getUserId(r),
            billing_account_id,
            created_at,
            updated_at: created_at,
            is_deleted: false,
            private_address: lbPrivateAddress,
            network_uuid,
            forwarding_rules: rules.map(({ source_port, target_port }) => ({
                protocol: "TCP",
                uuid: randomUuid(),
                created_at,
                source_port,
                target_port,
                settings: {
                    connection_limit: 10000,
                    session_persistence: "SOURCE_IP",
                },
            })),
            targets: targets.map(({ target_type, target_uuid }) => ({
                created_at,
                target_uuid,
                target_type,
                target_ip_address: randomPrivateIpv4(),
            })),
        };
        console.debug("Creating mock load balancer: %o", { lb });

        validateSpec(networkSpec.LoadBalancer, lb);

        getLocationDb(r).loadBalancers.push(lb);

        if (reserve_public_ip) {
            const ipAddress: IpAddress = {
                id: generateId(),
                address: randomPrivateIpv4(),
                user_id: getUserId(r),
                billing_account_id,
                type: "public",
                enabled: true,
                created_at,
                updated_at: created_at,
                uuid: randomUuid(),
                is_deleted: false,
                is_ipv6: false,
                assigned_to: lbUuid,
                assigned_to_resource_type: "load_balancer",
                assigned_to_private_ip: lbPrivateAddress,
            };
            console.debug("Creating mock IP for Load balancer: %o", { ipAddress });

            validateSpec(networkSpec.IpAddress, ipAddress);
            getLocationDb(r).ipAddresses.push(ipAddress);
        }
        markDirty();

        return HttpResponse.json(lb);
    }),
    http.patch("/{location}/network/load_balancers/{uuid}", async (r) => {
        const { display_name } = await parseJson(r, networkSpec.LoadBalancerRenameBody);

        const loadBalancer = getRequestLoadBalancer(r);
        loadBalancer.display_name = display_name;
        markDirty();

        console.debug("Updating load balancer name: %o", loadBalancer);

        validateSpec(networkSpec.LoadBalancer, loadBalancer);

        return HttpResponse.json(loadBalancer);
    }),
    http.put("/{location}/network/load_balancers/{uuid}/billing_account", async (r) => {
        const urlParams = getQueryParams(r);
        const loadBalancer = getRequestLoadBalancer(r);
        const billing_account_id = urlParams.has("set_id") ? Number(urlParams.get("set_id")) : undefined;
        if (billing_account_id) loadBalancer.billing_account_id = billing_account_id;
        markDirty();

        console.debug("Updating load balancer name: %o", loadBalancer);

        validateSpec(networkSpec.LoadBalancer, loadBalancer);

        return HttpResponse.json(loadBalancer);
    }),
    http.delete("/{location}/network/load_balancers/{uuid}", async (r) => {
        const loadBalancer = getRequestLoadBalancer(r);

        const db = getLocationDb(r);
        db.loadBalancers = db.loadBalancers.filter((lb) => lb.uuid !== loadBalancer.uuid);
        markDirty();

        return HttpResponse.json(true);
    }),
    http.post("/{location}/network/load_balancers/{uuid}/targets", async (r) => {
        const { target_type, target_uuid } = await parseJson(r, networkSpec.LbCreateTarget);

        const target: LbTarget = {
            created_at: getMockTimeTz(),
            target_uuid,
            target_type,
            target_ip_address: randomPrivateIpv4(),
        };

        validateSpec(networkSpec.LbTarget, target);

        const loadBalancer = getRequestLoadBalancer(r);
        loadBalancer.targets.push(target);
        markDirty();

        console.debug("Creating load balancer target: %o", { target });

        validateSpec(networkSpec.LoadBalancer, loadBalancer);

        return HttpResponse.json(target);
    }),
    http.delete("/{location}/network/load_balancers/{uuid}/targets/{target_uuid}", async (r) => {
        const targetUuid = getParam(r, "target_uuid");
        const loadBalancer = getRequestLoadBalancer(r);
        loadBalancer.targets = loadBalancer.targets.filter((t) => t.target_uuid !== targetUuid);

        markDirty();

        console.debug("Removing load balancer's target with UUID: %o", { targetUuid });

        validateSpec(networkSpec.LoadBalancer, loadBalancer);

        return HttpResponse.json(true);
    }),
    http.post("/{location}/network/load_balancers/{uuid}/forwarding_rules", async (r) => {
        const { source_port, target_port } = await parseJson(r, networkSpec.LbCreateForwardingRule);

        const rule: LbForwardingRule = {
            protocol: "TCP",
            uuid: randomUuid(),
            created_at: getMockTimeTz(),
            source_port,
            target_port,
            settings: {
                connection_limit: 10000,
                session_persistence: "SOURCE_IP",
            },
        };

        validateSpec(networkSpec.LbForwardingRule, rule);

        const loadBalancer = getRequestLoadBalancer(r);
        loadBalancer.forwarding_rules.push(rule);
        markDirty();

        console.debug("Creating load balancer forwarding rule: %o", { rule });

        validateSpec(networkSpec.LoadBalancer, loadBalancer);

        return HttpResponse.json(rule);
    }),
    http.delete("/{location}/network/load_balancers/{uuid}/forwarding_rules/{rule_uuid}", async (r) => {
        const ruleUuid = getParam(r, "rule_uuid");
        const loadBalancer = getRequestLoadBalancer(r);
        loadBalancer.forwarding_rules = loadBalancer.forwarding_rules.filter((r) => r.uuid !== ruleUuid);

        markDirty();

        console.debug("Removing load balancer's forwarding rule with UUID: %o", { ruleUuid });

        validateSpec(networkSpec.LoadBalancer, loadBalancer);

        return HttpResponse.json(true);
    }),
    //#endregion

    //#region Billing
    http.post("/{location}/user-resource/resource_billing", async (r) => {
        const db = getLocationDb(r);

        const { uuid, billing_account_id } = await parseJson(r, vmSpec.VmChangeBillingAccountBody);

        const vm = mustFindBy(db.vms, (v) => v.uuid, uuid, "VM");
        vm.billing_account = billing_account_id;

        markDirty();
        return HttpResponse.json(vm);
    }),

    http.get("/payment/billing_account/list", async (_r) => {
        return HttpResponse.json(getMockDb().billingAccounts);
    }),

    http.post("/payment/billing_account", async (r) => {
        const { client_email, site, title, type, ...body } = await parseForm(r, billingSpec.BillingAccountCreateFields);

        const billingAccount: BillingAccount = {
            ...body,

            id: generateId(),
            user_id: getUserId(r),
            created: getMockTimeEpoch(),

            title: notNull(title),
            site: notNull(site),
            email: notNull(client_email),

            ...createFieldValues,

            allow_debt: type === "invoice",
            restriction_level: "LIMITED",
            referral_share_code: randomLowerAlpha(),
            reseller: "",
            low_balance_notice_settings: {
                is_enabled: true,
            },
        };

        console.debug("Creating mock billing account: %o", billingAccount);
        validateSpec(billingSpec.BillingAccount, billingAccount);

        getMockDb().billingAccounts.push(billingAccount);
        markDirty();

        return HttpResponse.json(billingAccount);
    }),

    http.post("/payment/billing_account/set_default", async (r) => {
        const accountId = getBaIdQuery(r);
        for (const account of getMockDb().billingAccounts) {
            account.is_default = account.id === accountId;
        }
        markDirty();

        return HttpResponse.json({ success: true });
    }),

    http.post("/payment/billing_account/{id}/campaign_activate/{campaign_code}", async (r) => {
        const code = String(r.params.campaign_code);
        if (code === "valid") {
            return HttpResponse.json<CampaignActivateResponse>({ message: "123 EUR credit added" });
        } else if (code === "used") {
            throwStandardErrorResponse("Campaign code is already used.");
        }

        throwStandardErrorResponse("Invalid campaign.");
    }),

    http.put("/payment/billing_account/{id}/recurring_payment", async (r) => {
        const accountId = Number(r.params.id);
        const account = findById(getMockDb().billingAccounts, accountId);
        const body = await parseJson(r, billingSpec.AutomatedTopUpEnableDisableBody);

        account.is_recurring_payment_enabled = body.is_recurring_payment_enabled;

        if (body.is_recurring_payment_enabled) {
            account.recurring_payment_threshold = body.recurring_payment_threshold;
            account.recurring_payment_amount = body.recurring_payment_amount;
        }

        markDirty();

        return HttpResponse.json<AutomatedTopUpEnableDisableResponse>({ account: account });
    }),

    http.put("/payment/low_balance_notice_settings", async (r) => {
        const {
            billing_account_id: accountId,
            is_enabled,
            threshold,
        } = await parseJson(r, billingSpec.ModifyLowBalanceNoticeSettingsBody);
        const account = findById(getMockDb().billingAccounts, accountId);

        const noticeSettings = {
            is_enabled,
            threshold,
        };
        account.low_balance_notice_settings = noticeSettings;

        markDirty();

        return HttpResponse.json<ModifyLowBalanceNoticeSettingsResponse>(noticeSettings);
    }),

    http.post("/payment/apply_for_invoice_payment", async (r) => {
        const body = await parseJson(r, billingSpec.ApplyForInvoiceBody);
        const accountId = body.billing_account_id;

        const db = getMockDb();

        const account = findById(db.billingAccounts, accountId);

        const identifier = `mock_${randomAlphaNumeric(10)}`;
        const expirationDate = new Date(2124, 11);

        const existingPrimaryCard = db.billingCards.find(
            (c) => c.billing_account_id === accountId && !c.is_deleted && c.is_primary,
        );

        const cardData: BillingAccountCardData = {
            id: "N/A",
            type: "paying_by_invoice",
            card_type: "By invoice",
            expire_month: "12",
            expire_year: "2099",
            card_holder: account.email,
            client: {},
        };
        const card: BillingAccountCard = {
            additional_data: makeAdditionalCardData(cardData),
            billing_account_id: accountId,
            billing_account_processor_identifier_id: 1234,
            created: getMockTimeEpoch(),
            id: generateId(),
            identifier,
            is_deleted: false,
            is_primary: existingPrimaryCard === undefined,
            is_verification_pending: false,
            is_verified: false, // depends on payment componet's ENV variable value env.INVOICE_PAYMENT_INITIAL_STATE == "active"
            valid_thru: expirationDate.getTime() / 1000,
        };

        account.can_pay = true;
        // XXX: Perhaps this should be looked up dynamically in the BA query?
        account.primary_card = cardData;

        db.billingCards.push(card);
        markDirty();

        return HttpResponse.json(card);
    }),

    http.get("/payment/invoice/list", async (r) => {
        const _baId = getBaIdQuery(r); // TODO: use this
        return HttpResponse.json(getMockDb().billingInvoices);
    }),

    http.post("/payment/pay_invoice", async (r) => {
        const invoiceId = getInvoiceIdQuery(r);
        const invoice = findById(getMockDb().billingInvoices, invoiceId);
        invoice.status = InvoiceStatusEnum.PAID;
        markDirty();

        return HttpResponse.json<PayInvoiceResponse>({ success: true, message: "Invoice paid." });
    }),

    http.get("/payment/card/list", async (r) => {
        const baId = getBaIdQuery(r);
        const _account = findById(getMockDb().billingAccounts, baId);
        const cards = getMockDb().billingCards.filter((c) => c.billing_account_id === baId);
        return HttpResponse.json(cards);
    }),

    http.put("/payment/card/set_primary", async (r) => {
        const paymentObjectId = getPaymentObjectIdQuery(r);
        for (const card of getMockDb().billingCards) {
            card.is_primary = card.id === paymentObjectId;
        }
        markDirty();

        return HttpResponse.json({ success: true });
    }),

    http.get("/charging/usage", async (r) => {
        const _baId = getBaIdQuery(r); // TODO: use this
        return HttpResponse.json(getMockDb().billingUsage);
    }),

    http.get("/payment/credit/list", async (r) => {
        const _baId = getBaIdQuery(r); // TODO: use this
        return HttpResponse.json(getMockDb().billingHistory);
    }),

    http.post("/payment/credit/buy", async (r) => {
        const body = await parseJson(r, billingSpec.CreditBuyBody);
        invariant(body.payment_object_id === exampleVisaId, "Only Visa card is expected");
        const page = "<html><body>Mock authorize page</body></html>";
        const response: OmiseCreditBuyResponse = {
            success: true,
            message: "",
            data: {
                id: "mock-id",
                object: "mock",
                status: "mock",
            },
            relay_props: {
                authorize_uri: `data:text/html,${page}`,
                data_ref: "mock-credit-buy",
            },
        };
        return HttpResponse.json(response);
    }),

    http.post("/payment/link/send_data", async (r) => {
        const body = await parseJson(r, billingSpec.LinkSendDataBody);

        const { processor } = body;
        invariant(processor === "omise", "Only Omise processor is supported");

        const params = JSON.parse(body.params) as LinkParams;
        const { paymentMethod } = params;
        invariant(paymentMethod === "promptpay", "Only PromptPay payment method is supported");

        console.debug("Payment link send data: %o", { body, params });

        const qrCodePath = (await import("../modules/billing/topup/mock/qrcode.svg?url")).default;
        const qrcode_url = new URL(qrCodePath, window.location.origin).toString();

        return HttpResponse.json<LinkSendDataResponse>({
            success: true,
            error_msg: "",
            data: {
                charge_id: "123",
                charge_status: "pending",
                flow: "mock",
                persistent_data_ref: "mock-omise-link",
                qrcode_url,
            },
        });
    }),

    http.get("/payment/final_topup_amount", async (r) => {
        const { billing_account_id, processor, amount } = z
            .object({
                billing_account_id: zfd.numeric(z.number().int()),
                processor: z.string(),
                amount: zfd.numeric(z.number().int()),
            })
            .strict()
            .parse(Object.fromEntries(getQueryParams(r).entries()));

        invariant(billing_account_id);
        invariant(processor === "omise", "Only Omise processor is supported");
        // TODO: Actually calculate the final amount
        const final_amount = amount * 1.2;
        return HttpResponse.json<FinalAmountResponse>({ final_amount });
    }),

    http.post("/payment/link/save_methods", async (r) => {
        const body = await parseJson(r, billingSpec.SaveMethodsBody);
        const { billing_account_id, method_ids } = body;

        const ba = findById(getMockDb().billingAccounts, billing_account_id);
        ba.additional_data = JSON.stringify({
            link_methods: method_ids,
        });
        markDirty();

        return HttpResponse.json({ success: true });
    }),

    http.get("/payment/persistent_data", async (r) => {
        const params = getQueryParams(r);
        console.debug("Payment persistent data: %o", { params });

        const baId = getBaIdQuery(r);
        const identifier = mustGet(params, "identifier", "params");

        const base = {
            billing_account_id: baId,
            created: getMockTimeEpoch(),
            data: "{}",
            id: 123,
            identifier,
        } satisfies Partial<PersistentDataResponse>;

        switch (identifier) {
            case "mock-omise-link":
                return HttpResponse.json<PersistentDataResponse>({
                    ...base,
                    processor_method: "omise::promptpay",
                });

            case "mock-credit-buy":
                return HttpResponse.json<PersistentDataResponse>({
                    ...base,
                    data: JSON.stringify({
                        object: "charge",
                        status: "successful",
                    } satisfies OmiseChargeData),
                    processor_method: "omise_creditcard",
                });

            default:
                unreachableSwitchCase(identifier);
        }
    }),

    http.post("/payment/card", async (r) => {
        const body = await parseJson(r, billingSpec.AddCardBody);
        const { billing_account_id, token } = body;
        console.debug("Mock add card: %o", { billing_account_id, token });

        const { number, expiration_month, expiration_year } = parseOmiseMockToken(token);
        const expirationDate = new Date(expiration_year, expiration_month - 1);
        const identifier = `mock_${randomAlphaNumeric(10)}`;
        const cardData: BillingAccountCardData = {
            id: identifier,
            expire_month: expiration_month,
            expire_year: expiration_year,
            type: "creditcard",
            card_type: "visa",
            last4: number.slice(-4),
        };
        const card: BillingAccountCard = {
            additional_data: makeAdditionalCardData(cardData),
            billing_account_id: billing_account_id,
            billing_account_processor_identifier_id: 123,
            created: getMockTimeEpoch(),
            id: generateId(),
            identifier,
            is_deleted: false,
            is_primary: false,
            is_verification_pending: false,
            is_verified: false,
            valid_thru: expirationDate.getTime() / 1000,
        };

        const account = findById(getMockDb().billingAccounts, billing_account_id);

        account.can_pay = true;
        // XXX: Perhaps this should be looked up dynamically in the BA query?
        account.primary_card = cardData;

        getMockDb().billingCards.push(card);
        markDirty();

        return HttpResponse.json(card);
    }),

    http.post("/payment/card/verify", async (r) => {
        const body = await parseJson(r, billingSpec.CardVerifyRequest);
        console.debug("Mock verify card: %o", body);
        const { payment_object_id, front_base_url, force_3ds } = body;

        // TODO: Only verify card when we reach the 3DS page
        const card = findById(getMockDb().billingCards, payment_object_id);
        card.is_verified = true;
        markDirty();

        return HttpResponse.json<CardVerifyResponse>({
            card,
            authorize_uri: "data:text/html,<html><body>Mock card verify page</body></html>",
        });
    }),

    http.delete("/payment/card", async (r) => {
        const paymentObjectId = getPaymentObjectIdQuery(r);
        const db = getMockDb();
        const theCard = db.billingCards.find((c) => c.id === paymentObjectId);
        const billingAccountId = theCard?.billing_account_id;
        const setNewPrimary = theCard?.is_primary;

        db.billingCards = db.billingCards.filter((c) => c.id !== paymentObjectId);

        const baCards = db.billingCards.filter((c) => c.billing_account_id === billingAccountId);

        let baCounter = 0;
        for (const card of baCards) {
            baCounter++;
            if (setNewPrimary) {
                card.is_primary = baCounter === baCards.length;
            }
        }

        markDirty();

        return HttpResponse.json({ success: true });
    }),

    //#endregion

    //#region Common data
    http.get("/config/payment/restriction_level_clear_topup_threshold", async (_r) => {
        // TODO: maybe create proper mock db object?
        return HttpResponse.json<RestrictionLevelClearTopupThreshold>({ restriction_level_clear_topup_threshold: 25 });
    }),

    http.get("/config/locations", async (_r) => {
        return HttpResponse.json(getMockDb().locations);
    }),

    http.get("/pricing/list/current", async (_r) => {
        return HttpResponse.json(getMockDb().pricing);
    }),

    http.get("/pricing/list", async (_r) => {
        return HttpResponse.json(getMockDb().pricingHistory);
    }),

    http.get("/{location}/user-resource/host_pool/list", async (r) => {
        return HttpResponse.json(getLocationDb(r).hostPools);
    }),
    //#endregion Common data

    //#region Admin data
    http.get("/payment/admin/campaign/list", async (r) => {
        return HttpResponse.json(getMockDb().campaigns);
    }),

    http.get("/payment/admin/referral_enabled", async (r) => {
        return HttpResponse.json(getMockDb().referral_enabled);
    }),

    http.get("/payment/admin/referral_amounts", async (r) => {
        return HttpResponse.json(getMockDb().referral_amounts);
    }),

    http.get("/config/ui/gtm_key", async (r) => {
        return HttpResponse.json(getMockDb().gtm_key);
    }),
    //#endregion Admin data

    //#region Internal requests
    http.post("/mock/error", async (_r) => {
        return HttpResponse.text("Mock Internal server error", { status: 500 });
    }),
    //#endregion

    ///// Imports

    ...metalHandlers,
];

//#region Network-specific accessors
function getRequestIpAddress(r: StandardRequest) {
    const db = getLocationDb(r);
    const address = getParam(r, "address");
    return mustFindBy(db.ipAddresses, (ip) => ip.address, address, "IP address");
}

function getRequestVpcNetwork(r: StandardRequest) {
    const db = getLocationDb(r);
    const uuid = getUuidParam(r);
    return mustFindBy(db.vpcNetworks, (n) => n.uuid, uuid, "VPC network");
}

function getRequestLoadBalancer(r: StandardRequest) {
    const db = getLocationDb(r);
    const uuid = getUuidParam(r);
    return mustFindBy(db.loadBalancers, (lb) => lb.uuid, uuid, "Load Balancer");
}

function unAssignIp(attachedIp: IpAddress) {
    delete attachedIp.assigned_to;
    delete attachedIp.assigned_to_resource_type;
    delete attachedIp.assigned_to_private_ip;
    attachedIp.unassigned_at = getMockTimeTz();
}
//#endregion

//#region Billing-specific accessors
function getBaIdQuery(r: StandardRequest) {
    const query = getQueryParams(r);
    return Number(mustGet(query, "billing_account_id", "query"));
}

function getPaymentObjectIdQuery(r: StandardRequest) {
    const query = getQueryParams(r);
    return Number(mustGet(query, "payment_object_id", "query"));
}

function getInvoiceIdQuery(r: StandardRequest) {
    const query = getQueryParams(r);
    return Number(mustGet(query, "invoice_id", "query"));
}
//#endregion
