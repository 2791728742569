import T from "../../components/forms/TextField.module.css";

import { useForm, useStore } from "@tanstack/react-form";
import type { ReactNode } from "react";
import { Input, Label, NumberField } from "react-aria-components";
import { ariaTanstackFieldProps } from "../../components/forms/ariaTanstackFieldProps.tsx";
import { CurrencySymbol } from "../../components/l10n/Currency.tsx";
import { ModalResult, WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { modifyLowBalanceNoticeSettingsMutation } from "./billingAccountQuery.ts";
import type { EBillingAccount } from "./billingLogic.tsx";
import { ErrorTexts } from "./payment_forms/components.tsx";

export interface LowBalanceNotificationsModalProps {
    billingAccount: EBillingAccount;
}

export function LowBalanceNotificationsModalContent({ billingAccount }: LowBalanceNotificationsModalProps) {
    //#region Hooks
    const modifyMutation = useStandardMutation(modifyLowBalanceNoticeSettingsMutation);

    const {
        id,
        low_balance_notice_settings: { threshold, is_enabled },
    } = billingAccount.account;

    const form = useForm({
        defaultValues: {
            threshold,
        },
        async onSubmit({ value: { threshold } }) {
            await modifyMutation.mutateAsync({
                body: {
                    billing_account_id: id,
                    threshold: threshold!,
                    is_enabled: true,
                },
            });
        },
    });
    const isSubmitting = useStore(form.store, (s) => s.isSubmitting);
    //#endregion

    return (
        <WModalContent
            title="Low Balance Notifications"
            label={!is_enabled ? "Enable" : "Save"}
            modalAction={async () => {
                await form.handleSubmit();
                return form.state.isValid ? ModalResult.CLOSE : ModalResult.KEEP_OPEN;
            }}
        >
            <div className="w-50%">
                <form.Field
                    name="threshold"
                    validators={{
                        // `NaN` when the field is empty
                        onBlur: ({ value }) =>
                            Number.isNaN(value) || Number(value) <= 0 ? "Amount should be positive" : null,
                    }}
                >
                    {(api) => (
                        <NumberField
                            className={T.NumberField}
                            autoFocus
                            isDisabled={isSubmitting}
                            {...ariaTanstackFieldProps(api)}
                        >
                            <Label className={T.Label}>
                                Notify when balance goes below (<CurrencySymbol />)
                            </Label>
                            <Input className={T.Input} />
                            <ErrorTexts errors={api.state.meta.errors} />
                        </NumberField>
                    )}
                </form.Field>
            </div>
        </WModalContent>
    );
}

export function LowBalanceNotificationsModal({
    button,
    ...props
}: LowBalanceNotificationsModalProps & { button: ReactNode }) {
    return (
        <WModal button={button}>
            <LowBalanceNotificationsModalContent {...props} />
        </WModal>
    );
}
