import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { cn } from "../../utils/classNames.ts";

export function PercentageChange({ prev, last }: { prev: number; last: number }) {
    const diff = Math.abs(last) - Math.abs(prev);
    const mode = diff > 0 ? "positive" : diff < 0 ? "negative" : "neutral";
    const percentChange = prev ? ((last - prev) / prev) * 100 : undefined;

    const colorClass = mode === "positive" ? "color-success" : mode === "negative" ? "color-error" : undefined;
    const iconClass =
        mode === "positive" ? "jp-arrow-thin-up-icon" : mode === "negative" ? "jp-arrow-thin-down-icon" : undefined;

    return (
        <div className={cn(colorClass, "no-wrap")} title="Compared to previous value">
            {iconClass && <MaskIcon className={cn(iconClass, "size-0.75rem")} />}
            {percentChange ? `${percentChange.toFixed(2)}%` : undefined}
        </div>
    );
}
