import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import type { TooltipComponentFormatterCallback, TooltipComponentFormatterCallbackParams } from "echarts";
import type { BarSeriesOption } from "echarts/charts";
import { useState } from "react";
import { Label } from "react-aria-components";
import { keys, sortBy, values } from "remeda";
import invariant from "tiny-invariant";
import { BadgeDot } from "../../components/Badge.tsx";
import { Separator } from "../../components/Separator.tsx";
import { getPreferredLocation, type LocationWithEnabled } from "../../modules/location/location.ts";
import { usePricingLocations } from "../../modules/location/query.ts";
import { useThemeVar } from "../../modules/theme/useTheme.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { usePortal } from "../../utils/react/usePortal.tsx";
import { AdminLocationSelect } from "../AdminLocationSelect.tsx";
import { adminAllStatisticsQueryAtom, type VmAdminStatisticsLoc } from "../vm/vmQuery.ts";
import { CustomRadios, type FilterItem } from "./CustomRadios.tsx";
import { SmallDonut } from "./DonutChart.tsx";
import { LightBlock } from "./LightBlock.tsx";
import { WBarChart, type WBarChartOptions } from "./WBarChart.tsx";

type Distributed = "by_cpu" | "by_mem" | "by_os";
type Quantity = "quantity" | "volume";

function VirtualMachinesSectionContent() {
    const vms: VmAdminStatisticsLoc[] = useSuspenseQueryAtom(adminAllStatisticsQueryAtom);
    const locations = usePricingLocations();
    const location = getPreferredLocation(locations);
    const [selectedLocation, setSelectedLocation] = useState<LocationWithEnabled>(location);
    const [distributed, setDistributed] = useState<Distributed>("by_cpu");
    const [stacked, setStacked] = useState<Quantity>("quantity");

    const tooltipPortal = usePortal();

    const colorText = useThemeVar("color-text");
    const colorPrimary = useThemeVar("color-primary");
    const colorPrimaryDark = useThemeVar("color-primary-dark");

    const locationVms = vms.find((item) => item.location === selectedLocation.slug);

    let totalVMs = 0;
    let totalVMsRunning = 0;
    let totalVMsStopped = 0;

    for (const loc of vms) {
        for (const value of values(loc.by_cpu)) {
            if (value.running) {
                totalVMs += value.running;
                totalVMsRunning += value.running;
            }

            if (value.stopped) {
                totalVMs += value.stopped;
                totalVMsStopped += value.stopped;
            }
        }
    }

    const tooltip = (
        <div>
            <BadgeDot color="color-primary-dark">
                Stopped: <b>{totalVMsStopped.toFixed(0)}</b>
            </BadgeDot>

            <BadgeDot color="color-primary">
                Running: <b>{totalVMsRunning.toFixed(0)}</b>
            </BadgeDot>
        </div>
    );

    const vmItems = [
        { value: (totalVMsStopped / totalVMs) * 100, color: "var(--color-primary-dark)" },
        { value: (totalVMsRunning / totalVMs) * 100, color: "var(--color-primary)" },
    ];

    const distributedItems: FilterItem[] = [
        { key: "by_cpu", title: "vCPU" },
        { key: "by_mem", title: "vRAM" },
        { key: "by_os", title: "OS" },
    ];

    const stackedItems: FilterItem[] = [
        { key: "quantity", title: "Quantity" },
        { key: "volume", title: "Volume" },
    ];

    const distributedVms = locationVms ? locationVms[distributed] : {};
    const sortedItems = sortBy(keys(distributedVms), (l) => l);

    const xAxisData = sortedItems.map((item): string => {
        switch (distributed) {
            case "by_cpu":
                return `${item} vCPU(s)`;
            case "by_mem":
                return `${item} GB`;
            case "by_os":
                return item;
            default:
                exhaustiveSwitchCheck(distributed);
        }
    });

    const runningValues = sortedItems.map((item) =>
        stacked === "quantity" ? distributedVms[item].running : distributedVms[item].running * parseInt(item),
    );
    const stoppedValues = sortedItems.map((item) =>
        stacked === "quantity" ? distributedVms[item].stopped : distributedVms[item].stopped * parseInt(item),
    );
    const valueSuffix =
        stacked === "quantity" ? "pc" : distributed === "by_cpu" ? "vCPU" : distributed === "by_mem" ? "GB vRAM" : "";

    const tooltipFormatter: TooltipComponentFormatterCallback<TooltipComponentFormatterCallbackParams> = (params) => {
        invariant(!Array.isArray(params), "Expected single params");
        const { name, value, dataIndex } = params;
        invariant(typeof value === "number", "Expected number value");

        const content = (
            <div>
                <div>
                    <b>{name}</b>
                </div>

                {!!runningValues[dataIndex] && (
                    <BadgeDot color="color-primary">
                        Running: <b>{runningValues[dataIndex]}</b> {valueSuffix}
                    </BadgeDot>
                )}

                {!!stoppedValues[dataIndex] && (
                    <BadgeDot color="color-primary-dark">
                        Stopped: <b>{stoppedValues[dataIndex]}</b> {valueSuffix}
                    </BadgeDot>
                )}
            </div>
        );

        // return renderToStaticMarkup(content);
        tooltipPortal.setContent(content);
        return tooltipPortal.element;
    };

    const runningData: BarSeriesOption[] = [
        {
            name: "Running",
            type: "bar",
            stack: "total",
            label: {
                show: true,
            },
            itemStyle: {
                color: colorPrimary,
            },
            labelLayout(params) {
                return {
                    fontSize: params.rect.height > 12 ? 11 : 0,
                };
            },
            tooltip: {
                formatter: tooltipFormatter,
            },
            data: runningValues,
        },
    ];

    const stoppedData: BarSeriesOption[] = [
        {
            name: "Stopped",
            type: "bar",
            stack: "total",
            label: {
                show: true,
            },
            itemStyle: {
                color: colorPrimaryDark,
            },
            labelLayout(params) {
                return {
                    fontSize: params.rect.height > 12 ? 11 : 0,
                };
            },
            tooltip: {
                formatter: tooltipFormatter,
            },
            data: stoppedValues,
        },
    ];

    const options: WBarChartOptions = {
        tooltip: {
            trigger: "item",
            axisPointer: {
                type: "none",
            },
            borderWidth: 0,
            textStyle: {
                color: colorText,
            },
            extraCssText: "box-shadow: 0 8px 20px rgba(0 0 0 / 10%);",
        },
        grid: {
            top: "5px",
            left: "5px",
            right: "5px",
            bottom: "5px",
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: xAxisData,
            axisLabel: {
                rotate: 45,
            },
            axisLine: {
                lineStyle: {
                    color: "#888888",
                },
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
        },
        series: [...runningData, ...stoppedData],
    };

    function changeDistributed(value: Distributed) {
        setDistributed(value);
        if (value === "by_os") {
            setStacked("quantity");
        }
    }

    return (
        <>
            {tooltipPortal.portalNode}

            <div className="flex justify-between gap-1rem pb-3">
                <div>
                    <h2 className="font-size-heading">Virtual Machines</h2>
                    <p>Distribution by vCPU, vRAM and OS</p>
                </div>

                <SmallDonut
                    marginLeft
                    title="Total number of VMs"
                    items={vmItems}
                    tooltip={tooltip}
                    total={totalVMs.toFixed(0)}
                />
            </div>

            <Separator />

            <div className="flex justify-between pt-3 pb-3">
                <div className="flex gap-1rem font-size-small">
                    <BadgeDot color="color-chart-2">Running</BadgeDot>

                    <BadgeDot color="color-chart-3">Stopped</BadgeDot>
                </div>

                <AdminLocationSelect onChange={(item) => setSelectedLocation(item)} />
            </div>

            <div className="flex items-center pb-4 justify-end">
                <Label className="font-size-small pr-1">Distributed by:</Label>
                <div className="flex items-center">
                    <CustomRadios
                        items={distributedItems}
                        value={distributed}
                        onChange={(value) => changeDistributed(value as Distributed)}
                    />
                </div>
            </div>

            <WBarChart options={options} />

            <div className="flex items-center">
                <Label className="font-size-small pr-1">Stack by:</Label>
                <div className="flex items-center">
                    <CustomRadios
                        disabled={distributed === "by_os"}
                        items={stackedItems}
                        value={stacked}
                        onChange={(value) => setStacked(value as Quantity)}
                    />
                </div>
            </div>
        </>
    );
}

export function VirtualMachinesSection() {
    return (
        <LightBlock>
            <VirtualMachinesSectionContent />
        </LightBlock>
    );
}
