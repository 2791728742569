import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Slider.css";

import { Children, useEffect, useRef, useState, type ReactNode } from "react";
import Slider, { type Settings } from "react-slick";
import { NextArrow } from "../../modules/dashboard/NextArrow.tsx";
import { PrevArrow } from "../../modules/dashboard/PrevArrow.tsx";
import { cn } from "../../utils/classNames.ts";
import { useLayoutSizeObserver } from "../../utils/useLayoutSizeObserver.ts";

interface WCarouselProps {
    /** Slide elements */
    children: ReactNode[];

    settings: Settings;
    /** (in pixels) */
    slideWidth: number;
    slideClassName?: string;
    /** Arrows will always be shown if the number of slides is greater than or equal to this number */
    showArrowsMin?: number;
}

export function WCarousel({ children, settings, slideWidth, slideClassName, showArrowsMin }: WCarouselProps) {
    const { initialSlide } = settings;

    //#region Hooks
    const [perPage, setPerPage] = useState(4);
    const ref = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<Slider>(null);

    useLayoutSizeObserver({
        ref,
        onResize({ width }) {
            const newPerPage = Math.max(1, Math.floor(width / slideWidth));
            if (newPerPage !== perPage) {
                setPerPage(newPerPage);
            }
        },
    });

    useEffect(() => {
        /* XXX: Safari bug if perPage changes, need to move to correct slide again */
        void perPage;

        if (initialSlide && sliderRef.current) {
            console.debug("Slide init: Go to %s (%s per page)", initialSlide, perPage);
            sliderRef.current.slickGoTo(initialSlide, true);
        }
    }, [initialSlide, perPage]);

    //#endregion

    const fullSettings: Settings = {
        slidesToShow: perPage,
        slidesToScroll: Children.count(children) < perPage ? 1 : perPage,
        arrows: showArrowsMin ? perPage >= showArrowsMin : true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        speed: 500,

        // NB: Can not use `onReInit` because doing anything inside it will lead to an infinite loop of calling `onReInit`

        ...settings,
    };

    return (
        <div ref={ref}>
            <Slider
                ref={sliderRef}
                className={cn(slideClassName, settings.dots && "slick-with-dots")}
                {...fullSettings}
            >
                {children}
            </Slider>
        </div>
    );
}
