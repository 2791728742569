import D from "./Dashboard.module.css";

import { BadgeDot } from "../../components/Badge.tsx";
import { Separator } from "../../components/Separator.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { adminAllIpPoolsQueryAtom } from "../floating_ips/floatingIpsQuery.ts";
import { BigDonut } from "./DonutChart.tsx";
import { LightBlock } from "./LightBlock.tsx";

function FloatingSectionContent() {
    const ipPools = useSuspenseQueryAtom(adminAllIpPoolsQueryAtom);

    return (
        <>
            <h2 className="font-size-heading">Floating IPs</h2>
            <p className="pb-3">Floating IP pool usage</p>
            <Separator />
            <div className={D.Grid}>
                {ipPools.map((item, index) => {
                    const { location, percent_used, used_ips, total_ips } = item;

                    const items = [
                        { value: used_ips, color: "var(--color-chart-2)" },
                        { value: total_ips - used_ips, color: "var(--color-chart-free)" },
                    ];

                    const tooltipContent = (
                        <div>
                            <BadgeDot color="color-chart-2">
                                Stored: <b>{used_ips}</b>
                            </BadgeDot>

                            <BadgeDot color="color-chart-free">
                                Available: <b>{total_ips - used_ips}</b>
                            </BadgeDot>
                        </div>
                    );

                    return (
                        <BigDonut
                            key={index}
                            items={items}
                            tooltipContent={tooltipContent}
                            total={total_ips}
                            location={location}
                            percentUsed={percent_used}
                        />
                    );
                })}
            </div>
        </>
    );
}

export function FloatingSection() {
    return (
        <LightBlock className={D.Wide}>
            <FloatingSectionContent />
        </LightBlock>
    );
}
