import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { RestrictionLevelClearTopupThreshold } from "@warrenio/api-spec/spec.oats.gen";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";

export type Response = RestrictionLevelClearTopupThreshold;

const queryKey: QueryKey = ["restriction_level_clear_topup_threshold"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> =>
            getResponseData(await client.GET("/config/payment/restriction_level_clear_topup_threshold", { signal })),
    });
}

export const queryAtom = atomFromStandardQueryOptions(getQuery);
