import * as dom from "@testing-library/dom";
import userEvent from "@testing-library/user-event";
import type { BrowserTestContext } from "./BrowserTestContext.ts";

export function testingLibraryContext(canvasElement: HTMLElement = document.body): BrowserTestContext {
    return {
        canvasElement,
        canvas: dom.within(canvasElement),
        userEvent,
        dom,
        step(label, callback) {
            console.debug(`Step: ${label}`);
            return callback();
        },
    };
}
