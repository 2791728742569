import { useQueryClient } from "@tanstack/react-query";
import type { LinkConfigBase, MethodConfig, ProcessorConfig } from "@warrenio/api-spec/spec.oats.gen";
import { useImperativeHandle } from "react";
import { useApiClient } from "../api/apiClient.store.ts";
import { addLinkMethodSimple } from "./choose_method/addMethodUtils.ts";
import { getPaymentMethodId, type LinkMethod, type MethodBase } from "./PaymentMethod.tsx";
import { PaymentLinkIcon } from "./paymentMethodLogic.tsx";

/** @returns Default properties for a payment method (based on standard {@link config} fields) */
export function defaults(config: ProcessorConfig, method?: MethodConfig) {
    const id = getPaymentMethodId(config, method);
    // TODO: tuleb läbi arutada, kas credit card küljes on üldse payment_fee. Hetkel lisasin pricing omise credit card külge service fee confi ja toimib.
    return {
        id,
        fee:
            config.type === "creditcard" && config.service_fee
                ? {
                      service_fee: config.service_fee,
                      charge_currency_conversion: config.charge_currency_conversion,
                  }
                : undefined,
        canAdd: !config.disable_add,
        isAutomated:
            config.type === "invoice" || (config.type === "creditcard" && config?.supports_auto_topup !== false),
        // See on pm meetodi küljes olev props, kus peaks ka conf kätte saadav olema

        name: method ? method.name : config.name,
        icon: (props) => <PaymentLinkIcon icon={id} {...props} />,
    } satisfies Partial<MethodBase>;
}

// Learn more about intersection types here: https://www.typescriptlang.org/docs/handbook/2/objects.html#intersection-types
// string & number = never - ükski väärtus ei saa olla string ja number

/** @returns Default properties for a `link`-type payment method */
export function linkDefaults(config: ProcessorConfig & LinkConfigBase, method: MethodConfig) {
    const base = defaults(config, method);
    const paymentMethodId = base.id;
    // kui ette antud confis on service_fee, siis

    return {
        ...base,
        fee:
            config.service_fee != null || config.charge_currency_conversion != null
                ? {
                      service_fee: config.service_fee,
                      charge_currency_conversion: config.charge_currency_conversion,
                  }
                : undefined,
        type: "link",
        AddForm({ actionsRef }) {
            const queryClient = useQueryClient();
            const apiClient = useApiClient();

            useImperativeHandle(actionsRef, () => ({
                // eslint-disable-next-line @typescript-eslint/require-await
                async validate() {
                    return true;
                },
                async addPaymentMethod(params) {
                    await addLinkMethodSimple(queryClient, apiClient, params, paymentMethodId);
                },
            }));

            return null;
        },
    } satisfies Partial<LinkMethod>;
}
