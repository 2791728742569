/** An atom family keyed by objects. (does not work for scalars eg. strings)
 *
 * Useful for creating atoms based on temporary objects without leaking memory.
 *
 * @__NO_SIDE_EFFECTS__
 */
export function atomFamilyWeak<K extends WeakKey, V>(create: (key: K) => V): (key: K) => V {
    const cache = new WeakMap<K, V>();
    return (key) => {
        let value;
        if (!cache.has(key)) {
            value = create(key);
            cache.set(key, value);
        } else {
            value = cache.get(key)!;
        }
        return value;
    };
}
