import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { jsonBody, successResponse, tagPaths } from "../util.ts";
import {
    billing_account_id_query,
    BillingAccount,
    BillingAccountCard,
    BillingAccountInvoice,
    BillingHistoryListResponse,
    InvoiceId,
    InvoiceTypes,
    RestrictionLevel,
} from "./billing.ts";
import { billing_account_id, datetime_epoch_s, datetime_z, int, simpleSuccessResponse } from "./common.ts";

extendZodWithOpenApi(z);

const InvoiceEmailSendingBody = z
    .object({
        billing_account_id,
        send_invoice_email: z.boolean(),
    })
    .openapi({ ref: "InvoiceEmailSendingBody" });

const CreditAmountBody = z
    .object({
        billing_account_id,
        amount: z.number(),
        description: z.string(),
    })
    .openapi({ ref: "CreditAmountBody" });

const DiscountPercentageBody = z
    .object({
        billing_account_id,
        discount_percentage: z.number(),
    })
    .openapi({ ref: "DiscountPercentageBody" });

const VatPercentageBody = z
    .object({
        billing_account_id,
        vat_percentage: z.number(),
    })
    .openapi({ ref: "VatPercentageBody" });

const ReferralShareCodeBody = z
    .object({
        billing_account_id,
        code: z.string(),
    })
    .openapi({ ref: "ReferralShareCodeBody" });

const ReferralShareCodeResponse = z
    .object({
        referral_share_code: z.string(),
    })
    .openapi({ ref: "ReferralShareCodeResponse" });

const ActivateDeactivateBody = z
    .object({
        billing_account_id,
    })
    .openapi({ ref: "ActivateDeactivateBody" });

const DeleteBillingAccountBody = z
    .object({
        billing_account_id,
    })
    .openapi({ ref: "DeleteBillingAccountBody" });

const PayingByInvoiceBody = z
    .object({
        billing_account_id,
    })
    .openapi({ ref: "PayingByInvoiceBody" });

const DeleteBillingAccountResponse = z
    .object({
        success: z.boolean(),
        is_credit_note_created: z.boolean(),
    })
    .openapi({ ref: "DeleteBillingAccountResponse" });

const AccountClosingInvoicesResponses = z
    .object({
        success: z.boolean(),
        created_invoices_ids: z.array(z.number()),
    })
    .openapi({ ref: "AccountClosingInvoicesResponses" });

const SuspensionReasonBody = z
    .object({
        billing_account_id,
        reason: z.string(),
    })
    .openapi({ ref: "SuspensionReasonBody" });

const RestrictionLevelThresholds = z
    .array(z.object({ days: z.number(), restriction_level: RestrictionLevel }))
    .openapi({ ref: "RestrictionLevelThresholds" });

const GeneralSettings = z
    .object({
        first_account_credit: z.number(),
        min_topup_amount: z.number(),
        default_active_post_payment_level: RestrictionLevel,
        restriction_level_clear_topup_threshold: z.number().nullable(),
        restriction_level_thresholds: RestrictionLevelThresholds,
        low_balance_notice_threshold: z.number().nullable(),
    })
    .openapi({ ref: "GeneralSettings" });

const FirstAccountCredit = z
    .object({
        first_account_credit: z.number(),
    })
    .openapi({ ref: "FirstAccountCredit" });

const MinTopupAmount = z
    .object({
        min_topup_amount: z.number(),
    })
    .openapi({ ref: "MinTopupAmount" });

const DefaultActivePostPaymentLevel = z
    .object({
        default_active_post_payment_level: RestrictionLevel,
    })
    .openapi({ ref: "DefaultActivePostPaymentLevel" });

const LowBalanceNoticeThreshold = z
    .object({
        low_balance_notice_threshold: z.number().nullable(),
    })
    .openapi({ ref: "LowBalanceNoticeThreshold" });

const LowBalanceNoticeThresholdBody = z
    .object({
        threshold: z.number().nullable().optional(),
    })
    .openapi({ ref: "LowBalanceNoticeThresholdBody" });

const UsageBasedRevenue = z
    .object({
        period_start_month: datetime_z,
        subtotal: z.number(),
        type: z.string(),
        product_type: z.string().optional(),
        location_slug: z.string().optional(),
    })
    .openapi({ ref: "UsageBasedRevenue" });

const MonthlyArpa = z
    .object({
        period_start_month: datetime_epoch_s,
        paying_ba_count: z.number(),
        arpa: z.number(),
    })
    .openapi({ ref: "MonthlyArpa" });

const InvoiceTotals = z
    .object({
        period_start_month: datetime_epoch_s,
        subtotal: z.number(),
        discount_amount: z.number(),
        credit: z.number(),
        total_before_tax: z.number(),
        vat_tax: z.number(),
        total: z.number(),
        type: InvoiceTypes,
    })
    .openapi({ ref: "InvoiceTotals" });

const BillingAccountStats = z
    .object({
        total_first_billing_accounts: z.number(),
        last_x_days_first_billing_accounts: z.array(
            z.object({
                days: z.number(),
                users: z.number(),
            }),
        ),
        first_billing_accounts_monthly: z.array(
            z.object({
                month: datetime_epoch_s,
                users: z.number(),
            }),
        ),
        total_financial_commitment: z.number(),
        last_x_days_financial_commitment: z.array(
            z.object({
                days: z.number(),
                users: z.number(),
            }),
        ),
        financial_commitment_monthly: z.array(
            z.object({
                month: datetime_epoch_s,
                users: z.number(),
            }),
        ),
    })
    .openapi({ ref: "BillingAccountStats" });

const level_override_query = z.string().openapi({ param: { name: "level_override", in: "query" } });

/* XXX: needs to be updated to billing_account_id in BE */
const billingAccountId_query = int.openapi({ param: { name: "billingAccountId", in: "query" } });

export const billingAdminPaths = tagPaths("admin_billing")({
    /* Admin paths */
    "/payment/admin/billing_account/send_invoice_email": {
        put: {
            summary: "Enable/disable invoice email sending",
            requestBody: jsonBody(InvoiceEmailSendingBody),
            responses: {
                ...successResponse(BillingAccount),
            },
        },
    },

    "/payment/credit": {
        post: {
            summary: "Add or remove credit",
            requestBody: jsonBody(CreditAmountBody),
            responses: {
                ...successResponse(BillingHistoryListResponse),
            },
        },
    },

    "/payment/admin/billing_account/discount": {
        put: {
            summary: "Update discount percentage",
            requestBody: jsonBody(DiscountPercentageBody),
            responses: {
                ...successResponse(BillingAccount),
            },
        },
    },

    "/payment/admin/billing_account/vat": {
        put: {
            summary: "Update vat percentage",
            requestBody: jsonBody(VatPercentageBody),
            responses: {
                ...successResponse(BillingAccount),
            },
        },
    },

    "/payment/admin/billing_account/restriction_open_level_override": {
        put: {
            summary: "Update restriction level",
            parameters: [billing_account_id_query, level_override_query],
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },

    "/payment/admin/billing_account/referral_share_code": {
        put: {
            summary: "Update referral share code",
            requestBody: jsonBody(ReferralShareCodeBody),
            responses: {
                ...successResponse(ReferralShareCodeResponse),
            },
        },
    },

    "/payment/billing_account/activate": {
        put: {
            summary: "Activate billing account",
            requestBody: jsonBody(ActivateDeactivateBody),
            responses: {
                ...successResponse(BillingAccount),
            },
        },
    },

    "/payment/billing_account/deactivate": {
        put: {
            summary: "Deactivate billing account",
            requestBody: jsonBody(ActivateDeactivateBody),
            responses: {
                ...successResponse(BillingAccount),
            },
        },
    },

    "/payment/admin/set_suspend_reason": {
        put: {
            summary: "Set suspension reason",
            requestBody: jsonBody(SuspensionReasonBody),
            responses: {
                ...successResponse(BillingAccount),
            },
        },
    },

    "/payment/paying_by_invoice": {
        post: {
            summary: "Enable paying by invoice",
            requestBody: jsonBody(PayingByInvoiceBody),
            responses: {
                ...successResponse(BillingAccountCard),
            },
        },
        delete: {
            summary: "Disable paying by invoice",
            requestBody: jsonBody(PayingByInvoiceBody),
            responses: {
                ...successResponse(BillingAccountCard),
            },
        },
    },

    "/charging/admin/accountClosingInvoices": {
        post: {
            summary: "Close open invoices",
            parameters: [billingAccountId_query],
            responses: {
                ...successResponse(AccountClosingInvoicesResponses),
            },
        },
    },

    "/payment/admin/billing_account": {
        delete: {
            summary: "Delete billing account",
            requestBody: jsonBody(DeleteBillingAccountBody),
            responses: {
                ...successResponse(DeleteBillingAccountResponse),
            },
        },
    },

    "/payment/admin/invoices/{invoice_id}": {
        get: {
            summary: "get billing account invoice",
            parameters: [z.string().openapi({ param: { name: "invoice_id", in: "path" } })],
            responses: {
                ...successResponse(BillingAccountInvoice),
            },
        },
    },

    "/payment/admin/invoice/pdf": {
        get: {
            summary: "Download invoice PDF",
            parameters: [InvoiceId.openapi({ param: { name: "invoice_id", in: "query" } })],
            responses: {
                200: { content: { "application/pdf": {} } },
            },
        },
    },

    // Admin
    "/config/payment": {
        get: {
            summary: "get admin general settings",
            responses: {
                ...successResponse(GeneralSettings),
            },
        },
    },
    "/config/payment/first_account_credit": {
        put: {
            summary: "Update first account credit",
            requestBody: jsonBody(FirstAccountCredit),
            responses: {
                ...successResponse(FirstAccountCredit),
            },
        },
    },
    "/config/payment/min_topup_amount": {
        put: {
            summary: "Update min topup amount",
            requestBody: jsonBody(MinTopupAmount),
            responses: {
                ...successResponse(MinTopupAmount),
            },
        },
    },
    "/config/payment/default_active_post_payment_level": {
        put: {
            summary: "Update default active post payment level",
            requestBody: jsonBody(DefaultActivePostPaymentLevel),
            responses: {
                ...successResponse(DefaultActivePostPaymentLevel),
            },
        },
    },
    "/config/payment/restriction_level_thresholds": {
        put: {
            summary: "Update restriction level thresholds",
            requestBody: jsonBody(RestrictionLevelThresholds),
            responses: {
                ...successResponse(RestrictionLevelThresholds),
            },
        },
    },
    "/config/payment/low_balance_notice_threshold": {
        put: {
            summary: "Update low balance notice threshold",
            requestBody: jsonBody(LowBalanceNoticeThresholdBody),
            responses: {
                ...successResponse(LowBalanceNoticeThreshold),
            },
        },
    },

    "/charging/admin/monthly_subtotals": {
        get: {
            summary: "Get usage based revenue",
            parameters: [
                { name: "split", in: "query" },
                { name: "limit_months", in: "query" },
            ],
            responses: {
                ...successResponse(z.array(UsageBasedRevenue)),
            },
        },
    },

    "/payment/admin/invoice_totals": {
        get: {
            summary: "Get invoice totals",
            responses: {
                ...successResponse(z.array(InvoiceTotals)),
            },
        },
    },

    "/payment/admin/billing_account_stats": {
        get: {
            summary: "Get billing account stats",
            parameters: [{ name: "limit_months", in: "query" }],
            responses: {
                ...successResponse(BillingAccountStats),
            },
        },
    },

    "/payment/admin/monthly_arpa": {
        get: {
            summary: "Get customers ARPA & Paying BA",
            parameters: [
                { name: "created_after", in: "query" },
                { name: "allow_debt", in: "query" },
            ],
            responses: {
                ...successResponse(z.array(MonthlyArpa)),
            },
        },
    },
});
