import { mustGetProperty } from "@warrenio/utils/collections/getOwnProperty";
import type { ReactNode } from "react";
import { useFormState, type FieldValues } from "react-hook-form";
import { WSubmitButton, type WSubmitButtonProps } from "../../components/button/WButton.tsx";
import { WHookForm, type WHookFormProps } from "../../components/forms/WHookForm.tsx";
import {
    WModalContentSimple,
    WModalFooter,
    type WModalContentSimpleProps,
    type WModalFooterProps,
} from "../../components/modal/WModal.tsx";
import { entity, type EntityType } from "../entity.ts";

interface FormParams {
    entityType: EntityType;
    isUpdate: boolean;
}

function formProps({ entityType, isUpdate }: FormParams) {
    const e = mustGetProperty(entity, entityType);
    return {
        label: isUpdate ? "Edit" : "Add",
        title: isUpdate ? e.editLabel : e.addLabel,
    };
}

function WFormSubmitButton(props: WSubmitButtonProps) {
    const { isSubmitting } = useFormState();
    return <WSubmitButton isLoading={isSubmitting} {...props} />;
}

export interface WAdminModalCrudFormProps<T extends FieldValues>
    extends FormParams,
        Omit<WAdminModalFormProps<T>, "title" | "label"> {}

export function WAdminModalCrudForm<T extends FieldValues>({
    children,
    entityType,
    isUpdate,
    ...props
}: WAdminModalCrudFormProps<T>) {
    const entityProps = formProps({ entityType, isUpdate });

    return (
        <WAdminModalForm {...entityProps} {...props}>
            {children}
        </WAdminModalForm>
    );
}

interface WAdminModalFormProps<T extends FieldValues> extends Pick<WModalFooterProps, "footerNotice"> {
    title: WModalContentSimpleProps["title"];
    label: WSubmitButtonProps["label"];

    onSubmit: WHookFormProps<T>["onSubmit"];
    form: WHookFormProps<T>["form"];

    children: ReactNode;

    formProps?: Omit<WHookFormProps<T>, "title" | "form">;
    buttonProps?: Omit<WSubmitButtonProps, "label">;
    modalProps?: Omit<WModalContentSimpleProps, "children" | "title">;
}

export function WAdminModalForm<T extends FieldValues>({
    children,
    onSubmit,
    footerNotice,
    buttonProps,
    form,
    title,
    label,
    ...props
}: WAdminModalFormProps<T>) {
    return (
        <WModalContentSimple title={title}>
            {(close) => (
                <WHookForm
                    form={form}
                    onSubmit={async (v) => {
                        await onSubmit(v);

                        // NB: This will not be executed if onSubmit throws
                        close();
                    }}
                    {...props}
                >
                    {/* NB: Assumes children will have their own padding */}
                    {children}
                    <WModalFooter footerNotice={footerNotice}>
                        <WFormSubmitButton label={label} {...buttonProps} />
                    </WModalFooter>
                </WHookForm>
            )}
        </WModalContentSimple>
    );
}
