import C from "./NoticeBlock.module.css";

import type { ReactNode } from "react";
import { mcn, type BaseProps } from "../utils/baseProps.ts";
import { cn } from "../utils/classNames.ts";
import { MaskIcon } from "./icon/MaskIcon.tsx";

const iconColors = {
    primary: "color-primary",
    error: "color-error",
    success: "color-success",
};

export interface NoticeBlockProps extends BaseProps {
    children: ReactNode;

    color?: "primary" | "error" | "success";
    icon?: string;
    button?: ReactNode;
    compact?: boolean;
    full?: boolean;
}

export function NoticeBlock({ children, color = "primary", icon, button, compact, full, ...props }: NoticeBlockProps) {
    return (
        <div {...mcn(cn(C.NoticeBlock, C[color], compact && C.compact, full && C.full), props)}>
            {icon ? <MaskIcon className={cn(icon, C.Icon, iconColors[color])} /> : null}
            <div className={C.Content}>{children}</div>
            {button && <div className={C.Button}>{button}</div>}
        </div>
    );
}
