import type { QueryKey } from "@tanstack/react-query";
import { queryOptions } from "@tanstack/react-query";
import type {
    ActivateDeactivateBody,
    BillingAccount,
    BillingAccountStats,
    CreditAmountBody,
    DeleteBillingAccountBody,
    DiscountPercentageBody,
    InvoiceEmailSendingBody,
    InvoiceTotals,
    MonthlyArpa,
    PayingByInvoiceBody,
    ReferralShareCodeBody,
    SuspensionReasonBody,
    UsageBasedRevenue,
    VatPercentageBody,
} from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient.ts";
import { getResponseData } from "../../modules/api/apiClient.ts";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { atomFamilyMulti } from "../../utils/jotai/atomFamilyMulti.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { intensiveQueryOptions } from "../intensiveQueryOptions.ts";

//#region Query and parameters
export interface Params {
    split?: boolean;
    limit_months?: number;
    created_after?: string;
    allow_debt?: boolean;
}

export function getUsageQueryKey(params?: Params): QueryKey {
    return params == null
        ? ["charging/admin/monthly_subtotals"]
        : ["charging/admin/monthly_subtotals", params.split, params.limit_months];
}

export function getBillingAccountStatsQueryKey(params?: Params): QueryKey {
    return params == null
        ? ["payment/admin/billing_account_stats"]
        : ["payment/admin/billing_account_stats", params.limit_months];
}

export function getMonthlyArpaQueryKey(params?: Params): QueryKey {
    return params == null
        ? ["payment/admin/monthly_arpa"]
        : ["payment/admin/monthly_arpa", params.created_after, params.allow_debt];
}

const queryKey: QueryKey = ["payment/admin/invoice_totals"];

export function getUsageQuery(client: ApiClient, params: Params) {
    return queryOptions({
        ...intensiveQueryOptions,
        queryKey: getUsageQueryKey(params),
        queryFn: async ({ signal }): Promise<UsageBasedRevenue[]> => {
            const { split, limit_months } = params;
            return getResponseData(
                await client.GET("/charging/admin/monthly_subtotals", {
                    signal,
                    params: { query: { split: String(split), limit_months: String(limit_months) } },
                }),
            );
        },
    });
}

export function getInvoiceTotalsQuery(client: ApiClient) {
    return queryOptions({
        ...intensiveQueryOptions,
        queryKey,
        queryFn: async ({ signal }): Promise<InvoiceTotals[]> => {
            return getResponseData(
                await client.GET("/payment/admin/invoice_totals", {
                    signal,
                }),
            );
        },
    });
}

export function getBillingAccountStatsQuery(client: ApiClient, params: Params) {
    return queryOptions({
        ...intensiveQueryOptions,
        queryKey: getBillingAccountStatsQueryKey(params),
        queryFn: async ({ signal }): Promise<BillingAccountStats> => {
            const { limit_months } = params;
            return getResponseData(
                await client.GET("/payment/admin/billing_account_stats", {
                    signal,
                    params: { query: { limit_months: String(limit_months) } },
                }),
            );
        },
    });
}

export function getMonthlyArpaQuery(client: ApiClient, params: Params) {
    return queryOptions({
        ...intensiveQueryOptions,
        queryKey: getMonthlyArpaQueryKey(params),
        queryFn: async ({ signal }): Promise<MonthlyArpa[]> => {
            const { created_after, allow_debt } = params;
            return getResponseData(
                await client.GET("/payment/admin/monthly_arpa", {
                    signal,
                    params: { query: { created_after: String(created_after), allow_debt: String(allow_debt) } },
                }),
            );
        },
    });
}
//#endregion

//#region Atoms
export const usageBasedRevenueQueryAtom = atomFamilyMulti((split: boolean, limit_months: number) =>
    atomFromStandardQueryOptions(getUsageQuery, { split, limit_months }),
);

export const invoiceTotalsQueryAtom = atomFromStandardQueryOptions(getInvoiceTotalsQuery);

export const billingAccountStatsQueryAtom = atomFamilyMulti((limit_months: number) =>
    atomFromStandardQueryOptions(getBillingAccountStatsQuery, { limit_months }),
);

export const monthlyArpaQueryAtom = atomFamilyMulti((created_after: string, allow_debt: boolean) =>
    atomFromStandardQueryOptions(getMonthlyArpaQuery, { created_after, allow_debt }),
);
//#endregion

//#region Mutations

export const creditAmountMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: CreditAmountBody }) =>
        await apiClient.POST("/payment/credit", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const invoiceEmailSendingMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: InvoiceEmailSendingBody }) =>
        await apiClient.PUT("/payment/admin/billing_account/send_invoice_email", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const discountPercentageMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: DiscountPercentageBody }) =>
        await apiClient.PUT("/payment/admin/billing_account/discount", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const vatPercentageMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: VatPercentageBody }) =>
        await apiClient.PUT("/payment/admin/billing_account/vat", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const restrictionLevelMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ id, level_override }: { id: BillingAccount["id"]; level_override: string }) =>
        await apiClient.PUT("/payment/admin/billing_account/restriction_open_level_override", {
            params: { query: { billing_account_id: id, level_override: level_override } },
        }),
}));

export const referralShareCodeMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: ReferralShareCodeBody }) =>
        await apiClient.PUT("/payment/admin/billing_account/referral_share_code", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const activateMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: ActivateDeactivateBody }) =>
        await apiClient.PUT("/payment/billing_account/activate", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const deactivateMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: ActivateDeactivateBody }) =>
        await apiClient.PUT("/payment/billing_account/deactivate", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const suspensionReasonMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: SuspensionReasonBody }) =>
        await apiClient.PUT("/payment/admin/set_suspend_reason", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const accountClosingInvoicesMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ id }: { id: BillingAccount["id"] }) =>
        await apiClient.POST("/charging/admin/accountClosingInvoices", {
            params: { query: { billingAccountId: id } },
        }),
}));

export const deleteMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "delete",
    requestFn: async ({ body }: { body: DeleteBillingAccountBody }) =>
        await apiClient.DELETE("/payment/admin/billing_account", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const enableInvoiceMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: PayingByInvoiceBody }) =>
        await apiClient.POST("/payment/paying_by_invoice", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const disableInvoiceMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: PayingByInvoiceBody }) =>
        await apiClient.DELETE("/payment/paying_by_invoice", {
            ...jsonEncodedBody,
            body,
        }),
}));
//#endregion Mutations
