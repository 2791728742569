import MLC from "../modules/main/MainLayout.module.css";
import TNC from "../modules/main/TopNav.module.css";
import C from "./AdminLayout.module.css";

import { Link, type LinkProps, useMatchRoute } from "@tanstack/react-router";
import { useAtom, useAtomValue } from "jotai/react";
import { atom, type Atom } from "jotai/vanilla";
import React, { type ReactNode, Suspense, useMemo, useState } from "react";
import { WButton } from "../components/button/WButton.tsx";
import { MaskIcon } from "../components/icon/MaskIcon.tsx";
import { Spacer } from "../components/Spacer.tsx";
import { configAtom } from "../config.ts";
import { getResourceIconClass, type ResourceType, resourceTypeEnabledAtom } from "../modules/api/resourceTypes.tsx";
import { IsolatedChildren } from "../modules/error/Isolated.tsx";
import { LayoutContent } from "../modules/main/LayoutContent.tsx";
import { showOnlyContentAtom } from "../modules/main/MainLayout.store.ts";
import { userIsAdminAtom } from "../modules/user/apiOperations.ts";
import { type BaseProps, mcn } from "../utils/baseProps.ts";
import { cn } from "../utils/classNames.ts";
import { sideNavCompactAtom } from "./AdminLayout.store.ts";

const hasDebugBar = !import.meta.env.PROD;

const ColorSchemeMenu = React.lazy(() => import("../modules/main/ColorSchemeMenu.tsx"));
const NotificationMenu = React.lazy(() => import("../modules/notifications/NotificationMenu.tsx"));
const UserMenu = React.lazy(() => import("../modules/user/UserMenu.tsx"));
const DebugButton = React.lazy(() => import("../modules/main/debug/DebugButton.tsx"));
const DebugBar = React.lazy(() => import("../modules/main/debug/DebugMenu.tsx"));

export function AdminRootLayout({ children }: { children: ReactNode }) {
    //#region Hooks
    const isAdmin = useAtomValue(userIsAdminAtom);

    const showOnlyContent = useAtomValue(showOnlyContentAtom);
    //#endregion

    // TODO: Move this check to router
    if (!isAdmin) {
        return <div className="text-error">This page is only for administrator users</div>;
    }

    if (showOnlyContent) {
        return children;
    }

    return (
        <div className="size-full flex flex-col">
            <AdminTopNav />
            <div className={cn(MLC.mainArea)}>
                <AdminSideNav />
                {children}
            </div>
        </div>
    );
}

/** Scrollable pane for displaying main content */
export function AdminScrollPane({ children }: { children: ReactNode }) {
    return (
        <LayoutContent
            className={cn(
                "flex-grow overflow-y-auto contain-strict",
                // Needed for sticky headers
                "relative overflow-x-auto",
            )}
        >
            {children}
        </LayoutContent>
    );
}

export function AdminTopNav(props: BaseProps) {
    const header = (
        <header {...mcn(cn("HStack", TNC.TopNav, "contain-content"), props)}>
            <Logo />
            <Spacer />
            <IsolatedChildren>
                <NotificationMenu />
                <ColorSchemeMenu />
                {hasDebugBar && <DebugButton />}
                <UserMenu />
            </IsolatedChildren>
        </header>
    );

    return (
        <>
            {header}
            {hasDebugBar && (
                <Suspense>
                    <DebugBar />
                </Suspense>
            )}
        </>
    );
}

function Logo() {
    return <Link to="/admin" aria-label="Home" className={`bg-icon ${TNC.logo} mr-2`} />;
}

interface MenuItem {
    id: string;
    title: string;
    icon: string;
    enabledAtom?: Atom<boolean>;
    to?: LinkProps["to"];
    childItems?: MenuItem[];
}

function resourceItem(resourceType: ResourceType) {
    return {
        icon: getResourceIconClass(resourceType),
        enabledAtom: resourceTypeEnabledAtom(resourceType),
    } satisfies Partial<MenuItem>;
}

const menuItemsDefinition: MenuItem[] = [
    {
        id: "dashboard",
        title: "Dashboard",
        icon: "jp-view-apps-icon",
        to: "/admin",
    },
    {
        // Only show when VMs are enabled
        ...resourceItem("virtual_machine"),
        id: "compute",
        title: "Compute",
        icon: "jp-compute-cloud-icon",
        childItems: [
            {
                ...resourceItem("virtual_machine"),
                id: "virtual_machines",
                title: "Virtual Machines",
                to: "/admin/virtual_machines",
            },
            {
                id: "hosts",
                title: "Hypervisors",
                icon: "jp-host-icon",
                to: "/admin/hosts",
            },
            {
                id: "host_pools",
                title: "Hypervisor Pools",
                icon: "jp-hostpool-icon",
                to: "/admin/host_pools",
            },
        ],
    },
    {
        id: "metal",
        title: "Bare metal",
        icon: "jp-host-icon",
        enabledAtom: atom((get) => get(configAtom).bareMetalEnabled),
        childItems: [
            {
                id: "metal/machines",
                title: "Machines",
                icon: "jp-host-icon",
                to: "/admin/metal/machines",
            },
            {
                id: "metal/specs",
                title: "Machine Types",
                icon: "jp-hostpool-icon",
                to: "/admin/metal/specs",
            },
            {
                id: "metal/os",
                title: "Operating Systems",
                icon: "jp-linux-icon",
                to: "/admin/metal/os",
            },
        ],
    },
    {
        id: "network",
        title: "Network",
        icon: "jp-network-icon",
        childItems: [
            {
                ...resourceItem("load_balancer"),
                id: "load_balancers",
                title: "Load Balancers",
                to: "/admin/load_balancers",
            },
            {
                ...resourceItem("ip_address"),
                id: "floating_ips",
                title: "Floating IPs",
                to: "/admin/floating_ips",
            },
        ],
    },
    {
        ...resourceItem("bucket"),
        id: "buckets",
        title: "Object Storage",
        to: "/admin/buckets",
    },
    {
        ...resourceItem("managed_service"),
        id: "service_packages",
        title: "Services",
        to: "/admin/service_packages",
    },
    {
        // Only show when VMs are enabled
        ...resourceItem("virtual_machine"),
        id: "vm_images",
        title: "Images",
        icon: "jp-disc-icon",
        to: "/admin/vm_images",
    },
    {
        id: "users",
        title: "Users",
        icon: "jp-user-icon",
        childItems: [
            {
                id: "users",
                title: "Users",
                icon: "jp-user-group-icon",
                to: "/admin/users",
            },
            {
                id: "admin_users",
                title: "Admin Users",
                icon: "jp-user-id-icon",
                to: "/admin/admin_users",
            },
        ],
    },
    {
        ...resourceItem("billing_account"),
        id: "accounts",
        title: "Billing Accounts",
        to: "/admin/accounts",
    },
    {
        id: "reports",
        title: "Invoices & Reports",
        icon: "jp-to-do-icon",
        to: "/admin/reports",
    },
    {
        id: "prices",
        title: "Price Lists",
        icon: "jp-tags-icon",
        to: "/admin/prices",
    },
    {
        id: "marketing",
        title: "Marketing",
        icon: "jp-broadcast-icon",
        childItems: [
            {
                id: "campaigns",
                title: "Campaigns",
                icon: "jp-ticket-icon",
                to: "/admin/campaigns",
            },
            {
                id: "referral",
                title: "Referral Program",
                icon: "jp-trophy-icon",
                to: "/admin/referral",
            },
        ],
    },
    {
        id: "locations",
        title: "Locations",
        icon: "jp-web-icon",
        to: "/admin/locations",
    },
    {
        id: "settings",
        title: "Settings",
        icon: "i-lucide:settings",
        childItems: [
            {
                id: "general",
                title: "General",
                icon: "jp-icon-toggles",
                to: "/admin/general",
            },
            {
                // Only show when VMs are enabled
                ...resourceItem("virtual_machine"),
                id: "vm_conf",
                title: "Network",
                icon: "jp-network-icon",
                to: "/admin/vm_conf",
            },
            {
                id: "tracking",
                title: "Tracking Codes",
                icon: "i-lucide:cookie",
                to: "/admin/tracking",
            },
            {
                id: "configuration",
                title: "Platform Configuration",
                icon: "i-lucide:settings",
                to: "/admin/configuration",
            },
        ],
    },
];

const menuItemsAtom = atom((get) =>
    menuItemsDefinition
        .filter(({ enabledAtom }) => !enabledAtom || get(enabledAtom))
        .map((item) => ({
            ...item,
            childItems: item.childItems?.filter(({ enabledAtom }) => !enabledAtom || get(enabledAtom)),
        }))
        // Hide empty sections
        .filter(({ childItems }) => childItems == null || childItems.length > 0),
);

const commonIconClassName = "size-0.875rem color-primary";

function NavLink({ item: { icon, title, to }, ...props }: LinkProps & BaseProps & { item: MenuItem }) {
    return (
        <Link activeOptions={{ exact: true }} to={to} {...mcn(C.SideNavButton, props)}>
            <MaskIcon className={`${icon} ${commonIconClassName}`} />
            <span className={C.Label}>{title}</span>
        </Link>
    );
}

function AdminSideNav(props: BaseProps) {
    //#region Hooks
    const matchRoute = useMatchRoute();
    const menuItems = useAtomValue(menuItemsAtom);

    // Check if any child items are currently active
    const childrenActive = useMemo(
        () => menuItems.filter((item) => item.childItems?.some(({ to }) => matchRoute({ to }))).map((item) => item.id),
        [matchRoute, menuItems],
    );

    const [isCompact, setIsCompact] = useAtom(sideNavCompactAtom);
    const [isOpen, setIsOpen] = useState<string[]>(childrenActive);
    //#endregion

    const toggleOpen = (value: string) => {
        const found = isOpen.find((item) => item === value);
        if (found) {
            setIsOpen(isOpen.filter((item) => item !== value));
        } else {
            setIsOpen([...isOpen, value]);
        }
    };

    const hasOpen = (value: string) => {
        return isOpen.some((item) => item === value);
    };

    const menus = menuItems.map((item) => {
        const { title, icon, id, childItems } = item;
        if (childItems?.length) {
            const isOpen = hasOpen(id);
            const isActive = isOpen || childrenActive.includes(id);
            return (
                <div key={id} className={cn(C.SideNavHolder, hasOpen(id) && C.isOpen)}>
                    <div className={cn(C.SideNavHeader, isActive && "color-primary")} onClick={() => toggleOpen(id)}>
                        <MaskIcon className={cn(icon, commonIconClassName)} />
                        <span className={C.Label}>{title}</span>
                        <MaskIcon
                            className={cn(
                                isOpen ? "i-lucide:chevron-up" : "i-lucide:chevron-down",
                                isCompact ? C.Icon : "size-0.875rem",
                                "color-primary",
                            )}
                        />
                    </div>

                    <div style={isOpen ? {} : { visibility: "hidden", height: "0" }}>
                        {childItems.map((child) => (
                            <NavLink key={child.id} item={child} />
                        ))}
                    </div>
                </div>
            );
        } else {
            return <NavLink key={id} item={item} />;
        }
    });

    return (
        <nav {...mcn(cn(C.SideNav, "contain-content", isCompact && C.Compact), props)}>
            {menus}

            <div className={C.Footer}>
                <WButton
                    color="primary"
                    size="bar"
                    variant="ghost"
                    icon={isCompact ? "i-lucide:chevron-right" : "i-lucide:chevron-left"}
                    action={() => setIsCompact(!isCompact)}
                />
            </div>
        </nav>
    );
}
