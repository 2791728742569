import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { formBody, jsonBody, successResponse, tagPaths } from "../util.ts";
import { created_at, simpleSuccessResponse, uuid, vm_uuid } from "./common.ts";
import * as params from "./params.ts";
import {
    VirtualMachine,
    VirtualMachineStorage,
    VmDeleteBody,
    VmImage,
    VmRescueStartBody,
    VmResizeDiskBody,
    VmStartBody,
    VmStopBody,
} from "./vm.ts";

extendZodWithOpenApi(z);

//#region VM conf

export const VmConf = z
    .object({
        average_kbps: z.number(),
        peak_kbps: z.number(),
        burst_kb: z.number(),
    })
    .openapi({ ref: "VmConf" });

export const VmConfBody = z
    .object({
        average_kbps: z.number(),
        peak_kbps: z.number().optional(),
        burst_kb: z.number().optional(),
    })
    .openapi({ ref: "VmConfBody" });

export const AdminVmConfBody = z
    .object({
        uuid,
        average_kbps: z.number().optional(),
        peak_kbps: z.number().optional(),
        burst_kb: z.number().optional(),
    })
    .openapi({ ref: "AdminVmConfBody" });
//#endregion

const AdminVirtualMachine = VirtualMachine.extend({
    designated_pool_id: z.number(),
    hypervisor_uuid: uuid,
    is_current_pool_visible: z.boolean(),
    is_designated_pool_visible: z.boolean(),
    latest_host_pool_uuid: uuid,
    service_uuid: uuid.optional(),
}).openapi({ ref: "AdminVirtualMachine" });

export const VmMigrateBody = z
    .object({ uuid: vm_uuid, pool_uuid: uuid.optional(), hv_uuid: uuid.optional() })
    .openapi({ ref: "VmMigrateBody" });

export const VmDesignatedBody = z
    .object({ uuid: vm_uuid, designated_pool_uuid: uuid })
    .openapi({ ref: "VmDesignatedBody" });

export const AdminVmLimits = z
    .object({
        vcpu: z.number(),
        ram_mb: z.number(),
    })
    .openapi({ ref: "AdminVmLimits" });

export const VmActionLog = z
    .object({
        action: z.string(),
        created_at,
        guest_uuid: uuid,
        acting_user_id: z.number().optional(),
        host_uuid: uuid,
        hypervisor_uuid: uuid,
    })
    .openapi({ ref: "VmActionLog" });

export const VmActionLogResponse = z.array(VmActionLog).openapi({ ref: "VmActionLogResponse" });

const nestedStatisticsSchema = z.object({
    running: z.number(),
    stopped: z.number(),
});

export const VmAdminStatistics = z
    .object({
        by_cpu: z.record(z.string(), nestedStatisticsSchema),
        by_mem: z.record(z.string(), nestedStatisticsSchema),
        by_os: z.record(z.string(), nestedStatisticsSchema),
    })
    .openapi({ ref: "VmAdminStatistics" });

export const vmAdminPaths = tagPaths("admin_vm")({
    // Admin VM conf
    "/config/network/iftune": {
        get: {
            summary: "Get VM conf",
            responses: {
                ...successResponse(VmConf),
            },
        },
        put: {
            summary: "Update VM conf",
            requestBody: jsonBody(VmConfBody),
            responses: {
                ...successResponse(VmConf),
            },
        },
    },

    // Admin VM
    "/{location}/user-resource/admin/vm": {
        get: {
            summary: "Get virtual machine",
            parameters: [params.location, { name: "uuid", in: "query" }],
            responses: {
                ...successResponse(AdminVirtualMachine),
            },
        },
        delete: {
            summary: "Delete a virtual machine",
            parameters: [params.location],
            requestBody: jsonBody(VmDeleteBody),
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },
    "/{location}/user-resource/admin/vm/start": {
        post: {
            summary: "Start virtual machine",
            parameters: [params.location],
            requestBody: formBody(VmStartBody),
            responses: {
                ...successResponse(AdminVirtualMachine),
            },
        },
    },
    "/{location}/user-resource/admin/vm/stop": {
        post: {
            summary: "Stop virtual machine",
            parameters: [params.location],
            requestBody: formBody(VmStopBody),
            responses: {
                ...successResponse(AdminVirtualMachine),
            },
        },
    },

    "/{location}/base-operator/migrate": {
        post: {
            summary: "Migrate virtual machine",
            parameters: [params.location],
            requestBody: formBody(VmMigrateBody),
            responses: {
                ...successResponse(AdminVirtualMachine),
            },
        },
    },

    "/{location}/base-operator/admin/vm/iftune": {
        put: {
            summary: "Update VM conf",
            parameters: [params.location],
            requestBody: jsonBody(AdminVmConfBody),
            responses: {
                // TODO: proper response object
                ...successResponse(z.unknown()),
            },
        },
    },

    "/config/compute/limits/vm": {
        get: {
            summary: "Get VM limits",
            responses: {
                ...successResponse(AdminVmLimits),
            },
        },
        put: {
            summary: "Update VM limits",
            requestBody: jsonBody(AdminVmLimits),
            responses: {
                ...successResponse(AdminVmLimits),
            },
        },
    },

    "/{location}/base-operator/admin/vm/virsh_dumpxml": {
        get: {
            summary: "Get Domain XML",
            parameters: [params.location, { name: "uuid", in: "query" }],
            responses: {
                ...successResponse(z.string()),
            },
        },
    },

    "/{location}/user-resource/admin/vm/designated_pool": {
        patch: {
            summary: "Change designated pool for virtual machine",
            parameters: [params.location],
            requestBody: formBody(VmDesignatedBody),
            responses: {
                ...successResponse(AdminVirtualMachine),
            },
        },
    },

    "/{location}/base-operator/admin/action_log": {
        get: {
            summary: "Get Domain XML",
            parameters: [params.location, { name: "guest_uuid", in: "query" }],
            responses: {
                ...successResponse(VmActionLogResponse),
            },
        },
    },

    "/{location}/base-operator/admin/vm/storage": {
        patch: {
            summary: "Resize a virtual machine disk",
            parameters: [params.location],
            requestBody: formBody(VmResizeDiskBody),
            responses: {
                ...successResponse(VirtualMachineStorage),
            },
        },
    },

    "/{location}/user-resource/admin/vm/rescue_start": {
        post: {
            summary: "Start virtual machine in rescue mode",
            parameters: [params.location],
            requestBody: formBody(VmRescueStartBody),
            responses: {
                ...successResponse(AdminVirtualMachine),
            },
        },
    },

    "/{location}/base-operator/admin/vm/statistics/chart": {
        get: {
            summary: "Get virtual machine statistics",
            parameters: [params.location],
            responses: {
                ...successResponse(VmAdminStatistics),
            },
        },
    },

    // Vm images
    "/config/vm_images/{image_type}": {
        post: {
            summary: "Create new VM image",
            parameters: [{ name: "image_type", in: "path" }],
            requestBody: formBody(VmImage),
            responses: {
                ...successResponse(VmImage),
            },
        },
    },
    "/config/vm_images/{image_type}/{os_name}": {
        patch: {
            summary: "Update VM image",
            parameters: [
                { name: "image_type", in: "path" },
                { name: "os_name", in: "path" },
            ],
            requestBody: formBody(VmImage),
            responses: {
                ...successResponse(VmImage),
            },
        },
        delete: {
            summary: "Delete VM image",
            parameters: [
                { name: "image_type", in: "path" },
                { name: "os_name", in: "path" },
            ],
            responses: {
                ...successResponse(z.boolean()),
            },
        },
    },
});
