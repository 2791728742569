import { partialBind } from "remeda";
import invariant from "tiny-invariant";
import type { BrowserTestContext } from "../../../../dev/BrowserTestContext.ts";

async function fill({ canvas, userEvent }: BrowserTestContext, label: string, value: string) {
    const input = await canvas.findByLabelText(label);
    invariant(input instanceof HTMLInputElement, `Expected ${label} to be an input element`);
    if (input.value !== "") {
        await userEvent.clear(input);
    }
    await userEvent.type(input, value);
}

const cardInfo = {
    success: {
        name: "John Doe",
        number: "4242424242425678",
        expiration: "11/27",
        security_code: "123",
    },
    invalid_number: {
        name: "John Doe",
        number: "12345",
        expiration: "11/27",
        security_code: "123",
    },
};

type CardType = keyof typeof cardInfo;

export async function accountCreateInteract(ctx: BrowserTestContext, cardType: CardType) {
    const { userEvent, canvas, step } = ctx;

    const fill_ = partialBind(fill, ctx);

    await step("Fill in the form", async () => {
        await fill_("Billing account name", "My billing account");
        await fill_("Invoice email", "mock@warren.io");
        await fill_("Contact person's phone", "1234567890");
    });

    await step("Select the Omise payment method", async () => {
        const omiseMethod = await canvas.findByText(/Credit card( \(Omise\)|$)/);
        await userEvent.click(omiseMethod);

        const info = cardInfo[cardType];
        await fill_("Name (Min 4 chars)", info.name);
        await fill_("Card Number", info.number);
        await fill_("Valid thru", info.expiration);
        await fill_("CVC", info.security_code);
    });

    const createButton = await canvas.findByRole("button", { name: "Create" });
    await userEvent.click(createButton);

    await step("Wait for the account to be created", async () => {
        if (cardType === "success") {
            // NB: It takes a while for the popup / verification to progress, so use a longer timeout
            await canvas.findByText("Payment Methods", undefined, { timeout: 15000 });
            await canvas.findByText("Visa*5678", { exact: false });
        } else {
            // Find the error toast
            await canvas.findByText("Adding payment method failed:", { exact: false });
        }
    });
}
