import { Outlet, createRootRoute } from "@tanstack/react-router";
import { NotFoundPage } from "../modules/error/ErrorFallback";

export const Route = createRootRoute({
    component: RootComponent,
    notFoundComponent: NotFoundPage,
});

function RootComponent() {
    return <Outlet />;
}
