import { useState } from "react";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { LocationHostSelectCustom } from "../../modules/location/LocationHostSelect.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { priceLocsAtom } from "./priceLocs.tsx";

export function AddPricesModal({
    onChange,
    disabledKeys,
}: {
    onChange: (value: string) => void;
    disabledKeys: string[];
}) {
    const locs = useSuspenseQueryAtom(priceLocsAtom);
    const [location, setLocation] = useState<string>("");

    return (
        <WModalContent
            title="Add Prices"
            label="Add Prices"
            isActionDisabled={!location}
            modalAction={() => onChange(location)}
        >
            <div className="max-w-24rem">
                <p className="color-muted pb-2">Choose location or server class to add new prices</p>

                <LocationHostSelectCustom
                    disabledKeys={disabledKeys}
                    baseItems={locs.filter((l) => l.type !== "default")}
                    onChange={(_, key) => {
                        setLocation(key);
                    }}
                />

                <p className="color-muted font-size-small pt-2">
                    Each location can have their own price list that applies to all resources from that location.
                    DEFAULT prices are used if location specific prices are not defined. Server class specific price
                    list can be added to have different compute prices in one location.
                </p>
            </div>
        </WModalContent>
    );
}
