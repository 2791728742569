import type { Location } from "@warrenio/api-spec/spec.oats.gen";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WTableBody } from "../../components/table/WTable.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { CountryFlag } from "../../modules/location/CountryFlag.tsx";
import { getIso3CountryInfo } from "../../modules/location/locationCountry.ts";
import { locationDeleteMutation, queryAtom } from "../../modules/location/query.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { WAdminTable } from "../WAdminTable.tsx";
import { AddLocationButton, EditLocationButton } from "./AddEditLocationModal.tsx";

export function LocationsView() {
    const deleteMutation = useStandardMutation(locationDeleteMutation);
    const locations = useSuspenseQueryAtom(queryAtom);

    async function onDelete({ slug }: Location) {
        await deleteMutation.mutateAsync({ location: slug });
    }

    return (
        <>
            <AdminTitle title="Locations">
                <AddLocationButton button={<WModalButton label="New" color="primary" size="bar" variant="basic" />} />
            </AdminTitle>

            <WAdminTable>
                <thead>
                    <tr>
                        <th>Order Nr</th>
                        <th>Slug</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Create Resource Disabled</th>
                        <th>Is Preferred</th>
                        <th>Is Published</th>
                        <th>Country</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {locations
                        .sort((a, b) => a.order_nr - b.order_nr)
                        .map((item) => {
                            const {
                                order_nr,
                                country_code,
                                create_resource_disabled,
                                description,
                                display_name,
                                is_preferred,
                                is_published,
                                slug,
                            } = item;

                            const country = getIso3CountryInfo(country_code);

                            return (
                                <tr key={slug}>
                                    <td>{order_nr}</td>
                                    <td>{slug}</td>
                                    <td>{display_name}</td>
                                    <td>{description}</td>
                                    <td>
                                        {create_resource_disabled && (
                                            <MaskIcon className="jp-icon-checkmark color-text size-1rem" />
                                        )}
                                    </td>
                                    <td>
                                        {is_preferred && (
                                            <MaskIcon className="jp-icon-checkmark color-text size-1rem" />
                                        )}
                                    </td>
                                    <td>
                                        {is_published && (
                                            <MaskIcon className="jp-icon-checkmark color-text size-1rem" />
                                        )}
                                    </td>
                                    <td>
                                        <CountryFlag code={country_code} /> {country?.name}
                                    </td>
                                    <td className="text-right">
                                        <div className="flex gap-0.5em justify-end">
                                            <EditLocationButton
                                                item={item}
                                                button={<WModalButton label="Edit" inTable icon="jp-icon-edit" />}
                                            />

                                            <DeleteModal
                                                title="Delete Location"
                                                inTable
                                                modalAction={async () => await onDelete(item)}
                                            >
                                                Are you sure you want to delete location "<b>{display_name}</b>"?
                                            </DeleteModal>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </WTableBody>
            </WAdminTable>

            <div className="p-2">
                <AddLocationButton
                    button={
                        <WModalButton
                            className="w-full"
                            label="Add Location"
                            color="primary"
                            size="lg"
                            variant="dashed"
                            icon="jp-icon-add"
                        />
                    }
                />
            </div>
        </>
    );
}
