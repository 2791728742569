import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type { HostPool } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { type ApiClient, getResponseData } from "../api/apiClient.ts";
import { atomAllLocationsQuery } from "../location/atomAllLocationsQuery.ts";

export interface Params {
    location: string;
}

export type HostPoolUUID = string;

export interface HostPoolWithType extends HostPool, Params {
    $type: "host_pool";
}

export type Response = Map<HostPool["uuid"], HostPoolWithType>;

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["user-resource/host_pool/list"] : ["user-resource/host_pool/list", params.location];
}

function hostPoolFromData(data: HostPool, location: string): HostPoolWithType {
    return { ...data, $type: "host_pool", location };
}

export function getSingleQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            const response = getResponseData(
                await apiClient.GET("/{location}/user-resource/host_pool/list", {
                    signal,
                    params: { path: { location } },
                }),
            );
            return mapFromEntries(response, (pool: HostPool) => [pool.uuid, hostPoolFromData(pool, location)]);
        },
    });
}

export const hostPoolQueryAtom = atomFamily((location: string) =>
    atomFromStandardQueryOptions(getSingleQuery, { location }),
);

export const allHostPoolsQueryAtom = atomAllLocationsQuery(hostPoolQueryAtom, (results) => mergeMaps(results));
