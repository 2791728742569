import type { VmMigrateBody, VmStatusEnum } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { type ReactNode, useState } from "react";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { VmStatusBadge } from "../../modules/compute/VmStatusBadge.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { Missing } from "../AdminTable.tsx";
import { CurrentPool } from "../host_pools/CurrentPool.tsx";
import type { AdminHostPoolWithType } from "../host_pools/hostPoolsQuery.ts";
import { PoolSelect } from "../host_pools/PoolSelect.tsx";
import { CurrentHost } from "../hosts/CurrentHost.tsx";
import { adminHostQueryAtom } from "../hosts/hostQuery.ts";
import { HostSelect } from "../hosts/HostSelect.tsx";
import { ModalHeaderBlock } from "./ModalHeaderBlock.tsx";
import { migrateVmMutation } from "./vmQuery.ts";
import type { GQVmItem } from "./VmsTable.tsx";

interface MigrateVmBlockModalProps {
    item: GQVmItem;
}

export function MigrateVmBlockModalContent({ item }: MigrateVmBlockModalProps) {
    const migrateMutation = useStandardMutation(migrateVmMutation);
    const hosts = useSuspenseQueryAtom(adminHostQueryAtom(notNull(item._location)));

    const defaultHost = item?.hypervisor_uuid ? hosts.get(item.hypervisor_uuid) : null;
    const defaultPoolUuid = defaultHost?.pool_uuid ?? item.designated_pool_uuid ?? "";
    const [pool, setPool] = useState<string>(defaultPoolUuid);
    const [host, setHost] = useState<string>("");

    function changePool(item: AdminHostPoolWithType) {
        setHost("");
        setPool(item.uuid);
    }

    async function onSubmit() {
        const mutateBody: VmMigrateBody = {
            uuid: item.uuid,
        };
        if (host) {
            mutateBody.hv_uuid = host;
        } else {
            mutateBody.pool_uuid = pool;
        }
        await migrateMutation.mutateAsync({
            body: mutateBody,
            location: notNull(item._location),
        });
    }

    return (
        <WModalContent title="Migrate" label="Migrate" autoFocus modalAction={async () => await onSubmit()}>
            <ModalHeaderBlock item={item}>
                <VmStatusBadge value={item.status as VmStatusEnum} />
            </ModalHeaderBlock>

            <div className="HStack items-start gap-4 pt-4 w-45rem">
                <div className="flex-1">
                    <div className="pb-2">Current Pool</div>

                    <div className="lightBlock p-3">
                        <CurrentPool location={notNull(item._location)} value={notNull(defaultPoolUuid)} />
                    </div>
                </div>

                <div className="flex-1">
                    <div className="pb-2">Current Host</div>

                    <div className="lightBlock p-3">
                        {defaultHost?.uuid ? (
                            <CurrentHost location={notNull(item._location)} value={defaultHost?.uuid} />
                        ) : (
                            <Missing />
                        )}
                    </div>
                </div>
            </div>

            <div className="HStack items-start gap-4 pt-4">
                <div className="flex-1">
                    <div className="pb-2">Target Pool</div>

                    <div>
                        <PoolSelect
                            location={notNull(item._location)}
                            valueKey={pool}
                            onChange={(item) => changePool(item)}
                        />
                    </div>
                </div>

                <div className="flex-1">
                    <div className="pb-2">Target Host</div>

                    <div>
                        <HostSelect
                            location={notNull(item._location)}
                            pool={pool}
                            valueKey={host}
                            onChange={(item) => setHost(item.uuid)}
                        />
                    </div>
                </div>
            </div>
        </WModalContent>
    );
}

interface MigrateVmBlockProps extends MigrateVmBlockModalProps {
    button: ReactNode;
}

export function MigrateVmBlock(props: MigrateVmBlockProps) {
    return (
        <WModal button={props.button}>
            <MigrateVmBlockModalContent {...props} />
        </WModal>
    );
}
