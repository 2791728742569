import FF from "../../components/forms/FormField.module.css";
import P from "./PricesView.module.css";

import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import type {
    PriceFieldsBase,
    PricingHistory,
    ResourceTypePrices,
    ResourceTypePricesBase,
} from "@warrenio/api-spec/spec.oats.gen";
import { at } from "@warrenio/utils/collections/at";
import { mustGetProperty } from "@warrenio/utils/collections/getOwnProperty";
import { notNull } from "@warrenio/utils/notNull";
import { useAtomValue } from "jotai/react";
import { useMemo, useState } from "react";
import type { Settings } from "react-slick";
import { difference, findLast, isDeepEqual } from "remeda";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WCarousel } from "../../components/carousel/WCarousel.tsx";
import { ContentPane } from "../../components/ContentPane.tsx";
import { LongDate, MonthYearDate } from "../../components/l10n/DateFormat.tsx";
import { showModal } from "../../components/modal/registerModal.tsx";
import { WModal } from "../../components/modal/WModal.tsx";
import { Separator } from "../../components/Separator.tsx";
import { siteCurrencyAtom } from "../../config.ts";
import { resourceTypeEnabledAtom } from "../../modules/api/resourceTypes.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { DEFAULT_KEY } from "../../modules/pricing/resourcePricing.ts";
import { cn } from "../../utils/classNames.ts";
import { produce } from "../../utils/immer.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { AddEditItemModal } from "./AddEditItemModal.tsx";
import { ChangeCurrentPricesModal } from "./ChangeCurrentPricesModal.tsx";
import { DeleteScheduledPricesModal } from "./DeleteScheduledPricesModal.tsx";
import { LocationRadios } from "./LocationRadios.tsx";
import { PackagePrices } from "./PackagePrices.tsx";
import { historyPricesAtom, scheduledCreateMutation } from "./pricesQuery.ts";
import { PricesTable } from "./PricesTable.tsx";
import { pricingResourceTypes, type PricingResourceType } from "./pricingResourceTypes.tsx";
import { ScheduleNewPricesModal } from "./ScheduleNewPricesModal.tsx";
import { SchedulePricesSelect } from "./SchedulePricesSelect.tsx";
import { WarningModal } from "./WarningModal.tsx";

function getUtcStartOfCurrentMonth() {
    const date = new Date();
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
}

type DateCategory = "current" | "future" | "previous";

interface PeriodInfo {
    date: string;

    dateStamp: Date;
    lastMonth: Date | undefined;

    category: DateCategory;
    title: string;
}

function categorizeDates(dates: string[], currentStamp: Date): PeriodInfo[] {
    // NB: assumes these are sorted
    const dateStamps = dates.map((date) => ({ date, dateStamp: apiDatetimeToDate(date) }));

    return dateStamps.map((date, index): PeriodInfo => {
        const { dateStamp } = date;
        const nextDate = at(dateStamps, index + 1)?.dateStamp;
        const lastMonth = nextDate && toPreviousMonth(nextDate);

        if (dateStamp < currentStamp) {
            return { ...date, lastMonth, category: "previous", title: "Previous Prices" };
        } else if (dateStamp.valueOf() === currentStamp.valueOf()) {
            return { ...date, lastMonth, category: "current", title: "Current Prices" };
        } else {
            return { ...date, lastMonth, category: "future", title: "Scheduled Prices" };
        }
    });
}

function toPreviousMonth(date: Date) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - 1);
    return newDate;
}

function DateRange({ start, end }: { start: Date; end: Date | undefined }) {
    const isSingleMonth = start.valueOf() === end?.valueOf();
    return (
        <>
            <MonthYearDate date={start} />
            {!isSingleMonth && <> - {end ? <MonthYearDate date={end} /> : "…"}</>}
        </>
    );
}

export function PricesView() {
    //#region Hooks
    const updateMutation = useStandardMutation(scheduledCreateMutation);

    const vmsEnabled = useAtomValue(resourceTypeEnabledAtom("virtual_machine"));

    // NB: Memoize the date to avoid it changing while the user is editing (in case the time crosses to the next month)
    const currentStamp = useMemo(() => getUtcStartOfCurrentMonth(), []);

    const loadedHistory = useSuspenseQueryAtom(historyPricesAtom);
    const siteCurrency = useAtomValue(siteCurrencyAtom);

    // NB: Since the value of `data` can change (due to eg. query refresh), we need to to only use it when it's set and clear it when we want to re-fetch
    const [modifiedHistory, setModifiedHistory] = useState<PricingHistory | undefined>(undefined);

    // If not modified, use live query data
    const history = modifiedHistory ?? loadedHistory;
    const setHistory = (fn: (data: PricingHistory) => void) => {
        setModifiedHistory((data) => produce(data ?? history, fn));
    };

    const dates = categorizeDates(Object.keys(history), currentStamp);

    /** The date labeled as "Current" */
    const defaultDate = useMemo(
        () =>
            notNull(
                findLast(Object.keys(loadedHistory), (date) => apiDatetimeToDate(date) <= currentStamp),
                "TODO: Handle no dates",
            ),
        [loadedHistory, currentStamp],
    );
    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const selectedInfo = notNull(dates.find((item) => item.date === selectedDate));

    const selectedPrices = mustGetProperty(history[selectedDate], siteCurrency, "prices");

    const [savedLocation, setSelectedLocation] = useState<string | undefined>();
    /** Wrapper around the user-selected location to ensure it's valid */
    const selectedLocation = useMemo(() => {
        const validLocations = Object.keys(selectedPrices);
        return savedLocation && validLocations.includes(savedLocation) ? savedLocation : validLocations[0];
    }, [savedLocation, selectedPrices]);

    /** The date for the un-saved scheduled prices. We only allow adding one set of scheduled prices at a time. */
    const [schedulingDate, setSchedulingDate] = useState<string | undefined>();
    //#endregion

    const selectedLocationPrices = selectedPrices[selectedLocation];

    const isModified = !isDeepEqual(loadedHistory, history);

    const pricesEditable = useMemo(() => {
        const { category } = notNull(dates.find((item) => item.date === selectedDate));
        return category === "future" || category === "current";
    }, [selectedDate, dates]);

    function setActiveDate(date: string) {
        if (isModified) {
            showModal(
                <WarningModal
                    modalAction={() => {
                        // Clear any changes
                        setModifiedHistory(undefined);
                        setSchedulingDate(undefined);
                        setSelectedDate(date);
                    }}
                />,
            );
        } else {
            setSelectedDate(date);
        }
    }

    function addScheduledPrices(date: string) {
        setHistory((data) => {
            if (schedulingDate) {
                // Move the currently scheduled prices to the new date
                data[date] = data[schedulingDate];
                delete data[schedulingDate];
            } else {
                // Default to the current prices
                data[date] = notNull(data[defaultDate]);
            }
        });

        setSchedulingDate(date);
        setSelectedDate(date);
    }

    function deleteScheduledPrices() {
        setHistory((data) => {
            delete data[selectedDate];
            //delete data[schedulingDate!];
        });
        setSchedulingDate(undefined);

        setSelectedDate(defaultDate);
    }

    const slides = dates.map(({ date, lastMonth, dateStamp, category, title }) => {
        const isSelected = selectedDate === date;

        return (
            <div
                key={date}
                className={cn(
                    P.Slide,
                    category === "current" && P.Current,
                    category === "future" && P.Future,
                    isSelected && P.Active,
                )}
                onClick={() => setActiveDate(date)}
            >
                <div className={P.Content}>
                    <div className={cn(isSelected ? P.White : "color-muted", "font-size-small pb-4")}>{title}</div>
                    {category === "future" && date === schedulingDate ? (
                        <div className={P.Schedule}>
                            <SchedulePricesSelect
                                onChange={(value) => addScheduledPrices(value)}
                                value={schedulingDate}
                                disabledKeys={dates.filter(({ date }) => date !== schedulingDate).map((d) => d.date)}
                            />
                        </div>
                    ) : (
                        <div className="font-size-subtitle pt-2">
                            <DateRange start={dateStamp} end={lastMonth} />
                        </div>
                    )}

                    {category === "future" && (
                        <div className={P.Delete}>
                            {schedulingDate ? (
                                <WButton
                                    className={cn(isSelected && P.White)}
                                    size="xs"
                                    color="primary"
                                    variant="ghost"
                                    icon="jp-trash-icon"
                                    action={() => deleteScheduledPrices()}
                                />
                            ) : (
                                <WModal
                                    button={
                                        <WButton
                                            className={cn(isSelected && P.White)}
                                            size="xs"
                                            color="primary"
                                            variant="ghost"
                                            icon="jp-trash-icon"
                                            action={undefined}
                                        />
                                    }
                                >
                                    <DeleteScheduledPricesModal onDelete={() => deleteScheduledPrices()} item={date} />
                                </WModal>
                            )}
                        </div>
                    )}
                    {category === "current" && <div className={P.Banner}>Current</div>}
                </div>
            </div>
        );
    });

    const settings: Settings = {
        dots: false,
        infinite: false,
        initialSlide: dates.findIndex((item) => item.date === selectedDate),
    };

    function addLocation(locKey: string) {
        setHistory((data) => {
            data[selectedDate][siteCurrency][locKey] = data[selectedDate][siteCurrency][DEFAULT_KEY];
        });
        setSelectedLocation(locKey);
    }

    function deleteCurrentLocation() {
        setSelectedLocation(DEFAULT_KEY);
        setHistory((data) => {
            delete data[selectedDate][siteCurrency][selectedLocation];
        });
    }

    async function onSubmit() {
        await updateMutation.mutateAsync({ id: selectedDate, body: history[selectedDate] });
        // Clear any changes so we can re-fetch the data
        setModifiedHistory(undefined);
        setSchedulingDate(undefined);
    }

    function onCancel() {
        setModifiedHistory(undefined);
        if (selectedDate === schedulingDate) {
            setSelectedDate(defaultDate);
        }
        setSchedulingDate(undefined);
    }

    function onAddPriceRow(rows: PriceFieldsBase[], resourceType: PricingResourceType) {
        setHistory((data) => {
            const locData = data[selectedDate][siteCurrency][selectedLocation];

            const resource = locData.find((item) => item.resource_type === resourceType);
            if (!resource) {
                const newResource: ResourceTypePricesBase = { resource_type: resourceType, resource_prices: rows };
                locData.push(newResource as ResourceTypePrices);
            } else {
                resource.resource_prices = rows;
            }
        });
    }

    const disabledKeys = selectedPrices ? Object.keys(selectedPrices) : [];

    const usedTypes = selectedLocationPrices
        .filter((type) => type.resource_prices.length)
        .map((type) => type.resource_type);
    const addableResourceTypes = difference(
        pricingResourceTypes.map((t) => t.type),
        usedTypes,
    );

    const stateKey = `${selectedLocation};${selectedDate}`;

    return (
        <>
            <AdminTitle title="Price Lists" />

            <p className="p-2 color-muted">
                All configured platform prices are listed here.
                <br />
                You can view and schedule price updates for different locations.
            </p>

            <div className={P.Slides}>
                <WCarousel settings={settings} slideWidth={200}>
                    {slides}
                    <div className={P.Slide}>
                        <WModal
                            button={
                                <WButton
                                    isDisabled={!!schedulingDate}
                                    className={P.New}
                                    color="primary"
                                    variant="dashed"
                                    size="lg"
                                    icon="jp-icon-add"
                                    action={undefined}
                                >
                                    Schedule New Prices
                                </WButton>
                            }
                        >
                            <ScheduleNewPricesModal
                                onChange={(value: string) => addScheduledPrices(value)}
                                disabledKeys={Object.keys(history)}
                            />
                        </WModal>
                    </div>
                </WCarousel>
            </div>

            <div className="pt-3">
                <Separator />
            </div>

            <ContentPane>
                {!!selectedDate && (
                    <>
                        <div className="font-size-subtitle">
                            {selectedInfo.title} from <MonthYearDate date={selectedInfo.dateStamp} />
                            {isModified && " (unpublished)"}
                        </div>

                        {selectedInfo.category === "future" && (
                            <p className="color-muted pt-1">
                                These prices will take effect on <LongDate date={selectedInfo.dateStamp} />
                            </p>
                        )}
                    </>
                )}

                <LocationRadios
                    isDisabled={!pricesEditable}
                    disabledKeys={disabledKeys}
                    onAdd={(value) => addLocation(value)}
                    onChange={(locKey) => setSelectedLocation(locKey)}
                    onDelete={() => deleteCurrentLocation()}
                    selectedLocation={selectedLocation}
                    prices={selectedPrices}
                />

                {vmsEnabled && (
                    // TODO: Also disable this when metal spec is currently selected
                    <PackagePrices priceList={selectedPrices} location={selectedLocation} />
                )}

                <PricesTable
                    key={stateKey}
                    selectedPrices={selectedPrices}
                    selectedLocation={selectedLocation}
                    isDisabled={!pricesEditable}
                    onChangePrices={(prices) => {
                        setHistory((history) => {
                            history[selectedDate][siteCurrency] = prices;
                        });
                    }}
                />

                <div className="pt-3 flex gap-0.5rem">
                    <WModal
                        button={
                            <WModalButton
                                isDisabled={!pricesEditable || !addableResourceTypes.length}
                                className="w-full"
                                color="primary"
                                size="lg"
                                variant="dashed"
                                label="Add New Item"
                                icon="jp-icon-add"
                            />
                        }
                    >
                        <AddEditItemModal
                            usableTypes={addableResourceTypes}
                            onChange={(rows, resourceType) => onAddPriceRow(rows, resourceType)}
                        />
                    </WModal>
                </div>
            </ContentPane>

            <Separator />

            <div className={FF.FormActions}>
                {selectedInfo.category === "current" ? (
                    <WModal
                        button={
                            <WModalButton
                                isDisabled={!pricesEditable || !isModified}
                                color="primary"
                                variant="basic"
                                size="md"
                                label="Publish"
                            />
                        }
                    >
                        <ChangeCurrentPricesModal onChange={onSubmit} />
                    </WModal>
                ) : (
                    <WButton
                        color="primary"
                        variant="basic"
                        size="md"
                        isDisabled={!pricesEditable || !isModified}
                        action={onSubmit}
                    >
                        Publish
                    </WButton>
                )}

                <WButton color="default" variant="basic" size="md" isDisabled={!isModified} action={() => onCancel()}>
                    Cancel
                </WButton>
            </div>
        </>
    );
}
