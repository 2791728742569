import type {
    LinkProcessorId,
    MethodConfig,
    ProcessorConfig,
    ProcessorCurrencyFee,
    ProcessorServiceFee,
} from "@warrenio/api-spec/spec.oats.gen";
import type { ComponentType, ReactNode } from "react";
import type { AddMethodProps } from "./choose_method/AddMethodParams.ts";
import type { MethodIconProps } from "./choose_method/ChooseMethod.tsx";
import type { TopUpProps } from "./topup/TopUpParams.ts";

/** `${processor.keyword}::${method.key}` */
export type PaymentMethodId = ProcessorConfig["keyword"] | `${LinkProcessorId}::${MethodConfig["key"]}`;

export function getPaymentMethodId(processor: ProcessorConfig, method: MethodConfig | undefined): PaymentMethodId {
    return method != null ? `${processor.keyword as LinkProcessorId}::${method.key}` : processor.keyword;
}

/**
 * A central interface for all payment methods, containing their properties and business logic (actions).
 *
 * `link`-type methods are "flattened out" to create 1 of these objects for each method config.
 */
export interface MethodBase {
    type: string;

    id: PaymentMethodId;

    /** Can this payment method be added to an account? */
    canAdd: boolean;
    /** Is this an automated top up method? */
    isAutomated: boolean;

    name: string;
    icon: (props: MethodIconProps) => ReactNode;

    // Components
    // NB: The functions to actually handle the business logic are exposed by the form components via its ref.

    /** Component to show for "Add method" list.
     * @see {@link AddMethodProps}
     */
    AddForm: ComponentType<AddMethodProps>;

    /** Component to show for "Top up" modal.
     * Must be set if the method supports top-ups.
     * @see {@link TopUpProps}
     */
    TopUpForm?: unknown;

    // Fee peaks siia välja jõudma, ning meil võiks olla mingi völi vastavate fee'dega
    fee?: {
        service_fee?: ProcessorServiceFee;
        charge_currency_conversion?: ProcessorCurrencyFee;
    };
}

//#region All payment methods

export interface InvoiceMethod extends MethodBase {
    type: "invoice";

    TopUpForm?: never;
}

export interface LinkMethod extends MethodBase {
    type: "link";

    TopUpForm: ComponentType<TopUpProps>;
}

export interface CardMethod extends MethodBase {
    type: "creditcard";

    TopUpForm: ComponentType<TopUpProps>;
}

/** @see {@link MethodBase} */
export type PaymentMethod = LinkMethod | CardMethod | InvoiceMethod;

//#endregion
