import type Honeybadger from "@honeybadger-io/js";
import { environmentId } from "../utils/environment.ts";

function isTestProd() {
    const { hostname } = window.location;
    return hostname.endsWith(".pilw.io") && hostname.startsWith("test");
}

/** Small hack to consider "test.pilw.io" as a staging environment even though it uses the production build */
const environment = environmentId === "production" && isTestProd() ? "staging" : environmentId;

export const honeybadgerConfig: Parameters<typeof Honeybadger.configure>[0] = {
    apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY as string,
    environment,
    revision: import.meta.env.VITE_REVISION as string | undefined,
    filters: ["password", "apikey", "email", "access_token"],
    // debug: true,
};

console.info("Environment: %s, revision: %s", honeybadgerConfig.environment, honeybadgerConfig.revision);
