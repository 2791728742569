import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { successResponse, tagPaths } from "../util.ts";
import { int } from "./common.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

const IpPoolResponse = z
    .object({
        used_ips: int,
        total_ips: int,
        percent_used: z.number(),
    })
    .openapi({ ref: "IpPoolResponse" });

export const networkAdminPaths = tagPaths("admin_network")({
    "/{location}/network/admin/ip_pool_status": {
        get: {
            summary: "IP pool status",
            parameters: [params.location],
            responses: {
                ...successResponse(IpPoolResponse),
            },
        },
    },
});
