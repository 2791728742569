import type { BillingAccount } from "@warrenio/api-spec/spec.oats.gen";
import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import type { ReactNode } from "react";
import { NoticeBlock } from "../../components/NoticeBlock.tsx";
import { CurrencyPromo } from "../../components/l10n/Currency.tsx";
import { useConfig } from "../../config.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { ContactSupportButton } from "../support/ContactSupportButton.tsx";
import { useThemeProps } from "../theme/useTheme.ts";
import { TopUpNowButton } from "./ActionButtons.tsx";
import { useBillingAccount } from "./billingAccountQuery.ts";
import type { EBillingAccount } from "./billingLogic.tsx";
import { queryAtom } from "./restrictionLevelClearTopupThresholdQuery.ts";

export function LimitedBillingAccountNoticeLoader({ baId }: { baId: BillingAccount["id"] }) {
    const account = useBillingAccount(baId);
    return <LimitedBillingAccountNoticeContent account={account} />;
}

export function LimitsList() {
    const {
        limitedBillingAccountConfig: { ram_mb, vcpu },
        kubernetesEnabled,
        vmsEnabled,
        bareMetalEnabled,
    } = useConfig();

    const limits = filterFalse<ReactNode>([
        vmsEnabled && !!vcpu && `CPU: ${vcpu}`,
        vmsEnabled && !!ram_mb && `RAM: ${ram_mb} MB`,
        bareMetalEnabled && "1 bare metal machine",
        kubernetesEnabled && "Kubernetes service can not be created.",
    ]);

    if (limits.length === 0) {
        return null;
    }

    return (
        <ul className="m-0 pl-5">
            {limits.map((limit, idx) => (
                <li key={idx}>{limit}</li>
            ))}
        </ul>
    );
}

export function LimitedBillingAccountNoticeContent({ account }: { account: EBillingAccount }) {
    //#region Hooks
    const { restriction_level_clear_topup_threshold } = useSuspenseQueryAtom(queryAtom);
    const { limitedBillingAccountHideButton, limitedBillingAccountCopy } = useThemeProps();
    const { billingEmail } = useConfig();
    //#endregion

    return (
        account.isLimited && (
            <NoticeBlock
                icon="jp-warning-icon"
                button={
                    !limitedBillingAccountHideButton &&
                    (account.canTopUp ? <TopUpNowButton account={account} /> : <ContactSupportButton />)
                }
            >
                <p>
                    <b className="uppercase">Billing account is limited</b>
                    <br />
                    This billing account has limited capabilities when creating resources.{" "}
                    {!!limitedBillingAccountCopy && limitedBillingAccountCopy.replace("{billingEmail}", billingEmail)}
                </p>

                {!limitedBillingAccountCopy && (
                    <>
                        <LimitsList />

                        {account.isPostPay ? (
                            <p>Please contact support to remove the limit.</p>
                        ) : restriction_level_clear_topup_threshold ? (
                            <p>
                                Limit will be removed once the total top up amount reaches{" "}
                                <CurrencyPromo value={restriction_level_clear_topup_threshold} />.
                            </p>
                        ) : null}
                    </>
                )}
            </NoticeBlock>
        )
    );
}
