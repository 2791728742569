import C from "./Todo.module.css";

import type { ReactNode } from "react";
import { MaskIcon } from "../components/icon/MaskIcon.tsx";
import { mcn, type BaseProps } from "../utils/baseProps";

interface TodoProps extends BaseProps {
    /** Icon only */
    small?: boolean;

    title?: TodoIconProps["title"];
    children?: ReactNode;
}

/** Inline "Todo" component */
export function TODO({ children, title, small, ...props }: TodoProps) {
    if (import.meta.env.PROD) {
        return null;
    }

    const description = children ?? title;
    return (
        <span {...mcn(`text-muted select-none ${C.Todo}`, props)}>
            <TodoInlineIcon title={title} />
            {!small ? <>TODO{description && <>: {description}</>}</> : children}
        </span>
    );
}

interface TodoIconProps extends BaseProps {
    title?: string;
}

export function TodoInlineIcon(props: TodoIconProps) {
    return <MaskIcon title="TODO" {...mcn("jp-construction-icon size-1.1em mb-[-0.25em] mr-0.3em", props)} />;
}

export function TodoButtonIcon(props: TodoIconProps) {
    return <MaskIcon {...mcn("jp-construction-icon size-1.1em", props)} />;
}
