import type { ReactNode } from "react";
import { ErrorBoundary, type ErrorBoundaryPropsWithComponent } from "react-error-boundary";
import type { ErrorContext } from "./errorFields.tsx";
import { notifyError } from "./errorReporting.tsx";

export interface ReportingErrorBoundaryProps extends ErrorBoundaryPropsWithComponent {
    tags: string[] | string;
    context?: ErrorContext;
    children: ReactNode;
}

export function ReportingErrorBoundary({ tags, context, ...props }: ReportingErrorBoundaryProps) {
    return (
        <ErrorBoundary
            {...props}
            onError={(e, info) => {
                const fullContext = context ? { ...info, ...context } : info;
                notifyError(e, {
                    tags: ["error_boundary", ...(typeof tags === "string" ? [tags] : tags)],
                    context: fullContext,
                });
            }}
        />
    );
}
