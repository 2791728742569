import { mapFrom } from "@warrenio/utils/collections/maps";
import { mustGet } from "@warrenio/utils/collections/mustGet";
import { atom } from "jotai/vanilla";
import type { ReactNode } from "react";
import type { OptionalElement } from "../../components/SeparatedBy.tsx";
import { configAtom } from "../../config.ts";
import { CountryFlag } from "../../modules/location/CountryFlag.tsx";
import { queryAtom } from "../../modules/location/query.ts";
import { allHostPoolsQueryAtom } from "../../modules/pools/query.ts";
import { DEFAULT_KEY, getHostPoolId, getMetalSpecId } from "../../modules/pricing/resourcePricing.ts";
import { constantQueryResult, mergeLoadedQueries } from "../../utils/query/mergeQueries.ts";
import { metalSpecQueryAtom } from "../metal/specs/specQuery.ts";

const specsIfEnabledQueryAtom = atom((get) => {
    const { bareMetalEnabled } = get(configAtom);
    return bareMetalEnabled ? get(metalSpecQueryAtom) : constantQueryResult(null);
});

/** Information about a selectable "location" (pool) for which pricing can be added */
export interface PriceLoc {
    key: string;
    fallbackKeys: string[];
    type: "default" | "location" | "host_pool" | "metal_spec";
    title: OptionalElement;
    icon: ReactNode;
}

/** Return the entire sequence of keys that will be searched for a price (eg.` [host_pool_key, location_key, DEFAULT_KEY]`) */
export function getAllLocKeys(loc: PriceLoc) {
    return [loc.key, ...loc.fallbackKeys, DEFAULT_KEY];
}

const DEFAULT_LOC: PriceLoc = {
    key: DEFAULT_KEY,
    fallbackKeys: [],
    type: "default",
    title: "DEFAULT",
    icon: null,
};

export const priceLocsAtom = atom((get) => {
    const locations = get(queryAtom);
    const pools = get(allHostPoolsQueryAtom);
    const specs = get(specsIfEnabledQueryAtom);

    return mergeLoadedQueries({ locations, pools, specs }, ({ locations, pools, specs }) => {
        const locMap = mapFrom(locations, (l) => l.slug);

        const result: PriceLoc[] = [DEFAULT_LOC];

        for (const loc of locations) {
            result.push({
                key: loc.slug,
                fallbackKeys: [],
                type: "location",
                title: loc.display_name,
                icon: <CountryFlag code={loc.country_code} />,
            });
        }

        for (const pool of pools.values()) {
            const loc = mustGet(locMap, pool.location);
            result.push({
                key: getHostPoolId(pool.uuid),
                fallbackKeys: [loc.slug],
                type: "host_pool",
                title: `${loc.display_name} - ${pool.name}`,
                icon: <CountryFlag code={loc.country_code} />,
            });
        }

        for (const spec of specs?.values() ?? []) {
            const loc = mustGet(locMap, spec.location);
            result.push({
                key: getMetalSpecId(spec.uuid),
                fallbackKeys: [loc.slug],
                type: "metal_spec",
                title: `${loc.display_name} - ${spec.title}`,
                icon: <CountryFlag code={loc.country_code} />,
            });
        }

        return result;
    });
});
