import type { MetalOsCreate, MetalOsTypeCreate } from "@warrenio/api-spec/spec.oats.gen";
import { useForm } from "react-hook-form";
import { keys, pick } from "remeda";
import { useStandardMutation } from "../../../modules/api/useStandardMutation.ts";
import { changedFields } from "../../../utils/changedFields.ts";
import { AFCheckbox, AFFileField, AFTextField } from "../../form/AdminFormFields.tsx";
import { WAdminModalCrudForm } from "../../form/AdminModalContent.tsx";
import {
    osCreateMutation,
    osTypeCreateMutation,
    osTypeUpdateMutation,
    osUpdateMutation,
} from "../specs/metalOsMutation.ts";
import { osFields, osTypeFields } from "./osFields.tsx";

export function OsItemModal({ item }: { item?: MetalOsCreate | undefined }) {
    interface Inputs extends MetalOsCreate {}

    //#region Hooks
    const createDefaults: Inputs = {
        os_id: "",
        os_type_id: "",
        version_title: "",
        is_published: false,
    };

    const form = useForm<Inputs>({
        defaultValues: item ? pick(item, keys(createDefaults)) : createDefaults,
    });
    const { control } = form;

    const createMutation = useStandardMutation(osCreateMutation);
    const updateMutation = useStandardMutation(osUpdateMutation);

    //#endregion

    async function onSubmit(body: Inputs) {
        if (item) {
            const changed = changedFields(item, body);
            if (changed) {
                await updateMutation.mutateAsync({
                    body: changed,
                    os_id: item.os_id,
                });
            }
        } else {
            await createMutation.mutateAsync({ body });
        }
    }

    const isUpdate = !!item;

    return (
        <WAdminModalCrudForm entityType="os" isUpdate={isUpdate} form={form} onSubmit={onSubmit}>
            <AFTextField control={control} name="os_id" isRequired config={osFields.os_id} isReadOnly={isUpdate} />
            <AFTextField control={control} name="os_type_id" isRequired config={osFields.os_type_id} />
            <AFTextField control={control} name="version_title" isRequired config={osFields.version_title} />
            <AFCheckbox control={control} name="is_published" config={osFields.is_published} />
        </WAdminModalCrudForm>
    );
}

export function OsTypeItemModal({ item }: { item?: MetalOsTypeCreate | undefined }) {
    interface Inputs extends MetalOsTypeCreate {}

    //#region Hooks
    const createDefaults: Inputs = {
        os_type_id: "",
        title: "",
        icon: "",
    };

    const form = useForm<Inputs>({
        defaultValues: item ? pick(item, keys(createDefaults)) : createDefaults,
    });
    const { control } = form;

    const createMutation = useStandardMutation(osTypeCreateMutation);
    const updateMutation = useStandardMutation(osTypeUpdateMutation);

    //#endregion

    async function onSubmit(body: Inputs) {
        if (item) {
            const changed = changedFields(item, body);
            if (changed) {
                await updateMutation.mutateAsync({
                    body: changed,
                    os_type_id: item.os_type_id,
                });
            }
        } else {
            await createMutation.mutateAsync({ body });
        }
    }

    const isUpdate = !!item;

    return (
        <WAdminModalCrudForm entityType="os_type" isUpdate={isUpdate} form={form} onSubmit={onSubmit}>
            <AFTextField
                control={control}
                name="os_type_id"
                isRequired
                config={osTypeFields.os_type_id}
                isReadOnly={isUpdate}
            />
            <AFTextField control={control} name="title" isRequired config={osTypeFields.title} />

            <AFFileField control={control} name="icon" isRequired config={osTypeFields.icon} />
        </WAdminModalCrudForm>
    );
}
