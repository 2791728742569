import { createLazyFileRoute } from "@tanstack/react-router";
import { MetalLeaseDetailView } from "../../../../modules/metal/MetalLeaseDetailView.tsx";

export const Route = createLazyFileRoute("/_main/metal/lease/$uuid/")({
    component: Component,
});

function Component() {
    const { uuid } = Route.useParams();
    return <MetalLeaseDetailView key={uuid} bareId={uuid} />;
}
