import RA from "../../components/forms/Radio.module.css";
import R from "../../components/forms/Radiogroup.module.css";
import CD from "./ConnectionDetails.module.css";

import type { ManagedDbService } from "@warrenio/api-spec/spec.oats.gen";
import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { useState } from "react";
import { Radio, RadioGroup } from "react-aria-components";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { ContentPane } from "../../components/ContentPane.tsx";
import { simpleToText, type SimpleElements } from "../../components/renderAsText.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useStandardSuspenseQuery } from "../api/useStandardMutation.ts";
import { ipQueryAtom } from "../network/ipAddress/apiOperations.ts";
import { TextSecret } from "./TextSecret.tsx";
import type { ManagedServiceWithAssigned } from "./joinAssignedQuery.ts";
import { getServiceSecretsQuery } from "./servicesQuery.ts";

export function ConnectionDetailsContent({ item }: { item: ManagedServiceWithAssigned & ManagedDbService }) {
    const { uuid, properties, service } = item;
    const serviceSecrets = useStandardSuspenseQuery(getServiceSecretsQuery, { uuid });
    const [value, setValue] = useState<"parameters" | "string" | "flags">("parameters");
    const [visible, setVisible] = useState<boolean>(false);

    const ips = useSuspenseQueryAtom(ipQueryAtom(properties.location));
    const attachedPublicIp = [...ips.values()].find(
        (ip) => ip?.assigned_to_resource_type === "service" && ip.assigned_to === uuid,
    );

    const { service_ip, sql_user, port } = properties;

    function CreateDetails({ host }: { host: string | undefined }) {
        const password = serviceSecrets.data.sql_pass;

        function renderValue(): SimpleElements {
            switch (value) {
                case "parameters":
                    return (
                        <>
                            <b>username</b> = {sql_user}
                            <br />
                            <b>password</b> ={" "}
                            <TextSecret visible={visible} setVisible={setVisible} withButton>
                                {password}
                            </TextSecret>
                            <br />
                            <b>host</b> = {host}
                            <br />
                            <b>port</b> = {port}
                        </>
                    );

                case "string":
                    if (service === "mariadb") {
                        return (
                            <>
                                mysql://{sql_user}:
                                <TextSecret visible={visible} setVisible={setVisible}>
                                    {password}
                                </TextSecret>
                                @{host}:{port}
                            </>
                        );
                    } else if (service === "postgresql") {
                        return (
                            <>
                                postgresql://{sql_user}:
                                <TextSecret visible={visible} setVisible={setVisible}>
                                    {password}
                                </TextSecret>
                                @{host}:{port}
                            </>
                        );
                    } else if (service === "self-service-dummy-testing") {
                        return null;
                    }
                    return exhaustiveSwitchCheck(service);
                case "flags":
                    if (service === "mariadb") {
                        return (
                            <>
                                mysql -u {sql_user} -p '
                                <TextSecret visible={visible} setVisible={setVisible}>
                                    {password}
                                </TextSecret>
                                ' -h {host} -P {port}
                            </>
                        );
                    } else if (service === "postgresql") {
                        return (
                            <>
                                PGPASSWORD='
                                <TextSecret visible={visible} setVisible={setVisible}>
                                    {password}
                                </TextSecret>
                                ' psql -h {host} -p {port} -U {sql_user}
                            </>
                        );
                    }
                    return exhaustiveSwitchCheck(service);
            }
        }

        const tpl = renderValue();
        const copyString = simpleToText(tpl);
        return (
            <>
                {tpl}

                <div className="pt-3">
                    <ClipBoardTooltip text={copyString}>Copy</ClipBoardTooltip>
                </div>
            </>
        );
    }

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Connection Details</h2>

            <div className="pb-3">
                <RadioGroup
                    className={R.RadioGroup}
                    aria-label="Connection details type"
                    value={value}
                    orientation="horizontal"
                    onChange={(v) => setValue(v as typeof value)}
                >
                    <Radio className={RA.Radio} value="parameters">
                        Parameters
                    </Radio>
                    <Radio className={RA.Radio} value="string">
                        String
                    </Radio>
                    <Radio className={RA.Radio} value="flags">
                        Flags
                    </Radio>
                </RadioGroup>
            </div>

            <div className={CD.Details}>
                {attachedPublicIp?.address && (
                    <div className="lightBlock">
                        <h3 className="pb-3">Public network</h3>

                        <CreateDetails host={attachedPublicIp?.address} />
                    </div>
                )}

                <div className="lightBlock">
                    <h3 className="pb-3">VPC network</h3>

                    <CreateDetails host={service_ip} />
                </div>
            </div>
        </ContentPane>
    );
}
