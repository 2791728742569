import FF from "../../../components/forms/FormField.module.css";

import type { ReactNode } from "react";
import { Radio, type RadioProps } from "react-aria-components";
import { MonthlyCostElement } from "../../../components/forms/MonthlyCostField.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { cn } from "../../../utils/classNames.ts";
import type { Price } from "../../pricing/resourcePricing.ts";

export interface SpecRadioProps {
    value: RadioProps["value"];

    title: ReactNode;
    subtitle: ReactNode;
    icon: string;
    description: ReactNode;
    is_available: boolean;
    price: Price;
    wide?: boolean;
}

export function SpecRadio({ icon, value, title, subtitle, description, is_available, wide, price }: SpecRadioProps) {
    return (
        <Radio
            value={value}
            isDisabled={!is_available}
            className={cn(FF.FormFieldIconRadio, FF.SpecRadio, wide && FF.wide)}
        >
            <div className={FF.Header}>
                <MaskIcon className={`${icon} size-2.5rem`} />
                <div className="pl-3">
                    <div className="">{title}</div>
                    {subtitle && <div className="font-size-small">{subtitle}</div>}
                </div>
            </div>

            <div className={cn(FF.Content, "whitespace-pre")}>{description}</div>

            <div className={FF.Footer}>
                <MonthlyCostElement price={price} />
            </div>

            {!is_available && <div className={FF.Banner}>Sold out</div>}
        </Radio>
    );
}
