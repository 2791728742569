import C from "./UserAvatar.module.css";

import { useNavigate } from "@tanstack/react-router";
import type { User } from "@warrenio/api-spec/spec.oats.gen";
import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import { useAtomValue, useSetAtom } from "jotai/react";
import { useCallback, useMemo } from "react";
import { MenuTrigger } from "react-aria-components";
import { sortBy } from "remeda";
import { adminEnabled, useIsInAdminMode } from "../../admin/adminMode.ts";
import { ActionMenuContent, type ActionMenuItemProps } from "../../components/ActionMenu.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { showModal, waitModal } from "../../components/modal/registerModal.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { accessImpersonationListQuery } from "../access/impersonation/apiOperations.ts";
import { logoutAction } from "../api/logoutAction.ts";
import { useStandardQuery } from "../api/useStandardMutation.ts";
import { Isolated } from "../error/Isolated.tsx";
import { LogoutConfirmContent } from "../main/LogoutConfirmDialog.tsx";
import { ActAsModalContent } from "./ActAsModal.tsx";
import { NameAvatar, UserAvatar } from "./UserAvatar.tsx";
import { userDataAtom, userIsAdminAtom } from "./apiOperations.ts";
import { stopImpersonatingAction } from "./impersonation.action.ts";
import { impersonationAtom } from "./impersonation.store.ts";
import { profileLink } from "./profileLink.ts";

function useLogout() {
    const logout = useSetAtom(logoutAction);
    return useCallback(async () => {
        if (import.meta.env.PROD) {
            await waitModal(<LogoutConfirmContent />);
        } else {
            // For development - just hurry up and log out.
            await logout();
        }
    }, [logout]);
}

export function UserName() {
    const userData = useSuspenseQueryAtom(userDataAtom);

    const username = getUserName(userData);
    return (
        <>
            <UserAvatar inHeader user={userData} />
            <div className="ml-0.25rem hidden-mobile">{username}</div>
        </>
    );
}

function getUserName({ name, profile_data: { first_name, last_name } }: User) {
    return [first_name, last_name].filter(Boolean).join(" ") || name;
}

function UserMenuContent() {
    //#region Hooks
    const { data: impersonationData } = useStandardQuery(accessImpersonationListQuery);

    const logout = useLogout();
    const navigate = useNavigate();
    const impersonation = useAtomValue(impersonationAtom);
    const stopImpersonating = useSetAtom(stopImpersonatingAction);
    const isAdmin = useSuspenseQueryAtom(userIsAdminAtom);
    const isInAdminMode = useIsInAdminMode();
    //#endregion

    const items = useMemo(() => {
        const impersonations = impersonationData
            ? sortBy([...impersonationData.values()], [(a) => a.created_at, "desc"])
            : [];

        const impersonationItems = impersonations
            .filter((item) => item.is_accepted)
            .map(
                (item): ActionMenuItemProps => ({
                    id: `impersonate_${item.uuid}`,
                    title: item.access_owner,
                    image: <NameAvatar name={item.access_owner} />,
                    action: () => {
                        showModal(<ActAsModalContent item={item} />);
                    },
                }),
            );

        const impersonationMenu = filterFalse([
            ...impersonationItems,
            impersonationItems.length > 0 && {
                id: "manage",
                title: "Manage",
                icon: `jp-user-group-icon color-primary ${C.AvatarIconSize}`,
                action: () => navigate(profileLink),
            },
            !!impersonation && {
                id: "back_to_account",
                title: "Back to your account",
                icon: `jp-exit-icon ${C.AvatarIconSize}`,
                action: stopImpersonating,
            },
        ]);

        // TODO: Using just a <Link> does not work nicely with keyboard navigation. Should use react-aria's MenuItem's `href` instead of `navigate`.

        return filterFalse<ActionMenuItemProps>([
            isInAdminMode
                ? {
                      id: "user_mode",
                      title: "User view",
                      icon: "jp-icon-home color-primary size-0.875rem",
                      action: () => navigate({ to: "/" }),
                  }
                : adminEnabled &&
                  isAdmin && {
                      id: "admin_mode",
                      title: "Admin view",
                      icon: "jp-admin-user-icon color-primary size-0.875rem",
                      action: () => navigate({ to: "/admin" }),
                  },
            {
                id: "profile",
                title: "Profile",
                icon: "jp-user-icon color-primary size-0.875rem",
                action: () => navigate(profileLink),
            },
            {
                id: "act_as",
                title: "Act As",
                icon: "jp-swap-icon color-primary size-0.875rem",
                childItems: impersonationMenu,
                isDisabled: impersonationMenu.length === 0,
            },
            {
                id: "logout",
                title: <span className="color-error">Log out</span>,
                icon: "jp-exit-icon color-error size-0.875rem",
                action: logout,
            },
        ]);
    }, [logout, navigate, impersonationData, impersonation, stopImpersonating, isAdmin, isInAdminMode]);

    return <ActionMenuContent items={items} placement="bottom end" />;
}

export function UserMenu() {
    // NB: Use `Isolated` so logout is still accessible even any of the queries fail.
    return (
        <MenuTrigger>
            {/*<UserSwitch />*/}

            <WButton color="primary" variant="ghost" size="bar" action={undefined}>
                <Isolated fallback="...">
                    <UserName />
                </Isolated>
                <MaskIcon className="ml-2 jp-icon-caretdown size-0.875rem" />
            </WButton>
            <UserMenuContent />
        </MenuTrigger>
    );
}

export default UserMenu;
