import { showWarn } from "../../modules/error/errorStream.ts";

export function DoNotSuspendHere() {
    showWarn(
        "SUSPENSE: Activated in a component whose children should not suspend.\nFix: Find the suspenders and wrap them in <Suspense>.",
    );
    return import.meta.env.DEV ? (
        <div className="size-100% min-h-200px bg-error text-white font-size-heading">Do not suspend</div>
    ) : null;
}
