import C from "./ErrorFallback.module.css";

import { Link, type NotFoundRouteProps } from "@tanstack/react-router";
import { NotFoundError } from "@warrenio/utils/collections/getAndAssert";
import { useSetAtom } from "jotai/react";
import { WButton } from "../../components/button/WButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { resetConfigOverridesAtom, resetConfigOverridesTitle } from "../../config.override.ts";
import { cn } from "../../utils/classNames.ts";
import { getResourceCreateLink } from "../api/resourceCreateLinks.ts";
import { ResourceNotFoundError } from "../api/resourceTypeException.ts";
import { ErrorBox } from "./ErrorBox.tsx";
import { errorToString } from "./errorToString.tsx";
import { ReportingErrorBoundary, type ReportingErrorBoundaryProps } from "./ReportingErrorBoundary.tsx";
import { isZodError, ZodErrorMessage } from "./ZodError.tsx";

/** Render an error object into an appropriate, type-specific error message */
export function ErrorMessage({ error }: { error: unknown }) {
    if (error instanceof NotFoundError) {
        return (
            <>
                <h1 className="text-muted font-size-heading">This {error.objectName} is gone</h1>
                <p className="pb-5 text-muted">
                    Time to move on. This item is deleted or you do not have access.
                    {/* TODO: Add some actual text/links here */}
                    {/* <br />
                    Here are some ideas where to go next: */}
                </p>
            </>
        );
    } else if (import.meta.env.DEV && isZodError(error)) {
        return (
            <>
                <h1 className="font-size-heading">Validation Error</h1>
                <ZodErrorMessage error={error} />
            </>
        );
    }
    return <pre className="text-left whitespace-pre-wrap">{errorToString(error)}</pre>;
}

export function NotFoundPage(_props: NotFoundRouteProps) {
    return (
        <div
            className={cn(
                "size-full text-center flex text-center items-center justify-center bg-layout-2",
                C.ErrorHolder,
            )}
        >
            <div className="p-4">
                <MaskIcon className="jp-cactus-icon size-5rem color-grey-4" />
                <h1 className="text-muted font-size-heading pt-4 justify-center">This page is gone</h1>
                <p className="pb-5 text-muted">Time to move on. Here are some ideas where to go next:</p>
                <div className="flex justify-center gap-4">
                    <Link to="/" className="text-primary">
                        <b>Go to dashboard</b>
                    </Link>
                </div>
            </div>
        </div>
    );
}

interface ErrorFallbackProps {
    error: unknown;
}

export function ErrorFallback({ error }: ErrorFallbackProps) {
    let createLink;
    if (error instanceof ResourceNotFoundError) {
        const { type, objectName } = error;
        const linkProps = getResourceCreateLink(type);
        if (linkProps) {
            createLink = (
                <Link {...linkProps} className="text-primary">
                    <b>Create new {objectName}</b>
                </Link>
            );
        }
    }

    return (
        <ErrorBox>
            <ErrorMessage error={error} />
            <div className="flex flex-wrap flex-col justify-center gap-2">
                {createLink}

                <Link to="/" className="text-primary">
                    <b>Go to dashboard</b>
                </Link>
                {import.meta.env.DEV && <ResetConfigOverridesButton />}
            </div>
        </ErrorBox>
    );
}

export function WErrorBoundary(props: Omit<ReportingErrorBoundaryProps, "FallbackComponent">) {
    return <ReportingErrorBoundary FallbackComponent={ErrorFallback} {...props} />;
}

/** In case a debug override causes the entire page to error out, offer a backup option */
function ResetConfigOverridesButton() {
    const resetConfig = useSetAtom(resetConfigOverridesAtom);
    return <WButton icon="i-lucide:bug" action={resetConfig} label={resetConfigOverridesTitle} />;
}
