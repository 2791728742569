import { z } from "zod";
import { jsonBody, successResponse, tagPaths } from "../util.ts";
import { Location } from "./locations.ts";
import * as params from "./params.ts";

const LocationBody = Location.pick({
    display_name: true,
    description: true,
    is_preferred: true,
    is_published: true,
    order_nr: true,
    country_code: true,
    create_resource_disabled: true,
}).openapi({ ref: "LocationBody" });

export const locationAdminPaths = tagPaths("admin_locations")({
    "/config/locations/{location}": {
        put: {
            summary: "Update location",
            parameters: [params.location],
            requestBody: jsonBody(LocationBody),
            responses: {
                ...successResponse(z.boolean()),
            },
        },
        delete: {
            summary: "Delete location",
            parameters: [params.location],
            responses: {
                ...successResponse(z.boolean()),
            },
        },
    },
});
