import { globalOnError } from "./globalOnError.ts";

/** Run a block while catching and logging all errors. Useful for running event handlers / listeners. */
export function catching<T>(fn: () => T): T | undefined {
    try {
        return fn();
    } catch (e) {
        console.error("Uncaught error in handler", e);
        globalOnError(e);
        return undefined;
    }
}
