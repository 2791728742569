import { KeyNotFoundError, mustGetProperty } from "@warrenio/utils/collections/getOwnProperty";
import { useRef } from "react";
import { ResourceNotFoundError } from "../../modules/api/resourceTypeException.ts";

// For unused lint
void mustGetProperty;

/**
 * Custom hook that handles retrieving a deletable resource.
 *
 * @param get - A function that retrieves the resource. Should be {@link getResourceById} or {@link mustGetProperty} (that throws an appropriate exception type).
 * @param deleteMutation - The delete mutation or `null` if the delete mutation is not available.
 * @returns - The resource or `undefined` if the delete mutation is successful or the resource gets deleted after it has been loaded.
 */
export function useDeletableResource<T>(get: () => T, deleteMutation: { isSuccess: boolean } | null): T | undefined {
    const hasLoaded = useRef(false);

    if (deleteMutation?.isSuccess) {
        return undefined;
    }

    if (hasLoaded.current) {
        // After the initial load, catch any "Not Found" errors that indicate the resource has been deleted.
        try {
            return get();
        } catch (e) {
            if (e instanceof ResourceNotFoundError || e instanceof KeyNotFoundError) {
                return undefined;
            }
            throw e;
        }
    }

    // Otherwise, just call the getter and do not catch any errors.
    hasLoaded.current = true;
    return get();
}
