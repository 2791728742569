import { createLazyFileRoute, Navigate } from "@tanstack/react-router";
import { findById } from "@warrenio/utils/collections/findById";
import { useAtomValue } from "jotai/react";
import { isMobileDevice } from "../../../modules/main/mobile/isMobile.ts";
import { sectionsAtom } from "../../../modules/main/sidebar/Sidebar.store.tsx";

export const Route = createLazyFileRoute("/_main/section/$name")({
    component: Component,
});

function Component() {
    const { name } = Route.useParams();
    const sections = useAtomValue(sectionsAtom);

    if (!isMobileDevice) {
        // Do not load this on the desktop to prevent "double sidebar" effect
        return <Navigate to="/" />;
    }

    // TODO: allow having a different component in sidebar vs. mobile?
    const { component: Component } = findById(sections, name);
    return Component ? <Component /> : <div className="text-error">Section "{name}" not defined</div>;
}
