import C from "../AdminLayout.module.css";

import { AccordionItem } from "@szhsin/react-accordion";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useEffect, useState } from "react";
import { Link } from "react-aria-components";
import invariant from "tiny-invariant";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useApiClient } from "../../modules/api/apiClient.store.ts";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { AInput, ATextArea } from "../form/Fields.tsx";
import { getFullPath, type ValueEditorProps } from "./ConfigEditor.utils";

export function SingleValue({
    name,
    description,
    wikiLink,
    inputType,
    path,
    inputRows = 10,
    validationFun,
    required,
    placeholder,
}: ValueEditorProps) {
    const api = useApiClient();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [value, setValue] = useState("");

    async function getValue() {
        const { data } = await api.GET("/configuration/admin/value", {
            params: { query: { path } },
        });

        invariant(typeof data === "string" || data == null, "Expected string value");

        setValue(data ?? "");
        setLoading(false);
    }

    // initial data loading
    useEffect(() => {
        discardPromise(getValue());
    }, []);

    async function saveConfig() {
        setSaving(true);
        try {
            setErrorMessage("");
            if (validationFun !== undefined) {
                const validationResult = validationFun(value);
                if (!validationResult.success) {
                    setErrorMessage(validationResult.errorMsg ? validationResult.errorMsg : "Invalid value.");
                    return;
                }
            }

            if (required && value === "") {
                setErrorMessage("Fill out this field");
                return;
            }

            await api.POST("/configuration/admin/value", {
                body: { path: path, data: value },
                ...jsonEncodedBody,
            });
        } finally {
            setSaving(false);
        }
    }

    return (
        <AccordionItem
            className={C.AccordionItem}
            header={
                <>
                    <div>
                        <div className="font-size-subtitle">
                            {name}
                            {required && " *"}
                        </div>
                        <p className="color-muted font-size-small">
                            {getFullPath(path, false)}{" "}
                            {wikiLink && (
                                <Link href={wikiLink} target="_blank">
                                    WIKI
                                </Link>
                            )}
                        </p>
                    </div>

                    {value === "" && required && (
                        <MaskIcon className="ml-auto jp-warning-icon color-primary size-1.375rem" />
                    )}
                </>
            }
        >
            <div className={C.AccordionContent}>
                {description && <div className="pb-1">{description}</div>}

                {loading && <p>Loading..</p>}
                {saving && <p>Saving..</p>}

                {!loading && (
                    <div>
                        {inputType === "textarea" && (
                            <div>
                                <WTextField
                                    headless
                                    aria-label={name}
                                    isDisabled={saving}
                                    isRequired={required}
                                    value={value}
                                    onChange={(value) => setValue(value)}
                                >
                                    <ATextArea rows={inputRows} placeholder={placeholder} />
                                </WTextField>
                            </div>
                        )}

                        {inputType === "input" && (
                            <div>
                                <WTextField
                                    headless
                                    aria-label={name}
                                    isDisabled={saving}
                                    isRequired={required}
                                    value={value}
                                    onChange={(value) => setValue(value)}
                                >
                                    <AInput placeholder={placeholder} />
                                </WTextField>
                            </div>
                        )}
                    </div>
                )}

                {errorMessage && <p className="jp-message error">{errorMessage}</p>}

                <div className="pt-3">
                    <WModal
                        button={
                            <WModalButton
                                isDisabled={saving || loading}
                                label="Save"
                                color="primary"
                                size="md"
                                variant="basic"
                            />
                        }
                    >
                        <WModalContent
                            title="Are You Sure?"
                            label="Save"
                            isActionDisabled={saving || loading}
                            modalAction={saveConfig}
                        >
                            Are You Sure?
                        </WModalContent>
                    </WModal>
                </div>
            </div>
        </AccordionItem>
    );
}
