import { z } from "zod";
import { jsonBody, successResponse, tagPaths } from "../util.ts";

const ConfigSection = z
    .object({
        section_key: z.string(),
        data: z.unknown(),
    })
    .openapi({ ref: "ConfigSection" });

const ConfigGetResponse = z
    .union([
        z.string(),
        // When `recurse` is `true`
        z.array(ConfigSection),
        z.null(),
    ])
    .openapi({ ref: "ConfigGetResponse" });

const ConfigSaveBody = z
    .object({
        path: z.string(),
        data: z.string(),
    })
    .openapi({ ref: "ConfigSaveBody" });

export const configAdminPaths = tagPaths("admin_settings")({
    "/configuration/admin/value": {
        get: {
            summary: "Get Configuration",
            parameters: [
                { name: "path", in: "query" },
                { name: "recurse", in: "query" },
            ],
            responses: {
                ...successResponse(ConfigGetResponse),
            },
        },
        post: {
            summary: "Save Configuration",
            requestBody: jsonBody(ConfigSaveBody),
            responses: {
                ...successResponse(z.boolean()),
            },
        },
        delete: {
            summary: "Delete Configuration",
            parameters: [{ name: "path", in: "query" }],
            responses: {
                ...successResponse(z.boolean()),
            },
        },
    },
});
