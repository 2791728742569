import type { TooltipComponentFormatterCallback, TooltipComponentFormatterCallbackParams } from "echarts";
import type { BarSeriesOption } from "echarts/charts";
import { useState } from "react";
import { entries, groupBy, unique } from "remeda";
import invariant from "tiny-invariant";
import { BadgeDot } from "../../components/Badge.tsx";
import { Separator } from "../../components/Separator.tsx";
import { useStandardSuspenseQuery } from "../../modules/api/useStandardMutation.ts";
import { getPreferredLocation, type LocationWithEnabled } from "../../modules/location/location.ts";
import { usePricingLocations } from "../../modules/location/query.ts";
import { useThemeVar } from "../../modules/theme/useTheme.ts";
import { usePortal } from "../../utils/react/usePortal.tsx";
import { AdminLocationSelect } from "../AdminLocationSelect.tsx";
import { servicesStatisticsQuery } from "../services/servicesQuery.ts";
import { SmallDonut } from "./DonutChart.tsx";
import { LightBlock } from "./LightBlock.tsx";
import { WBarChart, type WBarChartOptions } from "./WBarChart.tsx";

function ManagedServicesSectionContent() {
    const status = ["active", "deleted"];
    const services = useStandardSuspenseQuery(servicesStatisticsQuery, { status });
    const locations = usePricingLocations();
    const location = getPreferredLocation(locations);
    const [selectedLocation, setSelectedLocation] = useState<LocationWithEnabled>(location);

    const tooltipPortal = usePortal();

    const colorText = useThemeVar("color-text");

    const colorCharts = [
        undefined,
        useThemeVar("color-chart-1"),
        useThemeVar("color-chart-2"),
        useThemeVar("color-chart-3"),
    ];

    const serviceTypes = services.data.length ? unique(services.data.map((item) => item.service)) : [];

    const locationServices = services.data.filter(
        (item) => !selectedLocation?.slug || item.properties?.location === selectedLocation.slug,
    );

    const total = locationServices.filter((item) => !item.is_deleted);
    const groupedTotal = groupBy([...total], (l) => l.service);

    const serviceItems = entries(groupedTotal).map(([_type, value], index) => {
        return {
            value: (value.length / total.length) * 100,
            color: `var(--color-chart-${index + 1})`,
        };
    });

    const tooltip = (
        <div>
            {entries(groupedTotal).map(([type, value], index) => (
                <BadgeDot key={type} color={`color-chart-${index + 1}`}>
                    {type}: <b>{value.length}</b>
                </BadgeDot>
            ))}
        </div>
    );

    const groupedItems = groupBy([...locationServices], (l) => l.service);

    const tooltipFormatter: TooltipComponentFormatterCallback<TooltipComponentFormatterCallbackParams> = (params) => {
        invariant(!Array.isArray(params), "Expected single params");
        const { value } = params;
        invariant(typeof value === "number", "Expected number value");

        const content = (
            <div>
                {entries(groupedItems).map(([type, value], index) => {
                    const deleted = value.filter((item) => item.is_deleted);
                    return (
                        <BadgeDot key={index} color={`color-chart-${index + 1}`}>
                            {type}: <b>{value.length}</b> (deleted: {deleted.length})
                        </BadgeDot>
                    );
                })}
            </div>
        );

        // return renderToStaticMarkup(content);
        tooltipPortal.setContent(content);
        return tooltipPortal.element;
    };

    const seriesData = entries(groupedItems).map(([type, value], index): BarSeriesOption => {
        return {
            name: type,
            type: "bar",
            stack: "total",
            label: {
                show: true,
            },
            itemStyle: {
                color: colorCharts[index + 1],
            },
            labelLayout(params) {
                return {
                    fontSize: params.rect.height > 12 ? 11 : 0,
                };
            },
            tooltip: {
                formatter: tooltipFormatter,
            },
            data: [value.length],
        };
    });

    const options: WBarChartOptions = {
        tooltip: {
            trigger: "item",
            axisPointer: {
                type: "none",
            },
            borderWidth: 0,
            textStyle: {
                color: colorText,
            },
            extraCssText: "box-shadow: 0 8px 20px rgba(0 0 0 / 10%);",
        },
        grid: {
            top: "5px",
            left: "5px",
            right: "5px",
            bottom: "5px",
            containLabel: true,
        },
        xAxis: {
            show: false,
            type: "category",
        },
        yAxis: {
            type: "value",
        },
        series: seriesData,
    };

    return (
        <>
            {tooltipPortal.portalNode}

            <div className="flex justify-between gap-1rem pb-3">
                <div>
                    <h2 className="font-size-heading">Managed Services</h2>
                    <p>Distribution by service type</p>
                </div>

                <SmallDonut
                    marginLeft
                    title="Total number of Managed Services"
                    items={serviceItems}
                    tooltip={tooltip}
                    total={total.length}
                />
            </div>

            <Separator />

            <div className="flex justify-between pt-3 pb-4">
                <div className="flex gap-1rem font-size-small">
                    {serviceTypes.map((type, index) => (
                        <BadgeDot key={index} color={`color-chart-${index + 1}`}>
                            {type}
                        </BadgeDot>
                    ))}
                </div>

                <AdminLocationSelect onChange={(item) => setSelectedLocation(item)} />
            </div>

            <WBarChart options={options} />
        </>
    );
}

export function ManagedServicesSection() {
    return (
        <LightBlock>
            <ManagedServicesSectionContent />
        </LightBlock>
    );
}
