import type { QueryKey } from "@tanstack/react-query";
import { queryOptions } from "@tanstack/react-query";
import type { StatisticsResponse } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient.ts";
import { getResponseData } from "../../modules/api/apiClient.ts";
import { intensiveQueryOptions } from "../intensiveQueryOptions.ts";

export interface Params {
    status?: string[];
    deleted_cutoff_date?: string;
}

export function getQueryKey(params?: Params): QueryKey {
    return params == null
        ? ["user-resource/admin/service/packages"]
        : ["user-resource/admin/service/packages", params.status, params.deleted_cutoff_date];
}

export function servicesStatisticsQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        ...intensiveQueryOptions,
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<StatisticsResponse> => {
            return getResponseData(
                await apiClient.GET("/user-resource/admin/service/packages", {
                    signal,
                    params: { query: params },
                }),
            );
        },
    });
}
