import { ApolloClient, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { notNull } from "@warrenio/utils/notNull";
import { atom } from "jotai/vanilla";
import { z } from "zod";
import { apikeyAtom } from "../../modules/api/apikey.store.ts";
import { graphQlUriAtom } from "./graphQlUriAtom.tsx";

export const apolloClientAtom = atom((get) => {
    const graphqlUri = get(graphQlUriAtom);

    const admin_user_details_args = z.object({ id: z.string() });

    const cache = new InMemoryCache({
        typePolicies: {
            // NB: Nested fields without IDs need manual merge functions, otherwise updating the cache will cause unnecessary re-fetches
            // https://www.apollographql.com/docs/react/caching/cache-field-behavior#merging-non-normalized-objects
            // (also see comment below)
            User: {
                fields: {
                    profile_data: {
                        merge: true,
                    },
                },
            },
            BillingAccount: {
                fields: {
                    running_totals: {
                        merge: true,
                    },
                },
            },

            // Forward single-ID queries directly to the cache
            // https://www.apollographql.com/docs/react/caching/advanced-topics#cache-redirects
            Query: {
                fields: {
                    admin_user_details: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: "User",
                                // Validate `args` with zod
                                id: admin_user_details_args.parse(args).id,
                            });
                        },
                    },
                },
            },
        },
    });

    const apikey = notNull(get(apikeyAtom), "Need an API key to access the GraphQL API");

    // Enable batching since we can have a lot of queries on the same page (eg. a table with many rows)
    const link = new BatchHttpLink({
        uri: graphqlUri,
        headers: { apikey },
        batchMax: 50,
        batchInterval: 20, // milliseconds
    });

    return new ApolloClient({
        link,
        cache,
        defaultOptions: {
            // `watchQuery` is used by hooks (like `useQuery`)
            watchQuery: {
                // We want to be notified when queries are refetching due to cache invalidation so we can show loading UI
                notifyOnNetworkStatusChange: true,

                // By default, when related queries update some objects in the cache, any other queries that have those objects will be re-fetched.
                // This happens when eg. a table details view uses a query that overlaps with the list view query.
                // Disable this behavior to avoid too many re-fetches by using the "cache-first" fetch policy.
                // [Updating cache triggers queries when fetchPolicy: cache-and-network is used](https://github.com/apollographql/apollo-client/issues/6833)
                nextFetchPolicy(lastFetchPolicy) {
                    if (lastFetchPolicy === "cache-and-network" || lastFetchPolicy === "network-only") {
                        return "cache-first";
                    }
                    return lastFetchPolicy;
                },

                // Misc. tweaks
                refetchWritePolicy: "overwrite",
            },
        },
    });
});
