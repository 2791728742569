import type { ReactNode } from "react";
import { Slide, toast, type ToastOptions } from "react-toastify";
import type { Action } from "../../components/Action.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { WProgressTicker, type WProgressTickerProps } from "../../components/WProgressBar.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon";
import { cn } from "../../utils/classNames";
import { isStorybook } from "../../utils/environment.ts";
import { ApiError } from "../api/ApiError.ts";
import { notifyError } from "../error/errorReporting.tsx";
import { showWarn } from "../error/errorStream.ts";
import { errorMessageAsString } from "../error/errorToString.tsx";

export type ToastId = number | string;

/** Properties accessible later from the notification center */
export interface ToastifyData {
    icon?: string;
    /* Do not show a notification in the notification center (only toast) */
    noNotification?: boolean;
}

export type ToastType = "success" | "error" | "info" | "warning" | "progress";

/** Represents a toast notification. */
export interface Toast extends ToastifyData {
    /** The message content of the toast. Can be an arbitrary React component. */
    message: ReactNode;

    /** The action button of the toast. */
    button?: ReactNode;

    /** Unique identifier. */
    toastId?: ToastId;

    /** CSS class name for the icon */
    icon?: string;
    /** The severity level of the toast. */
    type: ToastType;

    /** The duration in milliseconds for the toast to automatically close. Set to `false` to disable auto close. */
    autoClose?: number | false;

    /** The progress value of the toast. A number between 0 and 1. */
    progress?: number;
    hideProgressBar?: boolean;
}

function renderToastMessage({ message, button }: Toast) {
    return (
        <div className="flex justify-between items-center content-center gap-0.25em">
            {message}
            {button}
        </div>
    );
}

/** Convert {@link Toast} properties into {@link ToastOptions} for Toastify. */
function normalizeToast({
    type,
    toastId,
    autoClose,
    progress,
    hideProgressBar = true,
    icon,
    ...rest
}: Toast): ToastOptions<ToastifyData> {
    // Disable auto-close for tests to capture all of the toasts in snapshots
    if (isStorybook) {
        autoClose ??= false;
    }

    return {
        toastId,
        type: type === "progress" ? "success" : type,
        className: type === "progress" ? "Toastify__toast--progress" : undefined,

        autoClose,
        progress,
        // NB: Small hack to make toasts animate from the top (to match notification button position)
        transition: (props) => <Slide {...props} position="top-center" />,
        hideProgressBar,
        icon: icon ? <MaskIcon className={cn(icon, "size-1rem")} /> : undefined,

        data: { icon, ...rest },
    };
}

export function raiseToast(options: Toast): ToastId {
    return toast(renderToastMessage(options), normalizeToast(options));
}

export function updateToast(id: ToastId, options: Toast) {
    toast.update(id, normalizeToast(options));
}

export function dismissToast(id: ToastId) {
    if (!toast.isActive(id)) {
        showWarn("Tried to dismiss inactive toast id=%o", id);
    }
    // NB: Work around react-toastify bug where `dismiss` will not remove notifications from `useNotificationCenter()`
    toast.update(id, { data: { noNotification: true } });
    toast.dismiss(id);
}

export function clearAllToasts() {
    toast.dismiss();
}

//#region Toast error utilities
export function errorToToastMessage(err: unknown): ReactNode {
    if (err instanceof ApiError) {
        const message = err.errorMessage;
        const { correlationId } = err.meta;
        return (
            <>
                {message} {correlationId != null ? <span className="text-muted">[{correlationId}]</span> : null}
            </>
        );
    }

    return errorMessageAsString(err);
}

export function errorToToast(err: unknown, prefix: ReactNode): Toast {
    return {
        message: (
            <ClipBoardTooltip isHtml={true}>
                {prefix}: {errorToToastMessage(err)}
            </ClipBoardTooltip>
        ),
        type: "error",
        icon: "jp-wrong-icon",
    };
}

//#endregion

//#region Request status toasts
export interface RequestToastOptions extends Omit<Toast, "message" | "type" | "buttonLabel" | "buttonLink"> {
    /** Message to show on success. */
    success: ReactNode;
    successType?: ToastType;

    /** Message to show on error. */
    error: ReactNode;

    /** Whether to suppress/hide the toast on success. */
    silent?: boolean;

    /** "View" button link to display on success. */
    viewLink?: Action;
}

export function makeRequestToast(
    err: Error | null,
    { success, error, successType = "info", viewLink, noNotification, ...options }: Omit<RequestToastOptions, "silent">,
): Toast {
    const messageOptions = err
        ? errorToToast(err, error)
        : ({
              message: success,
              type: successType,
              button: !!viewLink && (
                  <WButton color="primary" variant="ghost" size="bar" action={viewLink}>
                      View
                  </WButton>
              ),
              // NB: Only success toasts respect `noNotification`, errors will always show in the notification center
              noNotification,
          } satisfies Partial<Toast>);
    return {
        ...messageOptions,
        ...options,
    };
}

export function raiseRequestToast(err: Error | null, { silent, ...options }: RequestToastOptions): ToastId | undefined {
    return silent && !err ? undefined : raiseToast(makeRequestToast(err, options));
}

//#endregion

//#region Progress toasts
export interface ProgressToastOptions extends Omit<Toast, "message" | "type"> {
    label?: WProgressTickerProps["label"];
    lifeTime: WProgressTickerProps["lifeTime"];
}

export function makeProgressToast({ label, lifeTime, ...options }: ProgressToastOptions): Toast {
    return {
        message: <WProgressTicker label={label} startTime={performance.now()} lifeTime={lifeTime} />,
        type: "progress",
        autoClose: lifeTime,
        ...options,
    };
}

export function raiseProgressToast(options: ProgressToastOptions): ToastId | undefined {
    return raiseToast(makeProgressToast(options));
}

//#endregion

//#region Error toasts
interface RetryToastOptions extends Omit<Toast, "type" | "button"> {
    retryLink: Action;
}

export function raiseRetryErrorToast(options: RetryToastOptions) {
    return raiseToast(makeRetryErrorToast(options));
}

export function makeRetryErrorToast({ retryLink, ...toastOptions }: RetryToastOptions): Toast {
    return {
        type: "error",
        autoClose: false,
        button: (
            <WButton color="primary" variant="ghost" size="bar" action={retryLink}>
                Retry
            </WButton>
        ),
        ...toastOptions,
    };
}

/** Show a toast for an error that can not be displayed inline (eg. in a form) */
export function raiseBackgroundErrorToast(err: unknown, prefix: string, options?: Partial<Toast>) {
    console.error("Background error: %s:", prefix, err);
    notifyError(err, { context: { message: prefix } });
    return raiseToast({
        ...errorToToast(err, prefix),
        ...options,
    });
}

//#endregion
