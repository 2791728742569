import { useQuery } from "@apollo/client";
import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { InvoiceStatus, InvoiceTypes } from "@warrenio/api-spec/spec.oats.gen";
import { InvoiceStatusEnum } from "@warrenio/api-spec/spec/billingEnums";
import { keys } from "remeda";
import { Badge, type BadgeProps } from "../../components/Badge.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { ReportStatus } from "../../modules/billing/ReportStatus.tsx";
import { BillingAccountBlock } from "../accounts/BillingAccountBlock.tsx";
import { DateViewer, Extra } from "../AdminTable.tsx";
import { gf, type GqlFieldsOf } from "../FieldConfig.tsx";
import {
    EnumComponentFilter,
    EnumFilter,
    ExactNumberFilter,
    NumberRangeFilter,
    UnixDateRangeFilter,
} from "../filters.tsx";
import { gql } from "../graphql.gen/gql.ts";
import { InvoiceOrderFields, OrderDirection, type GetInvoiceListQuery } from "../graphql.gen/graphql.ts";
import { extractData } from "../graphql/extractData.tsx";
import { GraphqlTable } from "../GraphqlTable.tsx";
import { baIdField, currencyField, idField, numberField } from "../table_fields/standardFields.tsx";
import { whenNotNull } from "../table_fields/whenNotNull.tsx";
import { InvoiceDetailView } from "./InvoiceDetailView.tsx";
import { InvoiceToolbar } from "./InvoiceToolbar.tsx";

const GET_INVOICES = gql(/* GraphQL */ `
    query GetInvoiceList(
        $limit: Int
        $page: Int
        $orderField: InvoiceOrderFields
        $orderDir: OrderDirection
        $search: String
        $filters: [InvoiceFilter!]
    ) {
        admin_invoice_list(
            limit: $limit
            page: $page
            orderField: $orderField
            orderDir: $orderDir
            search: $search
            filters: $filters
        ) {
            items {
                id
                padded_id
                # name
                period_start
                period_end
                created
                status
                billing_account_id
                # account_snapshot
                due_date
                vat_percentage
                discount_percentage
                type
                precalc_subtotal
                precalc_discount_amount
                precalc_credit
                precalc_total_before_tax
                precalc_vat_tax
                precalc_total
                billing_account {
                    allow_debt
                    ...BillingAccountBlock
                }
                totals {
                    subtotal_resource_usage
                    subtotal
                    discount_amount
                    credit
                    total_before_tax
                    vat_tax
                    total
                }
            }
            paging {
                total
            }
        }
    }
`);

export type GQInvoiceItem = NonNullable<GetInvoiceListQuery["admin_invoice_list"]["items"]>[number];

function WithPercent({ value, percent }: { value: number; percent: number | null | undefined }) {
    return <Extra value={<CurrencyBalance value={value} />}>{!!percent && `${percent}%`}</Extra>;
}

const reportTypes: Record<InvoiceTypes, { label: string; color: BadgeProps["color"] }> = {
    cloud_services: { label: "Cloud Services", color: "info" },
    credit_topup: { label: "Credit Topup", color: "warning" },
    credit_note: { label: "Credit Note", color: "success" },
};

function ReportType({ value }: { value: InvoiceTypes }) {
    const type = reportTypes[value];
    return <Badge color={type.color}>{type.label}</Badge>;
}

const userFields: GqlFieldsOf<GQInvoiceItem, InvoiceOrderFields> = [
    gf({
        ...idField,
        id: "id",
        title: "ID",
        get: (a) => a.id,
        order: InvoiceOrderFields.Id,
        filter: ExactNumberFilter,
        hidden: true,
    }),
    gf({
        id: "report_id",
        title: "Report ID",
        get: (a) => a.padded_id,
        order: InvoiceOrderFields.Id,
        filter: ExactNumberFilter,
        copyable: true,
    }),
    gf({
        ...baIdField,
        get: (a) => a.billing_account_id,
        order: InvoiceOrderFields.BillingAccountId,
        filter: ExactNumberFilter,
    }),
    gf({
        id: "billing_account",
        title: "Billing Account",
        get: (a) => a.billing_account,
        render: whenNotNull(BillingAccountBlock),
    }),
    gf({
        id: "created",
        title: "Created At",
        get: (a) => a.created && apiUnixToDate(a.created),
        order: InvoiceOrderFields.Created,
        filter: UnixDateRangeFilter,
    }),
    gf({
        id: "report_type",
        title: "Report Type",
        get: (a) => a.type as InvoiceTypes,
        render: ReportType,
        order: InvoiceOrderFields.Type,
        filter: () => <EnumComponentFilter values={keys(reportTypes)} component={ReportType} />,
    }),
    gf({
        id: "period_start",
        title: "Period Start",
        get: (a) => apiUnixToDate(a.period_start),
        order: InvoiceOrderFields.PeriodStart,
        filter: UnixDateRangeFilter,
    }),
    gf({
        id: "period_end",
        title: "Period End",
        get: (a) => apiUnixToDate(a.period_end),
        render: ({ value, item }) =>
            item.period_start === item.period_end ? (
                <div className="color-muted">
                    <DateViewer value={value} />
                </div>
            ) : (
                <DateViewer value={value} />
            ),
        order: InvoiceOrderFields.PeriodEnd,
        filter: UnixDateRangeFilter,
    }),
    gf({
        ...currencyField,
        id: "subtotal_resource_usage",
        title: "Resource Usage\nSubtotal",
        get: (a) => a.totals?.subtotal_resource_usage,
    }),
    gf({
        ...currencyField,
        id: "subtotal",
        title: "Subtotal",
        get: (a) => a.totals?.subtotal,
        order: InvoiceOrderFields.PrecalcSubtotal,
        filter: NumberRangeFilter,
    }),
    gf({
        ...currencyField,
        id: "discount",
        title: "Discount",
        get: (a) => a.precalc_discount_amount,
        render: ({ value, item }) => <WithPercent value={value} percent={item.discount_percentage} />,
        order: InvoiceOrderFields.PrecalcDiscountAmount,
        filter: NumberRangeFilter,
    }),
    gf({
        ...numberField,
        id: "discount_percentage",
        title: "Discount %",
        get: (a) => a.discount_percentage,
        render: ({ value }) => `${value}%`,
        order: InvoiceOrderFields.DiscountPercentage,
        filter: NumberRangeFilter,
        hidden: true,
    }),
    gf({
        ...currencyField,
        id: "credit",
        title: "Credit",
        get: (a) => a.precalc_credit,
        order: InvoiceOrderFields.PrecalcCredit,
        filter: NumberRangeFilter,
    }),
    gf({
        ...numberField,
        id: "vat",
        title: "VAT",
        get: (a) => a.precalc_vat_tax,
        render: ({ value, item }) => <WithPercent value={value} percent={item.vat_percentage} />,
        order: InvoiceOrderFields.PrecalcVatTax,
        filter: NumberRangeFilter,
    }),
    gf({
        ...numberField,
        id: "vat_percentage",
        title: "VAT %",
        get: (a) => a.vat_percentage,
        render: ({ value }) => `${value}%`,
        order: InvoiceOrderFields.VatPercentage,
        filter: NumberRangeFilter,
        hidden: true,
    }),
    gf({
        ...currencyField,
        id: "total",
        title: "Total",
        get: (a) => a.precalc_total,
        order: InvoiceOrderFields.PrecalcTotal,
        filter: NumberRangeFilter,
    }),
    gf({
        id: "status",
        title: "Status",
        get: (a) => a.status! as InvoiceStatus,
        render: ReportStatus,
        order: InvoiceOrderFields.Status,
        filter: () => (
            <EnumFilter
                items={Object.values(InvoiceStatusEnum).map((value) => ({
                    value: String(value),
                    label: <ReportStatus value={value} />,
                }))}
            />
        ),
    }),
];

export function ReportsTable() {
    return (
        <GraphqlTable
            title="Invoices & Reports"
            fields={userFields}
            defaults={{ orderField: InvoiceOrderFields.Created, orderDir: OrderDirection.Desc }}
            getId={(item) => item.id}
            useQuery={(variables) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const q = useQuery(GET_INVOICES, { variables });
                return extractData(q, (d) => d.admin_invoice_list);
            }}
            renderToolbar={(item) => <InvoiceToolbar item={item} />}
            renderDetail={(item) => <InvoiceDetailView item={item} />}
        />
    );
}
