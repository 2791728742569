import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { ClipBoardTooltip } from "../../../components/ClipBoardTooltip.tsx";
import { useStandardSuspenseQuery } from "../../../modules/api/useStandardMutation.ts";
import { OsInfo } from "../../../modules/metal/os/OsInfo.tsx";
import { MetalStatusBadge } from "../../../modules/metal/status/MetalStatus.tsx";
import { BillingAccountById } from "../../accounts/BillingAccountBlock.tsx";
import { AdminTable, Missing, Uuid } from "../../AdminTable.tsx";
import { AdminTitle } from "../../AdminTitle.tsx";
import { field, type FieldsOf } from "../../FieldConfig.tsx";
import { whenNotNull } from "../../table_fields/whenNotNull.tsx";
import { getQuery, type AdminMetalHistoryItemLoc, type Params } from "./machineHistoryQuery.ts";

const fields = {
    time: field("Time", (e) => apiDatetimeToDate(e.changed_at)),
    action: field("Action", (e) => e.action),
    status: field("Status", (e) => e.status, { render: MetalStatusBadge }),
    lease_uuid: field("Lease UUID", (e) => e.lease?.uuid, {
        render: ({ value }) =>
            value ? (
                <ClipBoardTooltip isHtml hideIcon>
                    <Uuid value={value} />
                </ClipBoardTooltip>
            ) : (
                <Missing />
            ),
    }),
    lease_name: field("Lease name", (e) => e.lease?.display_name),
    billing_account_id: field("Account", (e) => e.billing_account_id, { render: whenNotNull(BillingAccountById) }),
    user_id: field("User ID", (e) => e?.lease?.user_id),
    os: field("OS", (e) => e.os_id, { render: ({ value }) => <OsInfo os_id={value} /> }),
} satisfies FieldsOf<AdminMetalHistoryItemLoc>;

export function HistoryView({ params }: { params: Params }) {
    const { data } = useStandardSuspenseQuery(getQuery, params);
    return (
        <>
            <AdminTitle title="Machine history" />
            <AdminTable items={data} fields={fields} getId={(e) => e.state_id} />
        </>
    );
}
