import V from "./WFileUploadField.module.css";

import { type ReactNode, useState } from "react";
import { type FileWithSource, FileWithSourceInput } from "../../modules/billing/forms/FileWithSourceInput.tsx";
import { cn } from "../../utils/classNames.ts";
import { WButton } from "../button/WButton.tsx";
import { DataIcon } from "../icon/DataIcon.tsx";
import { FormField } from "./FormField.tsx";

// TODO: component to common and be used also in vm image form
export function WFileUploadField({
    value,
    label,
    description,
    onChange,
}: {
    value: string;
    label: ReactNode;
    description?: ReactNode;
    onChange: (value: string) => void;
}) {
    const [file, setFile] = useState<string | undefined>(value);
    const [errorMsg, setErrorMsg] = useState<string>("");

    function onFieldChange(value: FileWithSource | undefined) {
        if (value) {
            setFile(value.base64Source);
            onChange(value.base64Source);
        }
    }

    function clearField() {
        if (value) {
            setFile(undefined);
            onChange("");
        }
    }

    return (
        <FormField wide label={label} description={description} errorMessage={errorMsg}>
            {file ? (
                <div className="flex gap-0.5em items-center">
                    <div className={V.RadioIcon}>
                        <DataIcon data={file} mode="background" className="size-2.5rem" />
                    </div>

                    <div className={V.RadioIcon}>
                        <DataIcon data={file} mode="mask" className="color-muted size-2.5rem" />
                    </div>

                    <div className={cn(V.RadioIcon, V.selected)}>
                        <DataIcon data={file} mode="mask" className="size-2.5rem" />
                    </div>

                    <WButton
                        action={() => clearField()}
                        size="md"
                        color="default"
                        variant="ghost"
                        ariaLabel="Close"
                        icon="jp-icon-close"
                    />
                </div>
            ) : (
                <FileWithSourceInput
                    acceptedFileTypes={["image/svg"]}
                    maxFileSizeInMB={0.01}
                    onChange={onFieldChange}
                    onSetError={setErrorMsg}
                />
            )}
        </FormField>
    );
}
