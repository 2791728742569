// Import the generated route tree
import { createRouter } from "@tanstack/react-router";
import { addDevVars } from "./dev/initDev.tsx";
import { RouterErrorFallback } from "./modules/error/RouterErrorFallback.tsx";
import { basepath } from "./modules/main/urls.ts";
import { routeTree } from "./routeTree.gen";

export function createStandardRouter() {
    const router = createRouter({
        routeTree,
        basepath,

        defaultErrorComponent: RouterErrorFallback,
        // By default, this option delays the rendering of everything by 500ms (!!!). Disable it with prejudice.
        defaultPendingMinMs: 0,

        // Preload routes on hover
        defaultPreload: "intent",
    });
    addDevVars({ router });
    return router;
}

// Register the router instance for type safety
declare module "@tanstack/react-router" {
    interface Register {
        router: ReturnType<typeof createStandardRouter>;
    }
}
