import C from "../MainLayout.module.css";

import { clamp } from "@warrenio/utils/clamp";
import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import { notNull } from "@warrenio/utils/notNull";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useAtomValue } from "jotai/react";
import { Suspense, memo, useEffect, useMemo, useRef, type ReactNode } from "react";
import { useMove } from "react-aria";
import { SkeletonSidebar } from "../../../components/sidebar/StandardSidebar";
import { mcn, type BaseProps } from "../../../utils/baseProps";
import { cleanTimeout } from "../../../utils/cleanTimeout.ts";
import { isBuiltRelease } from "../../../utils/environment.ts";
import { WErrorBoundary } from "../../error/ErrorFallback.tsx";
import { getBreakPointAttribute, updateBreakpointAttribute } from "../breakPoints.ts";
import { currentSectionAtom, sectionsAtom, sidebarComponentAtom } from "./Sidebar.store";

export const Sidebar = memo(function Sidebar(props: BaseProps) {
    const Component = useAtomValue(sidebarComponentAtom);
    const section = useAtomValue(currentSectionAtom);

    // Keep the inner component memoized for efficiency (probably not necessary)
    const component = useMemo(() => {
        if (!Component) return null;

        // Add the standard wrapper components - Suspense and error boundary
        return (
            <WErrorBoundary tags="Sidebar" resetKeys={[section ?? ""]}>
                <Suspense fallback={<SkeletonSidebar />}>
                    <Component />
                </Suspense>
            </WErrorBoundary>
        );
    }, [section, Component]);

    usePreloadSidebar();

    return component ? (
        <aside {...mcn("flex flex-row items-stretch h-full", props)}>
            <Resizable settings={sidebarSettings}>{component}</Resizable>
        </aside>
    ) : null;
});

const PRELOAD_DELAY = 2000;

/** Pre-load the components for all sidebar sections */
function usePreloadSidebar() {
    const sections = useAtomValue(sectionsAtom);
    useEffect(() => {
        // No preload in development mode
        if (!isBuiltRelease) {
            return;
        }
        return cleanTimeout(() => {
            const preloadPromises = filterFalse(
                sections.map((s) => s.component).map((s) => s && "preload" in s && s.preload()),
            );
            return discardPromise(Promise.all(preloadPromises));
        }, PRELOAD_DELAY);
    }, [sections]);
}

const sidebarSettings: ResizerSettings = {
    minWidth: 250,
    maxWidth: 500,
    defaultWidth: 330,
    maxScreenRatio: 0.5,
    storageKey: "sidebarWidth",
    handleSide: "right",
};

export interface ResizerSettings {
    defaultWidth: number;
    minWidth: number;
    /** Maximum absolute width in pixels */
    maxWidth: number;
    /** Maximum width as a ratio of the screen width */
    maxScreenRatio: number;

    /** Which side the draggable handle is on */
    handleSide: "left" | "right";

    /** `localStorage` key to store the last used width */
    storageKey: string;
}

/** An element that is resizable via a "handle" */
export function Resizable({
    children,
    settings: { minWidth, maxWidth, defaultWidth, maxScreenRatio, storageKey, handleSide },
    ...props
}: { children: ReactNode; settings: ResizerSettings } & BaseProps) {
    function clampWidth(value: number) {
        const max = Math.max(maxWidth, window.innerWidth * maxScreenRatio);
        return clamp(value, minWidth, max);
    }

    const elRef = useRef<HTMLDivElement>(null);

    // TODO: Better way to use localStorage then just writing/reading string from it
    const initialWidth = useMemo(() => {
        const value = Number(localStorage.getItem(storageKey));
        if (value) {
            return clampWidth(value);
        }
        return defaultWidth;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const widthRef = useRef<number>(initialWidth);

    // Does not need to be state since it's not used for rendering
    const unclampedWidth = useRef(0);

    const directionSign = handleSide === "right" ? 1 : -1;

    const { moveProps } = useMove({
        onMoveStart(_event) {
            unclampedWidth.current = widthRef.current;
        },
        onMove: (event) => {
            unclampedWidth.current += event.deltaX * directionSign;
            const newWidth = clampWidth(Math.round(unclampedWidth.current));
            if (newWidth !== widthRef.current) {
                widthRef.current = newWidth;

                const el = notNull(elRef.current);
                el.style.width = `${newWidth}px`;

                // Also automatically add a breakpoint attribute to the element
                updateBreakpointAttribute(newWidth, el);
            }
        },
        onMoveEnd: (_event) => {
            localStorage.setItem(storageKey, String(widthRef.current));
        },
    });

    const content = (
        <div
            ref={elRef}
            {...mcn("h-full", props)}
            {...getBreakPointAttribute(widthRef.current)}
            style={{ width: `${widthRef.current}px` }}
        >
            {children}
        </div>
    );
    const resizer = <div className={C.resizer} {...moveProps} />;

    return handleSide === "right" ? (
        <>
            {content}
            {resizer}
        </>
    ) : (
        <>
            {resizer}
            {content}
        </>
    );
}
