import { addDevVars } from "../../dev/initDev.tsx";
import { MessageStream } from "../../utils/messageStream.ts";
import { notifyError, notifyErrorCustom } from "./errorReporting.tsx";

let id = 1;

export interface ErrorMessage {
    id: number;
    type: "warning" | "error";
    message: string;
    args: unknown[];
    createdAt: number;
}

export const showErrorsStream = new MessageStream<ErrorMessage>();

function findError(args: unknown[]): [Error | undefined, unknown[]] {
    const error = args.find((arg) => arg instanceof Error);
    return error //
        ? [error, args.filter((arg) => arg !== error)]
        : [undefined, args];
}

/** Log an error to the console and display it. */
export function showError(message: string, ...args: unknown[]) {
    // Check if we have an `Error` object in the arguments
    const [error, others] = findError(args);
    if (error) {
        notifyError(error, { context: { message, args: others } });
    } else {
        notifyErrorCustom(message, { context: { args } });
    }

    console.error(message, ...args);
    showErrorsStream.publish({ type: "error", message, args, id: id++, createdAt: Date.now() });
}

/** Log a warning to the console and display it. */
export function showWarn(message: string, ...args: unknown[]) {
    console.warn(message, ...args);
    showErrorsStream.publish({ type: "warning", message, args, id: id++, createdAt: Date.now() });
}

export function softInvariant(condition: boolean, message: string, ...args: unknown[]) {
    if (!import.meta.env.DEV) {
        return;
    }

    if (!condition) {
        showError(message, ...args);
    }
}

addDevVars({ showErrorsStream, showError, showWarn });
