import { isObjectWithKey } from "@warrenio/utils/isObject";
import type { ZodError } from "zod";

/** Custom type check function to prevent `import`-ing entire Zod (for bundle size) */

export function isZodError(error: unknown): error is ZodError {
    return (
        isObjectWithKey(error, "name") && error.name === "ZodError" && "issues" in error && Array.isArray(error.issues)
    );
}

export function ZodErrorMessage({ error }: { error: ZodError }) {
    return (
        <>
            <p>
                The data did not pass Zod spec validation:
                <br />
                <b>Solution: </b>Modify <code>api-spec</code> schema to match the data.
            </p>
            <p className="text-muted font-size-small">(see the console for the full data)</p>
            {error.errors.map(({ code, message, path, ...rest }, i) => (
                <table key={i} className="text-left b b-solid b-grey-4 my-2">
                    <tbody className="border-collapse children:children:px-1">
                        <tr>
                            <th>Message:</th>
                            <td>
                                <code>{message}</code>
                            </td>
                        </tr>
                        <tr>
                            <th>Where:</th>
                            <td>
                                <i>data</i>
                                <code>{path.map((e) => (typeof e === "string" ? e : `[${e}]`)).join(".")}</code>
                            </td>
                        </tr>
                        <tr>
                            <th>Code:</th>
                            <td>
                                <code>{code}</code>
                            </td>
                        </tr>
                        {Object.entries(rest).map(([key, value]) => (
                            <tr key={key}>
                                <th>
                                    <code>{key}:</code>
                                </th>
                                <td>
                                    <pre className="m-0">{JSON.stringify(value, null, 2)}</pre>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ))}
        </>
    );
}
