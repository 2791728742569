import FF from "../../components/forms/FormField.module.css";

import type { VirtualMachineStorage } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { useState } from "react";
import { last } from "remeda";
import { FormField } from "../../components/forms/FormField.tsx";
import { FormMaxWidthWrapper } from "../../components/forms/FormMaxWidthWrapper.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { VmStopModalHeaderBlock } from "../../modules/compute/ModalHeaderBlock.tsx";
import { VmSizeSelect } from "../../modules/compute/VmSizeSelect.tsx";
import { extractOsFields } from "../../modules/compute/os/os.ts";
import type { VirtualMachineLoc } from "../../modules/compute/vmQuery.ts";
import {
    getDiskPackages,
    getSizeComponentRanges,
    useSizeParams,
    type SizeValue,
} from "../../modules/compute/vmSizeSelectUtils.ts";
import type { GQVmItem } from "./VmsTable.tsx";
import { resizeDiskMutation } from "./vmQuery.ts";

const SIZE_DISABLED = 0;

export function DiskResizeModal({
    vm,
    disk,
}: {
    vm: GQVmItem;
    /** Selected disk to resize, or null if adding a new disk */
    disk: VirtualMachineStorage | null;
}) {
    const { status, _location, designated_pool_uuid, uuid, os_name, os_version } = vm;

    //region Hooks
    const resizeMutation = useStandardMutation(resizeDiskMutation);

    const sizeParams = useSizeParams();
    const ranges = getSizeComponentRanges(
        sizeParams,
        extractOsFields({ os_name: notNull(os_name), os_version: notNull(os_version) }),
        { minDiskSize: disk?.size ?? 0 },
    );
    const currentDiskSize = ranges.ssd[0];
    const [size, setSize] = useState<SizeValue>({
        disks: currentDiskSize,
        vcpu: SIZE_DISABLED,
        ram: SIZE_DISABLED,
        isCustom: true,
    });

    //endregion Hooks

    function handleChangeSize(newValue: SizeValue) {
        setSize(newValue);
    }

    async function resizeDisk() {
        await resizeMutation.mutateAsync({
            location: notNull(_location),
            body: {
                uuid,
                disk_uuid: disk!.uuid,
                size_gb: size.disks,
                check_vm_state: false,
            },
        });
    }

    return (
        <WModalContent
            title="Resize Disk"
            label="Resize"
            footerNotice={
                disk && status === "running" && disk.primary
                    ? "Warning: It is recommended to stop the VM before resizing boot disk."
                    : undefined
            }
            modalAction={resizeDisk}
        >
            <VmStopModalHeaderBlock vm={vm as VirtualMachineLoc} showStopButton={!!disk} />

            <FormMaxWidthWrapper>
                <FormField wide label="Size" block isRequired>
                    <div className={FF.FormFieldRadioGroup}>
                        <VmSizeSelect
                            vmData={{
                                location: notNull(_location),
                                designated_pool_uuid: notNull(designated_pool_uuid),
                                ...extractOsFields(vm as VirtualMachineLoc),
                            }}
                            ranges={ranges}
                            value={size}
                            onChange={handleChangeSize}
                            packages={getDiskPackages(extractOsFields(vm as VirtualMachineLoc), last(ranges.ssd)!)}
                            diskOnly
                            isDiskPrimary={disk ? disk.primary : false}
                        />
                    </div>
                </FormField>
            </FormMaxWidthWrapper>

            {disk && (
                <p>
                    Disk size can only be increased. This action is not reversible. Minimum disk size:{" "}
                    <b>{currentDiskSize} GB</b>.
                </p>
            )}
        </WModalContent>
    );
}
