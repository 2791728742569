import G from "../../components/Grid.module.css";
import V from "./VmImages.module.css";

import type { VmImage } from "@warrenio/api-spec/spec.oats.gen";
import { Suspense } from "react";

import { GridList, GridListItem, Link } from "react-aria-components";
import { Badge } from "../../components/Badge.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { TODO } from "../../dev/Todo.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { OsImageIcon } from "../../modules/compute/os/OsImageIcon.tsx";
import { allImagesQueryAtom } from "../../modules/compute/vmImagesQuery.ts";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { AddEditVmImageButton, EditHostPoolButton } from "./AddEditVmImageModal.tsx";
import { vmImageDeleteMutation, type VmImageType } from "./vmImagesQuery.ts";

function VmImageItem({ item }: { item: VmImage }) {
    const deleteMutation = useStandardMutation(vmImageDeleteMutation);
    const { os_name, display_name, versions, is_default } = item;
    const versionsString = versions.map((v) => v.display_name).join(", ");
    const isPublished = !!versions.filter((v) => v.published).length;
    const image_type: VmImageType = item?.is_app_catalog ? "app_catalog" : "plain_os";

    async function onDelete({ os_name }: VmImage) {
        await deleteMutation.mutateAsync({ os_name, image_type });
    }

    return (
        <GridListItem className={cn(V.ListItem, !isPublished && V.unPublished)} textValue={display_name}>
            <div className={V.Icon}>
                <OsImageIcon os_name={os_name} className="color-grey-5 size-0.875rem" />
            </div>
            <div className={V.Content}>
                {display_name}
                <div className="color-muted text-ellipsis font-size-small">{versionsString}</div>
            </div>

            <div className={V.Btn}>
                <div className="flex gap-0.5em justify-end">
                    <EditHostPoolButton
                        item={item}
                        button={<WModalButton label="Edit" inTable icon="jp-icon-edit" />}
                    />
                    <DeleteModal
                        isDisabled={is_default}
                        title="Delete VM Image"
                        inTable
                        modalAction={async () => await onDelete(item)}
                    >
                        Deleting VM Image "<b>{display_name}</b>" will permanently remove it from VM Images list.
                    </DeleteModal>
                </div>
            </div>

            {is_default && (
                <div className={V.holder}>
                    <WTooltip text="Default">
                        <Badge noDot iconOnly inList>
                            <MaskIcon className="jp-icon-checkmark size-0.75rem" />
                        </Badge>
                    </WTooltip>
                </div>
            )}
        </GridListItem>
    );
}

function VmImagesViewCustom() {
    const images = useSuspenseQueryAtom(allImagesQueryAtom);

    const osImages = images?.length
        ? images.filter((o) => !o.is_app_catalog).sort((a, b) => a.ui_position - b.ui_position)
        : [];
    const appImages = images?.length
        ? images.filter((o) => o.is_app_catalog).sort((a, b) => a.ui_position - b.ui_position)
        : [];

    return (
        <>
            <AdminTitle title="Images">
                <TODO>Ordering with dragging</TODO>
                <AddEditVmImageButton
                    button={<WModalButton label="New" color="primary" size="bar" variant="basic" />}
                />
            </AdminTitle>

            <div className="p-2 pb-0">
                <p className="color-muted">
                    Manage and publish VM images from UI. Make sure the images actually exist. Read more from{" "}
                    <Link
                        href="https://warrenio.atlassian.net/wiki/spaces/WARP/pages/299008011/VM+images"
                        target="_blank"
                    >
                        here
                    </Link>
                    .
                </p>
            </div>

            <div className={cn(G.WarrenGrid, V.Grid, "p2")}>
                <div className={V.List}>
                    <div className={V.Header}>Virtual Machine</div>

                    <GridList aria-label="VM images" selectionMode="single">
                        {osImages.map((item, index) => {
                            return <VmImageItem item={item} key={index} />;
                        })}
                    </GridList>
                </div>

                <div className={V.List}>
                    <div className={V.Header}>App Catalog</div>

                    <GridList aria-label="VM images" selectionMode="single">
                        {appImages.map((item, index) => {
                            return <VmImageItem item={item} key={index} />;
                        })}
                    </GridList>
                </div>
            </div>

            <div className="p-2">
                <AddEditVmImageButton
                    button={
                        <WModalButton
                            className="w-full"
                            label="Add VM Image"
                            color="primary"
                            size="lg"
                            variant="dashed"
                            icon="jp-icon-add"
                        />
                    }
                />
            </div>
        </>
    );
}

export function VmImagesView() {
    return (
        <Suspense fallback={<Loading key="loading" delay={0} icon="none" white />}>
            <VmImagesViewCustom />
        </Suspense>
    );
}
